import React, { useState, useMemo } from "react";
import styled from "styled-components";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import {
  Form,
  FormItem,
  FormItemUIOnly,
  FormItemLabel,
  Input,
  Button,
  FormItemError,
} from "atoms";
import CardDetails from "components/payment/CardDetails";
import { ElementsProvider } from "providers/StripeProvider";
import {
  SETUP_SUPPORTING_CREDIT_CARD_MUTATION,
  SUPPORTING_CREDIT_CARD_QUERY,
  UPDATE_SUPPORTING_CREDIT_CARD_MUTATION,
} from "apps/Dashboard/graphql";
import { useLazyQuery, useMutation } from "@apollo/client";
import { UPDATE_MEMBERSHIP_CREDIT_CARD_MUTATION } from "graphql-api/payment";

const SSetupCreditCard = styled.div``;
const SetupCreditCardForm = ({
  onTokenCreate,
  apiBusy,
  stripeApiError,
  onCardDetailsChange,
}) => {
  const [isTokenBusy, setIsTokenBusy] = useState(false);
  const [cardDetailsComplete, setCardDetailsComplete] = useState(false);
  const [cardDetailsError, setCardDetailsError] = useState(null);
  const [stripeCardIncompleteError, setStripeCardIncompleteError] =
    useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const isStripeBusy = useMemo(
    () => isTokenBusy || apiBusy,
    [apiBusy, isTokenBusy]
  );
  const handleCardDetailsChange = (event) => {
    onCardDetailsChange && onCardDetailsChange(event);
    setStripeCardIncompleteError(false);
    setCardDetailsComplete(event.complete);
    setCardDetailsError(event.error);
  };

  const handleFinish = async ({ nameOnCard }) => {
    try {
      if (!cardDetailsComplete) {
        !cardDetailsError && setStripeCardIncompleteError(true);
        return;
      }

      const cardElement = elements.getElement(CardElement);
      setIsTokenBusy(true);
      const {
        token: { id: tokenId },
      } = await stripe.createToken(cardElement, { name: nameOnCard });

      onTokenCreate(tokenId);
      setIsTokenBusy(false);
    } catch (e) {
      setIsTokenBusy(false);
    }
  };

  return (
    <SSetupCreditCard>
      <Form
        onFinish={handleFinish}
        initialValues={{
          nameOnCard: "",
        }}
      >
        <FormItem
          label="Name on Card"
          name="nameOnCard"
          rules={[{ required: true, message: "card name is required" }]}
        >
          <Input />
        </FormItem>
        <FormItemUIOnly>
          <FormItemLabel>Payment Info</FormItemLabel>
          <CardDetails onChange={handleCardDetailsChange} />
          {!!stripeApiError && <FormItemError>{stripeApiError}</FormItemError>}
          {stripeCardIncompleteError && (
            <FormItemError>All fields are required</FormItemError>
          )}
        </FormItemUIOnly>
        <Button htmlType="submit" block loading={isStripeBusy}>
          setup credit card
        </Button>
      </Form>
    </SSetupCreditCard>
  );
};

const _SetupCreditCard = (props: { onSetup: () => void }) => {
  const { onSetup, ...restProps } = props;
  const [stripeApiError, setStripeApiError] = useState(null);
  const [triggerSetupCreditCard, { loading }] = useMutation(
    SETUP_SUPPORTING_CREDIT_CARD_MUTATION,
    {
      awaitRefetchQueries: true,
      refetchQueries: [{ query: SUPPORTING_CREDIT_CARD_QUERY }],
      onCompleted({ setupSupportingCreditCard: { success, error } }) {
        if (success === true) {
          onSetup && onSetup();
        } else {
          setStripeApiError(error);
        }
      },
      onError(error) {
        console.log("on set card error", error);
        setStripeApiError(error);
      },
    }
  );

  const handleTokenCreate = async (token) => {
    await triggerSetupCreditCard({
      variables: {
        token,
      },
    });
  };

  const handleCardDetailsChange = (event) => {
    setStripeApiError(null);
  };

  return (
    <SetupCreditCardForm
      onTokenCreate={handleTokenCreate}
      stripeApiError={stripeApiError}
      onCardDetailsChange={handleCardDetailsChange}
      apiBusy={loading}
      {...restProps}
    />
  );
};

const UpdateCreditCardForm = ({
  onUpdateTokenCreate,
  onCancel,
  apiBusy,
  stripeApiError,
  onUpdateCardDetailsChange,
}) => {
  const [isTokenBusy, setIsTokenBusy] = useState(false);
  const [cardDetailsComplete, setCardDetailsComplete] = useState(false);
  const [cardDetailsError, setCardDetailsError] = useState(null);
  const [stripeCardIncompleteError, setStripeCardIncompleteError] =
    useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const [form] = Form.useForm();

  const isStripeBusy = useMemo(
    () => isTokenBusy || apiBusy,
    [apiBusy, isTokenBusy]
  );

  const handleCardDetailsChange = (event) => {
    onUpdateCardDetailsChange && onUpdateCardDetailsChange(event);
    setStripeCardIncompleteError(false);
    setCardDetailsComplete(event.complete);
    setCardDetailsError(event.error);
  };

  const handleUpdateFinish = async ({ nameOnCard }) => {
    try {
      if (!cardDetailsComplete) {
        !cardDetailsError && setStripeCardIncompleteError(true);
        return;
      }

      const cardElement = elements.getElement(CardElement);
      setIsTokenBusy(true);

      const {
        token: { id: tokenId },
      } = await stripe.createToken(cardElement, { name: nameOnCard });

      onUpdateTokenCreate(tokenId);
      setIsTokenBusy(false);
    } catch (e) {
      setIsTokenBusy(false);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <SSetupCreditCard>
      <Form
        layout="vertical"
        requiredMark={false}
        onFinish={handleUpdateFinish}
        form={form}
        initialValues={{
          nameOnCard: "",
        }}
      >
        <FormItem
          label="Name on Card"
          name="nameOnCard"
          rules={[{ required: true, message: "card name is required" }]}
        >
          <Input />
        </FormItem>
        <FormItemUIOnly>
          <FormItemLabel>Card Information</FormItemLabel>
          <CardDetails onChange={handleCardDetailsChange} />
          {!!stripeApiError && <FormItemError>{stripeApiError}</FormItemError>}
          {stripeCardIncompleteError && (
            <FormItemError>All fields are required</FormItemError>
          )}
        </FormItemUIOnly>
        <Button
          htmlType="submit"
          loading={isStripeBusy}
          block
          style={{ marginTop: "15px", marginBottom: "10px" }}
        >
          UPDATE CREDIT CARD
        </Button>
        <Button type="link" block onClick={handleCancel}>
          CANCEL
        </Button>
      </Form>
    </SSetupCreditCard>
  );
};

const _UpdateCreditCard = ({ onSetup, onClose }) => {
  const [stripeApiError, setStripeApiError] = useState(null);
  const [triggerCreditCardQuery, {}] = useLazyQuery(
    SUPPORTING_CREDIT_CARD_QUERY,
    {
      fetchPolicy: "network-only",
      onCompleted() {
        onSetup && onSetup();
      },
    }
  );

  const [triggerUpdateCreditCard, { error: mutationError, loading }] =
    useMutation(UPDATE_SUPPORTING_CREDIT_CARD_MUTATION, {
      onCompleted({ updateSupportingCreditCard: { success, error } }) {
        if (success === true) {
          triggerCreditCardQuery();
        } else {
          setStripeApiError(error);
        }
      },
      onError(error) {
        console.log("on update card error", error);
        setStripeApiError(error);
      },
    });

  const handleUpdateTokenCreate = async (token) => {
    await triggerUpdateCreditCard({
      variables: {
        token,
      },
    });
  };

  const handleUpdateCardDetailsChange = (event) => {
    setStripeApiError(null);
  };

  const handleCancel = () => {
    onClose && onClose();
  };

  return (
    <UpdateCreditCardForm
      onUpdateTokenCreate={handleUpdateTokenCreate}
      apiBusy={loading}
      onCancel={handleCancel}
      stripeApiError={stripeApiError}
      onUpdateCardDetailsChange={handleUpdateCardDetailsChange}
    />
  );
};

const _UpdateMembershipCard = ({ onSetup, onClose, membershipId }) => {
  const [stripeApiError, setStripeApiError] = useState(null);

  const [triggerUpdateCreditCard, { error: mutationError, loading }] =
    useMutation(UPDATE_MEMBERSHIP_CREDIT_CARD_MUTATION, {
      onCompleted({ updateMembershipCreditCard: { success, error } }) {
        if (success === true) {
          onSetup && onSetup();
        } else {
          setStripeApiError(error);
        }
      },
      onError(error) {
        console.log("on update card error", error);
        setStripeApiError(error);
      },
    });

  const handleUpdateTokenCreate = async (token) => {
    await triggerUpdateCreditCard({
      variables: {
        token,
        membershipId
      },
    });
  };

  const handleUpdateCardDetailsChange = (event) => {
    setStripeApiError(null);
  };

  const handleCancel = () => {
    onClose && onClose();
  };

  return (
    <UpdateCreditCardForm
      onUpdateTokenCreate={handleUpdateTokenCreate}
      apiBusy={loading}
      onCancel={handleCancel}
      stripeApiError={stripeApiError}
      onUpdateCardDetailsChange={handleUpdateCardDetailsChange}
    />
  );
};

const withElementProvider = (Component) => {
  return (props) => (
    <ElementsProvider>
      <Component {...props} />
    </ElementsProvider>
  );
};

export const SetupCreditCard = withElementProvider(_SetupCreditCard);
export const UpdateCreditCard = withElementProvider(_UpdateCreditCard);
export const UpdateMembershipCard = withElementProvider(_UpdateMembershipCard);