import React, { useMemo, useState } from "react";
import styled from "styled-components";

import { TwitterLink, InstagramLink } from "molecules";
import { Avatar, Row, Col, Popover, A, Modal } from "atoms";
import UserProfile from "./UserProfile";
import { FaSHeart } from "icons";

const S = {};

const LikedContainer = styled.div`
  padding: 8px 16px;
  background-color: ${(props) =>
    props.liked ? "var(--color-primary)" : "#dadada"};
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: var(--color-primary);
  }
`;

export const MessageLikesHeader = ({ liked, likes, onToggle }) => {
  return (
    <Row align="middle">
      <Col flex="1 0 0">
        <S.LikesCount>{likes} likes</S.LikesCount>
      </Col>
    </Row>
  );
};

S.MessageLikes = styled.div``;

S.LikesCount = styled.div`
  font-size: 20px;
  color: black;
  ${(props) => props.center && `text-align: center;`}
`;
S.LikeList = styled.div``;
S.ItemContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
  :last-child {
    margin-bottom: 0;
  }
`;
S.LikeItem = styled.div`
  cursor: pointer;
`;
S.ItemName = styled(A)`
  display: block;
`;

export const MessageLikesContainer = styled.div`
  padding: 20px 20px;
  background-color: #f4f2f5;
  height: 100%;
`;

const MessageLikes = ({
  message,
  center,
  onSelectUser,
  disablePopup = false,
}) => {
  const { likesBreakdown: likes } = message;
  const [profileUser, setProfileUser] = useState(null);
  const userProfileModalVisible = useMemo(() => !!profileUser, [profileUser]);
  const hideUserProfile = () => {
    setProfileUser(null);
  };
  const handleSelect = (user) => {
    onSelectUser(user)
    setProfileUser(user)
  }
  return (
    <S.MessageLikes>
      <S.LikeList>
        {likes.map((user, i) => (
          <S.ItemContainer key={i}>
            <S.LikeItem onClick={() => handleSelect(user)}>
              <Row gutter={20} singleRow align={"middle"}>
                <Col>
                  <Popover
                    placement={"top"}
                    trigger={["click"]}
                    content={<UserProfile user={user}/>}
                    simple
                  >
                    <Avatar src={user?.avatarUrl} width={"40px"} />
                  </Popover>
                </Col>
                <Col>
                  <Popover
                    placement={"top"}
                    trigger={["click"]}
                    content={<UserProfile user={user}/>}
                    simple
                  >
                    <S.ItemName>{user.displayName}</S.ItemName>
                  </Popover>
                </Col>
              </Row>
            </S.LikeItem>
          </S.ItemContainer>
        ))}
      </S.LikeList>
      {!disablePopup && (
        <Modal
          open={userProfileModalVisible}
          onCancel={() => hideUserProfile()}
          closable
          width={300}
          simple
          style={{ top: 170 }}
        >
          <UserProfile user={profileUser} />
        </Modal>
      )}
    </S.MessageLikes>
  );
};

MessageLikes.defaultProps = {
  center: false,
};

export default MessageLikes;
