import { gql } from "@apollo/client";

export const TierFields = gql`
  fragment tierFields on Tier {
    id
    active
    kind
    amountCents
  }
`;
