import styled from 'styled-components';
import { Form } from 'antd';

const PodForm = styled(Form)`
    ${props => props.css}
`;

PodForm.defaultProps = {
    layout: "vertical",
    requiredMark: false
};

export default PodForm;
