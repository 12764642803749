import { useAnimated } from 'hooks';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { colors } from 'styles';

const BarList = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${props => props.height || 50}px;
`;

const BarItem = styled.div`
    box-sizing: content-box;
    flex: 0 0 10px;
    padding: 0 2px;
    :last-child {
        margin-right: 0;
    }
`;

const ItemSize = styled.div`
    background-color: ${props => props.color};
    height: ${props => props.height}px;
    transition: all .1s ease-out;
    border-radius: 5px;
    max-height: 100%;
`;

const LevelVolumeVisualization = ({
    dataArray,
    height,
    amplitude
}) => {

    const computedVolume = useMemo(() => {
        const len = dataArray.length;
        let maxSize = 0;
        for (let i = 0; i < len; i++) {
            maxSize = Math.max(maxSize, dataArray[i]);
        }
        return maxSize;
    }, [dataArray]);
    const aniVolume = useAnimated(computedVolume);
    const level = useMemo(() => {
        if (aniVolume < 3) {
            return 0;
        }
        if (aniVolume < amplitude / 5) {
            return 1;
        }
        if (aniVolume < amplitude * 2 / 5) {
            return 2;
        }
        if (aniVolume < amplitude * 3 / 5) {
            return 3;
        }
        if (aniVolume < amplitude * 4 / 5) {
            return 4;
        }
        return 5;
    }, [aniVolume]);

    const levelBars = [
        {
            color: colors.green,
            height: height
        },
        {
            color: colors.green,
            height: Math.round(height * 0.8)
        },
        {
            color: colors.yello,
            height: Math.round(height * 0.6)
        },
        {
            color: colors.yello,
            height: Math.round(height * 0.5)
        },
        {
            color: colors.primary,
            height: Math.round(height * 0.38)
        }
    ]

    const graphBars = useMemo(() => {
        const _graphBars = [];
        for (let i = 0; i < level; i++) {
            _graphBars.push(levelBars[i]);
            _graphBars.unshift(levelBars[i]);
        }
        return _graphBars;
    }, [level])

    return (
        <BarList height={height}>
            {
                graphBars.map((item, i) => (
                    <BarItem key={i}>
                        <ItemSize height={item.height} color={item.color}/>
                    </BarItem>
                ))
            }
        </BarList>
    );
};

LevelVolumeVisualization.defaultProps = {
    height: 50,
    amplitude: 300
}

export default LevelVolumeVisualization;
