import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import { RangeSlider } from "atoms";
import { hasTwoDigit } from "utils/Datetime";

import { PLAYING, PAUSED, INITIAL, IDLE } from "libraries/Audio/const";

const StyledAudioPlayer = styled.div`
  ${(props) =>
    props.singleRow &&
    `
        display: flex;
        align-items: center;
        gap: 10px;
    `}
`;

const PlaySeekContainer = styled.div`
  flex: 1 0 100px;
`;

const AudioDuration = styled.div`
  ${(props) =>
    props.singleRow &&
    `
        width: auto;
        text-align: right;
    `}
`;

const AudioPlayer = (
  {
    src,
    duration,
    onReady,
    onPlay,
    onPause,
    onEnded,
    onChangeStatus,
    singleRow,
    showDuration = true,
    ...props
  }) => {
  const [status, setStatus] = useState(INITIAL);
  const audio = useMemo(() => new Audio(src), [src]);
  const tmm = useMemo(() => hasTwoDigit(Math.floor(duration / 60)), [duration]);
  const tss = useMemo(() => hasTwoDigit(duration % 60), [duration]);
  const [currentTime, setCurrentTime] = useState(0);
  const mm = useMemo(() => {
    const currentSeconds = Math.round(currentTime);
    return hasTwoDigit(Math.floor(currentSeconds / 60));
  }, [currentTime]);
  const ss = useMemo(() => {
    const currentSeconds = Math.round(currentTime);
    return hasTwoDigit(currentSeconds % 60);
  }, [currentTime]);

  const sliderValue = useMemo(
    () => Math.round((100 * currentTime) / duration),
    [duration, currentTime]
  );

  const onTimeUpdate = () => {
    if (duration > 0) {
      setCurrentTime(audio.currentTime);
    }
  };

  useEffect(() => {
    audio.addEventListener(
      "canplaythrough",
      (event) => {
        setStatus(IDLE);
        onReady({
          audio,
        });
      },
      { once: true }
    );
    audio.addEventListener("timeupdate", onTimeUpdate);
    const handlePause = () => {
      setStatus(PAUSED);
      onPause && onPause();
    };
    audio.addEventListener("pause", handlePause);
    audio.addEventListener("play", () => {
      setStatus(PLAYING);
      onPlay && onPlay();
    });
    audio.addEventListener("ended", () => {
      setStatus(IDLE);
      audio.currentTime = 0;
      onEnded && onEnded();
    });
    audio.addEventListener("error", (e) => {
    });
    audio.load();
    return () => {
      audio.removeEventListener("timeupdate", onTimeUpdate);
      audio.removeEventListener("pause", handlePause);
      audio.pause();
      audio.remove();
    };
  }, [audio]);

  useEffect(() => {
    onChangeStatus(status);
    return () => {
    };
  }, [status]);

  const onSliderChange = (v) => {
    const currentTime = (duration * v) / 100;
    audio.currentTime = currentTime;

    if (currentTime) {
      status === IDLE && setStatus(PAUSED);
    } else {
      status === PAUSED && setStatus(IDLE);
    }
  };
  return (
    <StyledAudioPlayer singleRow={singleRow}>
      <PlaySeekContainer>
        <RangeSlider
          defaultValue={0}
          value={sliderValue}
          onChange={onSliderChange}
          tooltip={{ open: false }}
          {...props}
        />
      </PlaySeekContainer>
      {showDuration && (
        <AudioDuration singleRow={singleRow}>
          {status === IDLE ? '' : `${mm}:${ss} / `}{tmm}:{tss}
        </AudioDuration>
      )}
    </StyledAudioPlayer>
  );
};
AudioPlayer.defaultProps = {
  singleRow: true,
};
export default AudioPlayer;
