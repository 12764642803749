import styled from 'styled-components';

import { getColorValue } from 'ai';

const ContentText = styled.div`
   font-size: 13px;
   margin-bottom: 15px;
   ${({ color }) => color && `
      color: ${getColorValue(color)}
   `}
`;

export default ContentText;
