import { gql } from '@apollo/client';

export const replyFields = gql`
  fragment replyFields on Reply {
    duration
    id
    text
    transcodedAudioUrl
    createdAt
    user {
      firstName
      avatarUrl
      lastName
      displayName
      twitter
      instagram
      isMembership
    }
  }
`;
