import { Typography, TypographyProps } from "antd";
import { ParagraphProps as AntdParagraphProps } from "antd/lib/typography/Paragraph";
import styled from "styled-components";

const { Paragraph: AntdParagraph } = Typography;
interface ParagraphProps extends AntdParagraphProps {
  css?: string;
}
export const Paragraph = styled(AntdParagraph)<ParagraphProps>`
  a, a:active {
    color: var(--color-primary);
  }
  a:hover {
    color: var(--color-primary-hover);
  }
  ${(props) => props.css}
`;
