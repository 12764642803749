import React from 'react';
import styled from 'styled-components';

const BarList = styled.div`
    display: flex;
    align-items: center;
    height: ${props => props.height || 50}px;
`;

const BarItem = styled.div`
    flex: 1 0 0;
    margin-right: 5px;
    :last-child {
        margin-right: 0;
    }
`;

const ItemSize = styled.div`
    background-color: var(--color-green);
    transition: all .1s ease-out;
    border-radius: 2px;
    max-height: 100%;
`;

const GraphVisualization = ({
    dataArray,
    amplitude = 50
}) => {

    const graphData = [];
    const len = dataArray.length;
    let maxSize = 0;

    for (let i = 0; i < len; i++) {
        maxSize = Math.max(maxSize, dataArray[i]);
        if (i % 2) {
            graphData.unshift(dataArray[i]);
        } else {
            graphData.push(dataArray[i]);
        }
    }

    let rate = 1;
    if (maxSize > amplitude) {
        rate = amplitude / maxSize;
    }
    return (
        <BarList height={amplitude}>
            {
                graphData.map((item, i) => (
                    <BarItem key={i}>
                        <ItemSize style={{
                            height: `${item * rate}px`
                        }} />
                    </BarItem>
                ))
            }
        </BarList>
    );
};

export default GraphVisualization;
