import styled from 'styled-components';
import { Table as AntTable, Pagination } from 'antd';

const Table = styled(AntTable)`
`;

Table.defaultProps = {
  tableLayout: "fixed",
  scroll: { x: 600 }
}

export default Table;
