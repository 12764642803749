import React from 'react';
import styled from 'styled-components';

import { Avatar } from 'atoms';
import { CircleCheck } from 'icons';
import SocialList from "./SocialList";

const SUserProfile = styled.div`
  max-width: 100%;
  width: 300px;
  padding: 16px;
`;

const ImageContainer = styled.div`
  width: 80px;
  margin-bottom: 5px;
`;

const UserName = styled.div`
  margin-bottom: 10px;
  font-size: 16px;
`;

const UserBio = styled.div`
  margin-bottom: 10px;
  white-space: pre-line;
  font-size: 14px;
`;

const UserProfile = (
  {
    user,
    superfan
  }) => {
  const {displayName, fullName, bio, avatarUrl, twitter, instagram, website, isMembership} = user;

  return (
    <SUserProfile>
      <ImageContainer>
        <Avatar
          src={avatarUrl}
          placeholder={(displayName || fullName).slice(0, 1).toUpperCase()}
          circle={false}
          width={'80px'}
          radius={'5px'}
        />
      </ImageContainer>
      <UserName>{displayName || fullName}{" "}{(superfan || isMembership) && <CircleCheck color="#0092ff"/>}</UserName>
      <UserBio>{bio}</UserBio>
      <SocialList
        twitter={twitter}
        instagram={instagram}
        website={website}
      />
    </SUserProfile>
  )
}

UserProfile.defaultProps = {
  superfan: false
}

export default UserProfile;
