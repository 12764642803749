import styled from 'styled-components';
import { Input as AntInput } from 'antd';

const Password = styled(AntInput.Password)`
padding: 6px 10px 6px 20px;
&.ant-input, & .ant-input {
   height: 33px;
   font-size: 15px;
   border-color: #CCCCCC;
   :hover, :focus {
      box-shadow: none;
   }
}
& .ant-input-group-addon {
   background-color: transparent;
}
`;

export default Password;
