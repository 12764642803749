import React from 'react';
import styled from "styled-components";
import { Pagination } from "antd";

const SPagination = styled(Pagination)`
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    
    .ant-pagination-prev, .ant-pagination-next {
        &:focus-visible, &:hover {
            .ant-pagination-item-link {
                color: var(--color-primary);
                border-color: var(--color-primary);
            }
        }
    }
    
    .ant-pagination-item {
        &:hover {
            border-color: var(--color-primary-hover);
        }
        
        a {
            &:hover {
                color: var(--color-primary-hover);
            }
        }
    }
    
    .ant-pagination-item-active {
        border-color: var(--color-primary);
        
        a {
            color: var(--color-primary);
            
            &:hover {
                color: var(--color-primary-hover);
            }
        }
    }
    
    .ant-pagination-jump-prev, .ant-pagination-jump-next {
        .ant-pagination-item-container {
            .ant-pagination-item-link-icon {
                color: var(--color-primary);
                
                &:hover {
                    color: var(--color-primary-hover);
                }
            }
        }
    }
`;

SPagination.defaultProps = {
    size: 'default',
    showSizeChanger: false,
    showQuickJumper: false
}

export default SPagination;
