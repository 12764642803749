import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';

import { Spin } from 'atoms';

const LoadingInner = styled.div`
    flex: 1 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Loading = styled.div`
    display: flex;
    background-color: black;
    ::after {
        content: '';
        display: block;
        width: 0;
        padding-top: 100%;
    }
`;

const Video = styled.video`
    display: block;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

const SVideoPreview = styled.div`
    display: flex;
    flex-wrap: nowrap;
    ${Video} {
    }
    ${Loading} {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    ::after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        padding-top: 100%;
    }
`;

const VideoPlaceholder = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-content-grey);
    color: white;
    ::after {
        content: '';
        display: block;
        width: 0;
        padding-top: 100%;
    }
`;

const PlaceholderInner = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const VideoPreview = ({ stream, error }) => {
    const videoRef = useRef(null);

    useEffect(() => {
        if (videoRef.current && stream) {
            videoRef.current.srcObject = stream;
        }
    }, [stream]);

    if (!stream && error) {
        return (
            <VideoPlaceholder>
                <PlaceholderInner>
                    No camera detected
                </PlaceholderInner>
            </VideoPlaceholder>
        )
    }

    return (
        <SVideoPreview>
            <Loading>
                <LoadingInner>
                    <Spin size={'large'} />
                </LoadingInner>
            </Loading>
            {
                stream && (
                    <Video ref={videoRef} autoPlay playsInline muted />
                )
            }
        </SVideoPreview>
    );
};

export default VideoPreview;
