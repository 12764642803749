import React from 'react';
import styled from 'styled-components';

const SVisa = styled.svg<{ css?: string }>`
  .Icon-fill {
    fill: currentColor;
  }
  ${props => props.css}
`;

const Visa = ({ css }: { css?: string }) => {
  return (
    <SVisa focusable="false" viewBox="0 0 32 21" css={css}><g fill="none" fillRule="evenodd"><g className="Icon-fill"><path d="M26.58 21H2.42A2.4 2.4 0 0 1 0 18.62V4.38A2.4 2.4 0 0 1 2.42 2h24.16A2.4 2.4 0 0 1 29 4.38v14.25A2.4 2.4 0 0 1 26.58 21zM10 7.83c0-.46-.35-.83-.78-.83H3.78c-.43 0-.78.37-.78.83v3.34c0 .46.35.83.78.83h5.44c.43 0 .78-.37.78-.83V7.83zM25 17c.65 0 1-.3 1-1s-.35-1-1-1h-3c-.65 0-1 .3-1 1s.35 1 1 1h3zm-6 0c.65 0 1-.3 1-1s-.35-1-1-1h-3c-.65 0-1 .3-1 1s.35 1 1 1h3zm-6 0c.65 0 1-.3 1-1s-.35-1-1-1h-3c-.65 0-1 .3-1 1s.35 1 1 1h3zm-6 0c.65 0 1-.3 1-1s-.35-1-1-1H4c-.65 0-1 .3-1 1s.35 1 1 1h3z"></path></g></g></SVisa>
  )
}

export default Visa;
