import styled from 'styled-components';
import { Menu } from "antd";
import { Dropdown } from 'antd';

export default Dropdown;

export const ControlMenu = styled(Menu)`
  min-width: 200px;
`;
export const ControlMenuItem = styled(Menu.Item)`
  padding: 10px 20px;
  display: flex;
  align-items: center;
  font-size: 15px;
  .ant-dropdown-menu-item-icon {
    margin-right: 20px;
    min-width: 15px;
    color: var(--color-content-grey);
  }
  span {
    display: flex;
    flex: 1 0 0;
    justify-content: space-between;
  }
  :hover {
    color: var(--color-primary);
    .ant-dropdown-menu-item-icon {
      color: inherit;
    }
  }
`;