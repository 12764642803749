import React, { useEffect, useMemo } from "react";
import styled from "styled-components";
import { useTimer, useStopwatch } from "react-timer-hook";
import { hasTwoDigit } from "utils/Datetime";

import { Button, Div, Popover } from "atoms";
import { faMicrophone, faStop } from "@fortawesome/free-solid-svg-icons";

import DeviceList from "./DeviceList";
import { StreamLevelVisualization, StreamVisualization } from "libraries/Media";

import { READY, RECORDING } from "libraries/Audio/const";

import {
  RecordRow,
  VisualizationContainer,
  RecordGraphContainer,
  RecordGraph,
  RecordCTAContainer,
  SettingButton,
  RecordFooter,
  FooterRow,
  FooterSecondaryCol,
  FooterPrimaryCol,
  ButtonIcon,
} from "./common";

const StyledRecordReady = styled.div``;
const RecordDuration = styled.div`
  padding: 5px 10px;
  text-align: center;
  letter-spacing: 1px;
  font-size: 16px;
`;

const RecordMain = (
  {
    onStop,
    onReady,
    onCancel,
    status,
    selectedId,
    setSelectedId,
    constraints,
    stream,
    isSmallScreen,
    visualizationType,
  }) => {
  const time = new Date();
  time.setSeconds(time.getSeconds() + 3);
  const {
    seconds: getReadySeconds,
    isRunning: getReadyRunning,
    start: startGetReady,
  } = useTimer({ autoStart: false, expiryTimestamp: time, onExpire: () => onReady() });

  const { start, seconds, minutes } = useStopwatch({});
  const mm = useMemo(() => hasTwoDigit(minutes), [minutes]);
  const ss = useMemo(() => hasTwoDigit(seconds), [seconds]);

  const buttonText = useMemo(() => {
    if (isSmallScreen) {
      return {
        start: "START",
        stop: "STOP",
        ready: "READY",
      };
    } else {
      return {
        start: "START RECORDING",
        stop: "STOP RECORDING",
        ready: "GET READY",
      };
    }
  }, [isSmallScreen]);

  const isTimeDomain = useMemo(
    () => visualizationType === "TimeDomain",
    [visualizationType]
  );

  const startRecording = () => {
    startGetReady();
  };

  useEffect(() => {
    if (status === RECORDING) {
      start();
    }
    return () => {
    };
  }, [status]);

  const handleStopClick = () => {
    const duration = minutes * 60 + seconds;
    onStop({ duration });
  };

  return (
    <StyledRecordReady>
      <RecordRow>
        <VisualizationContainer>
          <RecordGraphContainer>
            <RecordGraph css={isTimeDomain ? `width: 150px` : ``}>
              {isTimeDomain && (
                <StreamLevelVisualization
                  height={30}
                  constraints={constraints}
                  stream={stream}
                />
              )}
              {!isTimeDomain && (
                <StreamVisualization
                  height={30}
                  constraints={constraints}
                  stream={stream}
                />
              )}
            </RecordGraph>
          </RecordGraphContainer>
        </VisualizationContainer>
        <RecordCTAContainer>
          <Popover
            placement={"right"}
            trigger={[`click`]}
            title={"Select a Microphone"}
            content={
              <DeviceList
                readonly={status === RECORDING}
                stream={stream}
                setSelectedAudioId={setSelectedId}
                selectedAudioId={selectedId}
              />
            }
          >
            <SettingButton/>
          </Popover>
        </RecordCTAContainer>
      </RecordRow>
      <RecordFooter>
        <FooterRow>
          <FooterSecondaryCol>
            {status === READY && (
              <Button type={"default"} block onClick={() => onCancel()}>
                CANCEL
              </Button>
            )}
            {status === RECORDING && (
              <RecordDuration>
                {mm}:{ss}
              </RecordDuration>
            )}
          </FooterSecondaryCol>
          <FooterPrimaryCol>
            {status === READY && !getReadyRunning && (
              <Button
                onClick={() => startRecording()}
                block
                icon={<ButtonIcon icon={faMicrophone}/>}
                type={"green"}
              >
                {buttonText.start}
              </Button>
            )}
            {status === READY && !!getReadyRunning && (
              <Button type={"yellow"} onClick={() => {
              }} block>
                {getReadySeconds} {buttonText.ready}
              </Button>
            )}
            {status === RECORDING && (
              <Div originPrimaryColor>
                <Button
                  onClick={() => handleStopClick()}
                  block
                  icon={<ButtonIcon icon={faStop}/>}
                >
                  {buttonText.stop}
                </Button>
              </Div>
            )}
          </FooterPrimaryCol>
        </FooterRow>
      </RecordFooter>
    </StyledRecordReady>
  );
};

RecordMain.defaultProps = {
  visualizationType: "Frequency",
};

export default RecordMain;
