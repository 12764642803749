import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { LOGIN_MUTATION, WIDGET_LOGIN_MUTATION } from 'apps/Auth/graphql';
import { AUTH_TOKEN_KEY } from 'consts';

import { Form } from 'antd';

import { FormItem, Input, Button, Password } from 'atoms';

const [USER_NOT_FOUND] = ['User not found'];

const LoginForm = (
  {
    showId,
    onLogin,
    widget = false,
  }) => {

  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const [triggerWidgetLogin, { loading: isWidgetLogin }] = useMutation(WIDGET_LOGIN_MUTATION, {
    onCompleted({ widgetLogin: { authenticationToken, error } }) {
      if (!error) {
        localStorage.setItem(AUTH_TOKEN_KEY, authenticationToken);
        onLogin();
      } else {
        console.log(error)
        handleErrorResponse(error);
      }
    },
    onError(error) {
      console.log(error)
      handleErrorResponse(error);
    }
  });

  const [triggerLogin, { loading: isLogin }] = useMutation(LOGIN_MUTATION, {
    onCompleted({ userLogin: { error } }) {
      if (!error) {
        onLogin();
      } else {
        console.log(error)
        handleErrorResponse(error);
      }
    },
    onError(error) {
      console.log(error)
      handleErrorResponse(error);
    }
  });

  const handleErrorResponse = (error) => {
    if (error === USER_NOT_FOUND) {
      setEmailError(true);
    } else {
      setPasswordError(true);
    }
    form.validateFields(['email', 'password']);
  }

  const onFinish = async (
    {
      email,
      password
    }) => {
    if (widget) {
      await triggerWidgetLogin({
        variables: {
          email,
          password
        }
      });
    } else {
      await triggerLogin({
        variables: {
          email,
          password,
          showId
        }
      });
    }
  };

  const [form] = Form.useForm();

  return (
    <Form
      form={ form }
      layout="vertical"
      requiredMark={ false }
      onFinish={ onFinish }
      initialValues={ {
        email: '',
        password: ''
      } }
    >
      <FormItem
        name={ 'email' }
        rules={ [
          { required: true, message: 'Email is required' },
          { type: 'email', message: 'The input is not valid E-mail' },
          {
            validator(_, value) {
              if (emailError) {
                return Promise.reject('There is no account with this email');
              } else {
                return Promise.resolve();
              }
            }
          }
        ] }
      >
        <Input placeholder={ 'Email' } onChange={ () => setEmailError(false) }/>
      </FormItem>
      <FormItem
        name={ 'password' }
        rules={ [
          { required: true, message: 'Password is required' },
          {
            validator(_, value) {
              if (passwordError) {
                return Promise.reject('Login credentials are not valid');
              } else {
                return Promise.resolve();
              }
            }
          }
        ] }
      >
        <Password placeholder={ 'Password' } onChange={ () => setPasswordError(false) }/>
      </FormItem>
      <Button type="primary" htmlType="submit" loading={ isLogin || isWidgetLogin } block
              style={ { marginTop: '20px' } }>
        LOGIN
      </Button>

    </Form>
  );
};

export default LoginForm;
