export const parseDate = (date) => {
   const Mon = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
   const m = date.getMonth();
   const d = date.getDate();
   const yyyy = date.getFullYear();
   const hours = date.getHours();
   const minutes = date.getMinutes();

   return {
      Mon: Mon[m],
      d,
      dd: hasTwoDigit(d),
      M: m + 1,
      MM: hasTwoDigit(m + 1),
      yyyy,
      yy: yyyy % 100,
      h: hours,
      m: minutes,
      hh: hasTwoDigit(hours || 12),
      mm: hasTwoDigit(minutes),
      apm: hours >= 12 ? 'PM' : 'AM'
   }
};

export const formatDate = (date) => {
   const { yyyy, Mon, dd } = parseDate(date);
   return `${Mon} ${dd}, ${yyyy}`;
}

export const formatFromIos = (iosTime) => {
   const date = new Date(iosTime);
   return formatDate(date);
};

export const hasTwoDigit = (number) => {
   return number >= 10 ? Math.floor(number) : `0${Math.floor(number)}`;
};

export const sameAsISOTime = (date: Date): Date => {
   console.log(date);
   const offset: number = (new Date()).getTimezoneOffset() * 60 * 1000;
   return new Date(date.getTime() - offset);
}

export const sameAsLocalTime = (date: Date): Date => {
   const offset: number = (new Date()).getTimezoneOffset() * 60 * 1000;
   return new Date(date.getTime() + offset);
}

export const formatSameAsISOTime = (iosTime: string): string => {
   const localTime = sameAsLocalTime(new Date(iosTime));
   return formatDate(localTime);
}
