import { A, Col, Row } from 'atoms';
import React, { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';

const SListenConnects = styled.div``;
const ListenConnectTitle = styled.div`
  margin-bottom: 5px;
  color: black;
`;
const ListenConnectList = styled.div``;
const ListenConnectItem = styled(A)`
  display: block;
  min-width: 190px;
  padding: 10px 15px;
  margin-bottom: 6px;
  border: 1px solid var(--color-border);
  border-radius: 2px;
  color: var(--color-content);
  background-color: var(--color-body-background);
  &:last-child {
    margin-bottom: 0;
  }
  &:hover, &:focus {
  }
`;

const LinkLabel = styled.div`
  font-size: 14px;
`;

type ListenItem = {
  name: string
  link: string
  color: string
  // @ts-ignore
  Icon: ({color: string}) => ReactElement
  label: string
}
type Props = {
  title: string
  items: ListenItem[]
}
const ListenConnects = (props: Props) => {
  const { title, items } = props;
  return (
    <SListenConnects>
      <ListenConnectTitle>
        {title}
      </ListenConnectTitle>
      <ListenConnectList>
        {
          items.map(Link => (
            <ListenConnectItem key={Link.name} href={Link.link} target="_blank">
              <Row align="middle" gutter={8}>
                <Col flex="0 0 25px">
                  <Link.Icon color={Link.color}  />
                </Col>
                <Col>
                  <LinkLabel>{Link.label}</LinkLabel>
                </Col>
              </Row>
            </ListenConnectItem>
          ))
        }
      </ListenConnectList>
    </SListenConnects>
  )
};

export default ListenConnects;
