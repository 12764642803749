import { gql } from "@apollo/client";
import { fanFields } from "./fragments";

export const FAN_QUERY = gql`
  query fan($id: ID, $membershipId: ID, $userId: ID, $showId: ID) {
    fan(
      id: $id
      membershipId: $membershipId
      userId: $userId
      showId: $showId
    ) {
      ...fanFields
    }
  }
  ${fanFields}
`;
