import styled from 'styled-components';

const PageTitle = styled.h3`
  font-family: var(--title-font);
  letter-spacing: 0.5px;
  color: black;
  font-size: 21px;
  margin-bottom: 15px;
  text-transform: capitalize;
  font-weight: 500;
  margin-block-start: 0;
  margin-inline-start: 0;
  ${props => props.css}
`;

export default PageTitle;
