import React from 'react';
import styled, { withTheme } from 'styled-components';
import { useMutation } from '@apollo/client';
import { LOGOUT_MUTATION } from 'apps/Auth/graphql';

import { Menu } from 'antd';
import { A, Link } from 'atoms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faDesktop, faMicrophone, faHeart, faCog } from '@fortawesome/free-solid-svg-icons';
import { AUTH_TOKEN_KEY } from 'consts';


const StyledMenu = styled(Menu)`
  border-right: none;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 200px;

  &.ant-dropdown-menu {
    padding: 0;
  }
`;

const MenuItemGroup = styled(Menu.ItemGroup)`
  padding: 0;

  .ant-dropdown-menu-item-group-title, .ant-menu-item-group-title {
    display: none;
  }
`;
const MenuItem = styled(Menu.Item)`
  padding: 6px 12px;
  border-bottom: 1px solid var(--color-border);

  &:last-child {
    border-bottom: none;
  }

  &.ant-dropdown-menu-item-selected {
    background-color: var(--color-body-background);
  }

  ${MenuItemGroup} & {
    display: flex;
    align-items: center;
    /* padding: 0px 10px; */

    :hover {
      color: var(--color-primary);

      ${Link} {
        color: inherit;
      }
    }
  }

  ${props => props.css}
`;

const MenuIconContainer = styled.div`
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  box-sizing: border-box;
  vertical-align: middle;
  ${props => props.border && `border: 1px solid var(--light-grey);`}
  border-radius: 3px;
  font-size: 16px;
  color: #aaa;

  ${MenuItem}:hover & {
    color: var(--color-primary);
  }
`;
const MenuLink = styled(Link)`
  color: #000;
`;
const MenuIcon = styled(FontAwesomeIcon)`
  && {
    font-size: ${props => props.fontSize || '18px'};
    vertical-align: middle;
  }
`;

const AvatarMenu = (
  {
    theme,
    ...props
  }) => {
  const [triggerLogout] = useMutation(LOGOUT_MUTATION, {
    onCompleted() {
      localStorage.setItem(AUTH_TOKEN_KEY, '');
      location.reload();
    }
  });
  return (
    <StyledMenu
      mode='inline' {...props}
      selectedKeys={[]}
    >
      <MenuItem
        icon={<MenuIconContainer><MenuIcon icon={faDesktop}/></MenuIconContainer>} key={'dashboard'}
        className='ant-menu-item'>
        <A href={'/'}>
          dashboard
        </A>
      </MenuItem>
      <MenuItem
        icon={<MenuIconContainer><MenuIcon icon={faMicrophone}/></MenuIconContainer>} key={'messages'}
        className='ant-menu-item'>
        <A href={'/messages'}>
          my messages
        </A>
      </MenuItem>
      <MenuItem
        icon={<MenuIconContainer><MenuIcon icon={faHeart}/></MenuIconContainer>} key={'supporting'}
        className='ant-menu-item'>
        <A href={'/supporting/creators'}>
          supporting
        </A>
      </MenuItem>
      <MenuItem
        icon={<MenuIconContainer><MenuIcon icon={faCog}/></MenuIconContainer>} key={'account'}
        className='ant-menu-item'>
        <A href={'/account-settings/profile'}>
          account settings
        </A>
      </MenuItem>
      <MenuItem
        icon={<MenuIconContainer><MenuIcon icon={faSignOutAlt}/></MenuIconContainer>} className='ant-menu-item'
        onClick={() => {
          triggerLogout({
            variables: {
              input: {}
            }
          });
        }}>
        <A>
          logout
        </A>
      </MenuItem>
    </StyledMenu>
  );
};

export default withTheme(AvatarMenu);
