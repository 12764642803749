import React from 'react';
import styled from 'styled-components';
import { Badge } from 'antd';

const PodBadge = styled(({inCircle, ...props}) => <Badge {...props} />)`
    ${props => props.inCircle && `

    `}
`;

export default PodBadge;
