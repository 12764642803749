import React, { useEffect, useMemo, useRef, useState } from "react";
import { hasTwoDigit } from "utils/Datetime";
import { Howl } from 'howler';

type Props = {
  src: string;
  onLoad?: (howl: Howl) => void;
  onEnd?: (soundId: number) => void;
  onSeek?: (soundId: number) => void;
  lazyLoad?: boolean;
  duration?: number;
  rate: number
}
export const useHowler = (props: Props) => {
  const { src, onLoad, onSeek, onEnd, lazyLoad = true, duration: durationProp = 0, rate } = props;
  const howlerRef = useRef<Howl>();
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [seek, setSeek] = useState(0);
  const paused = loaded && !playing && seek > 0;
  const [duration, setDuration] = useState(durationProp);
  const idle = loaded && !playing && !paused;
  const seekPercent = 100 * seek / duration;
  const tmm = hasTwoDigit(Math.floor(duration / 60));
  const tss = hasTwoDigit(duration % 60);
  const mm = hasTwoDigit(Math.floor(seek / 60));
  const ss = hasTwoDigit(seek % 60);
  const [seekInterval, setSeekInterval] = useState(setInterval(() => {}, 100000));

  const load = (play = false) => {
    setLoading(true);
    const howl = new Howl({
      src: [src],
      rate,
    });
    howlerRef.current = howl;

    howl.once('load', () => {
      setLoading(false);
      setLoaded(true);
      onLoad && onLoad(howl);
      setDuration(howl.duration());
      if (play) {
        howl.play();
      }
    });
    howl.on('end', (soundId) => { onEnd && onEnd(soundId); setSeek(0); setPlaying(false); });
    howl.on('seek', (soundId) => {
      onSeek && onSeek(soundId); setSeek(howl.seek());
    });
    howl.on('play', () => { 
      setPlaying(true);
      const newSeekInterval = setInterval(() => {
        setSeek(howl.seek());
      }, 50);
      setSeekInterval(newSeekInterval);
    });
    howl.on('pause', () => { 
      setPlaying(false);
      setSeekInterval(setInterval(() => {}, 10000));
    });

    return () => {
      howl.unload();
      howlerRef.current = null;
    }
  }

  const play = () => {
    if (loaded) {
      howlerRef.current.play();
    }
    else {
      load(true);
    }
  }

  const pause = () => {
    howlerRef.current.pause();
  }

  const seekTo = (seek: number) => {
    howlerRef.current.seek(seek);
  }
  const forward = (seconds: number) => {
    const newSeek = Math.min(seek + seconds, duration);
    if (howlerRef.current) {
      howlerRef.current.seek(newSeek);
    } else {
      // setSeek(newSeek);
    }
  }
  const backward = (seconds: number) => {
    const newSeek = Math.max(seek - seconds, 0);
    if(howlerRef.current) {
      howlerRef.current.seek(newSeek);
    } else {
      // setSeek(newSeek);
    }
  }
  useEffect(() => {
    if (!lazyLoad) {
      load();
    }
    return () => {
      howlerRef.current && howlerRef.current.unload();
      howlerRef.current = null;
      clearInterval(seekInterval);
    }
  }, [src]);

  useEffect(() => {
    return () => {
      clearInterval(seekInterval);
    }
  }, [seekInterval]);

  useEffect(() => {
    if(howlerRef.current) {
      howlerRef.current.rate(rate);
    }
  }, [rate]);

  return {
    howl: howlerRef.current,
    loading,
    loaded,
    seek,
    duration,
    playing,
    paused,
    idle,
    seekPercent,
    tmm,
    tss,
    mm,
    ss,
    play,
    pause,
    seekTo,
    forward,
    backward
  }
}
