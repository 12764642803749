import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';

import { Divider } from 'antd';
import { Row, Col, Spacer, A, Popover, DeleteConfirmPopContent } from 'atoms';

import { formatDate, hasTwoDigit } from 'utils/Datetime';

import {
    RecordFooter
} from './common';
import { IDLE, INITIAL, PLAYING } from 'libraries/Audio/const';
import VideoPlayer from '../../VideoPlayer';
import { FaTrashAlt } from 'icons';

const StyledRecordUploaded = styled.div``;

const PlayerContainer = styled.div``;

const FooterInner = styled.div`
    padding: 10px;
    border: 1px solid var(--color-border);
    border-radius: 3px;
`;

const CreatedDate = styled.div`
    font-weight: bold;
`;

const Duration = styled.div`
    color: var(--color-content-grey);
`;

const Delete = styled(A)`
    color: var(--color-content);
`;

const RecordUploaded = ({ src, duration, onDelete, isDeleteBusy }) => {

    const [removePopoverVisible, setRemovePopoverVisible] = useState(false);

    const tmm = useMemo(() => hasTwoDigit(Math.floor(duration / 60)), [duration]);
    const tss = useMemo(() => hasTwoDigit(duration % 60), [duration]);

    const handleClickDelete = () => {
        onDelete && onDelete();
    }
    const createdDate = formatDate(new Date());

    return (
        <StyledRecordUploaded>
            <PlayerContainer>
                <VideoPlayer src={src} />
            </PlayerContainer>
            <RecordFooter>
                <FooterInner>
                    <Row align={'middle'} singleRow gutter={5}>
                        <Col>
                            <CreatedDate>{createdDate}</CreatedDate>
                        </Col>
                        <Col>
                            <Divider type="vertical" />
                        </Col>
                        <Col>
                            <Duration>
                                {tmm}:{tss} sec
                            </Duration>
                        </Col>
                        <Spacer />
                        <Col>
                            <Popover
                                placement={'top'}
                                title={'Are you sure?'}
                                content={
                                    <DeleteConfirmPopContent
                                        text={'Do you want to remove this video?'}
                                        onNo={() => setRemovePopoverVisible(false)}
                                        onYes={handleClickDelete}
                                        loading={isDeleteBusy}
                                    />}
                                trigger={'click'}
                                open={removePopoverVisible}
                                onOpenChange={visible => setRemovePopoverVisible(visible)}>
                                <Delete block>
                                    <FaTrashAlt />
                                </Delete>
                            </Popover>
                        </Col>
                    </Row>
                </FooterInner>
            </RecordFooter>
        </StyledRecordUploaded>
    );
};

export default RecordUploaded;
