import React, { useState, useMemo, useEffect, memo } from "react";
import { useQuery, useApolloClient, gql } from "@apollo/client";
import { PUBLIC_SHOW_QUERY } from "../graphql";
import { withPublicPageState } from "apps/PublicPage/context";
import { useMutation } from "@apollo/client";
import PureMessage, { PIN, PRIVATE, REPLY, TAG, TIPS } from "components/Message";
import RepliedMessage from "components/RepliedMessage";
import ReplyRecorder from "components/ReplyRecorder";
import { SIGNUP, TRUE, DELETE, DOWNLOAD } from "consts";
import {
  superfanFields,
  TOGGLE_LIKE_MUTATION,
  TRANSCRIBE_MESSAGE_MUTATION,
} from "graphql-api";

const Message = (
  {
    message,
    slug,
    afterMessageUpdate,
    showTranscription,
    publicPageState: {isLoggedIn, authModalVisible, openAuthModal},
    initialRepliedMessageVisible,
    ...props
  }) => {
  const [replyRecorderVisible, setReplyRecorderVisible] = useState(false);
  const {data: showData} = useQuery(PUBLIC_SHOW_QUERY, {
    variables: {
      slug,
      uuid: "",
    },
  });
  const show = useMemo(() => {
    return showData?.publicShow;
  }, [showData]);

  const {id, reply, monetization} = message;
  const client = useApolloClient();

  const [loginCallbacks, setLoginCallbacks] = useState([]);
  const [triggerToggleLike, {loading: isToggleLikeBusy}] = useMutation(
    TOGGLE_LIKE_MUTATION,
    {
      variables: {
        messageId: id,
      },
      onCompleted({toggleLike: {success, error}}) {
        afterMessageUpdate && afterMessageUpdate(id);
      },
      optimisticResponse: {
        toggleLike: {
          __typename: "ToggleLikePayload",
          success: TRUE,
          error: null,
          message: {
            __typename: message.__typename,
            id,
            liked: !message.liked,
            likes: !message.liked ? message.likes + 1 : message.likes - 1,
          },
        },
      },
    }
  );

  const [triggerTranscribe] = useMutation(TRANSCRIBE_MESSAGE_MUTATION, {
    variables: {
      messageId: id,
    },
  });

  const controls = useMemo(
    () => [...(monetization ? [TIPS] : []), ...(show.viewerIsAdmin ? [REPLY] : [])],
    [show]
  );

  const handleToggleLike = () => {
    if (isLoggedIn) {
      triggerToggleLike();
    } else {
      openAuthModal(SIGNUP);
      setLoginCallbacks([
        () => {
          triggerToggleLike();
        },
      ]);
    }
  };

  const handleSupport = ({message: _message, show: _show}) => {
    client.writeQuery({
      query: gql`
        query WriteMessage {
          message {
            id
            totalTipsCents
            superfans {
              ...superfanFields
            }
          }
        }
        ${superfanFields}
      `,
      data: {
        message: {
          ..._message,
          __typename: message.__typename,
        },
      },
    });
  };
  const handleTranscribe = async () => {
    await triggerTranscribe({
      optimisticResponse: {
        transcribeMessage: {
          success: TRUE,
          error: null,
          message: {
            __typename: message.__typename,
            id: message.id,
            transcribingIntended: true,
          },
        },
      },
    });
  };

  const handleUpdateMessage = () => {
    afterMessageUpdate && afterMessageUpdate(id);
  };

  const toggleReplyRecorderVisible = () => {
    setReplyRecorderVisible(!replyRecorderVisible);
  };

  const handleReplyToggle = () => {
    toggleReplyRecorderVisible();
  };

  const handleCreate = () => {
    setReplyRecorderVisible(false);
  };

  useEffect(() => {
    if (!authModalVisible) {
      setLoginCallbacks([]);
    }
    return () => {
    };
  }, [authModalVisible]);

  useEffect(() => {
    if (isLoggedIn && loginCallbacks.length) {
      loginCallbacks.forEach((callback) => callback());
    }
    return () => {
    };
  }, [isLoggedIn, loginCallbacks]);

  return (
    <PureMessage
      message={message}
      slug={slug}
      onToggleLike={handleToggleLike}
      onPinToggle={handleUpdateMessage}
      onPrivateToggle={handleUpdateMessage}
      onReplyToggle={handleReplyToggle}
      onToggleDelete={handleUpdateMessage}
      onTag={() => {
      }}
      controls={controls}
      showPin={true}
      showTags={false}
      replyRecorder={
        !reply &&
        replyRecorderVisible && (
          <ReplyRecorder
            onCancel={toggleReplyRecorderVisible}
            onCreate={handleCreate}
            messageId={message.id}
          />
        )
      }
      repliedMessage={
        reply ? <RepliedMessage reply={reply} control={!!show?.viewerIsAdmin}/> : null
      }
      show={show}
      isLoggedIn={isLoggedIn}
      onSupport={handleSupport}
      shadow
      createdAtVisible={false}
      onTranscribe={handleTranscribe}
      showTranscription={showTranscription}
      ellipsisControls={!!show?.viewerIsAdmin ? [DOWNLOAD, TAG, PIN, PRIVATE, DELETE] : []}
      {...props}
    />
  );
};

Message.defaultProps = {
  tipsList: true,
};

const TheMessage = memo(Message);

export default withPublicPageState(TheMessage);
