import React from 'react';
import styled from 'styled-components';
import { Popover as AntPopover, PopoverProps } from 'antd';

type ContainerProps = {
    simple?: boolean
    width?: string | number
    css?: string
}
const ContentContainer = styled.div<ContainerProps>`
    ${props => props.simple && `margin: -12px -16px;`}
    ${props => props.width && `width: ${props.width};`}
    ${props => props.css}
`;

export const PopoverInner = styled.div<{css?: string}>`
    padding: 12px 16px;
    border-bottom: 1px solid var(--light-grey);
    :last-child {
        border-bottom: none;
    }
    ${props => props.css}
`;

interface Props extends PopoverProps {
    simple?: boolean
    content: any
    css?: string
    width?: string | number
}

const Popover = ({ simple, content, width, css, ...props }: Props) => {
    return <AntPopover content={<ContentContainer simple={simple} width={width} css={css}>{content}</ContentContainer>} {...props} />
}

export default Popover;
