import React from 'react';
import styled from 'styled-components';
import { Menu } from 'antd';
import { message as messageAlert } from 'atoms';
import { FaTrashAlt, FaPencilAlt } from 'icons';
import { DELETE, EDIT, TRUE } from 'consts';
import { DeleteCta } from 'molecules';

import { useMutation } from '@apollo/client';
import { TOGGLE_DELETE_PAYMENT_MESSAGE_MUTATION, TOGGLE_DELETE_PAYMENT_REPLY_MUTATION } from 'graphql-api';

const ControlMenu = styled(Menu)`
  min-width: 200px;
`;

const MenuItem = styled(Menu.Item)`
  padding: 10px 20px;
  display: flex;
  align-items: center;
  font-size: 15px;

  .ant-dropdown-menu-item-icon {
    margin-right: 20px;
    min-width: 15px;
    color: var(--color-content-grey);
  }

  span {
    display: flex;
    flex: 1 0 0;
    justify-content: space-between;
  }

  :hover {
    color: var(--color-primary);

    .ant-dropdown-menu-item-icon {
      color: inherit;
    }
  }
`;

const DeleteMenuItem = ({ onDelete, loading, text, ...props }) => {
  return (
    <DeleteCta
      text={'Please confirm. This can not be undone'}
      onDelete={onDelete}
      loading={loading}
      showNo={false}
    >
      <MenuItem icon={<FaTrashAlt/>} {...props}>
        Delete {text}
      </MenuItem>
    </DeleteCta>
  )
}

const PaymentMessageControls = (
  {
    controls,
    message = null,
    reply = null,
    onDelete,
    onUpdate,
    text = 'Message',
    ...props
  }
) => {
  const [triggerToggleDelete, { loading: isDeleteBusy }] = useMutation(TOGGLE_DELETE_PAYMENT_MESSAGE_MUTATION, {
    variables: {
      messageId: message ? message.id : null
    },
    onCompleted({ toggleDeletePaymentMessage: { success, error } }) {
      if (success === TRUE) {
        onDelete();
        messageAlert.success({ content: 'Message Deleted' });
      } else {
        // @ts-ignore
        messageAlert.error({ content: 'Something went wrong, please try later' });
      }
    }
  });

  const [triggerToggleReplyDelete, { loading: isDeleteReplyBusy }] = useMutation(TOGGLE_DELETE_PAYMENT_REPLY_MUTATION, {
    variables: {
      replyId: reply ? reply.id : null
    },
    onCompleted({ toggleDeletePaymentReply: { success, error } }) {
      if (success === TRUE) {
        onDelete();
        messageAlert.success({ content: 'Reply Deleted' });
      } else {
        // @ts-ignore
        messageAlert.error({ content: 'Something went wrong, please try later' });
      }
    }
  });

  const handleDeleteMessage = async () => {
    await triggerToggleDelete();
  }

  const handleDeleteReply = async () => {
    await triggerToggleReplyDelete();
  }
  return (
    <ControlMenu {...props}>
      {
        controls.map(control => {
          switch (control) {
            case EDIT:
              return (
                <MenuItem
                  key={EDIT}
                  icon={<FaPencilAlt/>}
                  onClick={() => onUpdate()}
                >
                  Edit { text }
                </MenuItem>
              )
            case 'REPLY_EDIT':
              return (
                <MenuItem
                  key={'REPLY_EDIT'}
                  icon={<FaPencilAlt/>}
                  onClick={() => onUpdate()}
                >
                  Edit Reply
                </MenuItem>
              )
            case DELETE:
              return (
                <DeleteMenuItem
                  key={DELETE}
                  onDelete={handleDeleteMessage}
                  loading={isDeleteBusy}
                  text={'Message'}
                />
              )
            case 'REPLY_DELETE':
              return (
                <DeleteMenuItem
                  key={'REPLY_DELETE'}
                  onDelete={handleDeleteReply} l
                  loading={isDeleteReplyBusy}
                  text={'Reply'}
                />
              )
          }
        })
      }
    </ControlMenu>
  )
};

export default PaymentMessageControls;
