import { useState, useMemo, useRef, useEffect, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useURLQuery = () => {
    const { search } = useLocation();
    const params = useMemo(() => {
        const searchParams = new URLSearchParams(search);
        const urlQueries = {};
        for (const [key, value] of searchParams.entries()) {
            urlQueries[key] = value;
        }
        return {
            ...urlQueries
        };
    }, [search]);
    return params;
};

// Hook
export const usePrevious = (value) => {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref = useRef();
    // Store current value in ref
    useEffect(() => {
        ref.current = value;
    }, [value]); // Only re-run if value changes
    // Return previous value (happens before update in useEffect above)
    return ref.current;
}

export const useAnimated = (value, duration = 200, times = 5) => {
    const [aniValue, setValue] = useState(value);
    const aniValueRef = useRef(value);
    useEffect(() => {
        const stepValue = (value - aniValueRef.current) / times;
        if (value > aniValueRef.current) {
            setValue(value);
            return;
        }
        const _interval = setInterval(() => {
            const aniV = aniValueRef.current;
            if (Math.round(aniV) === value) {
                clearInterval(_interval);
                return;
            }
            setValue(aniV + stepValue);
        }, duration / times);
        return () => {
            clearInterval(_interval);
        }
    }, [value]);

    useEffect(() => {
        aniValueRef.current = aniValue;
    }, [aniValue]);

    return Math.round(aniValue);
}

export const useValueFalseDelayed = (value, duration) => {
    const formerValueRef = useRef(value);
    const theValue = useMemo(() => value || formerValueRef.current, [value, formerValueRef]);
    useEffect(() => {
        setTimeout(() => {
            formerValueRef.current = value;
        }, duration);
    }, [value, duration]);

    return theValue;
}

export function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}
