import React, { useContext, useEffect, useRef, useState } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBackward, faForward, faPause, faRedo, faUndo } from '@fortawesome/free-solid-svg-icons';

import {
  RecordRow,
  VisualizationContainer,
  RecordVisualization,
  RecordCTAContainer,
  RecordCTAButton,
} from 'libraries/Audio/components/AudioRecord/components/common';
import { INITIAL, PLAYING } from 'libraries/Audio/const';
import { Button, Dropdown, Flex, Slider } from 'antd';
import { FaPause, FaPlay } from 'icons';
import { useHowler } from 'libraries/howler';

export const PauseButton = (props) => (
  <RecordCTAButton type="primary" icon={<FontAwesomeIcon icon={faPause} />} {...props} />
);

const PlayButton = styled(Button)`
  &.ant-btn.ant-btn-icon-only {
    width: 50px;
    height: 50px;
    border-radius: 5px;
    border-width: 0px;
    .ant-btn-icon {
      svg {
        color: var(--color-primary);
      }
    }
  }
`;

const StyledPreviewPlayer = styled.div``;

const AudioPlayContainer = styled.div`
  width: 100%;
  padding: 10px 0 0 15px;
`;

const AudioDuration = styled.div`
  color: white;
  ${(props) =>
    props.singleRow &&
    `
        width: auto;
        text-align: right;
    `}
`;
const PodSlider = styled(Slider)`
  .ant-slider-track {
    background-color: ${(props) => `${props.theme.colors.primary}`};
  }
  .ant-slider-handle {
    background-color: var(--color-primary);
    :after {
      background-color: var(--color-primary);
      box-shadow: 0 0 0 2px white;
    }
  }
  &.ant-slider .ant-slider-rail {
    background-color: white;
  }
  &:hover {
    .ant-slider-track {
      background-color: ${(props) => `${props.theme.colors.primary}`};
    }
    .ant-slider-handle:not(.ant-tooltip-open) {
      background-color: var(--color-primary-hover);
      :after {
        box-shadow: 0 0 0 2px var(--color-primary-hover);
      }
    }
  }
`;

const SControlButton = styled(Button)`
  && {
    min-width: 60px;
    background-color: white;
    height: 30px !important;
    &:hover {
      background-color: rgba(255, 255, 255, 0.9) !important;
      color: var(--color-primary) !important;
    }
  }
`;

const rateOptions = [0.8, 1, 1.2, 1.5, 1.8, 2];

const PreviewPlayerDark = ({ src, duration, simple = false }) => {
  const { isMobile } = useContext(ThemeContext);
  const [playRate, setPlayRate] = useState(1);
  const { playing, paused, loaded, loading, play, idle, pause, seekPercent, seekTo, tmm, tss, mm, ss, forward, backward } = useHowler({
    src,
    duration,
    rate: playRate
  });

  const handlePlayClick = () => {
    play();
  };
  const handlePauseClick = () => {
    pause()
  };
  const onSliderChange = (v) => {
    seekTo((duration * v) / 100);
  }
  return (
    <StyledPreviewPlayer>
      <RecordRow css={!simple ? `padding: 15px 15px 15px 15px; border: 1px solid var(--color-border); border-radius: 5px;` : ''}>
        <RecordCTAContainer css={`width: 50px;
          align-items: center;`}>
          {
            !playing && (
              <PlayButton
                icon={<FaPlay />}
                onClick={() => handlePlayClick()}
                loading={loading}
              />
            )
          }
          {
            playing && (
              <PlayButton
                icon={<FaPause />}
                onClick={() => handlePauseClick()}
              />
            )
          }
        </RecordCTAContainer>
        <VisualizationContainer css={`margin-right: 0;`}>
          <RecordVisualization css={`border: 0;`}>
            <AudioPlayContainer>
              <div>
                <div>
                  <PodSlider
                    defaultValue={0}
                    value={seekPercent}
                    step={0.01}
                    onChange={onSliderChange}
                    tooltip={{ open: false }}
                  />
                </div>
                <Flex>
                  <div style={{ flex: '1 0 0' }}>
                    {
                      !isMobile && (
                        <Flex align='center' gap={6}>
                          <Dropdown
                            trigger={['click']}
                            menu={{
                              selectedKeys: [`rate_${playRate}`],
                              items: rateOptions.map((rate) => ({
                                key: `rate_${rate}`,
                                label: rate,
                                style: {
                                  padding: '3px 12px',
                                },
                                className: 'hover:text-primary',
                                onClick: () => setPlayRate(rate)
                              }))
                            }}
                          >
                            <SControlButton type='text' shape='round' size='small'>{playRate}×</SControlButton>
                          </Dropdown>
                          <div style={{
                            width: '4px',
                            height: '4px',
                            borderRadius: '10px',
                            backgroundColor: 'white'
                          }}></div>
                          <SControlButton onClick={() => backward(15)} shape='round' size='small'>
                            <Flex gap={6} align='center'>
                              <span>-15</span>
                              <FontAwesomeIcon icon={faUndo} />
                            </Flex>
                          </SControlButton>
                          <SControlButton onClick={() => forward(15)} shape='round' size='small'>
                            <Flex gap={6} align='center'>
                              <FontAwesomeIcon icon={faRedo} />
                              <span>+15</span>
                            </Flex>
                          </SControlButton>
                        </Flex>
                      )
                    }
                  </div>
                  <div>
                    <AudioDuration singleRow={true}>
                      {idle ? '' : `${mm}:${ss} / `}{tmm}:{tss}
                    </AudioDuration>
                  </div>
                </Flex>
              </div>
            </AudioPlayContainer>
          </RecordVisualization>
        </VisualizationContainer>
      </RecordRow>
      {
        isMobile && (
          <div className='mt-3'>
            <Flex align='center' gap={6}>
              <Dropdown
                trigger={['click']}
                menu={{
                  selectedKeys: [`rate_${playRate}`],
                  items: rateOptions.map((rate) => ({
                    key: `rate_${rate}`,
                    label: rate,
                    style: {
                      padding: '3px 12px',
                    },
                    className: 'hover:text-primary',
                    onClick: () => setPlayRate(rate)
                  }))
                }}
              >
                <SControlButton type='text' shape='round' size='small'>{playRate}×</SControlButton>
              </Dropdown>
              <div style={{
                width: '4px',
                height: '4px',
                borderRadius: '10px',
                backgroundColor: 'white'
              }}></div>
              <SControlButton onClick={() => backward(15)} shape='round' size='small'>
                <Flex gap={6} align='center'>
                  <span>-15</span>
                  <FontAwesomeIcon icon={faUndo} />
                </Flex>
              </SControlButton>
              <SControlButton onClick={() => forward(15)} shape='round' size='small'>
                <Flex gap={6} align='center'>
                  <FontAwesomeIcon icon={faRedo} />
                  <span>+15</span>
                </Flex>
              </SControlButton>
            </Flex>
          </div>
        )
      }
    </StyledPreviewPlayer>
  );
};

export default PreviewPlayerDark;
