import React from 'react';
import styled from 'styled-components';

import { Tour } from 'antd';

const STour = styled(Tour)`
  color: red;
`;

export default STour;
