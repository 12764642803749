import { gql } from "@apollo/client";

export const MAILCHIMP_QUERY = gql`
  query mailchimpQuery {
    mailchimpQuery {
      audiences {
        count
        id
        name
      }
      currentAudienceCount
      currentAudienceId
      currentAudienceName
    }
  }
`;

export const SELECT_MAILCHIMP_AUDIENCE_MUTATION = gql`
  mutation selectMailchimpAudience($audienceId: String!) {
    selectMailchimpAudience(audienceId: $audienceId) {
      success
      error
    }
  }
`;

export const DISCONNECT_MAILCHIMP_MUTATION = gql`
  mutation disconnectMailchimp {
    disconnectMailchimp {
      success
      error
    }
  }
`;

export const PUSH_CONTACTS_TO_MAILCHIMP_MUTATION = gql`
  mutation pushContactsToMailchimp {
    pushContactsToMailchimp {
      success
      error
      count
    }
  }
`;
