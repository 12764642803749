import styled from "styled-components";

const Flexbox = styled.div`
    display: ${props => props.inline ? 'inline-flex' : 'flex'};
    ${props => props.center && `align-items: center; justify-content: center;`}
    ${props => props.align && `align-items: ${props.align};`}
    ${props => props.justify && `justify-content: ${props.justify};`}
    ${props => props.css}
`;

Flexbox.defaultProps = {
};

export default Flexbox;
