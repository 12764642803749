import { Button, PopoverInner, TabA } from "atoms";
import styled from "styled-components";

export const SShow = styled.div`
  padding-bottom: 40px;
  ${(props) =>
    props.theme.isLarge &&
    `
        margin-top: -65px;
    `}
`;

export const ImageContainer = styled.div<{ pushTop: number }>`
  width: ${(props) => (props.theme.isMobile ? "100px" : "150px")};
  margin-top: ${(props) => -props.pushTop}px;
  position: relative;
`;

export const PageTitle = styled.h1`
  font-size: 24px;
  margin: 0 0 15px;
  color: white;
  text-shadow: 2px 2px 2px rgb(0 0 0 / 40%);
`;

export const MobilePageTitle = styled.h1`
  font-size: ${(props) => (props.theme.isMobile ? "20px" : "24px")};
  margin: 0 0 15px;
`;

export const SliderContainerInner = styled.div`
  margin: -10px -10px;
  transform-origin: center 100px;
  cursor: auto;
  transform: scale(1);
  transition: all 0.2s ease-in-out 0s;
`;

export const InboxContainer = styled.div<{ active: boolean }>`
  padding: 10px 10px;
  cursor: pointer;
  transform-origin: center 100px;
  transform: scale(0.9);
  & * {
    pointer-events: none;
  }
  ${(props) =>
    props.active &&
    `
        cursor: auto;
        transform: scale(1);
        & * {
            pointer-events: all;
        }
    `}
  transition: all .2s ease-in-out;
`;

export const SSponsorContainer = styled.div<{ active: boolean }>`
  padding: 10px 10px;
  cursor: pointer;
  transform-origin: center 100px;
  transform: scale(0.9);
  & * {
    pointer-events: none;
  }
  ${(props) =>
    props.active &&
    `
        cursor: auto;
        transform: scale(1);
        & * {
            pointer-events: all;
        }
    `}
  transition: all .2s ease-in-out;
`;

export const MessageContainer = styled.div`
  background-color: white;
`;

export const PaymentSelectionContainer = styled.div<{ center: boolean }>`
  display: flex;
  justify-content: ${(props) => (props.center ? "center" : "left")};
`;

export const SuperfansContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 0;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
`;

export const WelcomeVideoPositionContainer = styled.div`
  position: fixed;
  z-index: 100;
  bottom: ${(props) => (props.theme.isMobile ? 20 : 30)}px;
  right: ${(props) => (props.theme.isMobile ? 10 : 30)}px;
`;

export const WelcomeVideoPositionContainerInBanner = styled.div`
  position: absolute;
  z-index: 1;
  bottom: 30px;
  right: 30px;
`;

export const SectionTitle = styled.div<{ css?: string }>`
  font-size: 17px;
  letter-spacing: 0.5px;
  ${(props) => props.css}
`;
export const NextInboxTitle = styled.div`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
  text-align: right;
`;

export const SliderNavButton = styled(Button)`
  width: 26px;
  height: 26px;
  min-width: 26px;
  background-color: transparent;
  border-color: var(--color-primary);
  color: var(--color-primary);
  &.ant-btn-icon-only {
    display: flex;
    width: 26px;
    height: 26px;
    min-width: 26px;
  }
  &[disabled] {
    opacity: 0.6;
    background: transparent;
  }
  :focus {
  }
`;

export const HeaderBanner = styled.div<{ bannerImage: string }>`
  min-height: ${(props) => (props.theme.isMobile ? "120px" : "280px")};
  ${(props) =>
    props.bannerImage && `background-image: url("${props.bannerImage}");`}
  background-color: var(--color-primary);
  background-size: cover;
  position: relative;
  border-radius: 0 0 4px 4px;
  background-position: center center;
  display: flex;
  align-items: flex-end;
  padding: 30px;
  ${(props) =>
    props.theme.isMobile &&
    `
    margin-left: -15px;
    margin-right: -15px;
    border-radius: 0 0 0 0;
  `}
`;
export const ListenPositionContainer = styled.div`
  position: absolute;
  left: 30px;
  bottom: 30px;
  min-width: 400px;
`;
export const ListenContainer = styled.div``;

export const ListenButton = styled(Button)`
  padding: ${(props) => (props.theme.isMobile ? "10px 10px" : "10px 15px")};
  border-radius: 5px;
  min-width: 85px;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.04);
  --color: rgba(255, 255, 255, 0.85);
  color: var(--color-content);
  font-size: 12px;
  ${(props) => props.theme.isMobile && `font-size: 11px;`}
  &:hover,
  &:focus, 
  &.ant-btn-primary:not(:disabled):hover, 
  &.ant-btn-primary:not(:disabled):focus {
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.1);
    color: var(--color-primary);
    background-color: #ffffff;
    border-color: #ffffff;
  }

  &.ant-btn-primary *, 
  & * {
    font-size: 12px;
  }
`;
export const ListenPopoverInner = styled(PopoverInner)`
  padding: 20px 16px;
`;
export const MonetizationContainer = styled.div<{ css: string }>`
  ${(props) => props.css}
`;
export const SectionHeader = styled.div<{ css?: string }>`
  margin-bottom: 20px;
  ${(props) => props.css}
`;

export const STabA = styled(TabA)`
  text-transform: uppercase;
`;

export const TabContent = styled.div`
  padding: 20px 0;
`;

export const ChatButtonContainer = styled.div`
  position: fixed;
  bottom: 30px;
  left: 30px;
  z-index: 100;
  ${(props) =>
    props.theme.isMobile &&
    `
        bottom: 20px;
        left: 20px;
    `}
`;
export const StickyContainer = styled.div`
  position: sticky;
  top: 0;
  margin: 0 -15px 20px;
  padding: 0 15px;
  background-color: var(--color-body-background);
  z-index: 1;

  &.stuck {
    background-color: #ffffff;
    box-shadow: 0 0 10px var(--input-border);
  }
`;

export const PageContentRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: ${props => props.theme.isDesktop ? '30px' : '20px'};
  align-items: ${props => props.theme.isDesktop ? 'flex-start' : 'stretch'}
`;

export const PageContentColumnLeft = styled.div`
  flex: ${props => props.theme.isDesktop ? '3 0 300px' : '0 0 100%'};
  min-width: 0;
  max-width: 100%;
  order: ${props => props.theme.isDesktop ? 1 : 2};
`;
export const PageContentColumnRight = styled.div`
  flex: ${props => props.theme.isDesktop ? '2 0 200px': '0 0 100%'};
  max-width: ${props => props.theme.isDesktop ? '460px' : '100%'};
  min-width: 0;
  order: ${props => props.theme.isDesktop ? 2 : 1};
`;
