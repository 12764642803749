import React, {
  useState,
  useMemo,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useCallback } from "react";
import { RangeSlider, Div } from "atoms";
import AudioPlayer from "./AudioPlayer";
import { hasTwoDigit } from "utils/Datetime";
import { INITIAL } from "libraries/Audio/const";
import styled from "styled-components";

const RangeContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: space-between;
  width: 100%;
`;

const AudioLazyPlayer = (
  {onLoadingChange, onStatusChange, src, duration, singleRow = false},
  ref
) => {
  const [isPlayed, setIsPlayed] = useState(false);
  const [audio, setAudio] = useState(null);
  const [playerStatus, setPlayerStatus] = useState(INITIAL);
  const isLoading = useMemo(() => isPlayed && !audio, [isPlayed, audio]);
  const mm = useMemo(() => {
    const currentSeconds = Math.round(duration);
    return hasTwoDigit(Math.floor(currentSeconds / 60));
  }, [duration]);
  const ss = useMemo(() => {
    const currentSeconds = Math.round(duration);
    return hasTwoDigit(currentSeconds % 60);
  }, [duration]);

  const onReady = useCallback(
    ({audio: _audio}) => {
      setAudio(_audio);
      if (playerStatus === INITIAL) {
        _audio.play();
      }
    },
    [playerStatus]
  );

  const play = useCallback(() => {
    if (audio) {
      audio.play();
    } else {
      setIsPlayed(true);
    }
  }, [audio]);

  const pause = useCallback(() => {
    audio.pause();
  }, [audio]);

  useImperativeHandle(
    ref,
    () => ({
      play,
      pause,
    }),
    [play, pause]
  );

  useEffect(() => {
    onLoadingChange && onLoadingChange(isLoading);
  }, [isLoading]);

  useEffect(() => {
    onStatusChange && onStatusChange(playerStatus);
  }, [playerStatus]);

  if (isPlayed) {
    return (
      <AudioPlayer
        src={src}
        duration={Math.round(duration)}
        onReady={onReady}
        onChangeStatus={(status) => setPlayerStatus(status)}
        singleRow={singleRow}
      />
    );
  } else {
    return (
      <RangeContainer>
        <Div css={`flex: 1 0 calc(100% - 45px);`}>
          <RangeSlider
            defaultValue={0}
            value={0}
            onChange={() => {
            }}
            tooltip={{open: false}}
          />
        </Div>
        <Div css={`flex: 1 0 40px;`}>
          {mm}:{ss}
        </Div>
      </RangeContainer>
    );
  }
};

export default forwardRef(AudioLazyPlayer);
