import { gql } from '@apollo/client';

export const CREATE_VIDEO_MESSAGE_MUTATION = gql`
    mutation createMessage($file: Upload!, $showId: String!) {
        createMessage(file: $file, showId: $showId) {
            success,
            error
        }
    }
`;

export const CREATE_INBOX_WELCOME_VIDEO_MUTATION = gql`
    mutation createInboxWelcomeVideo($file: Upload!) {
        createInboxWelcomeVideo(file: $file) {
            success,
            error
        }
    }
`;

export const DELETE_INBOX_WELCOME_VIDEO_MUTATION = gql`
    mutation deleteInboxWelcomeVideo {
        deleteInboxWelcomeVideo {
            success,
            error
        }
    }
`;
