import styled from 'styled-components';
import { Input } from 'antd';

const { TextArea } = Input;

const PodTextArea = styled(TextArea)`
&.ant-input, & .ant-input {
   padding: 6px 20px;
   font-size: 15px;
   border-color: #CCCCCC;
   :hover, :focus {
      box-shadow: none;
   }
}
`;

export default PodTextArea;
