import { getColorValue } from 'ai';
import styled from 'styled-components';

type PropsType = {
   block?: boolean
   color?: string
   css?: string
}

const A = styled.a<PropsType>`
   ${props => props.block && `
      display: block;
   `}
   cursor: pointer;
   color: ${props => props.color ? getColorValue(props.color) : `var(--color-primary)`};
   :hover{
      --color: var(--color-primary-hover);
      color: var(--color-primary-hover);
   }
   ${props => props.css}
`;

export default A;
