import React, {
  useMemo,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";

import { useReactMediaRecorder } from "libraries/Media";

import {
  NORMAL,
  READY,
  RECORDING,
  STOPPED,
  PERMISSION_DENIED,
} from "libraries/Audio/const";

import { RecordNormal, RecordMain, RecordPlayer } from "./components";

const StyledAudioRecord = styled.div``;
const PureAudioRecord = (
  {
    onSave,
    isSaveBusy,
    onStop,
    onToggle,
    onError,
    visualizationType,
    disableMessagesPosting = false
  },
  ref
) => {
  const [status, setStatus] = useState(NORMAL);
  const [duration, setDuration] = useState(0);
  const [selectedId, setSelectedId] = useState(null);
  const [recordedBlob, setRecordedBlog] = useState(null);

  const audioConstraints = useMemo(
    () => (selectedId ? { deviceId: selectedId } : true),
    [selectedId]
  );
  const constraints = useMemo(
    () => ({
      audio: audioConstraints,
    }),
    [audioConstraints]
  );
  const mediaRecordActive = useMemo(
    () => [READY, RECORDING].includes(status),
    [status]
  );
  const isSmallScreen = useMediaQuery({ query: `(max-width: 380px)` });

  const {
    stream,
    stopStream,
    status: recorderStatus,
    startRecording,
    stopRecording,
    mediaBlobUrl,
    error,
  } = useReactMediaRecorder({
    audio: audioConstraints,
    active: mediaRecordActive,
    onStop: (blobUrl, blob) => {
      console.log("onStop blob", blob);
      setRecordedBlog(blob);
      onStop && onStop(blob);
      setStatus(STOPPED);
    },
  });
  const errorMessage = useMemo(() => {
    const errorMessageMap = {
      [PERMISSION_DENIED]: `Looks like your microphone is disabled on your browser. Please enable the microphone in your browser settings to use this feature.`,
    };
    return error ? (
      errorMessageMap[error] ||
      `Sorry, our recorder won't work on this browser due to browser limitations. Please use Chrome or update this browser and try again. Thanks!`
    ) : false;
  }, [error]);

  const onReady = () => {
    console.log("onReady start recording");
    startRecording();
    setStatus(RECORDING);
  };

  const handleStop = ({ duration: _duration }) => {
    console.log("handleStop stop recording");
    setDuration(_duration);
    stopRecording();
  };
  const onCancel = () => {
    stopStream();
    setStatus(NORMAL);
    setDuration(0);
    onToggle && onToggle(false);
  };
  const reset = () => {
    stopStream();
    setTimeout(() => {
      setStatus(NORMAL);
      setDuration(0);
    }, 300);
  };
  const handleOnStart = () => {
    onToggle && onToggle(true);
    setStatus(READY);
  };
  const handleOnSave = () => {
    onSave && onSave(recordedBlob);
  };

  useImperativeHandle(
    ref,
    () => ({
      setStatus,
      setDuration,
      reset,
    }),
    [setStatus, setDuration, reset]
  );

  useEffect(() => {
    console.log('onError 1', error);
    if (error) {
      onError && onError(errorMessage);
      onCancel();
    } else {
      onError && onError(null);
    }
  }, [error]);

  useEffect(() => {
    if (disableMessagesPosting) {
      onCancel();
    }
  }, [disableMessagesPosting]);

  return (
    <>
      <StyledAudioRecord>
        { status === NORMAL && (
          <RecordNormal
            onStart={ () => handleOnStart() }
            errorMessage={ errorMessage }
            isSmallScreen={ isSmallScreen }
            disableMessagesPosting={ disableMessagesPosting }
          />
        ) }
        { !disableMessagesPosting && [READY, RECORDING].includes(status) && (
          <RecordMain
            onReady={ onReady }
            status={ status }
            onStop={ handleStop }
            onCancel={ onCancel }
            selectedId={ selectedId }
            setSelectedId={ setSelectedId }
            constraints={ constraints }
            stream={ stream }
            isSmallScreen={ isSmallScreen }
            visualizationType={ visualizationType }
          />
        ) }
        { !disableMessagesPosting && status === STOPPED && (
          <RecordPlayer
            src={ mediaBlobUrl }
            duration={ duration }
            onSave={ handleOnSave }
            onCancel={ onCancel }
            isUploadBusy={ isSaveBusy }
            isSmallScreen={ isSmallScreen }
          />
        ) }
      </StyledAudioRecord>
    </>
  );
};

PureAudioRecord.defaultProps = {
  visualizationType: "Frequency",
};

export default forwardRef(PureAudioRecord);
