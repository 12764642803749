import React, { useState } from "react";
import styled from "styled-components";
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';

const SHTMLEllipsis = styled.div`
  font-size: 15px;
  p {
    margin-top: 0;
    margin-bottom: 15px;
  }
  .ellipsis-more {
    color: var(--color-primary);
    &:hover {
      color: var(--color-primary-hover);
    }
  }
`;

export const HtmlEllipsis = ({
  unsafeHTML,
  maxLine,
  onMore
}: { unsafeHTML: string; maxLine: number, onMore?: (event: MouseEvent) => void }) => {

  const [localMaxLine, setLocalMaxLine] = useState(maxLine);
  const handleClickContent = (event: React.MouseEvent<HTMLElement>) => {
    const target = event.nativeEvent.target as HTMLElement;
    if (target.tagName === 'A', target.classList.contains('ellipsis-more')) {
      if(onMore) {
        onMore(event.nativeEvent);
      } else {
        setLocalMaxLine(1000);
      }
    }
  }
  return (
    <SHTMLEllipsis onClick={handleClickContent}>
      <HTMLEllipsis
        unsafeHTML={unsafeHTML}
        maxLine={localMaxLine}
        ellipsisHTML={`<span>... <a class="ellipsis-more">more</a></span>`}
        basedOn='letters' />
    </SHTMLEllipsis>
  )
}
