import { faRssSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Flex } from "antd";
import { Card, CardDescription, CardHeader, CardInner, CardTitle, Span } from "atoms";
import CopyClipboard from "components/CopyClipboard";
import { FaCopy } from "icons";
import React, { useContext } from "react";
import { FanPageContext } from "../context";

const RssFeedCard = () => {
  const { membership } = useContext(FanPageContext);
  const rssLink = `${window.location.origin}/rss/${membership.uuid}.xml`;
  return (
    <Card shadow simple>
      <CardHeader>
        <Flex align="center" gap={8} className="mb-1">
          {/* TODO: typescript issue */}
          <FontAwesomeIcon icon={faRssSquare} color="#e15a0c" fontSize="18px" />
          <CardTitle>RSS Feed</CardTitle>
        </Flex>
        <CardDescription grey>This is your unique podcast feed. Please don't share this.</CardDescription>
      </CardHeader>
      <CardInner>
        <CopyClipboard copyContent={rssLink}>
          <div>
            <Flex align="center" gap={10}>
              <span style={{ flex: '1 0 0', minWidth: 0, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: 'pre' }}>{rssLink}</span>
              <FaCopy />
            </Flex>
          </div>
        </CopyClipboard>
      </CardInner>
    </Card>
  )
}

export default RssFeedCard;
