import React, { useState } from 'react';
import styled from 'styled-components';
import { ModalInner, Row, Col, A, Skeleton, Input, Button, Form, FormItem, message, NoContent } from 'atoms';
import { FaPencilAlt, FaSave, FaTag, FaTimes, FaTrashAlt } from 'icons';
import { DeleteCta } from 'molecules';
import { useQuery, useMutation } from '@apollo/client';
import {
  CREATE_SHOW_TAG_MUTATION,
  UPDATE_SHOW_TAG_MUTATION,
  SHOW_QUERY,
  DELETE_SHOW_TAG_MUTATION
} from 'apps/Dashboard/graphql';

import {
  PUBLIC_SHOW_QUERY
} from 'apps/PublicPage/graphql';
import { useMemo } from 'react';
import { TRUE } from 'consts';

const S = {};

S.ManageShowTags = styled.div`
  ${ModalInner} {
    border-bottom: 1px solid var(--color-border);

    :last-child {
      border-bottom: none;
    }
  }
`;

S.TagItem = styled.div`
`;
S.ItemName = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
`;
S.ActionA = styled(A)`
  color: var(--color-grey-lighter);
`;

const TagItem = ({tag, slug, showTags}) => {
  const {id, name} = tag;
  const [isEdit, setIsEdit] = useState(false);
  const [form] = Form.useForm();
  const [triggerDeleteTag, {loading: isDeleteBusy}] = useMutation(DELETE_SHOW_TAG_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: [(showTags ? {query: SHOW_QUERY} : {query: PUBLIC_SHOW_QUERY, variables: {slug, uuid: ''}})],
    variables: {
      id
    },
    onCompleted({deleteShowTag: {success, error}}) {
      if (success === TRUE) {
        message.success({content: 'Tag deleted'});
      } else {
        message.error({content: 'Something went wrong, please try later'});
      }
    }
  });

  const [triggerUpdateTag, {loading: isUpdateBusy}] = useMutation(UPDATE_SHOW_TAG_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: [(showTags ? {query: SHOW_QUERY} : {query: PUBLIC_SHOW_QUERY, variables: {slug, uuid: ''}})],
    onCompleted({updateShowTag: {success, error}}) {
      if (success === TRUE) {
        message.success({content: 'Tag updated'});
        setIsEdit(false);
      } else {
        message.error({content: 'Something went wrong, please try later'});
      }
    }
  });

  const handleDelete = () => {
    triggerDeleteTag();
  }

  const handleSave = () => {
    form.submit();
  }
  const handleFinish = ({name}) => {
    triggerUpdateTag({
      variables: {
        id,
        name
      }
    })
  }

  return (
    <S.TagItem>
      <Form
        form={form}
        initialValues={{name: name}}
        onFinish={handleFinish}
      >
        <Row align={'middle'} gutter={14}>
          <Col>
            <FaTag color={`lighterGrey`}/>
          </Col>
          <Col flex={`1 0 0`}>
            {
              isEdit && (
                <FormItem
                  name={'name'}
                  rules={[{required: true, message: 'Name is required'}]}
                >
                  <Input size={`middle`} placeholder={`Tag Name`}/>
                </FormItem>
              )
            }
            {
              !isEdit && (
                <S.ItemName>
                  {name}
                </S.ItemName>
              )
            }
          </Col>
          <Col>
            {
              isEdit && (
                <S.ActionA onClick={handleSave}>
                  <FaSave/>
                </S.ActionA>
              )
            }
            {
              !isEdit && (
                <S.ActionA onClick={() => setIsEdit(true)}>
                  <FaPencilAlt/>
                </S.ActionA>
              )
            }
          </Col>
          <Col>
            {
              isEdit ? (
                <S.ActionA onClick={() => setIsEdit(false)}><FaTimes/></S.ActionA>
              ) : (
                <DeleteCta onDelete={handleDelete} loading={isDeleteBusy}>
                  <S.ActionA><FaTrashAlt/></S.ActionA>
                </DeleteCta>
              )
            }
          </Col>
        </Row>
      </Form>
    </S.TagItem>
  )
}

S.TagItemContainer = styled.div`
  margin-bottom: 5px;
`;

const ManageShowTags = ({slug, showTags}) => {
  const {data, loading} = showTags ? useQuery(SHOW_QUERY) : useQuery(PUBLIC_SHOW_QUERY, {
    variables: {slug, uuid: ''}
  });
  const tags = useMemo(() => (showTags ? data?.show?.tags : data?.publicShow?.tags) || [], [data]);
  const [createForm] = Form.useForm();

  const [triggerCreateShowTag, {loading: isCreateBusy}] = useMutation(CREATE_SHOW_TAG_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: [
      (showTags ? {query: SHOW_QUERY} : {query: PUBLIC_SHOW_QUERY, variables: {slug, uuid: ''}})
    ],
    onCompleted({createShowTag: {success, error}}) {
      if (success === TRUE) {
        message.success({content: 'Tag Created'});
      } else {
        message.error({content: 'Something went wrong, please try later'});
      }
    }
  });

  const handleFinish = ({name}) => {
    triggerCreateShowTag({
      variables: {
        name
      }
    });
    createForm.resetFields();
  }

  return (
    <S.ManageShowTags>
      <ModalInner>
        {
          loading && (
            <Skeleton paragraph={{rows: 2}} active/>
          )
        }
        {
          !loading && tags.map(tag => (
            <S.TagItemContainer key={tag.id}>
              <TagItem
                tag={tag}
                slug={slug}
                showTags={showTags}
              />
            </S.TagItemContainer>
          ))
        }
        {
          !loading && tags.length === 0 && (
            <NoContent>
              Please create a tag
            </NoContent>
          )
        }
      </ModalInner>
      <ModalInner>
        <Form
          initialValues={{
            name
          }}
          onFinish={handleFinish}
          form={createForm}
        >
          <Row gutter={10}>
            <Col flex={`1 0 150px`}>
              <FormItem
                name={'name'}
                rules={[{required: true, message: 'Name is required'}]}
              >
                <Input size={'middle'} placeholder={`Tag Name`}/>
              </FormItem>
            </Col>
            <Col>
              <Button htmlType={'submit'} css={`padding: 9px 16px;`} loading={isCreateBusy}>
                ADD TAG
              </Button>
            </Col>
          </Row>
        </Form>
      </ModalInner>
    </S.ManageShowTags>
  );
};

export default ManageShowTags;
