import React from 'react';
import styled from 'styled-components';

import { A } from 'atoms';
import { FaInstagram, FaTwitter, FaDesktop } from 'icons';
import { colors } from 'styles';

const SocialA = styled(A)`
    font-size: 20px;
`;

export const TwitterLink = ({ link, ...props }) => (
    <SocialA href={`https://instagram.com/${link}`} color={'#197efe'} target={'_blank'} {...props}>
        <FaTwitter />
    </SocialA>
);

export const InstagramLink = ({ link, ...props }) => (
    <SocialA href={`https://instagram.com/${link}`} color={'#cd3a86'} target={'_blank'} {...props}>
        <FaInstagram />
    </SocialA>
);

export const TwitterIcon = ({ disabled = false, ...props }) => <FaTwitter color={disabled ? colors.lightGrey : '#197efe'} {...props} />;

export const InstagramIcon = ({ disabled = false, ...props }) => <FaInstagram color={disabled ? colors.lightGrey : '#cd3a86'} {...props} />;

export const WebsiteIcon = ({ disabled = false, ...props }) => <FaDesktop color={disabled ? colors.lightGrey : '#EC304A'} verticalMiddle {...props} />