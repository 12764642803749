import styled from 'styled-components';

type Props = {
   single?: boolean
   css?: string
}
const AsideHelper = styled.aside<Props>`
   font-size: 14px;
   color: var(--color-content-grey);
   margin-bottom: ${props => props.single ? 0 : 6}px;
   ${props => props.css}
`;

AsideHelper.defaultProps = {
   single: false
};

export default AsideHelper;
