import React, { forwardRef } from 'react';
import styled from 'styled-components';
import Slider from "react-slick";

const StyledSlider = styled(Slider)`
  ${props => props.dots && `
        padding-top: 40px;
    `}
  .slick-list {
  }

  .slick-dots {
    top: 0;
    bottom: auto;

    li {
      button {
        &:before {
          font-size: 10px;
        }
      }

      &.slick-active {
        button {
          &:before {
            color: var(--color-primary);
          }
        }
      }
    }
  }
`;

const InboxSlider = ({settings, breakpoint, children, ...props}, ref) => {
  const _settings = {
    className: "center",
    centerMode: false,
    centerPadding: "0",
    slidesToShow: 1,
    speed: 400,
    arrows: false,
    infinite: true,
    waitForAnimate: true,
    responsive: [
      {
        breakpoint,
        settings: {
          slidesToShow: 1
        }
      }
    ],
    ...settings
  };
  return (
    <StyledSlider {..._settings} {...props} ref={ref}>
      {children}
    </StyledSlider>
  )
};

export default forwardRef(InboxSlider);
