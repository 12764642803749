import { gql } from "@apollo/client";
import { collectionMetadataFields } from "graphql-api";

const publicEpisodeMemberFields = gql`
  fragment publicEpisodeMemberFields on PublicEpisodeMember {
    id
    uuid
    title
    description
    audioUrl
    audioDuration
    explicitContent
    useCustomArtwork
    customArtworkUrl
    publishAt
  }
`;

const publicEpisodeNonMemberFields = gql`
  fragment publicEpisodeNonMemberFields on PublicEpisodeNonMember {
    id
    uuid
    title
    audioDuration
    explicitContent
    useCustomArtwork
    customArtworkUrl
    publishAt
  }
`;

export const PUBLIC_EPISODES_MEMBER_QUERY = gql`
  query publicEpisodesMember($podcastId: ID!, $page: Int, $limit: Int) {
    publicEpisodesMember(podcastId: $podcastId, page: $page, limit: $limit) {
      collection {
        ...publicEpisodeMemberFields
      }
      metadata {
        ...collectionMetadataFields
      }
    }
  }
  ${publicEpisodeMemberFields}
  ${collectionMetadataFields}
`;

export const PUBLIC_EPISODES_NON_MEMBER_QUERY = gql`
  query publicEpisodesNonMember($podcastId: ID!, $page: Int, $limit: Int) {
    publicEpisodesNonMember(podcastId: $podcastId, page: $page, limit: $limit) {
      collection {
        ...publicEpisodeNonMemberFields
      }
      metadata {
        ...collectionMetadataFields
      }
    }
  }
  ${publicEpisodeNonMemberFields}
  ${collectionMetadataFields}
`;

export const PUBLIC_EPISODE_MEMBER_QUERY = gql`
  query publicEpisodeMember($uuid: String!) {
    publicEpisodeMember(uuid: $uuid) {
      ...publicEpisodeMemberFields
    }
  }
  ${publicEpisodeMemberFields}
`;

export const PUBLIC_EPISODE_NON_MEMBER_QUERY = gql`
  query publicEpisodeNonMember($uuid: String!) {
    publicEpisodeNonMember(uuid: $uuid) {
      ...publicEpisodeNonMemberFields
    }
  }
  ${publicEpisodeNonMemberFields}
`;
