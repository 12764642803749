export const isUrlID = () => {

}


export const isHttp = (url) => {
    return /^https?:\/\//.test(url)
}

export const removeHttp = (url) => {
    return url.replace(/^https?:\/\//, '');
}

export const idRegex = /^[a-zA-Z0-9._-]+$/;
export const isID = uname => uname && idRegex.test(uname);
export const getUsernameFromProfile = url => {
    const parts = url.split("/").reverse();
    return parts[0] || parts[1];
}

export const isWebisteRegex = /^(www\.)?[a-zA-Z0-9_-]+\.[a-zA-Z0-9_-]+/;
export const isInstagramProfileRegex = /^(https:\/\/)?(www\.)?instagram\.com\/[a-zA-Z0-9._]+\/?$/;
export const isTwitterProfileRegex = /^(https:\/\/)?(www\.)?x\.com\/[a-zA-Z0-9._-]+\/?$/;
export const isFacebookPageRegex = /^(www\.)?facebook\.com\/[a-zA-Z0-9._-]+\/?$/;
export const isFacebookProfileRegex = /^(www\.)?facebook\.com\/profile\.php\?id=[a-zA-Z0-9._-]+\/?$/;
export const isLinkedinProfileRegex = /^(www\.)?linkedin\.com\/in\/[a-zA-Z0-9._-]+\/?$/;
export const isTiktokProfileRegex = /^(www\.)?tiktok\.com\/@[a-zA-Z0-9._-]+(\?lang=[a-z]+)\/?$/;
export const isYoutubeChannelRegex = /^(www\.)?youtube\.com\/channel\/[a-zA-Z0-9._-]+\/?$/;

export const isWebsite = url => isWebisteRegex.test(url);
export const isInstagram = url => /^(www\.)?instagram\.com\//.test(url);
export const isInstagramProfile = url => isInstagramProfileRegex.test(url);
export const isTwitter = url => /^(www\.)?x\.com\//.test(url);
export const isTwitterProfile = url => isTwitterProfileRegex.test(url);
export const isFacebook = url => /^(www\.)?facebook\.com\//.test(url);
export const isFacebookPage = url => isFacebookPageRegex.test(url);
export const isFacebookProfile = url => isFacebookProfileRegex.test(url);
export const isLinkedin = url => /^(www\.)?linkedin\.com\//.test(url);
export const isLinkedinProfile = url => isLinkedinProfileRegex.test(url);
export const isTiktok = url => /^(www\.)?tiktok\.com\//.test(url);
export const isTiktokProfile = url => isTiktokProfileRegex.test(url);
export const isYoutube = url => /^(www\.)?youtube\.com\//.test(url);
export const isYoutubeChannel = url => isYoutubeChannelRegex.test(url);

export const generateInstagramProfileByID = id => `instagram.com/${id}`;
export const generateTwitterProfileByID = id => `x.com/${id}`;
export const generateFacebookProfileByID = id => `facebook.com/profile.php?id=${id}`;
export const generateFacebookPageByID = id => `facebook.com/${id}`;
export const generateLinkedinProfileByID = id => `linkedin.com/in/${id}`;
export const generateTiktokProfileByID = id => `tiktok.com/@${id}`;
export const generateYoutubeChannelByID = id => `youtube.com/channel/${id}`;
