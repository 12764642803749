import React, { useMemo, useState } from 'react';
import styled, { withTheme } from 'styled-components';
import { useMutation } from '@apollo/client';
import { DELETE_REPLY_MUTATION } from 'graphql-api';
import { Menu } from "antd";
import { Avatar, Row, Col, message, Dropdown, MenuItem, A, Popover } from 'atoms';
import { DeleteCta } from 'molecules';
import { TRUE } from 'consts';
import { FaEllipsisV, FaTrashAlt } from 'icons';
import ReplyAudioPlayer from './ReplyAudioPlayer';
import ReplyTextContent from './ReplyTextContent';
import UserProfile from "../UserProfile";
import { CircleCheck } from 'icons';

const S = {};

S.RepliedMessage = styled.div``;

const UserContainer = styled.div``;

const Title = styled.div`
  font-size: 16px;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
  ${(props) => props.css}
`;

const Inner = styled.div`
  padding: 10px 15px;

  ${(props) => props.css}
  .ant-typography-expand {
    color: var(--color-primary);

    &:hover {
      color: var(--color-primary-hover);
    }
  }
`;

const ControlMenu = styled(Menu)`
  min-width: 200px;
`;

const ContentContainer = styled.div`
  padding: 1em 15px 0;
  border: 1px solid var(--color-border);
  border-radius: 4px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  gap: 15px;
  background-color: var(--color-body-background);
  margin-top: 5px;
  ${(props) => props.css}
`;

const TextControl = styled.a`
  cursor: pointer;
  color: var(--color-content-grey);
  margin-top: 4px;
`;

const TextControlInner = styled(Inner)`
  height: 100%;
  padding: 0;
  font-size: 16px;
  border: 0;
  min-width: 29px;
  text-align: right;
`;

const DeleteMenuItem = ({onDelete, loading, ...props}) => {
  return (
    <DeleteCta
      text={'Please confirm. This can not be undone'}
      onDelete={onDelete}
      loading={loading}
      showNo={false}
    >
      <MenuItem icon={<FaTrashAlt/>} {...props}>
        Delete
      </MenuItem>
    </DeleteCta>
  )
}

const [TEXT, AUDIO] = ['TEXT', 'AUDIO'];

const RepliedMessage = (
  {
    reply,
    control,
    theme: {isMobile},
    ...props
  }
) => {
  const {text, transcodedAudioUrl, createdAt, duration, user, id} = reply;
  const replyType = useMemo(() => text ? TEXT : AUDIO, [text]);
  const [messageControlsVisible, setMessageControlsVisible] = useState(false);

  const [triggerDeleteReply, {loading: isDeleteBusy}] = useMutation(DELETE_REPLY_MUTATION, {
    variables: {
      replyId: id
    },
    onCompleted({deleteReply: {success, error}}) {
      if (success === TRUE) {
        message.success({content: 'Message deleted'});
      } else {
        message.error({content: 'Something went wrong, please try again later'});
      }
    }
  });

  const handleDelete = () => {
    triggerDeleteReply();
  }

  const handleMessageControlsOpenChange = (e) => {
    setMessageControlsVisible(e);
  };

  return (
    <S.RepliedMessage>
      <UserContainer>
        <Row singleRow gutter={isMobile ? 10 : 12}>
          <Col minZero>
            <Avatar 
              css={`border-radius: 5px;`} 
              circle={false} 
              src={user.avatarUrl}
              width={isMobile ? "40px" : "50px"}
            />
          </Col>
          <Col flex={'1 0 0'}>
            <Row
              align={'middle'}
              justify={'space-between'}
              gutter={16}
              css={control ? `padding-right: 7px;` : ``}
            >
              <Col minZero>
                <Popover
                  placement={"top"}
                  trigger={["click"]}
                  content={<UserProfile user={user}/>}
                  simple
                >
                  <A>
                    <Title css={isMobile ? `font-size: 14px;` : ``}>
                      {user.displayName}{" "}
                      {user.isMembership && <CircleCheck color="#0092ff"/>}
                    </Title>
                  </A>
                </Popover>
              </Col>
              {
                control && (
                  <Col css={`min-width: 30px;
                    margin-right: 5px;`}>
                    <Dropdown
                      trigger={["click"]}
                      open={messageControlsVisible}
                      arrow
                      onOpenChange={handleMessageControlsOpenChange}
                      key={'DELETE'}
                      overlay={
                        <ControlMenu>
                          <DeleteMenuItem
                            key={'DeleteReply'}
                            onDelete={handleDelete}
                            loading={isDeleteBusy}
                          />
                        </ControlMenu>
                      }
                    >
                      <TextControl>
                        <TextControlInner>
                          <FaEllipsisV/>
                        </TextControlInner>
                      </TextControl>
                    </Dropdown>
                  </Col>
                )
              }
            </Row>

            <ContentContainer
              css={replyType === AUDIO ? `padding: 0;
                background-color: transparent;
                margin-top: 0;
                border: 0;` : ``}
            >
              {
                replyType === TEXT && (
                  <ReplyTextContent text={text}/>
                )
              }
              {
                replyType === AUDIO && (
                  <ReplyAudioPlayer src={transcodedAudioUrl} duration={duration}/>
                )
              }
            </ContentContainer>
          </Col>
        </Row>
      </UserContainer>
    </S.RepliedMessage>
  )
}

export default withTheme(RepliedMessage);
