import { AsideHelper, Button, Card, CardDescription, CardInner, CardTitle, Col, PageInner, Row } from 'atoms';
import React from 'react';
import styled from 'styled-components';

const SErrorContainer = styled.div`
  min-height: 100vh;
  padding: 100px 15px 50px;
`;

type Props = {

}

type State = {
  hasError: boolean
}

class ErrorBoundary extends React.Component<Props, State> {
  state: State;
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
  }
  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <SErrorContainer>
          <PageInner css="margin: 0 auto; max-width: 400px;">
            <Card simple shadow>
              <CardInner css='padding-top: 30px; padding-bottom: 25px;'>
                <CardTitle>Oops! Unable to Load Page</CardTitle>
                <AsideHelper>We encountered an error</AsideHelper>
                <CardDescription css='margin-bottom: 20px;'>
                  Sorry, something went wrong.
                  Please click the button below and send us an email with your account email address. We'll get this resolved. Thanks!
                </CardDescription>
                <Row gutter={[12, 12]}>
                  <Col>
                    <Button href='/' radius='25px;'>back to home</Button>
                  </Col>
                  <Col>
                    <Button href='mailto:support@fanlist.com;' radius='25px;' type='default'>email support</Button>
                  </Col>
                </Row>
              </CardInner>
            </Card>
          </PageInner>
        </SErrorContainer>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
