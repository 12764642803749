import React from "react";
import { AutoComplete } from "antd";
import styled from "styled-components";
import { Input } from "atoms";
const { Option } = AutoComplete;

const SAutoComplete = styled(AutoComplete)<{ css?: string }>`
  ${(props) => props.css}
`;
SAutoComplete.defaultProps = {
  children: <Input />,
};

export default SAutoComplete;

export const AutoCompleteOption = styled(Option)``;
