import React, { useState, useMemo, useEffect, forwardRef } from 'react';
import styled from 'styled-components';
import { Menu, MenuProps } from 'antd';
import { Switch, message as messageAlert, Popover, Row, Col } from 'atoms';
import { FaTrashAlt, FaDownload, FaShareAlt, FaThumbtack, FaEye, FaTag, FaCopy } from 'icons';
import { DELETE, DOWNLOAD, TRUE } from 'consts';
import { DeleteCta } from 'molecules';

import { useMutation } from '@apollo/client';
import {
  TOGGLE_DELETE_MUTATION,
  TOGGLE_PINNED_MUTATION,
  TOGGLE_PRIVATE_MUTATION
} from 'graphql-api';
import MessageTagControl from 'apps/Dashboard/components/MessageTagControl';

export const [REPLY, SHARE, TAG, PIN, PRIVATE] = [`REPLY`, `SHARE`, `TAG`, `PIN`, `PRIVATE`];

const S = {};

const ControlMenu = styled(Menu)`
  min-width: 200px;
`;
const MenuItem = styled(Menu.Item)`
  padding: 10px 20px;
  display: flex;
  align-items: center;
  font-size: 15px;
  ${(props) => props.css}

  &.ant-dropdown-menu-item, &.ant-dropdown-menu-submenu-title {
    ${(props) => props.css}
  }

  .ant-dropdown-menu-item-icon {
    margin-right: 20px;
    min-width: 15px;
    color: var(--color-content-grey);
  }

  span {
    display: flex;
    flex: 1 0 0;
    justify-content: space-between;
  }

  :hover {
    color: var(--color-primary);

    .ant-dropdown-menu-item-icon {
      color: inherit;
    }
  }
`;

const DeleteMenuItem = ({ onDelete, loading }) => {
  return (
    <DeleteCta
      onDelete={onDelete}
      loading={loading}
    >
      Delete
    </DeleteCta>
  )
}

S.ShareBox = styled.div`
  padding: 15px 20px;
  cursor: pointer;

  :hover {
    color: var(--color-primary);
  }
`;
S.ShareText = styled.div`
  white-space: pre;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const ShareBox = ({ text, copy }) => {
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(copy);
      messageAlert.success({ content: `Copied` });
    } catch (e) {
      messageAlert.error({ content: `Something went wrong, please try later` });
    }
  }

  return (
    <S.ShareBox onClick={handleCopy}>
      <Row singleRow align={`middle`} gutter={15}>
        <Col flex={`1 0 0`}>
          <S.ShareText>
            {text}
          </S.ShareText>
        </Col>
        <Col>
          <FaCopy />
        </Col>
      </Row>
    </S.ShareBox>
  )
};

export const ShareControl = ({ text, copy, children }) => {
  const [visible, setVisible] = useState(false);
  return (
    <Popover
      trigger={['click']}
      placement={`bottomLeft`}
      open={visible}
      onOpenChange={e => setVisible(e)}
      content={<ShareBox text={text} copy={copy} />}
      zIndex={1061}
      simple
    >{ }
      {children}
    </Popover>
  )
}

const ShareMenuItem = ({ message, slug }) => {
  const text = `${window.location.hostname}/${slug}?mid=${message.id}&open=true`;
  const copy = `${window.location.origin}/${slug}?mid=${message.id}&open=true`;

  return (
    <ShareControl text={text} copy={copy}>
      <MenuItem icon={<FaShareAlt />}>
        Share
      </MenuItem>
    </ShareControl>
  )
}

export const TagControl = ({ message, children, onTag, show, showTags }) => {
  const [visible, setVisible] = useState(false);
  return (
    <Popover
      trigger={['click']}
      placement={`right`}
      open={visible}
      onOpenChange={e => setVisible(e)}
      content={
        <MessageTagControl
          message={message}
          onTag={onTag}
          show={show}
          showTags={showTags}
        />
      }
      zIndex={1061}
      simple
    >{ }
      {children}
    </Popover>
  )
}

const TagMenuItem = ({ message, onTag, show, showTags }) => {
  return (
    <TagControl
      message={message}
      onTag={onTag}
      show={show}
      showTags={showTags}
    >
      <MenuItem icon={<FaTag />} css={`width: 100%;`}>
        Tag
      </MenuItem>
    </TagControl>
  )
}

const EllipsisControls = (
  {
    controls,
    message,
    slug,
    onTag,
    onPinToggle,
    onPrivateToggle,
    onDelete,
    transcodedDownloadAudioUrl = '',
    messageType = 'audio',
    hasOwnDelete = false,
    show,
    showTags
  }) => {
  const [triggerToggleDelete, { loading: isDeleteBusy }] = useMutation(TOGGLE_DELETE_MUTATION, {
    variables: {
      messageId: message.id
    },
    onCompleted({ toggleDelete: { success, error } }) {
      if (success === TRUE) {
        onDelete();
        messageAlert.success({ content: 'Message deleted' });
      } else {
        messageAlert.error({ content: 'Something went wrong, please try later' });
      }
    }
  });

  const [triggerTogglePin] = useMutation(TOGGLE_PINNED_MUTATION, {
    variables: {
      messageId: message.id
    },
    onCompleted({ togglePinned: { success, error, message } }) {
      if (success === TRUE) {
        onPinToggle();
        if (message.pinned) {
          messageAlert.success({ content: "Message pinned" });
        } else {
          messageAlert.success({ content: "Message unpinned" });
        }
      } else {
        messageAlert.error({ content: "Someting went wrong, please try later" });
      }
    },
    optimisticResponse: {
      togglePinned: {
        success: TRUE,
        error: null,
        message: {
          __typename: message.__typename,
          id: message.id,
          pinned: !message.pinned
        }
      }
    }
  });

  const [triggerTogglePrivate] = useMutation(TOGGLE_PRIVATE_MUTATION, {
    variables: {
      messageId: message.id
    },
    onCompleted({ togglePrivate: { success, error, message } }) {
      console.log('run toggle private', success);
      if (success === TRUE) {
        onPrivateToggle();
        if (message.private) {
          messageAlert.success({ content: "Marked as private" });
        } else {
          messageAlert.success({ content: "Marked as public" });
        }
      } else {
        messageAlert.error({ content: "Someting went wrong, please try later" });
      }
    },
    optimisticResponse: {
      togglePrivate: {
        success: TRUE,
        error: null,
        message: {
          __typename: message.__typename,
          id: message.id,
          private: !message.private
        }
      }
    }
  });

  const handleDelete = async () => {
    if (hasOwnDelete)
      onDelete()
    else
      await triggerToggleDelete();
  }

  const handlePinToggle = async () => {
    await triggerTogglePin();
  }

  const handlePrivateToggle = async (e) => {
    await triggerTogglePrivate();
  }

  return (
    <ControlMenu>
      {
        controls.map(control => {
          switch (control) {
            case DOWNLOAD:
              return messageType === 'audio' ? (
                <MenuItem key={DOWNLOAD} icon={<FaDownload />}>
                  <a href={transcodedDownloadAudioUrl} download>
                    Download
                  </a>
                </MenuItem>
              ) : ''
            case SHARE:
              return (
                <ShareMenuItem message={message} slug={slug} key={SHARE} />
              )
            case TAG:
              return (
                <MenuItem key={TAG} css={`padding: 0 !important;`}>
                  <TagMenuItem
                    message={message}
                    onTag={onTag}
                    key={'TAG_ITEMS'}
                    show={show}
                    showTags={showTags}
                  />
                </MenuItem>
              )
            case PIN:
              return (
                <MenuItem key={PIN} icon={<FaThumbtack />}>
                  Pin
                  <Switch checked={message.pinned} onChange={handlePinToggle} />
                </MenuItem>
              )
            case PRIVATE:
              return (
                <MenuItem key={PRIVATE} icon={<FaEye />}>
                  Private
                  <Switch checked={message.private} onChange={handlePrivateToggle} />
                </MenuItem>
              )
            case DELETE:
              return (
                <MenuItem key={DELETE} icon={<FaTrashAlt />}>
                  <DeleteMenuItem onDelete={handleDelete} loading={isDeleteBusy} />
                </MenuItem>
              )
          }
        })
      }
    </ControlMenu>
  )
};

export default EllipsisControls;
