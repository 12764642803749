import React from 'react';
import styled from 'styled-components';
import { Collapse } from 'antd';

const { Panel } = Collapse;

const SOneCollapse = styled(Collapse)`
`;

const OnePanel = styled(Panel)`
  ${SOneCollapse} > && > .ant-collapse-header {
    display: none;
  }
  .ant-collapse-ghost > & > .ant-collapse-content {
    > .ant-collapse-content-box {
      padding: 0;
    }
  }
`;

type Props = {
  open: boolean,
  children: React.ReactNode[]
}
const OneCollapse = ({ children, open, ...props }: Props) => {
  return (
    <SOneCollapse activeKey={open ? 'one' : null} ghost expandIcon={null} {...props}>
      <OnePanel key={'one'} header={null} showArrow={false}>
        {children}
      </OnePanel>
    </SOneCollapse>
  )
}

OneCollapse.defaultProps = {
  open: false
}

export {
  OneCollapse
}