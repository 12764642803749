import React, { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { withPublicPageState } from "apps/PublicPage/context";
import {
  PUBLIC_SPONSORS_BY_SHOW,
} from "apps/PublicPage/graphql";

import SponsorList from "apps/PublicPage/components/SponsorList";
import { SectionHeader, SectionTitle } from "apps/PublicPage/components/FanPage";
import {
  Card,
  CardInner,
  NoContentCard,
  Skeleton
} from "atoms";
import { PageInner } from "apps/PublicPage/atoms";
import styled from "styled-components";
import { useQuery } from "@apollo/client";
import { SShow } from "apps/PublicPage/containers/FanPageContainer";

const ActiveSponsors = (
  {
    show,
    publicPageState: { isLoggedIn },
  }
) => {
  const {id} = show;

  const {data: sponsorsData, loading: loadingSponsors, refetch} = useQuery(
    PUBLIC_SPONSORS_BY_SHOW,
    {
      variables: {
        showId: id,
      },
    }
  );


  const sponsors = useMemo(() => sponsorsData?.publicSponsorsByShow || [], [sponsorsData]);

  const hasSponsors = useMemo(() => sponsors.length > 0, [sponsors]);

  const refetchSponsors = useCallback(() => {
    return refetch();
  }, [refetch]);

  if (loadingSponsors) {
    return (
      <SShow>
        <PageInner>
          <Skeleton />
        </PageInner>
      </SShow>
    );
  }

  return (
    <>
      <SectionHeader css="">
        <SectionTitle>Sponsors</SectionTitle>
      </SectionHeader>
      <div>
        {hasSponsors ? (
          <SponsorList
            sponsors={sponsors}
            isLoggedIn={isLoggedIn}
            onSponsorCreate={refetchSponsors}
          />
        ) : (
          <NoContentCard shadow>
            Please check back. We'll have some sponsors soon!
          </NoContentCard>
        )}
      </div>
    </>
  )
}

export default withPublicPageState(ActiveSponsors);