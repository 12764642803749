import React from 'react';
import styled from 'styled-components';
import { Button } from 'atoms';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faKeyboard, faMicrophone, faPlay } from '@fortawesome/free-solid-svg-icons';

export const RecordRow = styled.div`
  display: flex;
  height: 50px;
  align-items: stretch;
  ${ props => props.css }
`;

export const VisualizationContainer = styled.div`
  flex: 1 0 100px;
  min-width: 0;
  margin-right: 15px;
  display: flex;
  align-items: stretch;
  ${ props => props.css }
`;

export const RecordCTAContainer = styled.div`
  width: 50px;
  display: flex;
  align-items: stretch;
  ${ props => props.css }
`;

export const MessageCTAContainer = styled.div`
  display: flex;
  align-items: stretch;
  width: 100%;
`;

export const RecordVisualization = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--color-border);
  border-radius: 3px;
  ${ props => props.css }
`;

export const RecordCTAButton = styled(Button)`
  width: 50px;
  padding: 5px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${ props => props.css }

  &.ant-btn-icon-only {
    width: 50px;
    font-size: 12px;
    
    ${ props => props.css }
  }
`;

export const MessageCTAButton = styled(Button)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  gap: 10px;
  max-width: 100%;

  > span {
    font-size: 12px;
  }
`;

export const RecordYourMessage = styled(RecordVisualization)`
  display: block;
  padding: 0 20px;
  background-color: #f6f5f7;
  text-align: center;
  line-height: 50px;
  white-space: pre;
  letter-spacing: 0.5px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const RecordButton = (props) => (
  <MessageCTAButton type="primary" { ...props }>
    <FontAwesomeIcon icon={ faMicrophone }/>
    <span>RECORD</span>
  </MessageCTAButton>
);

export const TextContentButton = (props) => (
  <MessageCTAButton type="primary" { ...props }>
    <FontAwesomeIcon icon={ faKeyboard }/>
    <span>TEXT</span>
  </MessageCTAButton>
);

export const StyledRecordReady = styled.div``;

export const RecordGraphContainer = styled(RecordVisualization)`
  background-color: transparent;
`;

export const RecordGraph = styled.div`
  width: 50px;
  ${ props => props.css }
`;

export const SettingButton = (props) => (
  <RecordCTAButton type="default" icon={ <FontAwesomeIcon icon={ faCog }/> } { ...props } />
);

export const RecordFooter = styled.footer`
  padding: 20px 0 0;
`;

export const FooterRow = styled.div`
  display: flex;
  align-items: center;
`;

export const FooterPrimaryCol = styled.div`
  flex: 1 0 100px;
`;

export const FooterSecondaryCol = styled.div`
  width: 100px;
  margin-right: 15px;
`;

export const ButtonIcon = styled(FontAwesomeIcon)`
  margin-right: 5px;
`;

export const PlayButton = (props) => (
  <RecordCTAButton type="primary" icon={ <FontAwesomeIcon icon={ faPlay }/> } { ...props } />
);
