import React, { useState, useMemo } from 'react';
import { createGlobalStyle } from 'styled-components';
import { withTheme } from 'styled-components';
import { CardElement } from '@stripe/react-stripe-js';
import { FormItemError } from 'atoms';
import { Theme } from 'types';
import { StripeCardElementOptions } from '@stripe/stripe-js';

const CardElementStyle = createGlobalStyle`
  .StripeElement {
    display: flex;
    align-items: center;
    height: 45px;
    padding: 6px 15px;
    border: 1px solid var(--input-border);
    width: 100%;
    border-radius: 2px;

    .__PrivateStripeElement {
      flex: 1 0 0;
    }
  }

  .StripeElement--focus {
  }

  .StripeElement--invalid {
    border-color: var(--color-primary);
  }

  .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }
`;

type Props = {
  onChange?: (event: object) => void
  onCompleteChange?: (complete: boolean) => void
  theme: Theme
}
const CardDetails = ({onChange, onCompleteChange, theme: {colors: {primary}}}: Props) => {
  const [error, setError] = useState(null);
  const errorMessage = useMemo(() => error?.message, [error]);

  const CARD_ELEMENT_OPTIONS = {
    iconStyle: "solid",
    style: {
      base: {
        iconColor: primary,
        color: "rgba(0, 0, 0, 0.85)",
        fontWeight: 500,
        fontSize: "15px",
      },
      invalid: {
        iconColor: primary,
        color: primary,
      },
    },
  } as StripeCardElementOptions;
  const handleChange = (event) => {
    if (event.error) {
      setError(event.error);
    } else {
      setError(null);
      if (event.complete) {
        onCompleteChange && onCompleteChange(true);
      } else {
        onCompleteChange && onCompleteChange(false);
      }
    }
    onChange && onChange(event);
  }
  return (
    <>
      <CardElementStyle/>
      <CardElement options={CARD_ELEMENT_OPTIONS} onChange={handleChange}/>
      {
        errorMessage && (
          <FormItemError>{errorMessage}</FormItemError>
        )
      }
    </>
  );
}

export default withTheme(CardDetails);
