import { useQuery } from "@apollo/client";
import { faUnlock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Flex, Row } from "antd";
import { PageContentColumnLeft, PageContentColumnRight, PageContentRow } from "apps/PublicPage/components/FanPage";
import PurchasePerk from "apps/PublicPage/components/PurchasePerk";
import SuperfanList from "apps/PublicPage/components/SuperfanList";
import { FanPageContext, PublicPageContext } from "apps/PublicPage/context";
import { PUBLIC_PERK_QUERY } from "apps/PublicPage/graphql";
import { A, afterModalClose, Amount, AsideHelper, Button, Card, CardDescription, CardFooter, CardHeader, CardImage, CardInner, CardTitle, Div, Link, message, Modal, ModalScrollInner, ModalTitle, NoContentCard, SkeletonCard, Span } from "atoms";
import Confetti from "components/Confetti";
import ViewPerk from "components/ViewPerk";
import { SIGNUP } from "consts";
import { FaChevronLeft } from "icons";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { PublicPerkType } from "types";
import { formatDate, formatFromIos } from "utils/Datetime";
import styled from 'styled-components';
import { StyledSwiper, SwiperSlide } from 'utils/swiperConfig';
import SwiperCore, { Navigation, Pagination, Thumbs } from 'swiper';
import 'swiper/swiper-bundle.css';

SwiperCore.use([Navigation, Pagination, Thumbs]);

const PerkDetails = () => {

  const { uuid } = useParams();
  const { isLoggedIn, openAuthModal, authModalVisible } = useContext(PublicPageContext);
  const { show } = useContext(FanPageContext);
  const {
    superfansPluralName,
    superfansSingularName,
  } = show;

  const { data, loading, error, refetch } = useQuery<{publicPerk: PublicPerkType}>(PUBLIC_PERK_QUERY, {
    variables: {
      uuid: uuid
    },
    notifyOnNetworkStatusChange: true
  })
  const perk = data?.publicPerk;
  
  const [purchaseIntended, setPurchaseIntended] = useState<boolean>(false);
  const [purchaseVisible, setPurchaseVisible] = useState<boolean>(false);
  const [thanksVisible, setThanksVisible] = useState<boolean>(false);
  const [viewVisible, setViewVisible] = useState<boolean>(false);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0); 
  const superfansBlock = useMemo(
    () =>
      perk ? (
          <Div css="font-size: 15px;" className="pt-1 pb-1">
            <span style={{fontWeight: 500}}>
              {perk.purchasesNumber}{" "}
              {perk.purchasesNumber > 1
                ? superfansPluralName
                : superfansSingularName}
            </span>
            &nbsp;
            <Span color="contentGrey">got this perk</Span>
          </Div>
      ) : '',
    [perk]
  );
  const [superfansModalVisible, setSuperfansModalVisible] =
    useState<boolean>(false);

  const handlePurchaseSuccess = ({ intendMessage }) => {
    setPurchaseVisible(false);
    afterModalClose(() => {
      if (intendMessage) {
        message.success(intendMessage);
        setViewVisible(true);
      } else {
        setThanksVisible(true);
        setViewVisible(true);
      }
    });
  };
  const handleGetPerk = () => {
    if (isLoggedIn) {
      setPurchaseVisible(true);
    } else {
      openAuthModal(SIGNUP);
      setPurchaseIntended(true);
    }
  };
  useEffect(() => {
    if (!viewVisible) {
      setThanksVisible(false);
    }
  }, [viewVisible]);
  useEffect(() => {
    if(purchaseIntended && isLoggedIn) {
      refetch().then(({data: { publicPerk }}) => {
        if(!publicPerk.unlocked) {
          setPurchaseVisible(true);
        }
      });
    }
  }, [isLoggedIn, purchaseIntended]);
  useEffect(() => {
    if (!authModalVisible) {
      setPurchaseIntended(false);
    }
  }, [authModalVisible]);

  if (loading) {
    return (
      <SkeletonCard rows={3} />
    )
  } else if (!perk) {
    return (
      <NoContentCard>
        No perk result found
      </NoContentCard>
    )
  }
  const renderImageComponents = () => {
    const breakpoints = {
      320: { slidesPerView: 3, spaceBetween: 10 },
      640: { slidesPerView: 4, spaceBetween: 10 },
      768: { slidesPerView: 4, spaceBetween: 10 },
      1024: { slidesPerView: 4, spaceBetween: 5 },
    };
    if (perk.perkImages.length > 1) {
      return (
        <>
          <StyledSwiper
            slidesPerView={1}
            spaceBetween={30}
            loop={true}
            navigation={true}
            thumbs={{ swiper: thumbsSwiper }}
            style={{ borderRadius: '8px' }}
            onSlideChange={(swiper) => setSelectedImageIndex(swiper.realIndex)} 
          >
            {perk.perkImages.map((image, index) => (
              <SwiperSlide key={image.id}>
                <CardImage
                  image={image.imageUrl}
                  aspectRatio={2 / 1}
                  css="border-radius: 8px"
                />
              </SwiperSlide>
            ))}
          </StyledSwiper>
          <StyledSwiper
            onSwiper={setThumbsSwiper}
            spaceBetween={5}
            slidesPerView={4}
            freeMode={true}
            watchSlidesProgress={true}
            style={{ marginTop: '10px' }}
            className="mySwiper"
            breakpoints={breakpoints}
          >
            {perk.perkImages.map((image, index) => (
              <SwiperSlide key={image.id}>
                <div
                  style={{
                    overflow: 'hidden',
                    borderRadius: '4px',
                    height: 'auto',
                    opacity: selectedImageIndex === index ? 1 : 0.4, 
                  }}
                >
                  <img
                    src={image.imageUrl}
                    style={{
                      width: '100%',
                      height: '100%',
                    }}
                    alt="perk image"
                  />
                </div>
              </SwiperSlide>
            ))}
          </StyledSwiper>
        </>
      );
    } else {
      return (
        <CardImage
          image={perk.perkImages.length === 1 ? perk.perkImages[0].imageUrl : ""}
          aspectRatio={2 / 1}
          css="border-radius: 8px"
        />
      );
    }
  };

  return (
    <div className="PerkDetails">
      <div className="mb-3">
        <Link to={`/${show.slug}/perks`}>
          <Flex align="center" gap={10}>
            <FaChevronLeft />
            <Span>Back to List</Span>
          </Flex>
        </Link>
      </div>
      <PageContentRow>
        <PageContentColumnLeft>
          <Card shadow>
            <CardInner>
              <CardTitle className="mb-3" large>{perk.title}</CardTitle>
              {renderImageComponents()}
              <CardDescription className="mt-3">
                <div
                  dangerouslySetInnerHTML={{ __html: perk.description }}
                ></div>
              </CardDescription>
            </CardInner>
          </Card>
        </PageContentColumnLeft>
        <PageContentColumnRight>
          {
            perk.unlocked ? (
              <Card shadow simple>
                <CardInner>
                  <CardTitle>Your Purchase Details</CardTitle>
                </CardInner>
                <CardInner css="padding: 15px 25px;">
                  <Row align="middle" justify="space-between">
                    <Col>
                    <Amount color="green" style={{fontWeight: 500}}>{perk.purchasedPrice === 0 ? 'FREE' : `$${perk.purchasedPrice / 100}`}</Amount>
                    </Col>
                    <Col>
                      <div>{formatFromIos(perk.unlockedAt)}</div>
                    </Col>
                  </Row>
                </CardInner>
                <CardInner>
                <AsideHelper>
                  <Row align="middle" gutter={8}>
                    <Col>
                      <FontAwesomeIcon icon={faUnlock} />
                    </Col>
                    <Col>
                      Unlocked Purchase
                    </Col>
                  </Row>
                </AsideHelper>
                  <CardDescription>
                    <div
                      dangerouslySetInnerHTML={{ __html: perk.unlockedDescription }}
                    ></div>
                  </CardDescription>
                </CardInner>
                {perk.showClaimButton && (
                  <CardInner>
                      <Button href={perk.claimButtonLink} target="_blank" block css="padding: 15px 20px !important;">
                        {perk.claimButtonLabel}
                      </Button>
                  </CardInner>
                )}
                {
                  /* show only if show_purchases_number_on_perk is toggled on */
                  show.showPurchasesNumberOnPerk && (
                    <CardInner>
                      {show.showPurchasedFansOnPerk && perk.superfans.length > 0 ? (
                          <A
                            block
                            color="content"
                            onClick={() => setSuperfansModalVisible(true)}
                          >
                            {superfansBlock}
                          </A>
                        ) : (
                          superfansBlock
                        )}
                    </CardInner>
                  )
                }
              </Card>
            ) : perk.status === 'published' && (
              <Card shadow simple>
                <CardInner>
                  <CardTitle>Get This Perk</CardTitle>
                </CardInner>
                <CardInner>
                  <Amount color="green" className="mb-2" style={{fontWeight: 500}}>{perk.amountCents === 0 ? 'FREE' : `$${perk.amountCents / 100}`}</Amount>
                  <Button block onClick={handleGetPerk}>get this perk</Button>
                </CardInner>
                {
                  /* show only if show_purchases_number_on_perk is toggled on */
                  show.showPurchasesNumberOnPerk && (
                    <CardInner>
                      {show.showPurchasedFansOnPerk && perk.superfans.length > 0 ? (
                          <A
                            block
                            color="content"
                            onClick={() => setSuperfansModalVisible(true)}
                          >
                            {superfansBlock}
                          </A>
                        ) : (
                          superfansBlock
                        )}
                    </CardInner>
                  )
                }
              </Card>
            )
          }
        </PageContentColumnRight>
      </PageContentRow>
      <Modal
        open={purchaseVisible}
        onCancel={() => setPurchaseVisible(false)}
        simple
        width={380}
        destroyOnClose
      >
        <div className="pb-2">
          <CardInner>
            <Row align="middle">
              <Col flex="1 0 0">
                <CardTitle>{ perk.amountCents === 0 ? 'Get This Perk' : 'One-Time Payment'}</CardTitle>
              </Col>
              <Col>
                <Amount color="green">{perk.amountCents === 0 ? 'FREE' : `$${perk.amountCents / 100}`}</Amount>
              </Col>
            </Row>
          </CardInner>
          <CardInner>
            <PurchasePerk
              perk={perk}
              onSuccess={handlePurchaseSuccess}
            />
          </CardInner>
        </div>
      </Modal>
      <Modal
        open={viewVisible}
        onCancel={() => setViewVisible(false)}
        simple
        destroyOnClose
        zIndex={1100}
        mask={!thanksVisible}
        width={550}
      >
        <CardInner>
          <AsideHelper style={{marginBottom: '12px'}}>
            <Row align="middle" gutter={8}>
              <Col>
                <FontAwesomeIcon icon={faUnlock} />
              </Col>
              <Col>
                Unlocked Purchase
              </Col>
            </Row>
          </AsideHelper>
          <ViewPerk perk={perk} />
        </CardInner>
      </Modal>
      <Modal
        open={superfansModalVisible}
        simple
        onCancel={() => setSuperfansModalVisible(false)}
        centered
        width="450px"
        title={
          <>
            <ModalTitle>
              {perk.purchasesNumber}{" "}
              {perk.purchasesNumber > 1
                ? superfansPluralName
                : superfansSingularName}
            </ModalTitle>
            <AsideHelper single>purchased this perk</AsideHelper>
          </>
        }
      >
        <ModalScrollInner css="max-height: calc(100vh - 85px - 45px * 2);">
          <SuperfanList superfans={perk.superfans} sortCompare={false} />
        </ModalScrollInner>
      </Modal>
      {thanksVisible && <Confetti zIndex={1090} mask />}
    </div>
  )
};

export default PerkDetails;
