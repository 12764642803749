import styled from "styled-components";
import { InputNumber as AntInputNumber, InputNumberProps as AntdInputNumberProps } from "antd";

export interface InputNumberProps extends AntdInputNumberProps {
  css?: string
}
const InputNumber = styled(AntInputNumber)<InputNumberProps>`
`;

InputNumber.defaultProps = {
  size: 'large'
};

export default InputNumber;
