import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Alert } from 'atoms';

import {
    RecordRow,
    VisualizationContainer,
    RecordCTAContainer,
    RecordYourMessage,
    RecordButton
} from './common';

const StyledRecordNormal = styled.div``;
const AlertContainer = styled.div`
    margin-top: 20px;
`;

const RecordNormal = ({ onStart, permissionDenied }) => {
    return (
        <StyledRecordNormal>
            <RecordRow>
                <VisualizationContainer>
                    <RecordYourMessage>record your greeting</RecordYourMessage>
                </VisualizationContainer>
                <RecordCTAContainer>
                    <RecordButton onClick={() => onStart()} disabled={permissionDenied} />
                </RecordCTAContainer>
            </RecordRow>
            {
                permissionDenied && (
                    <AlertContainer>
                        <Alert
                            type={'error'}
                            description={`Sorry, our recorder won't work on this browser due to browser limitations. Please use Chrome or update this browser and try again. Thanks!`}
                        />
                    </AlertContainer>
                )
            }
        </StyledRecordNormal>
    );
};

RecordNormal.defaultProps = {
    permissionDenied: false
};

export default RecordNormal;
