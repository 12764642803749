import { gql } from '@apollo/client';

export const notificationFields = gql`
    fragment notificationFields on Notification {
        id
        notificationText
        senderIsFan
        seen
        sender {
            id,
            displayName
            avatarUrl
        }
        sentAgo
    }
`;

export const NOTIFICATIONS_QUERY = gql`
    query notifications {
        notifications {
            ...notificationFields
        }
    }
    ${notificationFields}
`;

