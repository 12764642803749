import React, { useCallback, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { Row, Col, Button, CardTitle } from "atoms";
import ChatMessageList from "./components/ChatMessageList";
import { useMemo } from "react";
import { Show, User } from "types";
import ChatForm from "./components/ChatForm";
import ChatPaywall from "./components/ChatPaywall";
import { useQuery } from "@apollo/client";
import { PUBLIC_SHOW_CHAT_CONTACTS_QUERY } from "graphql-api/chat";

const SShowChat = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  height: 100%;
  min-height: 200px;
`;

const MessagePanel = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const PanelInner = styled.div`
  padding: 13px 15px;
  border-bottom: 1px solid var(--color-border);
  :last-child {
    border-bottom: none;
  }
`;
const PanelHeader = styled(PanelInner)``;
const MessageListContainer = styled.div`
  flex: 1 0 0;
  min-height: 0;
  border-bottom: 1px solid var(--color-border);
  :last-child {
    border-bottom: none;
  }
`;

const ChatformContainer = styled(PanelInner)`
  padding-top: 15px;
  padding-bottom: 15px;
`;
const SButton = styled(Button)`
  border-radius: 25px;
  padding-top: 12px;
  padding-bottom: 12px;
`;
const appear = keyframes`
  from {
    pointer-events: none;
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0px);
  }
`;

export const ShowChatContainer = styled.div<{ inPaywall: boolean }>`
  position: fixed;
  bottom: 90px;
  left: 30px;
  width: 400px;
  ${(props) =>
    props.inPaywall
      ? `
      top: 130px;
    `
      : `
      top: 30px;
    `}
  z-index: 100;
  background-color: white;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.03);
  border-radius: 4px;
  border: 1px solid var(--color-border);
  animation: ${appear} 250ms ease-out;

  &.box-leave {
    transition: all 0.3s ease-out;
    opacity: 1;
    transform: translateY(0px);
  }
  &.box-leave.box-leave-active {
    transform: translateY(20px);
    opacity: 0;
  }

  ${(props) =>
    props.theme.isMobile &&
    `
        ${props.inPaywall
      ? `
          top: 130px;
        `
      : `
          top: 30px;
        `
    }
        bottom: 96px;
        left: 10px;
        right: 10px;
        width: auto;
        height: auto;
    `}
`;

type Props = {
  show: Show;
  me?: User;
  refetchShowQuery: Function;
  onLogin?: () => {};
  onSignUp?: () => {};
};

const ShowChat = (props: Props) => {
  const { show, refetchShowQuery, me, onLogin, onSignUp } = props;
  const { viewerHasChatAccess } = show;
  const isLoggedIn = useMemo(() => !!me, [me]);
  const { data: publicChatContactsData, loading } = useQuery(
    PUBLIC_SHOW_CHAT_CONTACTS_QUERY,
    {
      variables: {
        showId: show.id,
      },
    }
  );
  const publicChatContacts = useMemo(
    () => publicChatContactsData?.publicShowChatContacts || [],
    [publicChatContactsData]
  );
  const handleLogin = useCallback(() => {
    onLogin && onLogin();
  }, [onLogin]);

  const handleSignUp = useCallback(() => {
    onSignUp && onSignUp();
  }, [onSignUp]);

  return (
    <SShowChat>
      <MessagePanel>
        <PanelHeader>
          <CardTitle>Chat Room</CardTitle>
        </PanelHeader>
        {
          viewerHasChatAccess ? (
            <>
              <MessageListContainer>
                <ChatMessageList show={show} me={me} />
              </MessageListContainer>
              <ChatformContainer>
                <ChatForm show={show} chatContacts={publicChatContacts} />
              </ChatformContainer>
            </>
          ) : (
            show.chatRoomForMembers ? (
              <ChatPaywall show={show} refetchShowQuery={refetchShowQuery} />
            ) : (
              <>
                <MessageListContainer>
                  <ChatMessageList show={show} me={me} />
                </MessageListContainer>
                <ChatformContainer>
                  {isLoggedIn ? (
                    <ChatForm show={show} chatContacts={publicChatContacts} />
                  ) : (
                    <Row gutter={[12, 12]}>
                      <Col flex="1 0 100px">
                        <Button block type={`default`} onClick={handleLogin}>
                          login
                        </Button>
                      </Col>
                      <Col flex="1 0 100px">
                        <Button block onClick={handleSignUp}>
                          sign up
                        </Button>
                      </Col>
                    </Row>
                  )}
                </ChatformContainer>
              </>
            )
          )
        }
      </MessagePanel>
    </SShowChat>
  );
};

export default ShowChat;
