import React from 'react';

import {
    FaEnvelopeOpenText,
    FaDesktop,
    FaInstagram,
    FaXTwitter,
    FaFacebookF,
    FaLinkedinIn,
    FaTikTok,
    FaYoutube,
    FaPodcast,
    FaSpotify,
    FaGoogle,
    FaRss
} from 'icons';

export const socialLinks = {
    email: {
        color: `#000`,
        Icon: (props) => <FaEnvelopeOpenText {...props} />,
        label: 'Email',
    },
    website: {
        color: `#4285F4`,
        Icon: (props) => <FaDesktop {...props} />,
        label: 'Website',
    },
    instagram: {
        color: `#C13584`,
        Icon: (props) => <FaInstagram {...props} />,
        label: 'Instagram'
    },

    twitter: {
        Icon: (props) => <FaXTwitter {...props} />,
        color: '#000',
        label: 'X'
    },
    facebook: {
        Icon: (props) => <FaFacebookF {...props} />,
        color: '#4267B2',
        label: 'Facebook'
    },
    linkedin: {
        Icon: (props) => <FaLinkedinIn {...props} />,
        color: '#0976b4',
        label: 'Linkedin'
    },
    tiktok: {
        Icon: (props) => <FaTikTok {...props} />,
        color: '#000',
        label: 'TikTok',
    },
    youtube: {
        Icon: (props) => <FaYoutube {...props} />,
        color: '#FF0000',
        label: 'YouTube',
        listenOn: true
    },
    applePodcast: {
        Icon: (props) => <FaPodcast {...props} />,
        color: '#B150E2',
        label: 'Apple Podcasts',
        listenOn: true
    },
    spotify: {
        Icon: (props) => <FaSpotify {...props} />,
        color: '#1DB954',
        label: 'Spotify',
        listenOn: true
    },
    rssFeed: {
        Icon: (props) => <FaRss {...props} />,
        color: '#F26522',
        label: 'RSS Feed'
    }
};