import React, { useRef, useCallback, useEffect, useLayoutEffect } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import { CHAT_MESSAGES_QUERY, SUBSCRIPTION_ADD_CHAT_MESSAGE, SUBSCRIPTION_DELETE_CHAT_MESSAGE } from 'graphql-api';
import { useMemo } from 'react';
import { Col, Row, Avatar, Skeleton } from 'atoms';
import ChatMessageItem from './ChatMessageItem';
import { Show, User } from 'types';
import { parseDate } from 'utils/Datetime';
import WelcomeMessageItem from './WelcomeMessageItem';

const ScrollContainer = styled.div`
  height: 100%;
  overflow: auto;
  overscroll-behavior: none;
`;
const SChatMessageList = styled.div`
  padding: 13px 15px;
`;
const ChatMessageItemContainer = styled.div`
  margin-bottom: 15px;
`;
const LoadingChatMessageItemContainer = styled.div`
  background-color: white;
  margin-bottom: 
`;
const MsgGroupContainer = styled.div`
  margin-bottom: 10px;
`;
const GroupDate = styled.div`
  margin-bottom: 15px;
  text-align: center;
  color: var(--color-content-grey);
`;
const formatGroupDate = (iosDate) => {
  const date = new Date(iosDate);
  const parsed = parseDate(date);
  return { 'short': `${parsed['Mon']} ${parsed['d']}`, year: parsed['yyyy'] };
}

type Props = {
  show: Show
  me: User
}

const ChatMessageList = (props: Props) => {

  const ref = useRef();
  const containerRef = useRef();
  const scrollRef = useRef({ scrollHeight: 0, scrollTop: 0 });

  const { show, me } = props;
  const { viewerIsAdmin } = show;
  const { data, loading, subscribeToMore, fetchMore } = useQuery(CHAT_MESSAGES_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: {
      showId: show.id,
      last: 100
    }
  });
  const messages = useMemo(() => {
    if (containerRef.current) {
      console.log('at useMemo', containerRef.current.scrollHeight, containerRef.current.scrollTop);
      scrollRef.current.scrollHeight = containerRef.current.scrollHeight;
      scrollRef.current.scrollTop = containerRef.current.scrollTop;
    }
    return data?.chatMessages.edges.map(edge => edge.node) || []
  }, [data]);
  const msgGroupsByDate = useMemo(() => {
    const groups = [];
    let currGroup = {
      date: null,
      dateFormatted: null,
      year: null,
      items: []
    };
    for (const message of messages) {
      const iDate = message.createdAt;
      const { short: iDateFormatted, year } = formatGroupDate(iDate)
      if (iDateFormatted !== currGroup.dateFormatted || year !== currGroup.year) {
        currGroup = {
          date: iDate,
          dateFormatted: iDateFormatted,
          year,
          items: []
        }
        groups.push(currGroup);
      }
      currGroup.items.push(message);
    }
    return groups;
  }, [messages]);
  const lastMessage = useMemo(() => messages.length > 0 ? messages[messages.length - 1].id : null, [messages]);
  const pageInfo = useMemo(() => data?.chatMessages.pageInfo, [data]);

  const handleScroll = useCallback((e) => {
    if (!loading && pageInfo.hasPreviousPage) {
      const scrollTop = e.target.scrollTop;
      if (scrollTop < 300) {
        if (containerRef.current) {
        }
        fetchMore({
          variables: {
            cursor: pageInfo.startCursor,
            last: 20
          },
        });
      }
    }
  }, [loading, pageInfo]);

  useEffect(() => {
    const unsubscribe = subscribeToMore({
      document: SUBSCRIPTION_ADD_CHAT_MESSAGE,
      variables: {
        showId: show.id
      },
      updateQuery(prev, { subscriptionData }) {
        if (!subscriptionData.data?.chatMessageAdded?.chatMessage) return prev;
        return {
          chatMessages: { ...prev.chatMessages, edges: [...prev.chatMessages.edges, { node: subscriptionData.data.chatMessageAdded.chatMessage }] }
        }
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const unsubscribe = subscribeToMore({
      document: SUBSCRIPTION_DELETE_CHAT_MESSAGE,
      variables: {
        showId: show.id
      },
      updateQuery(prev, { subscriptionData }) {
        if (!subscriptionData.data?.chatMessageRemoved?.id) return prev;
        return {
          chatMessages: { ...prev.chatMessages, edges: [...prev.chatMessages.edges].filter(ed => ed.node.id !== subscriptionData.data.chatMessageRemoved.id) }
        }
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (!lastMessage) {
      return () => { };
    }
    if (ref.current) {
      ref.current.scrollIntoView(false);
    }
  }, [lastMessage, ref]);

  useLayoutEffect(() => {
    if (messages.length > 0) {
      console.log('at useLayoutEffect', containerRef.current.scrollHeight, containerRef.current.scrollTop);
      containerRef.current.scrollTop = containerRef.current.scrollTop + containerRef.current.scrollHeight - scrollRef.current.scrollHeight;
    }
  }, [messages]);
  useEffect(() => {
    if (messages.length > 0) {
    }
  }, [messages]);

  return (
    <ScrollContainer onScroll={handleScroll} ref={containerRef}>
      <SChatMessageList ref={ref}>
        {
          (pageInfo?.hasPreviousPage || loading) && (
            <>
              <LoadingChatMessageItemContainer>
                <Skeleton avatar={{ shape: 'circle', size: 44 }} title={false} loading active paragraph={{ rows: 2 }} />
              </LoadingChatMessageItemContainer>
            </>
          )
        }
        {
          msgGroupsByDate.map(group => (
            <MsgGroupContainer key={group.date}>
              <GroupDate>{group.dateFormatted}</GroupDate>
              {
                group.items.map(message => (
                  <ChatMessageItemContainer key={message.id}>
                    {
                      message.kind === 'conversation' ? (
                        <ChatMessageItem message={message} me={me} viewerIsAdmin={viewerIsAdmin} />
                      ) : (
                        <WelcomeMessageItem message={message} me={me} viewerIsAdmin={viewerIsAdmin} />
                      )
                    }
                  </ChatMessageItemContainer>
                ))
              }
            </MsgGroupContainer>
          ))
        }
      </SChatMessageList>
    </ScrollContainer>
  )
}

export default ChatMessageList;
