import styled from 'styled-components';
import { Button as AntButton, ButtonProps } from 'antd';
import React from 'react';

type ButtonType = React.ComponentProps<typeof AntButton>

interface ButtonPropsType extends Omit<Omit<ButtonProps, "type" | "size">, "shape"> {
   type?: "link" | "text" | "ghost" | "default" | "primary" | "dashed" | "green" | "yellow"
   transform?: string
   simple?: boolean
   radius?: string
   css?: string
   shape?: "circle" | "round" | "default"
   size?: ButtonProps["size"] | "semi-middle"
}

// @ts-ignore
const Button = styled(React.forwardRef((props: ButtonPropsType, ref: React.Ref<HTMLElement>) => <AntButton ref={ref} {...props} />)) <ButtonPropsType>`
   text-transform: ${props => props.transform};
   ${props => !['default', 'link', 'dashed', 'text'].includes(props.type) && `
      background-color: var(--color);
      border-color: var(--color);
      color: white;
      box-shadow: none;
      border: 0;
      &:hover, &:focus {
         color: white;
         background-color: var(--color-hover);
         border-color: var(--color-hover);
      }
   `}
   ${props => props.type === 'default' && `
      --color-hover: var(--color-primary);
      &:hover, &:focus, &:focus:hover {
         color: var(--color-hover);
         border-color: var(--color-hover);
      }
   `}
   ${props => props.type === 'primary' && `
      --color: var(--color-primary);
      --color-hover: var(--color-primary-hover);
   `}
   ${props => props.type === 'green' && `
      --color: var(--color-green);
      --color-hover: var(--color-green-hover);
   `}
   ${props => props.type === 'yellow' && `
      --color: var(--color-yellow);
      --color-hover: var(--color-yellow-hover);
   `}
   ${props => props.size === 'middle' && `
      height: auto;
      padding: 15px 20px;
      font-size: 12px;
      letter-spacing: 1px;
      &[href] {
         padding: 10px 20px;
      }
   `}
   ${props => props.size === 'semi-middle' && `
      height: auto;
      padding: 12px 15px;
      font-size: 12px;
      letter-spacing: 1px;
      &[href] {
         padding: 10px 15px;
      }
   `}
   ${props => props.size === 'small' && `
      height: 30px;
      padding: 0.5px 10px;
      font-size: 12px;
      letter-spacing: 1px;
      
      &.ant-btn-sm {
         height: 30px;
         padding: 0.5px 10px;
         font-size: 12px;
      }
   `}
   ${props => props.shape === 'default' && `
      border-radius: 3px;
   `}
   &.ant-btn-icon-only {
      padding: 5px;
      display: ${props => props.block ? `flex` : `inline-flex`};
      align-items: center;
      justify-content: center;
      position: relative;
      min-width: 40px;
      & > *:first-child {
         position: absolute;
         top: 50%;
         left: 50%;
         transform: translateX(-50%) translateY(-50%);
      }
   }
   ${props => props.simple && `
      border-radius: 0;
      padding: 0;
   `}
   ${props => props.disabled && `
      &[disabled] {
         background-color: #d9d9d9;
         color: white;
      }
   `}
   ${props => props.radius && `
      border-radius: ${props.radius};
   `}
   ${props => props.css}
`;

Button.defaultProps = {
   type: 'primary',
   size: 'middle',
   shape: 'default',
   transform: 'uppercase',
   radius: null
}

export default Button;
