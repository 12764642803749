import React, { Component } from 'react';
import PropTypes from 'prop-types';

class PostLink extends Component {
  onClick = () => {
    const { action, method, dataConfirm, hiddenFields } = this.props;
    const form = document.createElement('form');
    const csrfParam = document.querySelector('meta[name="csrf-param"]');
    const csrfToken = document.querySelector('meta[name="csrf-token"]');
    let formContent = `<input name="_method" value="${method}" type="hidden" />`;

    if (dataConfirm && !window.confirm(dataConfirm)) return;

    if (csrfToken && csrfParam) {
      formContent += `<input name="${csrfParam.content}" value="${csrfToken.content}" type="hidden" />`;
    }

    Object.keys(hiddenFields).forEach((item) => {
      const value = hiddenFields[item];
      formContent += `<input name="${item}" value="${value != null ? value : ''}" type="hidden" />`;
    });

    formContent += '<input type="submit" />';
    form.method = 'post';
    form.action = action;
    form.innerHTML = formContent;
    form.style.display = 'none';

    document.body.appendChild(form);
    form.querySelector('[type=submit]').click();
  }

  render() {
    return (
      <a className={this.props.className} onClick={this.onClick} role="link" tabIndex={0}>
        {this.props.children}
      </a>
    );
  }
}

PostLink.propTypes = {
  action: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  method: PropTypes.string,
  className: PropTypes.string,
  dataConfirm: PropTypes.string,
  hiddenFields: PropTypes.shape(),
};

PostLink.defaultProps = {
  method: 'post',
  className: '',
  dataConfirm: '',
  hiddenFields: {},
};

export default PostLink;
