import React from 'react';
import styled, { withTheme } from 'styled-components';

import { Row, Col, Logo, A } from 'atoms';

const Nav = styled.nav``;
const FooterLink = styled(A)`
    color: var(--color-content-grey);
`;

const Footer = ({ theme: { isMobile } }) => {
    return (
        <Nav>
            <Row justify={'end'} align={'middle'} singleRow gutter={30}>
                <Col>
                    <FooterLink href={`https://www.fanlist.com/terms`}>terms</FooterLink>
                </Col>
                <Col>
                    <FooterLink href={`https://www.fanlist.com/privacy`}>privacy</FooterLink>
                </Col>
                <Col>
                    <FooterLink href={`https://www.fanlist.com`} block><Logo css={`width: ${isMobile ? 20 : 100}px;`} /></FooterLink>
                </Col>
            </Row>
        </Nav>
    );
};

export default withTheme(Footer);
