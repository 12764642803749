import { Modal } from "atoms";
import { VideoPlayer } from "libraries/Video";
import React, { ReactNode, useMemo, useState } from "react";
import styled from "styled-components";

const VideoContainer = styled.div<{
  simple?: boolean;
  color?: string;
  size: number;
}>`
  border-radius: 50%;
  overflow: hidden;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  ${(props) =>
    !props.simple &&
    `
      border: 7px solid ${props.color || "#fff"};
      box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 20px;
  `}
`;
const ContainerInner = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: black;
  position: relative;
  z-index: 1;
`;

const VideoContent = styled.div<{ offsetY: number; width: number }>`
  min-width: 100%;
  min-height: 100%;
  pointer-events: none;
  position: relative;
  top: ${(props) => props.offsetY}px;
  width: ${(props) => props.width}px;
  flex-shrink: 0;
`;
const VideoPlayerModalInner = styled.div`
  padding: 8px;
`;

type Props = {
  size: number;
  videoRatio: number;
  videoUrl: string;
  modalZIndex?: number;
  simple?: boolean;
  color?: string;
};

export const CircleContainer = (props: Props) => {
  const { size, videoRatio, videoUrl, modalZIndex, ...restProps } = props;

  const width = useMemo(() => {
    const aspect = videoRatio;
    if (aspect <= 1) {
      return size;
    }
    return aspect * size;
  }, [size, videoRatio]);
  const offsetY = useMemo(() => {
    const aspect = videoRatio;
    if (aspect >= 1) {
      return 0;
    }
    const height = size / aspect;
    const offset = (height - size) / 2 / 2;
    return Math.round(offset);
  }, [videoRatio, size]);
  const modalWidth = useMemo(() => {
    const aspect = videoRatio;
    if (aspect <= 1) {
      return 360;
    }
    return Math.round(360 * aspect);
  }, [videoRatio]);
  const [videoModalVisible, setVideoModalVisible] = useState(false);
  const controls = useMemo(() => [], []);
  const storage = useMemo(() => ({ enabled: false, key: "plyr" }), []);
  const modalVideoRatio = useMemo(
    () => `${videoRatio * 1000}:1000`,
    [videoRatio]
  );
  return (
    <VideoContainer size={size} {...restProps}>
      <ContainerInner onClick={() => setVideoModalVisible(true)}>
        <VideoContent width={width} offsetY={offsetY}>
          {/*@ts-ignore*/}
          <VideoPlayer
            src={videoUrl}
            muted
            autoplay
            loop
            ratio={null}
            controls={controls}
            hideLoading
            storage={storage}
          />
        </VideoContent>
      </ContainerInner>
      <Modal
        open={videoModalVisible}
        width={modalWidth}
        onCancel={() => setVideoModalVisible(false)}
        simple
        destroyOnClose
        centered
        zIndex={modalZIndex}
      >
        <VideoPlayerModalInner>
          {/*@ts-ignore*/}
          <VideoPlayer
            src={videoUrl}
            ratio={modalVideoRatio}
            autoplay
            storage={storage}
          />
        </VideoPlayerModalInner>
      </Modal>
    </VideoContainer>
  );
};
