import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Spin } from 'atoms';

import Plyr from 'plyr-react';
import { useRef } from 'react';

const StyledVideoPlayer = styled.div`
    display: flex;
    position: relative;
    background-color: black;
    --plyr-color-main: var(--color-primary);
    .plyr {
        min-width: 72px;
        .plyr__video-wrapper--fixed-ratio {
            height: auto;
            padding-bottom: 0;
            video {
                object-fit: cover;
            }
        }
    }
    ::after {
        content: '';
        display: block;
        width: 0;
        ${props => props.ratioNumber && `
            padding-top: ${100 / props.ratioNumber}%;
        `}
    }
`;

const PlayerInner = styled.div`
    flex: 1 0 0;
    position: relative;
    & .plyr--paused {
        .plyr__controls {
            display: none;
        }
    }
`;

const SpinContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const VideoPlayer = ({
    src,
    previewThumb,
    controls,
    ratio,
    reactControls,
    autoplay,
    muted,
    loop,
    hideLoading,
    storage,
    onPlay,
    ...props
}) => {

    const source = useMemo(() => ({
        type: 'video',
        sources: [
            {
                src
            }
        ]
    }), [src]);

    const options = useMemo(() => ({
        controls,
        ratio,
        autoplay,
        muted,
        loop: { active: loop },
        storage
    }), [controls, ratio, autoplay, muted, loop]);
    const ratioNumber = useMemo(() => {
        if (!ratio) {
            return null;
        }
        const [w, h] = ratio.split(':');
        return w / h;
    }, [ratio]);
    const ref = useRef();

    useEffect(() => {
        if (ref.current) {  
        }
    }, [ref]);

    return (
        <StyledVideoPlayer ratioNumber={ratioNumber}>
            {
                !hideLoading && (
                    <SpinContainer>
                        <Spin size={'large'} />
                    </SpinContainer>
                )
            }
            <PlayerInner>
                <Plyr source={source} options={options} ref={ref} {...props} />
            </PlayerInner>
        </StyledVideoPlayer>
    );
};

VideoPlayer.defaultProps = {
    controls: ['play-large', 'play', 'progress', 'mute', 'volume'],
    ratio: '1:1',
    reactControls: true,
    autoplay: false,
    muted: false,
    loop: false,
    hideLoading: false,
    onPlay: null,
    storage: { enabled: true, key: 'plyr' }
};

export default VideoPlayer;
