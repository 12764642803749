import React, { useEffect, useRef, useState, useMemo } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPause } from '@fortawesome/free-solid-svg-icons';

import {
  RecordRow,
  VisualizationContainer,
  RecordVisualization,
  RecordCTAContainer,
  PlayButton,
  RecordCTAButton,
} from './common';
import { INITIAL, PLAYING } from 'libraries/Audio/const';
import { Flex } from 'antd';
import { FaLock } from 'icons';
import { Span } from 'atoms';
import { hasTwoDigit } from 'utils/Datetime';

export const PauseButton = (props) => (
  <RecordCTAButton type="primary" icon={<FontAwesomeIcon icon={faPause} />} {...props} />
);

const StyledPreviewPlayer = styled.div``;

const AudioPlayContainer = styled.div`
  width: 100%;
  padding: 15px 0 15px 15px;
`;

const PreviewPlayerInactive = ({ simple = false, duration }) => {
  
  const tmm = useMemo(() => hasTwoDigit(Math.floor(duration / 60)), [duration]);
  const tss = useMemo(() => hasTwoDigit(Math.floor(duration % 60)), [duration]);

  return (
    <StyledPreviewPlayer>
      <RecordRow css={!simple ? `padding: 15px 0 15px 15px;
        border: 1px solid var(--color-border);
        border-radius: 5px;` : ''}>
        <RecordCTAContainer css={`width: 40px;
          align-items: center;`}>
          <PlayButton
            css={`width: 40px; height: 40px; border-radius: 5px; --color: #d9d9d9; --color-hover: #d9d9d9;`}
          />
        </RecordCTAContainer>
        <VisualizationContainer css={`margin-right: 0;`}>
          <RecordVisualization css={`border: 0;`}>
            <AudioPlayContainer>
              <Flex align='center' gap={10}>
                <FaLock color="grey" />
                <Span color='grey' css="margin-right: auto;">Become a member to unlock this episode</Span>
                <Span color='white' css='background-color: #7c7c7c; padding: 1px 3px; border-radius: 3px;'>{tmm}:{tss}</Span>
              </Flex>
            </AudioPlayContainer>
          </RecordVisualization>
        </VisualizationContainer>
      </RecordRow>
    </StyledPreviewPlayer>
  );
};

export default PreviewPlayerInactive;
