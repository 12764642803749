import React, {useMemo} from 'react';
import styled, { withTheme } from "styled-components";

import LogoSmall from 'assets/icons/podinbox_logo.png'
import LogoWide from 'assets/icons/podinbox_logo_wide.png'

const SLogo = styled.img`
   width: ${props => props.small ? 25 : 105}px;
   ${props => props.css}
`;

const Logo = ({
    small,
    alwaysWide,
    theme: {
        isMobile
    },
    ...props
}) => {
    
    const isSmall = useMemo(() => alwaysWide ? false : small || isMobile, [small, isMobile, alwaysWide]);

    return (
        <SLogo small={isSmall} src={isSmall ? LogoSmall : LogoWide} {...props} />
    )
};

export default withTheme(Logo);
