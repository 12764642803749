import { ApolloClient, useQuery } from "@apollo/client";
import React, { createContext, useCallback, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Membership, Show } from "types";
import { PUBLIC_SHOW_QUERY } from "../graphql";

export const FanPageContext = createContext<{
  show: Show
  loading: boolean
  refetch: Function
  client: ApolloClient<any>
  onCompleted: (fn: (props: { publicShow: Show }) => void) => Function
  showQueryVariables: {
    slug: any;
    uuid: string;
  },
  membership: Membership
  isMember: boolean
}>({
  show: null,
  loading: false,
  refetch: () => { },
  client: null,
  onCompleted: () => () => { },
  showQueryVariables: null,
  membership: null,
  isMember: false
});

export const FanPageProvider = (props) => {
  const { slug } = useParams();
  const showQueryVariables = useMemo(
    () => ({
      slug,
      uuid: "",
    }),
    [slug]
  );
  const completedCallbacks = useRef([]);
  const onCompleted = useCallback((fn: (props: { publicShow: Show }) => void): Function => {
    completedCallbacks.current = [...completedCallbacks.current, fn];
    return () => {
      completedCallbacks.current = completedCallbacks.current.filter(f => f !== fn)
    }
  }, [])
  const {
    data: showData,
    loading,
    refetch,
    client,
  } = useQuery(PUBLIC_SHOW_QUERY, {
    variables: showQueryVariables,
    onCompleted({ publicShow }) {
      completedCallbacks.current.forEach((fn) => fn({ publicShow }))
    },
  });
  const show = useMemo(() => {
    return showData?.publicShow;
  }, [showData]);
  const membership = useMemo<Membership>(() => show?.membership || null, [show]);
  const isMember = useMemo<boolean>(
    () =>
    (
      !!membership
      && membership.accessType !== 'canceled'
      && membership.accessType !== 'past_due'
    ),
    [membership]
  );
  return (
    <FanPageContext.Provider value={{
      show,
      loading,
      client,
      refetch,
      onCompleted,
      showQueryVariables,
      membership,
      isMember
    }}>
      {props.children}
    </FanPageContext.Provider>
  )
}
