import React, { useMemo } from "react";
import styled from "styled-components";

import { Flexbox, Image, ModalInner } from "atoms";
import { DELETED } from "consts";
import { useQuery } from "@apollo/client";
import { GENERAL_TIPS_THANK_YOU, MESSAGE_TIPS_THANK_YOU } from "graphql-api";
import { superfansThankYouMessage } from "micro-data";

const SThanksSuperfan = styled.div`
  text-align: center;
`;

const ThanksTitle = styled.div`
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 500;
  color: black;
  letter-spacing: 0.5px;
  ${(props) => props.css}
`;

const ImageContainer = styled.div`
  width: 100px;
  margin: 0 auto 20px;
`;

const Content = styled.div`
  font-size: 15px;
  ${(props) => props.css}
`;

const ThanksSuperfan = ({ show, forMessage }) => {
  const { title, coverImageUrl } = show;
  const { data: generalTipsData } = useQuery(GENERAL_TIPS_THANK_YOU, {
    variables: { showId: show.id },
    fetchPolicy: "cache-only"
  });
  const { data: messageTipsData } = useQuery(MESSAGE_TIPS_THANK_YOU, {
    variables: { showId: show.id },
    fetchPolicy: "cache-only"
  });
  const generalTipsThankYou = useMemo(() => generalTipsData?.generalTipsThankYou || superfansThankYouMessage, [generalTipsData]);
  const messageTipsThankYou = useMemo(() => messageTipsData?.messageTipsThankYou || superfansThankYouMessage, [messageTipsData]);

  return (
    <SThanksSuperfan>
      <ThanksTitle>Thanks!</ThanksTitle>
      <ImageContainer>
        <Image src={coverImageUrl} radius="5px" />
      </ImageContainer>
      <Content className="mb-3">
        {forMessage ? messageTipsThankYou : generalTipsThankYou}
      </Content>
    </SThanksSuperfan>
  );
};
const RewardLabelContainer = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  width: 200px;
`;
const RewardLabel = styled.div`
  padding: 3px 6px;
  background-color: var(--color-primary);
  color: white;
  font-size: 12px;
  cursor: pointer;
  opacity: 0.6;
  border-radius: 2px;
`;
const Level = styled.div`
  position: relative;
  --color: var(--color-primary);
`;

const LevelContent = styled.div`
  position: relative;
  padding: 8px 15px;
  background-color: var(--color);
  color: white;
`;

const UnlockedContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  position: relative;
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: ${(props) => props.theme.colors.border};
  }
`;

export const ThanksUnlockedReward = ({ reward, level }) => {
  const { name, unlockedDescription, minCents, status } = reward;
  const minDollar = useMemo(() => minCents / 100, [minCents]);

  return (
    <SThanksSuperfan>
      <RewardLabelContainer>
        {DELETED === status && <RewardLabel>archived</RewardLabel>}
      </RewardLabelContainer>
      <ModalInner css="padding: 35px 35px 25px;">
        <ThanksTitle css="margin-bottom: 0;">{name}</ThanksTitle>
      </ModalInner>
      <UnlockedContainer>
        <Level>
          <LevelContent>
            {level > 0 ? `LEVEL $${minDollar}` : "REWARD UNLOCKED!"}
          </LevelContent>
        </Level>
      </UnlockedContainer>
      <ModalInner css="padding: 35px 35px 45px;">
        <Content
          dangerouslySetInnerHTML={{ __html: unlockedDescription }}
          css="text-align: left;"
        />
      </ModalInner>
    </SThanksSuperfan>
  );
};

ThanksUnlockedReward.defaultProps = {
  level: -1,
};

export default ThanksSuperfan;
