import React, { useContext, useMemo } from "react";
import { Route, Switch, useRouteMatch, Redirect, useParams } from "react-router-dom";

import ActiveInbox from "../pages/tabs/ActiveInbox";
import ActiveTips from "../pages/tabs/ActiveTips";
import ActivePerks from "../pages/tabs/ActivePerks";
import PerkDetails from "../pages/tabs/PerkDetails";
import ActiveMembers from "../pages/tabs/ActiveMembers";
import ActiveSponsors from "../pages/tabs/ActiveSponsors";
import EpisodeDetails from "../pages/tabs/EpisodeDetails";
import { FanPageContext } from "../context";
import { useMenus } from "../hooks";

const FanPageTabs = () => {
  const { show, refetch: refetchShowQuery } = useContext(FanPageContext);
  const { displayGeneralTips, displayPerks, displayMembers, displayInbox, displaySponsors } = show;
  const paymentSelectionVisible = useMemo(() => !!show?.displayGeneralTips, [show]);

  const match = useRouteMatch();
  const menus = useMenus();
  const menusByTab = menus.reduce((prev, curr) => ({
    ...prev,
    [curr.key]: {
      ...curr,
    }
  }), {})

  return (
    <Switch>
      {
        displayGeneralTips && (<Route exact path={menusByTab['tips'].path}>
          <ActiveTips
            show={show}
            refetchShowQuery={refetchShowQuery}
            paymentSelectionVisible={paymentSelectionVisible}
          />
        </Route>)
      }
      {
        displayPerks && (
          <Route exact path={menusByTab['perks'].path}>
            <ActivePerks
              show={show}
            />
          </Route>
        )
      }
      {
        displayPerks && (
          <Route exact path={`${menusByTab['perks'].path}/:uuid`}>
            <PerkDetails
              show={show}
            />
          </Route>
        )
      }
      {
        displayMembers && (
          <Route exact path={menusByTab['members'].path}>
            <ActiveMembers
              show={show}
              refetchShowQuery={refetchShowQuery}
            />
          </Route>
        )
      }
      {
        displaySponsors && (
          <Route exact path={menusByTab['sponsors'].path}>
            <ActiveSponsors
              show={show}
              refetchShowQuery={refetchShowQuery}
            />
          </Route>
        )
      }
      <Route exact path={`${match.path}/episode/:uuid`}>
        <EpisodeDetails />
      </Route>
      {
        displayInbox && (
          <Route exact path={menusByTab['inbox'].path}>
            <ActiveInbox
              show={show}
            />
          </Route>
        )
      }
    </Switch>
  );
};

export default FanPageTabs;
