import { gql } from '@apollo/client';

export const CREATE_AUDIO_MESSAGE_MUTATION = gql`
  mutation createMessage($file: Upload!, $inboxId: String!, $messageType: String!) {
    createMessage(file: $file, inboxId: $inboxId, messageType: $messageType) {
      success
      error
      message  {
        duration
        id
        liked
        likes
        transcodedAudioUrl
        transcodedDownloadAudioUrl
        pinned
        private
        messageType
        totalTipsCents
        monetization
        isSender
        tags {
          id
          name
        }
        createdAt
        status
        user {
          avatarUrl
          firstName
          avatarUrl
          lastName
          displayName
          bio
          twitter
          instagram
          website
          isMembership
        }
      }
      inbox {
        id
        messageCount
      }
      show {
        id
        reachedMaxDailyMessagesCount
      }
    }
  }
`;
