import React, { useMemo } from "react";
import styled, { withTheme } from "styled-components";
import { Row, Col, Card, CardInner, Div } from "atoms";

import InboxCardContent from "../components/InboxCardContent";
import InboxMessages from "apps/PublicPage/components/InboxMessages";
import { FaEyeSlash } from "icons";
import { useApolloClient } from "@apollo/client";

const S = {};

S.Inbox = styled.div``;

const MessageListContainer = styled.div``;

const PrivateMessage = styled.div``;

const Inbox = (
  {
    inbox,
    show,
    slug,
    active,
    onCreateMessage,
    cardCss,
    theme: {isMobile},
  }) => {
  const client = useApolloClient();
  const showVideo = useMemo(
    () => active && inbox?.welcomeVideoUrl && inbox?.welcomeVideoActive,
    [inbox, active]
  );
  const isPrivate = useMemo(() => !!inbox?.private, [inbox]);

  const clearMessages = async () => {
    client.cache.evict({
      id: "ROOT_QUERY",
      fieldName: "publicInboxMessages",
    });
  };

  const handleMessageUpdate = async () => {
    await clearMessages();
  };
  
  const handleCreatedAudioMessage = async (...payload) => {
    if (onCreateMessage) {
      await onCreateMessage(...payload);
    }

    await clearMessages();
  };

  const handleCreatedTextMessage = async () => {
    await clearMessages();
  }

  return (
    <S.Inbox>
      {isMobile ? (
        <>
          <Div css={`margin-bottom: 20px;`}>
            <Card shadow>
              <CardInner>
                <InboxCardContent
                  inbox={inbox}
                  show={show}
                  slug={slug}
                  onCreatedMessage={handleCreatedAudioMessage}
                  onCreatedTextMessage={handleCreatedTextMessage}
                  showVideo={showVideo}
                  active={active}
                  css={cardCss}
                  isMobile={isMobile}
                />
              </CardInner>
            </Card>
          </Div>
          {isPrivate ? (
            <Card className="mt-5" simple shadow>
              <CardInner>
                <Row align="middle">
                  <Col flex="1 0 100px">
                    <PrivateMessage>
                      Messages for this inbox are private
                    </PrivateMessage>
                  </Col>
                  <Col>
                    <FaEyeSlash
                      verticalMiddle
                      css="font-size: 18px; color: var(--color-content-grey);"
                    />
                  </Col>
                </Row>
              </CardInner>
            </Card>
          ) : (
            active && (
              <MessageListContainer>
                <InboxMessages
                  inbox={inbox}
                  slug={slug}
                  afterMessageUpdate={handleMessageUpdate}
                  showTranscription={inbox.showTranscription}
                />
              </MessageListContainer>
            )
          )}
        </>
      ) : (
        <Card shadow>
          <CardInner>
            <InboxCardContent
              inbox={inbox}
              show={show}
              slug={slug}
              onCreatedMessage={handleCreatedAudioMessage}
              onCreatedTextMessage={handleCreatedTextMessage}
              showVideo={showVideo}
              active={active}
              css={cardCss}
              isMobile={isMobile}
            />
          </CardInner>
          {isPrivate ? (
            <CardInner>
              <Card simple shadow>
                <CardInner>
                  <Row align="middle">
                    <Col flex="1 0 100px">
                      <PrivateMessage>
                        Messages for this inbox are private
                      </PrivateMessage>
                    </Col>
                    <Col>
                      <FaEyeSlash
                        verticalMiddle
                        css="font-size: 18px; color: var(--color-content-grey);"
                      />
                    </Col>
                  </Row>
                </CardInner>
              </Card>
            </CardInner>
          ) : (
            active && (
              <CardInner>
                <MessageListContainer>
                  <InboxMessages
                    inbox={inbox}
                    slug={slug}
                    afterMessageUpdate={handleMessageUpdate}
                    showTranscription={inbox.showTranscription}
                  />
                </MessageListContainer>
              </CardInner>
            )
          )}
        </Card>
      )}
    </S.Inbox>
  );
};

export default withTheme(Inbox);
