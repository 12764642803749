import React from 'react';
import styled, { withTheme } from 'styled-components';

import { Form, FormItem, TextArea, Row, Col, Spacer, Button } from 'atoms';

const S = {};

S.TextRecorder = styled.div`
  width: 100%;
  ${(props) => props.css}
`;

const MessageEditor = (
  {
    onCancel,
    onUpdate,
    message = '',
    loading,
    text,
    theme: {isMobile},
    required = false,
    ...props
  }) => {
  const handleFinish = ({content}) => {
    onUpdate && onUpdate({content});
  }

  const [form] = Form.useForm();

  const handleCancel = () => {
    form.resetFields();

    onCancel && onCancel();
  }

  return (<S.TextRecorder {...props}>
    <Form
      initialValues={{
        content: message
      }}
      onFinish={handleFinish}
      form={form}
    >
      <FormItem
        name="content"
        rules={[
          {type: 'string', max: 2000, message: "You've exceeded the 2,000 character limit"},
          required ? {required, message: 'Please enter your message'} : {}
        ]}
      >
        <TextArea
          placeholder={`type your ${text}...`}
          rows={4}
        />
      </FormItem>
      <Row justify={'end'} gutter={isMobile ? 7 : 14}>
        {!isMobile && (<Spacer/>)}
        <Col>
          <Button
            size={isMobile ? 'small' : 'middle'}
            type={'default'}
            onClick={handleCancel}
          >
            CANCEL
          </Button>
        </Col>
        <Col>
          <Button
            size={isMobile ? 'small' : 'middle'}
            htmlType={'submit'}
            loading={loading}
          >
            SAVE {text}
          </Button>
        </Col>
      </Row>
    </Form>
  </S.TextRecorder>)
};

export default withTheme(MessageEditor);
