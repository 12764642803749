import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useTimer, useStopwatch } from 'react-timer-hook';
import { hasTwoDigit } from 'utils/Datetime';

import { Button, Popover } from 'atoms';
import { faMicrophone, faStop } from '@fortawesome/free-solid-svg-icons';

import VideoPreview from './VideoPreview';
import DeviceList from './DeviceList';

import {
    READY, RECORDING
} from 'libraries/Audio/const';

import {
    RecordRow,
    VisualizationContainer,
    RecordCTAContainer,
    SettingButton,
    RecordFooter,
    FooterRow,
    FooterSecondaryCol,
    FooterPrimaryCol,
    ButtonIcon
} from './common';

const StyledRecordReady = styled.div``;
const RecordPreview = styled.div`
    position: relative;
`;
const RecordDuration = styled.div`
    padding: 5px 10px;
    text-align: center;
    letter-spacing: 1px;
    font-size: 16px;
`;

const DeviceSettingsContainer = styled.div`
    position: absolute;
    bottom: 15px;
    right: 15px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: stretch;
`;

const RecordMain = ({ onStop, onReady, onCancel, status, stream, previewStream, error, selectedAudioId, setSelectedAudioId, selectedVideoId, setSelectedVideoId, constraints }) => {
    const time = new Date();
    time.setSeconds(time.getSeconds() + 3);
    const {
        seconds: getReadySeconds,
        isRunning: getReadyRunning,
        start: startGetReady
    } = useTimer({ expiryTimestamp: time, onExpire: () => onReady() });

    const {
        start,
        seconds,
        minutes
    } = useStopwatch({});
    const mm = useMemo(() => hasTwoDigit(minutes), [minutes]);
    const ss = useMemo(() => hasTwoDigit(seconds), [seconds]);

    const startRecording = () => {
        startGetReady();
    }

    useEffect(() => {
        if (status === RECORDING) {
            start();
        }
        return () => {

        }
    }, [status]);

    const handleStopClick = () => {
        const duration = minutes * 60 + seconds;
        onStop({ duration });
    }

    return (
        <StyledRecordReady>
            <RecordPreview>
                <VideoPreview stream={previewStream} error={error} />
                {
                    status === READY && !getReadyRunning && (
                        <DeviceSettingsContainer>
                            <Popover
                                placement={'right'}
                                simple
                                zIndex={1100}
                                trigger={[`click`, `hover`]}
                                content={
                                    <DeviceList
                                        stream={stream}
                                        constraints={constraints}
                                        selectedAudioId={selectedAudioId}
                                        setSelectedAudioId={setSelectedAudioId}
                                        selectedVideoId={selectedVideoId}
                                        setSelectedVideoId={setSelectedVideoId}
                                    />
                                }
                            >
                                <SettingButton />
                            </Popover>
                        </DeviceSettingsContainer>
                    )
                }
            </RecordPreview>
            <RecordFooter>
                <FooterRow>
                    <FooterSecondaryCol>
                        {
                            status === READY && (
                                <Button type={'default'} block onClick={() => onCancel()}>CANCEL</Button>
                            )
                        }
                        {
                            status === RECORDING && (
                                <RecordDuration>
                                    {mm}:{ss}
                                </RecordDuration>
                            )
                        }
                    </FooterSecondaryCol>
                    <FooterPrimaryCol>
                        {
                            status === READY && !getReadyRunning && (
                                <Button onClick={() => startRecording()} block disabled={!!error} icon={<ButtonIcon icon={faMicrophone} />} type={'green'}>START RECORDING</Button>
                            )
                        }
                        {
                            status === READY && getReadyRunning && (
                                <Button type={'yellow'} onClick={() => { }} block>{getReadySeconds} GET READY</Button>
                            )
                        }
                        {
                            status === RECORDING && (
                                <Button onClick={() => handleStopClick()} block icon={<ButtonIcon icon={faStop} />}>STOP RECORDING</Button>
                            )
                        }
                    </FooterPrimaryCol>
                </FooterRow>
            </RecordFooter>
        </StyledRecordReady>
    );
};

export default RecordMain;
