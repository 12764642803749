import React from 'react';
import styled from 'styled-components';
import { Spin as AntSpin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = <LoadingOutlined spin />;

const Spin = styled(AntSpin)``;
Spin.defaultProps = { indicator: antIcon };

export default Spin;
