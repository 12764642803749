import { gql } from "@apollo/client";

const userNotificationFields = gql`
  fragment userNotificationFields on UserNotifications {
    id
    userNewGeneralTip
    userNewLike
    userNewMessageTip
    userNewReply
    userNewPerkBought
    userNewPaymentReply
    userEpisodeEmailAnnouncement
    userPerkEmailAnnouncement
  }
`;

export const USER_NOTIFICATIONS_QUERY = gql`
  query userNotifications {
    userNotifications {
      ...userNotificationFields
    }
  }
  ${userNotificationFields}
`;

export const UPDATE_USER_NOTIFICATIONS_MUTATION = gql`
  mutation updateUserNotifications(
    $userNewGeneralTip: Boolean
    $userNewLike: Boolean
    $userNewMessageTip: Boolean
    $userNewReply: Boolean
    $userNewPerkBought: Boolean
    $userNewPaymentReply: Boolean
    $userEpisodeEmailAnnouncement: Boolean
    $userPerkEmailAnnouncement: Boolean
  ) {
    updateUserNotifications(
      userNewGeneralTip: $userNewGeneralTip
      userNewLike: $userNewLike
      userNewMessageTip: $userNewMessageTip
      userNewReply: $userNewReply
      userNewPerkBought: $userNewPerkBought
      userNewPaymentReply: $userNewPaymentReply
      userEpisodeEmailAnnouncement: $userEpisodeEmailAnnouncement
      userPerkEmailAnnouncement: $userPerkEmailAnnouncement
    ) {
      success
      error
      notifications {
        ...userNotificationFields
      }
    }
  }
  ${userNotificationFields}
`;
