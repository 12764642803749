import { gql } from "@apollo/client";

export {
  ME_QUERY,
  UPDATE_USER_MUTATION,
  UPDATE_USER_AVATAR_MUTATION,
  DELETE_USER_AVATAR_MUTATION,
  UPDATE_EMAIL_MUTATION,
  UPDATE_PASSWORD_MUTATION,
  REMOVE_CANCELLED_BANNER,
  USER_PAYMENTS_QUERY,
  USER_SHOW_PAYMENTS_QUERY,
} from "./User";

export {
  UPDATE_CREDIT_CARD_MUTATION,
  PAYMENT_HISTORY_QUERY,
  CREDIT_CARD_QUERY,
  DELETE_SHOW_STRIPE_ACCOUNT_MUTATION,
  SETUP_SUPPORTING_CREDIT_CARD_MUTATION,
  SUPPORTING_CREDIT_CARD_QUERY,
  UPDATE_SUPPORTING_CREDIT_CARD_MUTATION,
  DELETE_SUPPORTING_CREDIT_CARD,
} from "./Payment";

export {
  UPDATE_PUBLIC_PAGE_COVER_IMAGE_MUTATION,
  UPDATE_PUBLIC_PAGE_MUTATION,
  DELETE_PUBLIC_PAGE_COVER_IMAGE_MUTATION,
} from "./PublicPage";

export {
  INBOX_QUERY,
  CREATE_INBOX_MUTATION,
  UPDATE_INBOX_MUTATION,
  DELETE_INBOX_MUTATION,
  TOGGLE_WELCOME_VIDEO_MUTATION,
  ORDER_INBOXES_MUTATION,
  TOGGLE_INBOX_ACTIVE_MUTATION,
  DELETE_INBOX_WELCOME_VIDEO_MUTATION,
  TOGGLE_INBOX_PRIVATE,
  TOGGLE_INBOX_SHOW_TRANSCRIPTION,
  INBOXES_BY_SHOW,
  TOGGLE_INBOX_ALLOW_TEXT_CONTENT,
} from "./inbox";

export * from "./Show";

export {
  USER_NOTIFICATIONS_QUERY,
  UPDATE_USER_NOTIFICATIONS_MUTATION,
} from "./Notification";

export {
  CONTACTS_QUERY,
  FANS_QUERY,
  MEMBERS_QUERY,
  MEMBERSHIPS_QUERY,
  GIFT_MEMBERSHIP_MUTATION,
  ACTIVE_MEMBERSHIPS_COUNT_QUERY,
  TOGGLE_ARCHIVE_CONTACT_MUTATION,
  TOGGLE_SUSPEND_FAN_MUTATION,
  TOGGLE_MEMBERS_CHATROOM_MUTATION,
  MANUAL_CANCEL_MEMBERSHIP_MUTATION,
} from "./Contact";

export {
  COMPANY_QUERY,
  CREATE_TEAM_MEMBER_MUTATION,
  MEMBER_SIGNUP_MUTATION,
  REMOVE_TEAM_MEMBER_MUTATION,
  RESEND_TEAM_MEMBER_INVITE_MUTATION,
  LEAVE_SHOW_MUTATION,
} from "./Company";

export {
  CREATE_SHOW_TAG_MUTATION,
  UPDATE_SHOW_TAG_MUTATION,
  DELETE_SHOW_TAG_MUTATION,
  TOGGLE_MESSAGE_TAG_ACTIVE_MUTATION,
} from "./Tag";

export {
  MAILCHIMP_QUERY,
  SELECT_MAILCHIMP_AUDIENCE_MUTATION,
  DISCONNECT_MAILCHIMP_MUTATION,
  PUSH_CONTACTS_TO_MAILCHIMP_MUTATION,
} from "./MailChimp";

export {
  CREATE_OR_UPDATE_REWARD_MUTATION,
  DELETE_REWARD_MUTATION,
} from "./Reward";

export {
  REMOVE_CHAT_PARTICIPANT_MUTATION,
  TOGGLE_CHATROOM_WELCOME_ENABLED_MUTATION,
} from "./Chat";

export {
  PERKS_QUERY,
  perkFields,
  CREATE_PERK_MUTATION,
  UPDATE_PERK_MUTATION,
  TOGGLE_ARCHIVE_PERK_MUTATION,
  TOGGLE_PUBLISH_PERK_MUTATION,
  ORDER_PERKS_MUTATION,
  SEND_PERK_ANNOUNCEMENT,
} from "./Perk";

export {
  sponsorFields,
  SPONSORS_QUERY,
  CREATE_SPONSOR_MUTATION,
  UPDATE_SPONSOR_MUTATION,
  DELETE_SPONSOR_MUTATION,
  TOGGLE_SPONSOR_ACTIVE_MUTATION,
  ORDER_SPONSORS_MUTATION,
  DELETE_SPONSOR_AUDIO_MUTATION,
} from "./Sponsor";

export { INBOX_MESSAGES_QUERY, MESSAGE_QUERY } from "./Message";
export * from "./Podcast";

export const STRIPE_ACCOUNT = gql`
  query stripeAccount {
    stripeAccount {
      pending
      retry
      connected
      url
    }
  }
`;

export const RESET_ACCOUNT_LAST_CONNECTED_AT = gql`
  mutation resetAccountLastConnectedAt {
    resetAccountLastConnectedAt {
      success
      error
    }
  }
`;
