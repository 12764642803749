import React, { useMemo, useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

import { NORMAL } from 'libraries/Audio/const';

import { message, Modal } from 'atoms';
import { PureAudioRecord } from 'libraries/Audio';

import Auth from 'components/Auth';
import { AccountLockedModal } from 'components/AccountLocked';

import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { CREATE_AUDIO_MESSAGE_MUTATION } from 'libraries/Audio/graphql';

import { LOCKED, SIGNUP, TRUE } from 'consts';
import { REACHED_MAX_MESSAGES_COUNT_QUERY } from 'graphql-api';

const AuthModalInner = styled.div`
  padding: 25px 0 20px;
`;

const S = {};

S.AudioRecord = styled.div`
  flex: 1 0 calc(50% - 5px);
`;

const AudioRecord = (
  {
    inboxId,
    slug,
    me,
    disableMessagesPosting,
    onCreatedMessage,
    onSuccess,
    onToggle,
    onError,
    onLimit
  }) => {
  const [recordedBlob, setRecordedBlog] = useState(null);
  const [authType, setAuthType] = useState(null);
  const authModalVisible = useMemo(() => !!authType, [authType]);
  const isLoggedIn = useMemo(() => !!me, [me]);
  const [accountLockedVisible, setAccountLockedVisible] = useState(false);
  const [onSuccessBusy, setOnSuccessBusy] = useState(false);

  const audioRef = useRef();

  const handleToggleRecording = (payload) => {
    onToggle && onToggle(payload);
  }

  const [triggerMessagesCount] = useLazyQuery(REACHED_MAX_MESSAGES_COUNT_QUERY, {
    fetchPolicy: "network-only"
  });

  const [triggerCreateMessage, { loading: isUploadBusy }] = useMutation(CREATE_AUDIO_MESSAGE_MUTATION, {
    variables: {
      file: recordedBlob,
      messageType: "audio",
      inboxId
    },
    onCompleted({ createMessage: { success, error, show, message: audioMessage } }) {
      if (success === TRUE) {
        const handleLocalSuccess = () => {
          audioRef.current.setStatus(NORMAL);
          audioRef.current.setDuration(0);
          message.success({ content: 'Message Submitted' });
          onCreatedMessage && onCreatedMessage(audioMessage);
          setOnSuccessBusy(false);

          handleToggleRecording(false);
        }

        if (onSuccess) {
          onSuccess(() => handleLocalSuccess());
        } else {
          handleLocalSuccess();
        }
      } else {
        if (error === LOCKED) {
          setAccountLockedVisible(true);
        } else {
          message.error({ content: 'Something went wrong, please try again later' });
        }
      }
    },
    onError() {
      setAuthType(SIGNUP);
    }
  });
  
  const handleCreateMessage = () => {
    triggerMessagesCount({
      variables: {
        slug,
        uuid: "",
      },
    }).then(({ data: mCntData }) => {
      if (!mCntData) {
        setAuthType(SIGNUP);
      } else {
        const mCnt = mCntData.reachedMaxDailyMessagesCount;

        if (!mCnt) {
          triggerCreateMessage();
        } else {
          onLimit && onLimit();
          handleToggleRecording(false);
        }
      }
    });
  };

  const handleSave = async () => {
    if (isLoggedIn) {
      handleCreateMessage();
    } else {
      setAuthType(SIGNUP);
    }
  }

  const handleStop = (blob) => {
    setRecordedBlog(blob);
  }

  const handleError = (e) => {
    onError && onError(e);
  }

  const onAuthSuccess = () => {
    setAuthType(null);

    handleCreateMessage();
  }

  useEffect(() => {
    if (isUploadBusy && onSuccess) {
      setOnSuccessBusy(true);
    }
  }, [isUploadBusy]);

  return (
    <>
      <S.AudioRecord>
        <PureAudioRecord
          onSave={ handleSave }
          onStop={ handleStop }
          onToggle={ handleToggleRecording }
          onError={ handleError }
          isSaveBusy={ isUploadBusy || onSuccessBusy }
          ref={ audioRef }
          disableMessagesPosting={ disableMessagesPosting }
        />
      </S.AudioRecord>
      <Modal
        open={ authModalVisible }
        onCancel={ () => setAuthType(null) }
      >
        <AuthModalInner>
          <Auth
            onSuccess={ onAuthSuccess }
            type={ authType }
            setType={ setAuthType }
          />
        </AuthModalInner>
      </Modal>

      {
        me && (
          <AccountLockedModal
            lockedUntil={ me.lockedUntil }
            open={ accountLockedVisible }
            onCancel={ () => setAccountLockedVisible(false) }
          />
        )
      }
    </>
  )
};
AudioRecord.defaultProps = {
  onSuccess: null
}
export default AudioRecord;
