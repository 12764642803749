import styled from "styled-components";

export const Page = styled.div``;

export const PageInner = styled.div`
  max-width: ${props => props.maxWidth || "500px"};
  ${props => props.css}
`;

export const PageHeader = styled.header`
   padding-top: 10px;
   margin-bottom: 15px;
`;
export const PageContent = styled.div``;