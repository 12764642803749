import { gql } from '@apollo/client';
import { superfanFields } from 'graphql-api';
import { replyFields } from './reply';

export const messageFields = gql`
  fragment messageFields on Message {
    transcribedText
    duration
    id
    liked
    likes
    transcodedAudioUrl
    transcodedDownloadAudioUrl
    pinned
    private
    totalTipsCents
    monetization
    transcribedText
    transcribing
    transcribingIntended
    messageType
    textContent
    isSender
    superfans {
      ...superfanFields
    }
    tags {
      id
      name
    }
    createdAt
    status
    user {
      id
      avatarUrl
      firstName
      avatarUrl
      lastName
      displayName
      bio
      twitter
      instagram
      website
      superfan(showId: $showId, showSlug: $showSlug)
      isMembership(showId: $showId, showSlug: $showSlug)
    }
    likesBreakdown {
      id
      avatarUrl
      displayName
      bio
      instagram
      twitter
      website
    }
  }
  ${superfanFields}
`;

export const MESSAGES_QUERY = gql`
  query messages($showId: ID, $showSlug: String) {
    messages {
      ...messageFields
      reply {
        duration,
        id,
        text,
        transcodedAudioUrl,
        createdAt,
        user {
          firstName,
          avatarUrl,
          lastName,
          displayName,
          bio,
          twitter,
          instagram,
          website
          isMembership(showId: $showId, showSlug: $showSlug)
        }
      },
      show {
        id
        slug
        coverImageUrl
        title
        supportAmounts
      }
    }
  }
  ${messageFields}
`;

export const TOGGLE_LIKE_MUTATION = gql`
  mutation toggleLike($messageId: ID) {
    toggleLike(messageId: $messageId) {
      success
      error
      message {
        id
        liked
        likes
        likesBreakdown {
          id
          avatarUrl
          displayName
          instagram
          twitter
        }
      }
    }
  }
`;

export const TOGGLE_ARCHIVE_MUTATION = gql`
  mutation toggleArchive($messageId: ID) {
    toggleArchive(messageId: $messageId) {
      success
      error
    }
  }
`;

export const TOGGLE_PINNED_MUTATION = gql`
  mutation togglePinned($messageId: ID) {
    togglePinned(messageId: $messageId) {
      success
      error,
      message {
        id,
        pinned
      }
    }
  }
`;

export const TOGGLE_PRIVATE_MUTATION = gql`
  mutation togglePrivate($messageId: ID) {
    togglePrivate(messageId: $messageId) {
      success
      error
      message {
        id,
        private
      }
    }
  }
`;

export const TOGGLE_DELETE_MUTATION = gql`
  mutation toggleDelete($messageId: ID) {
    toggleDelete(messageId: $messageId) {
      success,
      error
    }
  }
`;

export const DELETE_MESSAGE_MUTATION = gql`
  mutation deleteMessage($messageId: String!) {
    deleteMessage(messageId: $messageId) {
      success,
      error
    }
  }
`;

export const CREATE_REPLY_MUTATION = gql`
  mutation createReply($messageId: String!, $content: String, $file: Upload) {
    createReply(messageId: $messageId, content: $content, file: $file) {
      success
      error
      message {
        id
        reply {
          ...replyFields
        }
      }
    }
  }
  ${replyFields}
`;

export const UPDATE_MESSAGE_MUTATION = gql`
  mutation updateMessage($messageId: String!, $content: String) {
    updateMessage(messageId: $messageId, content: $content) {
      success
      error
    }
  }
`;

export const DELETE_REPLY_MUTATION = gql`
  mutation deleteReply($replyId: String!) {
    deleteReply(replyId: $replyId) {
      success
      error
      message {
        id
        reply {
          ...replyFields
        }
      }
    }
  }
  ${replyFields}
`;

export const TRANSCRIBE_MESSAGE_MUTATION = gql`
  mutation transcribeMessage($messageId: ID!) {
    transcribeMessage(messageId: $messageId) {
      success
      error
      message {
        id
        transcribingIntended
      }
    }
  }
`;

export const MESSAGE_TRANSCRIBING_SUBSCRIPTION = gql`
  subscription onMessageTranscribing($showId: ID!) {
    messageTranscribing(showId: $showId) {
      message {
        id
        transcribing
        transcribingIntended
      }
    }
  }
`;

export const MESSAGE_TRANSCRIBED_SUBSCRIPTION = gql`
  subscription onMessageTranscribed($showId: ID!) {
    messageTranscribed(showId: $showId) {
      message {
        id
        transcribedText
        transcribing
        transcribingIntended
      }
    }
  }
`;

export const INBOX_MESSAGES_QUERY = gql`
  query inboxMessages($inboxId: ID!, $limit: Int!, $page: Int!, $showId: ID, $showSlug: String) {
    inboxMessages(inboxId: $inboxId, limit: $limit, page: $page) {
      collection {
        ...messageFields
      }
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
    }
  }
  ${messageFields}
`;

export const REACHED_MAX_MESSAGES_COUNT_QUERY = gql`
  query publicShow($slug: String!, $uuid: String) {
    publicShow(slug: $slug, uuid: $uuid) {
      id
      reachedMaxDailyMessagesCount
    }
  }
`;
