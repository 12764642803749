import React, { useEffect, useRef, useMemo } from 'react';
import styled from 'styled-components';
import { createPortal } from 'react-dom';
import confetti from 'canvas-confetti';

type MaskProps = {
  zIndex?: number
}
const Mask = styled.div<MaskProps>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${props => props.zIndex || 1000};
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
`;

type Props = {
  zIndex?: number,
  mask?: boolean,
  duration?: number
};
const Confetti = (props: Props) => {
  const { zIndex, mask, duration } = props;
  const animationEnd = useMemo(() => Date.now() + duration, [duration]);
  const defaults = useMemo(() => ({ startVelocity: 30, spread: 360, ticks: 60, zIndex: zIndex }), []);

  function randomInRange(min, max) {
    return Math.random() * (max - min) + min;
  }
  const frame = () => {
    var timeLeft = animationEnd - Date.now();
    var particleCount = Math.round(50 * (timeLeft / duration));
    // since particles fall down, start a bit higher than random
    confetti({ ...defaults, particleCount, origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 } });
    confetti({ ...defaults, particleCount, origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 } });
  }
  useEffect(() => {
    const intervalID = setInterval(() => {
      var timeLeft = animationEnd - Date.now();
      if (timeLeft <= 0) {
        clearInterval(intervalID);
        return;
      }
      frame();
    }, 250);
    return () => {
      clearInterval(intervalID);
    }
  }, []);
  // TODO: render it only if mask by conditional rendering
  return mask ? createPortal(<Mask zIndex={zIndex - 10} />, document.querySelector('body')) : null;
}

Confetti.defaultProps = {
  zIndex: 1001,
  mask: false,
  duration: 15000
}

export default Confetti;
