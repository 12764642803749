import { gql } from "@apollo/client";
import { RewardFields } from "graphql-api/reward";

export const CREATE_OR_UPDATE_REWARD_MUTATION = gql`
  mutation createOrUpdateReward($id: ID, $name: String, $description: String, $unlockedDescription: String, $minCents: Int, $status: String) {
    createOrUpdateReward(id: $id, name: $name, description: $description, unlockedDescription: $unlockedDescription, minCents: $minCents, status: $status) {
      success
      error
      reward {
        ...rewardFields
      }
    }
  }
  ${RewardFields}
`;

export const DELETE_REWARD_MUTATION = gql`
  mutation deleteReward($rewardId: ID!) {
    deleteReward(rewardId: $rewardId) {
      success
      error
    }
  }
`;