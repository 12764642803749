import React, { useState } from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import { REQUEST_RESET_PASSWORD_MUTATION } from 'apps/Auth/graphql';

import { Form } from 'antd';

import { FormItem, Input, Button, Password } from 'atoms';

const ForgotPasswordForm = ({
   onSend,
   onError
}) => {

   const [requestResetPassword, { loading: isMutationBusy }] = useMutation(REQUEST_RESET_PASSWORD_MUTATION, {
      onCompleted({ requestResetPassword: { success, error } }) {
         if (!error) {
            console.log(error);
            onSend({});
         } else {
            onError({ message: error });
         }
      },
      onError() {
         form.validateFields(['email']);
      }
   });

   const onFinish = ({
      email
   }) => {
      requestResetPassword({
         variables: {
            email
         }
      });
   };

   const [form] = Form.useForm();

   return (
      <Form
         form={form}
         layout="vertical"
         requiredMark={false}
         onFinish={onFinish}
         initialValues={{
            email: ''
         }}
      >
         <FormItem
            name={'email'}
            label={'Email'}
            rules={[
               { required: true, message: 'Email is required' },
               { type: 'email', message: 'The input is not valid E-mail' }
            ]}
         >
            <Input placeholder={''} />
         </FormItem>

         <Button type="primary" htmlType="submit" loading={isMutationBusy} block style={{ marginTop: '10px' }}>
            RESET PASSWORD
         </Button>
      </Form>
   );
};

export default ForgotPasswordForm;
