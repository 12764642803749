import { gql } from '@apollo/client';

import {
  messageFields,
  replyFields,
  TierFields,
  superfanFields,
  paymentMessageFields,
  paymentReplyFields,
  publicPerkFields
} from 'graphql-api';
export * from "./podcast";

export const publicInboxFields = gql`
  fragment publicInboxFields on PublicInbox {
    id
    name
    description
    prompt
    default
    active
    private
    showTranscription
    showTextContent
    messageCount
    slug
    draft
    welcomeVideoActive
    welcomeVideoThumbnailUrl
    welcomeVideoUrl
    welcomeVideoRatio
    welcomeVideoCreatedDate
    welcomeVideoDuration
    show {
      id
    }
  }
`;

export const publicSponsorFields = gql`
  fragment publicSponsorFields on PublicSponsor {
    id
    name
    description
    fullUrlLink
    priority
    active
    sponsorImageUrl
    sponsorAudioUrl
    sponsorAudioDuration
    show {
      id
      slug
    }
  }
`;

export const publicShowFields = gql`
  fragment publicShowFields on PublicShow {
    active
    viewerIsOwner
    viewerIsAdmin
    viewerHasChatAccess
    coverImageUrl
    bannerImageUrl
    id
    status
    slug
    themeColor
    title
    monetization
    welcomeVideoUrl
    welcomeVideoRatio
    welcomeVideoActive
    welcomeMessage
    superfansSingularName
    superfansPluralName
    supportAmounts
    donationLabel
    messageSupportAmounts
    membershipDescription
    chatroomEnabled
    viewerSuspendedPosting
    website
    applePodcast
    email
    facebook
    instagram
    linkedin
    rssFeed
    spotify
    tiktok
    twitter
    youtube
    displayInbox
    generalTipsCount
    displayGeneralTips
    displayPerks
    displaySponsors
    displayMembers
    tabsOnFanpage
    showSuperfanDollarAmounts
    showLeaderboard
    reachedMaxDailyMessagesCount
    showSponsorsAsList
    chatRoomForMembers
    finishedMembershipSetup
    showPurchasesNumberOnPerk
    showPurchasedFansOnPerk
    membership {
      id
      displayName
      nextBillAt
      isRecurring
      amountCents
      accessType
      createdAt
      canceledAt
      cancelRequestedAt
      status
      uuid
      user {
        id
      }
      tier {
        id
        kind
        amountCents
      }
    }
    podcast {
      id
      coverArtUrl
    }
    tags {
      id
      name
    }
  }
`;

export const PUBLIC_SHOW_QUERY = gql`
  query publicShow($slug: String, $uuid: String) {
    publicShow(slug: $slug, uuid: $uuid) {
      ...publicShowFields
      activeTiers {
        ...tierFields
      }
    }
  }
  ${publicShowFields}
  ${TierFields}
`;

export const GET_SUPPORTING_PAYMENT_METHOD = gql`
  query getSupportingPaymentMethod {
    getSupportingPaymentMethod {
      last4
      brand
    }
  }
`;

export const GIVE_SUPPORT_MUTATION = gql`
  mutation giveSupport($showId: ID, $messageId: ID, $token: String, $cents: Int!, $paymentMessage: String) {
    giveSupport(showId: $showId, messageId: $messageId, token: $token, cents: $cents, paymentMessage: $paymentMessage) {
      message {
        id
        totalTipsCents
        superfans {
          ...superfanFields
        }
      }
      success
      error
    }
  }
  ${superfanFields}
`;

export const PUBLIC_GENERAL_TIPS_QUERY = gql`
  query publicGeneralTips($showSlug: String!, $limit: Int!, $page: Int!) {
    publicGeneralTips(showSlug: $showSlug, limit: $limit, page: $page) {
      id
      amountCents
      source
      paymentMessage {
        ...paymentMessageFields
      }
      paymentReply {
        id
        content
        createdAt
        status
        user {
          id
          displayName
          bio
          avatarUrl
          website
          twitter
          instagram
          isMembership(showSlug: $showSlug)
        }
      }
      user {
        id
        displayName
        bio
        avatarUrl
        website
        twitter
        instagram
        isMembership(showSlug: $showSlug)
      }
      date
    }
  }
  ${paymentMessageFields}
`;

export const PUBLIC_LEADERBOARDS_QUERY = gql`
  query publicLeaderboards($showSlug: String!, $limit: Int!, $page: Int!) {
    publicLeaderboards(showSlug: $showSlug, limit: $limit, page: $page) {
      ...superfanFields
    }
  }
  ${superfanFields}
`;

export const PUBLIC_PERKS_QUERY = gql`
  query publicPerks($showSlug: String!) {
    publicPerks(showSlug: $showSlug) {
      ...publicPerkFields
      superfans {
        ...superfanFields
      }
      perkImages {
        id
        position
        imageUrl
      }
    }
  }
  ${publicPerkFields}
  ${superfanFields}
`;

export const PUBLIC_PERK_QUERY = gql`
  query publicPerk($uuid: String!) {
    publicPerk(uuid: $uuid) {
      ...publicPerkFields
      superfans {
        ...superfanFields
      }
      perkImages {
        id
        position
        imageUrl
      }
    }
  }
  ${publicPerkFields}
  ${superfanFields}
`;

export const PURCHASE_PERK_MUTATION = gql`
  mutation purchasePerk(
    $perkId: ID
    $token: String
    $customFieldValue: String
  ) {
    purchasePerk(
      perkId: $perkId
      token: $token
      customFieldValue: $customFieldValue
    ) {
      success
      error
      perk {
        ...publicPerkFields
      }
    }
  }
  ${publicPerkFields}
`;

export const PUBLIC_INBOX_MESSAGES_QUERY = gql`
  query publicInboxMessages($inboxId: ID!, $limit: Int!, $page: Int!, $showId: ID, $showSlug: String) {
    publicInboxMessages(inboxId: $inboxId, limit: $limit, page: $page) {
      collection {
        ...messageFields
        reply {
          duration
          id
          text
          transcodedAudioUrl
          createdAt
          user {
            firstName
            avatarUrl
            lastName
            displayName
            twitter
            instagram
            isMembership(showSlug: $showSlug)
          }
        }
      }
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
    }
  }
  ${messageFields}
`;

export const PUBLIC_INBOXES_BY_SHOW = gql`
  query publicInboxesByShow($showSlug: String!) {
    publicInboxesByShow(showSlug: $showSlug) {
      ...publicInboxFields
    }
  }
  ${publicInboxFields}
`;

export const PUBLIC_SPONSORS_BY_SHOW = gql`
  query publicSponsorsByShow($showId: ID!) {
    publicSponsorsByShow(showId: $showId) {
      ...publicSponsorFields
    }
  }
  ${publicSponsorFields}
`;

export const QUERIES_AFTER_LAZY_LOGIN = gql`
  query queriesAfterLazyLogin($slug: String!, $uuid: String) {
    publicShow(slug: $slug, uuid: $uuid) {
      id
      viewerIsOwner
      viewerIsAdmin
      viewerHasChatAccess
      viewerSuspendedPosting
      reachedMaxDailyMessagesCount
    }
    publicPerks(showSlug: $slug) {
      ...publicPerkFields
    }
  }
  ${publicPerkFields}
`;

export const CREATE_TEXT_MESSAGE_MUTATION = gql`
  mutation createMessage($content: String!, $inboxId: String!, $messageType: String!) {
    createMessage(content: $content, inboxId: $inboxId, messageType: $messageType) {
      success
      error
      message  {
        duration
        id
        liked
        likes
        transcodedAudioUrl
        transcodedDownloadAudioUrl
        pinned
        private
        totalTipsCents
        monetization
        messageType
        textContent
        isSender
        tags {
          id
          name
        }
        createdAt
        status
        user {
          avatarUrl
          firstName
          avatarUrl
          lastName
          displayName
          bio
          twitter
          instagram
          website
          isMembership
        }
      }
      inbox {
        id
        messageCount
      }
      show {
        id
        reachedMaxDailyMessagesCount
        viewerSuspendedPosting
      }
    }
  }
`;
