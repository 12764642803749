import React, { useContext, useEffect, useRef, useState } from "react";
import { ThemeContext } from "styled-components";
import { FanPageContext, withPublicPageState } from "apps/PublicPage/context";

import {
  StickyContainer,
} from "../components/FanPage";

import FanPageTabHeader from "./FanPageTabHeader";
import FanPageTabs from "./FanPageTabs";
import { useMenus } from "../hooks";

const FanPageMain = () => {
  const { isDesktop } = useContext(ThemeContext);
  const { show } = useContext(FanPageContext);
  const {
    displayGeneralTips,
    displayPerks,
    displayMembers,
    displaySponsors
  } = show;
  const menus = useMenus();
  const canTabs = menus.length > 1;
  const [tabStuck, setTabStuck] = useState(false);
  const stickyTabRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const observer = new IntersectionObserver((
      entries, observer
    ) => {
      const [entry] = entries;
      setTabStuck(!entry.isIntersecting);
    }, {
      root: document.querySelector('body'),
      rootMargin: '0px 0px -55% 0px',
      threshold: 0,
    })

    if (stickyTabRef.current) {
      observer.observe(stickyTabRef.current);
    }

    return () => {
      if (stickyTabRef.current) {
        observer.unobserve(stickyTabRef.current);
      }
    };
  }, []);

  return (
    <div className="FanPageMain">
      {isDesktop ? (
        <div>
          {
            canTabs && (
              <div style={{
                marginBottom: '35px'
              }}>
                <FanPageTabHeader />
              </div>
            )
          }
          <FanPageTabs />
        </div>
      ) : (
        <div style={{ marginTop: canTabs ? '-10px' : '0' }}>
          {
            canTabs && (
              <StickyContainer
                ref={stickyTabRef}
                className={tabStuck ? 'stuck' : ''}
              >
                <div style={{ paddingTop: '10px' }}>
                  <FanPageTabHeader />
                </div>
              </StickyContainer>
            )
          }
          <FanPageTabs />
        </div>
      )}
    </div>
  );
};

export default withPublicPageState(FanPageMain);
