import { gql } from "@apollo/client";

export const COMPANY_QUERY = gql`
  query company {
    company {
      id
      status
      stripeCustomerId
    }
  }
`;

export const CREATE_TEAM_MEMBER_MUTATION = gql`
  mutation createTeamMember($email: String!, $showId: String!) {
    createTeamMember(email: $email, showId: $showId) {
      success
      error
    }
  }
`;

export const MEMBER_SIGNUP_MUTATION = gql`
  mutation memberSignUp(
    $email: String!
    $password: String!
    $firstName: String!
    $lastName: String!
    $invitedUserKey: String!
  ) {
    memberSignUp(
      email: $email
      password: $password
      firstName: $firstName
      lastName: $lastName
      invitedUserKey: $invitedUserKey
    ) {
      success
      error
    }
  }
`;

export const RESEND_TEAM_MEMBER_INVITE_MUTATION = gql`
  mutation resendTeamMemberInvite($email: String!, $showId: String!) {
    resendTeamMemberInvite(email: $email, showId: $showId) {
      success
      error
    }
  }
`;

export const REMOVE_TEAM_MEMBER_MUTATION = gql`
  mutation removeTeamMember($email: String!, $showId: String!) {
    removeTeamMember(email: $email, showId: $showId) {
      success
      error
    }
  }
`;
export const LEAVE_SHOW_MUTATION = gql`
  mutation leaveShow($showId: String!) {
    leaveShow(showId: $showId) {
      success
      error
    }
  }
`;
