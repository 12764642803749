import { gql } from "@apollo/client";
import { contactFields } from "./fragments";

export const chatMessageFields = gql`
  fragment chatMessageFields on ChatMessage {
    id
    content
    kind
    createdAt
    user {
      id
      displayName
      avatarUrl
      bio
      website
      twitter
      instagram
      isMembership
    }
    likeUsers {
      id
      displayName
    }
  }
`;

export const CREATE_CHAT_MESSAGE_MUTATION = gql`
  mutation createChatMessage($content: String!, $showId: ID!) {
    createChatMessage(content: $content, showId: $showId) {
      success
      error
    }
  }
`;

export const CHAT_MESSAGES_QUERY = gql`
  query chatMessages($showId: ID!, $cursor: String, $last: Int!) {
    chatMessages(showId: $showId, last: $last, before: $cursor) {
      edges {
        node {
          ...chatMessageFields
        }
      }
      pageInfo {
        startCursor
        hasPreviousPage
      }
    }
  }
  ${chatMessageFields}
`;

export const SUBSCRIPTION_ADD_CHAT_MESSAGE = gql`
  subscription OnChatMessageAdded($showId: ID!) {
    chatMessageAdded(showId: $showId) {
      chatMessage {
        ...chatMessageFields
      }
    }
  }
  ${chatMessageFields}
`;

export const DELETE_CHAT_MESSAGE_MUTATION = gql`
  mutation deleteChatMessage($chatMessageId: ID!) {
    deleteChatMessage(chatMessageId: $chatMessageId) {
      success
      error
    }
  }
`;

export const SUBSCRIPTION_DELETE_CHAT_MESSAGE = gql`
  subscription OnChatMessageRemoved($showId: ID!) {
    chatMessageRemoved(showId: $showId) {
      id
    }
  }
`;

export const VIEWER_HAS_CHAT_ACCESS = gql`
  query publicShow($slug: String, $uuid: String) {
    publicShow(slug: $slug, uuid: $uuid) {
      id
      viewerHasChatAccess
    }
  }
`

export const TOGGLE_CHAT_MESSAGE_LIKE_MUTATION = gql`
  mutation toggleChatMessageLike($chatMessageId: ID!) {
    toggleChatMessageLike(chatMessageId: $chatMessageId) {
      success
      error
      chatMessage {
        id
        likeUsers {
          id
          displayName
        }
      }
    }
  }
`;

export const PUBLIC_SHOW_CHAT_CONTACTS_QUERY = gql`
    query publicShowChatContacts($showId: ID!) {
        publicShowChatContacts(showId: $showId) {
          id
          avatarUrl
          displayName
          bio
          addedAt
          twitter
          instagram
          website
          superfan
        }
    }
`;
