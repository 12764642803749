import React from "react";
import styled from "styled-components";

import {
  RecordRow,
  MessageCTAContainer,
  RecordButton,
} from "./common";

const StyledRecordNormal = styled.div``;

const RecordNormal = (
  {
    onStart,
    errorMessage,
    disableMessagesPosting = false
  }) => {
  return (
    <StyledRecordNormal>
      <RecordRow>
        <MessageCTAContainer>
          <RecordButton
            onClick={ () => onStart() }
            disabled={ errorMessage || !!disableMessagesPosting }
          />
        </MessageCTAContainer>
      </RecordRow>
    </StyledRecordNormal>
  );
};

export default RecordNormal;
