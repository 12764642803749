import React from 'react';
import styled from 'styled-components';

const ScrollContainer = styled.div<{css?: string, maxHeight: string}>`
  overflow: auto;
  max-height: ${props => props.maxHeight};
  ${props => props.css}
`;

export default ScrollContainer;
