import styled from 'styled-components';

const PageCard = styled.div`
   padding-bottom: 10px;
   background-color: white;
   border-radius: 8px;
   border: 1px solid #eeeeee;
   ${props => props.shadow && `
      box-shadow: 0px 0px 15px rgb(0 0 0 / 10%);
   `}
`;

export default PageCard;
