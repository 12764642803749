import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button } from 'atoms';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { } from '@fortawesome/free-regular-svg-icons';
import { faVideo, faCog, faPlay } from '@fortawesome/free-solid-svg-icons';

export const RecordRow = styled.div`
    display: flex;
    height: 50px;
    align-items: stretch;
`;

export const VisualizationContainer = styled.div`
    flex: 1 0 100px;
    margin-right: 15px;
    display: flex;
    align-items: stretch;
`;

export const RecordCTAContainer = styled.div`
    width: 50px;
    display: flex;
    align-items: stretch;
`;

export const RecordVisualization = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--color-border);
    border-radius: 3px;
`;

export const RecordCTAButton = styled(Button)`
    width: 50px;
    padding: 5px 5px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const RecordYourMessage = styled(RecordVisualization)`
    background-color: #f6f5f7;
`;

export const RecordButton = (props) => (
    <RecordCTAButton type="primary" icon={<FontAwesomeIcon icon={faVideo} />} {...props} />
);

export const StyledRecordReady = styled.div``;

export const RecordGraphContainer = styled(RecordVisualization)`
    background-color: transparent;
`;

export const RecordGraph = styled.div`
    width: 50px;
`;

const StyledSettingButton = styled(RecordCTAButton)`
    background-color: rgba(255, 255, 255, .7);
    width: 100%;
    :hover{
        background-color: rgba(255, 255, 255, 1);
    }
`;

export const SettingButton = (props) => (
    <StyledSettingButton
        type="default"
        icon={<FontAwesomeIcon icon={faCog} />}
        {...props} />
);

export const RecordFooter = styled.footer`
    padding: 20px 0 0;
`;

export const FooterRow = styled.div`
    display: flex;
    align-items: center;
`;

export const FooterPrimaryCol = styled.div`
    flex: 1 0 100px;
`;

export const FooterSecondaryCol = styled.div`
    width: 100px;
    margin-right: 15px;
`;

export const ButtonIcon = styled(FontAwesomeIcon)`
    margin-right: 5px;
`;

export const PlayButton = (props) => (
    <RecordCTAButton type="primary" icon={<FontAwesomeIcon icon={faPlay} />} {...props} />
);
