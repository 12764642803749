import {gql} from '@apollo/client';

export const inboxFields = gql`
    fragment inboxFields on Inbox {
        id
        name
        description
        prompt
        default
        active
        private
        showTranscription
        showTextContent
        messageCount
        slug
        draft
        welcomeVideoActive
        welcomeVideoThumbnailUrl
        welcomeVideoUrl
        welcomeVideoRatio
        welcomeVideoCreatedDate
        welcomeVideoDuration
    }
`;
