import React, { useContext } from "react";
import { EpisodeType } from "types";
import { Card, CardInner, CardTitle, Col, Div, HtmlEllipsis, Image, Link, Row } from "atoms";
import { PreviewPlayer } from "libraries/Audio/components/AudioRecord/components";
import PreviewPlayerInactive from "libraries/Audio/components/AudioRecord/components/PreviewPlayerInactive";
import { formatSameAsISOTime } from "utils/Datetime";
import { FanPageContext } from "../context";
import { ThemeContext } from "styled-components";
import { CardDescription, CardGreyInner } from "atoms/Card";

const PremiumCard = (props: { episode: EpisodeType, inactive?: boolean }) => {
  const { show } = useContext(FanPageContext);
  const { episode, inactive = false } = props;
  const { isMobile } = useContext(ThemeContext);

  const handlePlayerClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
  }
  return (
    <Link block to={`/${show.slug}/episode/${episode.uuid}`} css='color: inherit !important;'>
      <Card simple shadow>
        <CardInner thin>
          <Row gutter={14}>
            <Col flex="1 0 0" minZero>
              <Div color="grey">{formatSameAsISOTime(episode.publishAt)}</Div>
              <CardTitle>{episode.title}</CardTitle>
            </Col>
            <Col>
              <Image width={isMobile ? '60px' : '70px'} src={episode.customArtworkUrl || show.podcast.coverArtUrl} radius="5px" />
            </Col>
          </Row>
        </CardInner>
        <CardInner thin>
          {
            inactive ? (
              <PreviewPlayerInactive simple duration={episode?.audioDuration} />
            ) : (
              <div onClick={handlePlayerClick}>
                <PreviewPlayer src={episode.audioUrl} duration={episode?.audioDuration} simple />
              </div>
            )
          }
        </CardInner>
        {
          !inactive && (
            <CardInner thin>
              <CardGreyInner>
                <CardDescription>
                  <HtmlEllipsis maxLine={2} unsafeHTML={episode?.description} />
                </CardDescription>
              </CardGreyInner>
            </CardInner>
          )
        }
      </Card>
    </Link>
  )
}

export default PremiumCard;
