import React, { useMemo, useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import styled, { withTheme } from "styled-components";

import { Pagination, SkeletonCard, Card, CardInner } from "atoms";

const ListFooter = styled.div`
  margin-top: 30px;
`;

import TipsItem from './TipsItem'

const TipContainer = styled.div``;
const TipItemCard = styled(Card)`
  margin-bottom: 16px;

  :last-child {
    margin-bottom: 0;
  }
`;
const TipItem = styled.div``;
const SCardInner = styled(CardInner)`
  padding: 15px 0;
`;
import { useQuery } from "@apollo/client";
import {
  PUBLIC_GENERAL_TIPS_QUERY,
} from "apps/PublicPage/graphql";

const TipsList = (
  {
    show,
    generalTipsCount,
    theme: {isDesktop}
  }) => {
  // @ts-ignore
  const {slug} = useParams<{slug: string}>();

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const total = useMemo(() => generalTipsCount, [generalTipsCount]);
  const hasLeaderboard = useMemo(() => generalTipsCount > 0, [generalTipsCount]);
  const {data, loading, refetch} = useQuery(PUBLIC_GENERAL_TIPS_QUERY, {
    variables: {
      showSlug: slug,
      limit: pageSize,
      page: currentPage
    },
  });

  const tipsData = useMemo(
    () => data?.publicGeneralTips || [],
    [data]
  );

  const refetchTips = useCallback(() => {
    return refetch();
  }, [refetch]);

  useEffect(() => {
    refetchTips();
    setCurrentPage(1);
  }, [total]);

  return (
    <div className="TipsList">
      <div>
        {loading ? (
          <div>
            {[1, 2, 3, 4, 5].map((i) => (
              <div key={i} className="mb-4">
                {/*@ts-ignore*/}
                <SkeletonCard avatar={{size: (isDesktop ? 50 : 40), shape: 'square'}} rows={1}/>
              </div>
            ))}
          </div>
        ) : (
          <TipContainer>
            {
              (hasLeaderboard && tipsData) ?
                tipsData.map(generalTip => (
                  <TipItemCard simple shadow key={generalTip.id}>
                    <SCardInner>
                      <TipsItem
                        isShowOwner={show.viewerIsOwner}
                        isAdmin={show.viewerIsAdmin}
                        generalTip={generalTip}
                        reFetchTips={refetchTips}
                      />
                    </SCardInner>
                  </TipItemCard>
                )) :
                (
                  <TipItemCard simple shadow>
                    <CardInner>
                      <TipItem>Be the first to leave a tip!</TipItem>
                    </CardInner>
                  </TipItemCard>
                )
            }
          </TipContainer>
        )}
      </div>
      {total > pageSize && (
        <ListFooter>
          <Pagination
            current={currentPage}
            onChange={(pageNumber) => setCurrentPage(pageNumber)}
            pageSize={pageSize}
            total={total}
          />
        </ListFooter>
      )}
    </div>
  );
};

export default withTheme(TipsList);
