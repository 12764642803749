import { Button, CardDescription, CardImage, CardTitle } from "atoms";
import React from "react";
import { PerkType, PublicPerkType } from "types";

const ViewPerk = (props: { perk: PerkType | PublicPerkType }) => {
  const { perk } = props;

  return (
    <div>
      {perk.coverImageUrl && (
        <div className="mb-3">
          <CardImage simple image={perk.coverImageUrl} aspectRatio={2} />
        </div>
      )}
      <CardTitle className="mb-2">{perk.title}</CardTitle>
      <CardDescription>
        <div
          dangerouslySetInnerHTML={{ __html: perk.unlockedDescription }}
        ></div>
      </CardDescription>
      {perk.showClaimButton && (
        <div className="mt-3">
          <Button href={perk.claimButtonLink} target="_blank" block>
            {perk.claimButtonLabel}
          </Button>
        </div>
      )}
    </div>
  );
};

export default ViewPerk;
