export const MONTHLY = 'monthly';
export const YEARLY = 'yearly';
export const INACTIVE_MESSAGE_LIMIT_TEXT = "To post more messages, click the link in your activation email.";
export const DAILY_MESSAGE_LIMIT_TEXT = "You've reached the daily message limit. Please try again tomorrow.";
export const TRUE = 'true';
export const FALSE = 'false';
export const FAN = 'fan';
export const OWNER = 'owner';
export const MEMBER = 'member';
export const ADMIN = 'admin';
export const [LOGIN, SIGNUP, FORGOT_PASSWORD] = ['LOGIN', 'SIGNUP', 'FORGOT_PASSWORD'];
export const [ARCHIVED, ACTIVE, REPLIED] = ['archived', 'active', 'replied'];
export const LOCKED = 'locked';
export const PINNED = 'PINNED';
export const [DELETE, DOWNLOAD, ARCHIEVE, EDIT] = ['DELETE', 'DOWNLOAD', 'ARCHIEVE', 'EDIT'];
export const ALL = 'ALL';
export const AUTH_TOKEN_KEY = 'authToken';
export const [PAST_DUE, UNPAID, INCOMPLETE, CANCELED, CANCELLED] = ['past_due', 'unpaid', 'incomplete', 'canceled', 'canceled'];
export const [FREE, PRO, BUSINESS] = ['FREE', 'PRO', 'BUSINESS'];
export const [PUBLISHED, DRAFT, DELETED] = ["published", "draft", "deleted"];
export const [BOTTOM_LEFT, BOTTOM_RIGHT] = ['BOTTOM_LEFT', 'BOTTOM_RIGHT'];
export const [PODINBOX, PODCAST, AVATAR, VIDEO] = ['PODINBOX', 'PODCAST', 'AVATAR', 'VIDEO'];
export const SUBSCRIPTION_TYPES = {FREE: 'free', ONE_TIME: 'one_time', MONTHLY: 'monthly', CANCELED_SUBSCRIPTION: 'canceled', ADMIN: 'admin', INVITED: 'gifted', INACTIVE: 'inactive'};
export const BLANK = 'BLANK';
export const IS_CONFIRMED_STATUS = 'IsUserConfirmedEmail';