import React from 'react';
import styled from 'styled-components';

export const Container = styled.div`
  border: 1px solid var(--color-border);
  padding: 20px 20px;
  border-radius: 4px;
  text-align: center;
  position: relative;
  line-height: 1.767;

  :hover {
    border-style: dashed;
    border-color: var(--color-content-grey);
  }
`;

export const InputContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
`;

export const Input = styled.input`
    position: absolute;
    bottom: 0;
    right: 0;
    width: 200%;
    height: 200%;
    opacity: 0;
    cursor: pointer;
    padding: 12px 12px;
`;

export const UploadText = styled.div`
    margin-bottom: 2px;
    font-size: 16px;
    font-weight: 500;
`;

export const ClickOrDrop = styled.div`
    color: var(--color-content-grey);
`;

export const AlertContainer = styled.div`
    margin-top: 20px;
`;
