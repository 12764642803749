import React, { ReactNode } from 'react';
import { PageCard, CardInner, CardTitle, Alert } from "atoms";

type Props = {
    title?: string
    message?: ReactNode
    description?: ReactNode
}

const ErrorCard = ({
    title,
    message,
    description = 'Something went wrong'
}: Props) => (
    <PageCard>
        {
            title && (
                <CardInner>
                    <CardTitle>{title}</CardTitle>
                </CardInner>
            )
        }
        <CardInner>
            <Alert
                type={'error'}
                message={message}
                description={description}
            />
        </CardInner>
    </PageCard>
);

export default ErrorCard;
