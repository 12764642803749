import styled from 'styled-components';
import { Input as AntInput } from 'antd';

const Input = styled(AntInput)`
&.ant-input, & .ant-input {
   border-color: #CCCCCC;
   :hover, :focus {
      box-shadow: none;
   }
   height: 38px;
   padding: 5px 15px;
   font-size: 14px;
   &.ant-input-lg {
      height: 45px;
      padding: 6px 20px;
      font-size: 15px;
      ${props => props.css}
   }
   ${props => props.css}
}
& .ant-input-group-addon {
   background-color: transparent;
}
`;

Input.defaultProps = {
   size: 'large'
};

export default Input;

export const SearchInput = styled(AntInput)`
  && {
    width: 188px;
    margin-bottom: 8px;
    display: block;
  }
`;
