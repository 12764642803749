import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import { Form, FormItem, TextArea, Button } from 'atoms';

import { message, Modal } from 'atoms';
import Auth from 'components/Auth';
import { AccountLockedModal } from 'components/AccountLocked';

import { useLazyQuery, useMutation } from '@apollo/client';
import { ME_QUERY, REACHED_MAX_MESSAGES_COUNT_QUERY } from 'graphql-api';
import { CREATE_TEXT_MESSAGE_MUTATION } from "apps/PublicPage/graphql";
import { LOCKED, SIGNUP, TRUE } from "consts";

import {
  FooterRow,
  FooterSecondaryCol,
  FooterPrimaryCol
} from "libraries/Audio/components/AudioRecord/components/common";

const AuthModalInner = styled.div`
  padding: 25px 0 20px;
`;

const STextRecorder = styled.div`
  width: 100%;
`;

const SFooterRow = styled(FooterRow)`
  padding-top: 5px;
`;

const TextContentEditor = (
  {
    inbox,
    slug,
    text,
    me,
    onCancel,
    onSuccess,
    onToggle,
    onLimit,
  }) => {
  const [textContent, setTextContent] = useState('');
  const [authType, setAuthType] = useState(null);
  const authModalVisible = useMemo(() => !!authType, [authType]);
  const isLoggedIn = useMemo(() => !!me, [me]);
  const [accountLockedVisible, setAccountLockedVisible] = useState(false);
  const [form] = Form.useForm();

  const [triggerMessagesCount] = useLazyQuery(REACHED_MAX_MESSAGES_COUNT_QUERY, {
    fetchPolicy: "network-only"
  });

  const [triggerCreateMessage, {loading: isUpdateBusy}] = useMutation(CREATE_TEXT_MESSAGE_MUTATION, {
    variables: {
      content: textContent,
      inboxId: inbox.id,
      messageType: "text",
    },
    onCompleted({createMessage: {success, error, show, message: textMessage}}) {
      if (success === TRUE) {
        message.success({content: 'Message Submitted'});
        form.resetFields();
        onSuccess && onSuccess(textMessage);
        onToggle && onToggle();
      } else {
        if (error === LOCKED) {
          setAccountLockedVisible(true);
        } else {
          // @ts-ignore
          message.error({content: 'Something went wrong, please try again later'});
        }
      }
    },
    onError() {
      // @ts-ignore
      message.error({content: 'Something went wrong, please try again later'});
      setAuthType(SIGNUP);
    }
  });

  const handleCreateTextMessage = () => {
    triggerMessagesCount({
      variables: {
        slug,
        uuid: "",
      },
    }).then(
      ({data: mCntData}) => {
        if (!mCntData) {
          setAuthType(SIGNUP);
        } else {
          const mCnt = mCntData.reachedMaxDailyMessagesCount;

          if (!mCnt) {
            triggerCreateMessage();
          } else {
            onLimit && onLimit();
            onToggle && onToggle();
          }
        }
      }
    );
  };

  const handleSave = async ({content}) => {
    setTextContent(content);

    if (isLoggedIn) {
      handleCreateTextMessage();
    } else {
      setAuthType(SIGNUP);
    }
  }

  const onAuthSuccess = () => {
    setAuthType(null);

    handleCreateTextMessage();
  }

  const handleCancel = () => {
    form.resetFields();

    onCancel();
  }

  const handleAccountLockedChange = (e) => {
    setAccountLockedVisible(e)
  }

  return (<>
      <STextRecorder>
        <Form
          initialValues={ {
            content: ''
          } }
          onFinish={ handleSave }
          form={ form }
        >
          <FormItem
            name="content"
            rules={ [
              {required: true, message: 'Please enter your message'},
              {type: 'string', max: 2000, message: "You've exceeded the 2,000 character limit"}
            ] }
          >
            <TextArea
              placeholder={ `type your ${ text }...` }
              rows={ 4 }
            />
          </FormItem>
          <SFooterRow>
            <FooterSecondaryCol>
              <Button
                type={ "default" }
                block
                onClick={ handleCancel }
              >
                CANCEL
              </Button>
            </FooterSecondaryCol>
            <FooterPrimaryCol>
              <Button
                htmlType={ 'submit' }
                loading={ isUpdateBusy }
                block
                type={ "green" }
              >
                SUBMIT { text }
              </Button>
            </FooterPrimaryCol>
          </SFooterRow>
        </Form>
      </STextRecorder>
      <Modal
        open={ authModalVisible }
        onCancel={ () => setAuthType(null) }
      >
        <AuthModalInner>
          <Auth
            onSuccess={ onAuthSuccess }
            type={ authType }
            setType={ setAuthType }
            successQuery={ ME_QUERY }
            showId={ inbox && inbox.show ? inbox.show.id : null }
            widget={ false }
          />
        </AuthModalInner>
      </Modal>

      {
        me && (
          <AccountLockedModal
            lockedUntil={ me.lockedUntil }
            open={ accountLockedVisible }
            onOpenChange={ handleAccountLockedChange }
            onCancel={ () => setAccountLockedVisible(false) }
          />
        )
      }
    </>
  )
};

export default TextContentEditor;
