import React, { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPause } from '@fortawesome/free-solid-svg-icons';
import AudioPlayer from 'libraries/Audio/components/AudioPlayer';

import {
  VisualizationContainer,
  RecordVisualization,
  RecordCTAContainer,
  PlayButton,
  RecordCTAButton,
} from './common';
import { INITIAL, PLAYING } from 'libraries/Audio/const';

export const PauseButton = (props) => (
  <RecordCTAButton type="primary" icon={<FontAwesomeIcon icon={faPause}/>} {...props} />
);

const StyledCardPlayer = styled.div``;

const AudioPlayContainer = styled.div`
  width: 100%;
  padding: 0;
`;

const SRecordRow = styled.div`
  padding: 0;
  margin: 0;
  ${props => props.css}
`;

const CardPlayer = ({src, duration}) => {
  const [audio, setAudio] = useState(null);
  const [playerStatus, setPlayerStatus] = useState(INITIAL);

  const handleReady = ({audio: _audio}) => {
    setAudio(_audio);
  };
  const handlePlayClick = () => {
    if (playerStatus !== INITIAL) {
      audio && audio.play();
    }
  };
  const handlePauseClick = () => {
    audio && audio.pause();
  };

  return (
    <StyledCardPlayer>
      <SRecordRow>
        <RecordCTAContainer css={`
          width: 35px;
          align-items: center;
          margin: 0 0 -5px 10px;
        `}>
          {
            playerStatus !== PLAYING && (
              <PlayButton
                shape="circle"
                css={`
                  width: 30px;
                  min-width: 30px;
                  height: 30px;
                  font-size: 14px;
                `}
                onClick={() => handlePlayClick()}
              />
            )
          }
          {
            playerStatus === PLAYING && (
              <PauseButton
                shape="circle"
                css={`
                  width: 30px;
                  min-width: 30px;
                  height: 30px;
                  font-size: 14px;
                `}
                onClick={() => handlePauseClick()}
              />
            )
          }
        </RecordCTAContainer>
        <VisualizationContainer css={`margin: 0 -5px;`}>
          <RecordVisualization css={`border: 0;`}>
            <AudioPlayContainer>
              <AudioPlayer
                src={src}
                duration={duration}
                onReady={handleReady}
                onChangeStatus={status => setPlayerStatus(status)}
                singleRow
                showDuration={false}
                handleStyle={{
                  width: 0,
                  height: 0,
                  opacity: 0
                }}
              />
            </AudioPlayContainer>
          </RecordVisualization>
        </VisualizationContainer>
      </SRecordRow>
    </StyledCardPlayer>
  );
};

export default CardPlayer;
