import React from "react";
import styled, { withTheme } from "styled-components";
import {
  InboxContainer as STipsContainer,
  SectionTitle,
  SectionHeader,
} from "../components/FanPage";
import Tips from "./Tips";

const TipsContainer = ({ show, generalTipsCount, theme: { isDesktop } }) => {

  return (
    <div className="TipsContainer">
      <SectionHeader>
        <SectionTitle>Recent Tips</SectionTitle>
      </SectionHeader>
      <Tips
        show={show}
        generalTipsCount={generalTipsCount}
      />
    </div>
  );
};

// @ts-ignore
export default withTheme(TipsContainer);
