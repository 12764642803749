import React from 'react';
import styled from 'styled-components';
import { Row as AntRow } from 'antd';

import Col from './Col';

const Row = styled(({ singleRow, ...props }) => <AntRow {...props} />)`
    ${props => props.singleRow && `
        margin-left: 0 !important;
        margin-right: 0 !important;
        > ${Col} {
            min-width: 0;
        }
        > ${Col}:first-child {
            padding-left: 0 !important;
        }
        > ${Col}:last-child {
            padding-right: 0 !important;
        }
    `}
    ${props => props.css}
`;

export default Row;
