import React, { useState, useRef } from 'react';
import styled from 'styled-components';

import { PureAudioRecord } from 'libraries/Audio';

const S = {};

S.ReplyAudioRecorder = styled.div``;

const ReplyAudioRecorder = (
  {
    onCreate,
    loading
  }) => {
  const [recordedBlob, setRecordedBlog] = useState(null);

  const audioRef = useRef();

  const handleSave = () => {
    onCreate && onCreate({ file: recordedBlob });
  }
  const handleStop = (blob) => {
    setRecordedBlog(blob);
  }
  return (
    <PureAudioRecord
      onSave={ handleSave }
      onStop={ handleStop }
      isSaveBusy={ loading }
      ref={ audioRef }
    />
  )
}

export default ReplyAudioRecorder;
