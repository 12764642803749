import React, {
  useMemo,
  useState,
  useRef,
  useCallback,
} from "react";
import styled from "styled-components";

import { useParams, useHistory } from "react-router-dom";

import { A, Row, Col, Skeleton } from "atoms";
import { PageInner } from "apps/PublicPage/atoms";
import Inbox from "./Inbox";
import InboxSlider from "apps/PublicPage/components/Slider";
import { FaChevronRight } from "icons";
import { useLayoutEffect } from "react";
import {
  SliderContainerInner,
  InboxContainer as SInboxContainer,
  SectionTitle,
  NextInboxTitle,
  SliderNavButton,
  SectionHeader,
} from "apps/PublicPage/components/FanPage";

import {
  PUBLIC_INBOXES_BY_SHOW,
} from "apps/PublicPage/graphql";
import { useQuery } from "@apollo/client";
import { SShow } from "./FanPageContainer";

const InboxContainer = ({ show }) => {
  // @ts-ignore
  const { slug, inboxSlug } = useParams<{ slug: string; inboxSlug: string | any }>();
  const { data: inboxesData, loading: loadingInboxes } = useQuery(
    PUBLIC_INBOXES_BY_SHOW,
    {
      variables: {
        showSlug: slug,
      },
    }
  );

  const inboxes = useMemo(
    () => inboxesData?.publicInboxesByShow || [],
    [inboxesData]
  );

  const theInboxSlug = useMemo(
    () => (inboxSlug ? inboxSlug : (inboxes.length > 0 ? inboxes[0].slug : null)),
    [inboxes, inboxSlug]
  );

  const [isSlidingTo, setIsSlidingTo] = useState(null);
  const breakpoint = 1150;
  const isMultiInboxes = useMemo(() => inboxes.length > 1, [inboxes]);
  const slugIndex = useMemo(
    () => inboxes.findIndex((inbox) => inbox.slug === theInboxSlug),
    [theInboxSlug, inboxes]
  );
  const activeInbox = useMemo(
    () => (inboxes.length > 0 ? inboxes[slugIndex] : null),
    [inboxes, slugIndex]
  );
  const nextInbox = useMemo(
    () =>
      inboxes.length > 0 ? inboxes[(slugIndex + 1) % inboxes.length] : null,
    [inboxes, slugIndex]
  );
  const initialSlide = useMemo(
    () =>
      inboxes.length > 0
        ? theInboxSlug
          ? inboxes.findIndex((inbox) => inbox.slug === theInboxSlug)
          : 0
        : -1,
    [inboxes]
  );
  const sliderRef = useRef(null);
  const history = useHistory();

  const handleCreatedMessage = async (next) => {
    next();
    return true;
  };

  const handleSlideChange = useCallback(
    (oldIndex, index) => {
      const _activeInbox = inboxes[index];
      history.push(`/${slug}/inbox/${_activeInbox.slug}`);
      setTimeout(() => {
        setIsSlidingTo(null);
      }, 100);
    },
    [inboxes, history, slug]
  );

  const slideTo = useCallback(
    (slide) => {
      if (isSlidingTo === null && slugIndex !== slide) {
        setIsSlidingTo(slide);
      }
    },
    [isSlidingTo, slugIndex]
  );

  useLayoutEffect(() => {
    if (isSlidingTo !== null) {
      sliderRef.current.slickGoTo(isSlidingTo);
    }
  }, [isSlidingTo]);

  if (loadingInboxes) {
    return (
      <SShow>
        <PageInner>
          <Skeleton />
        </PageInner>
      </SShow>
    );
  }

  return (
    <div className="InboxContainer">
      <SectionHeader css=''>
        <Row align="middle" gutter={20} singleRow>
          <Col>
            <SectionTitle>Leave a Message</SectionTitle>
          </Col>
          {isMultiInboxes && (
            <Col minZero flex="1 0 0" css="margin-left: auto;">
              <A
                block
                color="contentGrey"
                onClick={() => slideTo(slugIndex + 1)}
              >
                <Row gutter={10} align="middle" justify="right">
                  <Col flex="1 0 0" minZero>
                    <NextInboxTitle>{nextInbox?.name}</NextInboxTitle>
                  </Col>
                  <Col>
                    <SliderNavButton
                      type={"default"}
                      shape={`circle`}
                      icon={
                        <FaChevronRight
                          css={`
                            font-size: 11px;
                          ` }
                        />
                      }
                    />
                  </Col>
                </Row>
              </A>
            </Col>
          )}
        </Row>
      </SectionHeader>
      {isMultiInboxes ? (
        <SliderContainerInner>
          <InboxSlider
            beforeChange={handleSlideChange}
            initialSlide={initialSlide}
            breakpoint={breakpoint + 1}
            ref={sliderRef}
            centerMode={false}
          >
            {inboxes.map((iInbox, iIndex) => (
              <SInboxContainer
                active={slugIndex === iIndex}
                onClick={() => slideTo(iIndex)}
                key={iInbox.id}
              >
                <Inbox
                  inbox={iInbox}
                  slug={slug}
                  show={show}
                  onCreateMessage={handleCreatedMessage}
                  active={slugIndex === iIndex}
                  cardCss={`min-height: 225px;`}
                />
              </SInboxContainer>
            ))}
          </InboxSlider>
        </SliderContainerInner>
      ) : (
        <Inbox
          inbox={activeInbox}
          slug={slug}
          show={show}
          onCreateMessage={handleCreatedMessage}
          active={true}
          cardCss={undefined}
        />
      )}
    </div>
  );
};

export default InboxContainer;
