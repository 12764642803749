import React, { useEffect, useRef, useState } from 'react';

import { Frequency } from './AnalyzerFrequency';
import GraphVisualization from './GraphVisualization';

const StreamVisualization = ({
    height = 40,
    stream
}) => {
    const [analyser, setAnalyser] = useState(null);

    useEffect(() => {

        if (!stream) {
            return () => { };
        }
        const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
        const _analyser = audioCtx.createAnalyser();

        const source = audioCtx.createMediaStreamSource(stream);
        source.connect(_analyser);
        setAnalyser(_analyser);
        return () => {
            source.disconnect();
            audioCtx.close();
            setAnalyser(null);
        }
    }, [stream]);

    if (!analyser) {
        return null;
    }

    return (
        <Frequency analyser={analyser} render={({ dataArray }) => {
            if (!dataArray) {
                return null;
            }
            const graphArray = dataArray.map(v => v / 10);
            return (
                <GraphVisualization dataArray={graphArray} amplitude={height} />
            )
        }} />
    );
};

export default StreamVisualization;
