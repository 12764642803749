import React, { useState } from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';

import { passwordChecker } from 'ai';

import { CREATE_ACCOUNT_MUTATION, FAN_SIGNUP_MUTATION, WIDGET_SIGNUP_MUTATION } from 'apps/Auth/graphql';
import { Form, Checkbox } from 'antd';
import { Input, FormItem, Button, A, Password } from 'atoms';
import { AUTH_TOKEN_KEY } from 'consts';

const TermsOfServiceContainer = styled.div`
  padding: 10px 0;
`;

const TermsLink = styled(A)`
  color: var(--color-primary);

  :hover {
    color: var(--color-primary);
    opacity: .8;
  }
`;

const TermsCheckboxText = styled.span`
  font-size: 13px;
`;

const [
  EMAIL_ALREADY_EXISTS_ERROR
] = ['Validation failed: Email has already been taken'];

const CreateAccount = (
  {
    onCreateAccount,
    simple = false,
    widget = false,
    showId
  }) => {
  const [email, setEmail] = useState('');
  const [existingEmails, setExistingEmails] = useState([]);
  const [form] = Form.useForm();

  const [triggerWidgetSignUp, {loading: isWidgetCreating}] = useMutation(WIDGET_SIGNUP_MUTATION, {
    onCompleted({widgetSignUp: {authenticationToken, error, success}}) {
      if (!error) {
        localStorage.setItem(AUTH_TOKEN_KEY, authenticationToken);
        onCreateAccount({});
      } else {
        handleErrorResponse(error);
      }
    },
    onError(error) {
      console.error("detected Error", error);
    }
  });

  const [triggerCreateAccount, {loading: isCreating}] = useMutation(CREATE_ACCOUNT_MUTATION, {
    onCompleted({userSignUp: {error, success}}) {
      if (!error) {
        onCreateAccount({});
      } else {
        handleErrorResponse(error);
      }
    },
    onError(error) {
      console.error("detected Error", error);
    }
  });


  const [triggerCreateFanAccount, {loading: isFanCreating}] = useMutation(FAN_SIGNUP_MUTATION, {
    onCompleted({fanSignUp: {error, success}}) {
      if (!error) {
        onCreateAccount({});
      } else {
        handleErrorResponse(error);
      }
    },
    onError(error) {
      console.error("detected Error", error);
    }
  });

  const handleErrorResponse = (error) => {
    switch (error) {
      case EMAIL_ALREADY_EXISTS_ERROR:
        setExistingEmails([...existingEmails, email]);
        form.validateFields(['email']);
        break;
      default:
        break;
    }
  }

  const onFinish = (props) => {
    let referral;

    try {
      if (Rewardful.referral) referral = Rewardful.referral
    } catch {
      console.log('Rewardful blocked')
    }

    const {email, password, firstName, lastName} = props;

    if (simple) {
      if (widget) {
        triggerWidgetSignUp({
          variables: {
            email,
            firstName,
            lastName,
            password,
          }
        });
      } else {
        triggerCreateFanAccount({
          variables: {
            email,
            firstName,
            lastName,
            password,
            referral,
            showId
          }
        });
      }
    } else {
      triggerCreateAccount({
        variables: {
          email,
          firstName,
          lastName,
          password,
          referral
        }
      });
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      requiredMark={false}
      onFinish={onFinish}
      data-rewardful
      id="signup-form"
      initialValues={{
        email: '',
        firstName: '',
        lastName: '',
        password: '',
        referral: ''
      }}
    >
      <FormItem
        name={'email'}
        rules={[{required: true, message: 'Please input your email'}, {
          type: 'email',
          message: 'The input is not valid E-mail',
        }, {
          validator: (_, value) => {
            if (existingEmails.includes(value)) {
              return Promise.reject('This account already exists. Please login.');
            } else {
              return Promise.resolve();
            }
          }
        }]}
      >
        <Input placeholder={'Email'} onChange={e => setEmail(e.target.value)}/>
      </FormItem>
      <FormItem
        name={'firstName'}
        rules={[{required: true, message: 'Please input first name'}]}
      >
        <Input placeholder={'First Name'}/>
      </FormItem>
      <FormItem
        name={'lastName'}
        rules={[{required: true, message: 'Please input last name'}]}
      >
        <Input placeholder={'Last Name'}/>
      </FormItem>
      <FormItem
        name={'password'}
        rules={[{required: true, message: 'Please input your password'}, {
          validator: (_, value) => {
            const result = passwordChecker(value);
            if (result === true) {
              return Promise.resolve();
            } else if (result && value) {
              return Promise.reject(result)
            } else {
              return Promise.resolve();
            }
          },
        }]}
        hasFeedback
      >
        <Password placeholder={'Password'}/>
      </FormItem>
      <TermsOfServiceContainer>
        <FormItem
          name={'agreement'}
          valuePropName="checked"
          rules={[{
            validator: (_, value) =>
              value ? Promise.resolve() : Promise.reject(new Error('Please accept the agreement')),
          }]}
        >
          <Checkbox>
            <TermsCheckboxText>
              I agree to the <TermsLink href='https://www.fanlist.com/terms/' target="_blank">terms of
              use</TermsLink> and <TermsLink href='https://www.fanlist.com/privacy/' target="_blank">privacy
              policy</TermsLink>
            </TermsCheckboxText>
          </Checkbox>
        </FormItem>
      </TermsOfServiceContainer>
      <Button type="primary" htmlType="submit" loading={isCreating || isFanCreating || isWidgetCreating} block>
        CREATE ACCOUNT
      </Button>
    </Form>
  );
};

export default CreateAccount;
