import React, { useMemo, useState, useEffect } from 'react';
import styled from 'styled-components';

import { useReactMediaRecorder } from 'libraries/Media';

import {
    NORMAL, READY, RECORDING, STOPPED, UPLOADING, SAVED, UPLOADED, PERMISSION_DENIED
} from 'libraries/Video/const';

import { message, Modal } from 'atoms';
import {
    RecordNormal,
    RecordMain,
    RecordPlayer
} from './components';
import Auth from 'components/Auth';

import { useMutation, useQuery } from '@apollo/client';
import { DELETE_INBOX_WELCOME_VIDEO_MUTATION } from 'libraries/Video/graphql';

import { LOGIN, SIGNUP, TRUE } from 'consts';
import { ME_QUERY, INBOX_QUERY } from 'graphql-api';
import RecordUploaded from './components/RecordUploaded';
import { UPDATE_INBOX_MUTATION } from 'apps/Dashboard/graphql';

const StyledAudioRecord = styled.div``;

const AuthModalInner = styled.div`
    padding: 25px 0 20px;
`;

const VideoRecord = ({ inboxID, onCreatedVideo, onDeletedVideo, onCancel: $onCancel, initialStatus }) => {

    const [status, setStatus] = useState(initialStatus);
    const [duration, setDuration] = useState(0);
    const [selectedAudioId, setSelectedAudioId] = useState(null);
    const [selectedVideoId, setSelectedVideoId] = useState(null);
    const [recordedBlob, setRecordedBlog] = useState(null);
    const [authType, setAuthType] = useState(null);
    const authModalVisible = useMemo(() => !!authType, [authType]);
    const { data: meData, error: meError } = useQuery(ME_QUERY);
    const isLoggedIn = useMemo(() => !!meData, [meData]);
    const audioConstraints = useMemo(() => (selectedAudioId ? { deviceId: selectedAudioId } : true), [selectedAudioId]);
    const videoConstraints = useMemo(() => ({
        // width: 600,
        // height: 600,
        // resizeMode: "crop-and-scale",
        ...(selectedVideoId ? { deviceId: selectedVideoId } : {})
    }), [selectedVideoId]);
    const constraints = useMemo(() => ({
        audio: audioConstraints
    }), [audioConstraints]);
    const mediaRecordActive = useMemo(() => [READY, RECORDING].includes(status), [status]);
    const [triggerCreateVideo, { loading: isUploadBusy }] = useMutation(UPDATE_INBOX_MUTATION, {
        awaitRefetchQueries: true,
        refetchQueries: [{ query: INBOX_QUERY }],
        variables: {
            inboxId: inboxID,
            file: recordedBlob
        },
        onCompleted({ updateInbox: { success, error } }) {
            if (success === TRUE) {
                stopStream();
                setStatus(UPLOADED);
                message.success({ content: 'Wecome Video Created' });
                onCreatedVideo && onCreatedVideo();
            } else {
                message.error({ content: 'Something went wrong, please try again later!!' });
            }
        }
    });

    const [triggerDeleteVideo, { loading: isDeleteBusy }] = useMutation(DELETE_INBOX_WELCOME_VIDEO_MUTATION, {
        onCompleted({ deleteInboxWelcomeVideo: { success, error } }) {
            if (success === TRUE) {
                setStatus(NORMAL);
                message.success({ content: 'Wecome Video Deleted' });
                onDeletedVideo && onDeletedVideo();
            } else {
                message.error({ content: 'Something went wrong, please try again later!!' });
            }
        }
    })

    const options = useMemo(() => ({
        audio: audioConstraints,
        video: videoConstraints,
        onStop: (blobUrl, blob) => {
            setRecordedBlog(blob);
        },
        active: mediaRecordActive
    }), [audioConstraints, videoConstraints, mediaRecordActive]);

    const {
        stream,
        getMediaStream,
        stopStream,
        status: recorderStatus,
        startRecording,
        stopRecording,
        mediaBlobUrl,
        previewStream,
        error
    } = useReactMediaRecorder(options);

    const onReady = () => {
        startRecording();
        setStatus(RECORDING);
    }

    const onStop = ({
        duration: _duration
    }) => {
        setDuration(_duration);
        stopRecording();
        setStatus(STOPPED);
    }
    const onCancel = () => {

        stopStream();
        setStatus(NORMAL);
        setDuration(0);
        $onCancel && $onCancel();
    }
    const handleOnStart = () => {

        if (!stream) {
            // getMediaStream();
        }
        setStatus(READY);
    };
    const onSave = () => {
        if (isLoggedIn) {
            triggerCreateVideo();
        } else {
            setAuthType(SIGNUP);
        }
    }

    const handleDelete = () => {
        triggerDeleteVideo();
    }

    const onAuthSuccess = () => {
        setAuthType(null);
        triggerCreateVideo();
    }
    useEffect(() => {
        if (error) {
        }
        return () => {
        }
    }, [error]);

    return (
        <>
            <StyledAudioRecord>
                {
                    status === NORMAL && (
                        <RecordNormal onStart={() => handleOnStart()} permissionDenied={error === PERMISSION_DENIED} />
                    )
                }
                {
                    [READY, RECORDING].includes(status) && (
                        <RecordMain
                            onReady={onReady}
                            status={status}
                            onStop={onStop}
                            onCancel={onCancel}
                            selectedAudioId={selectedAudioId}
                            setSelectedAudioId={setSelectedAudioId}
                            selectedVideoId={selectedVideoId}
                            setSelectedVideoId={setSelectedVideoId}
                            constraints={constraints}
                            previewStream={previewStream}
                            stream={stream}
                            error={error}
                        />
                    )
                }
                {
                    status === STOPPED && (
                        <RecordPlayer src={mediaBlobUrl} duration={duration} onSave={onSave} onCancel={onCancel} isUploadBusy={isUploadBusy} />
                    )
                }
                {
                    status === UPLOADED && (
                        <RecordUploaded src={mediaBlobUrl} duration={duration} onDelete={handleDelete} isDeleteBusy={isDeleteBusy} />
                    )
                }
            </StyledAudioRecord>
            {
                !isLoggedIn && (
                    <Modal open={authModalVisible} onCancel={() => setAuthType(null)}>
                        <AuthModalInner>
                            <Auth onSuccess={onAuthSuccess} type={authType} setType={setAuthType} />
                        </AuthModalInner>
                    </Modal>
                )
            }
        </>
    )
};

VideoRecord.defaultProps = {
    initialStatus: READY
}

export default VideoRecord;
