import styled from 'styled-components';
import { getColorValue } from 'ai';
import { string } from "prop-types";

const Span = styled.span`
   ${({ color }) => color && `
      color: ${getColorValue(color)};
   `}
   ${({ defaultColor }) => defaultColor && `
      color: var(--color, ${getColorValue(defaultColor)});
   `}
   ${props => props?.css}
`;

export default Span;