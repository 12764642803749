import React from 'react';
import styled, { css } from "styled-components";

const BackgroundImage = styled(({ src, ...props }) => <div {...props} />)`
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center center;
`;

const BImageLayout = ({ src = null, className, asBackground, alt, ratio, fullHeight, round, css, circle, imgCss, ...restProps }) => (
  <div className={`${className}`}>
    {src ? (asBackground ? <BackgroundImage className={'b-image'} src={src} {...restProps} /> : <img className={'b-image'} src={src} alt={alt} {...restProps} />) : <div className={'b-image__placeholder'} />}
  </div>
);

const Image = styled(BImageLayout)`
  padding-bottom: ${props => props.fullHeight ? 0 : props.ratio === 'auto' ? !props.src ? 100 : 0 : 100 / props.ratio}%;
  height: ${props => props.fullHeight ? '100%' : 'auto'};
  position: relative;
  overflow: hidden;
  border-radius: ${props => props.radius ? props.radius : props.circle || props.round ? '50%' : 0};
  ${props => props.width && `width: ${props.width};`}
  .b-image {
    max-width: 100%;
    object-fit: cover;
    object-position: center;
    ${props => props.ratio !== 'auto' || props.fullHeight ? css`
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;` : ``
  }
    ${props => props.imgCss || ''}
  }
  .b-image__placeholder {
    position: absolute;
    top: 0;
    left: 0;
    width: ${props => props.width || `100%`};
    height: ${props => props.width || `100%`};
    background-color: #E8E8E8;
    border-radius: ${props => props.circle || props.round ? '50%' : 0};
  }
  ${props => props.css}
`;
Image.defaultProps = {
  css: '',
  alt: '',
  ratio: 1, // width to height, ie: 1/1, 4/3
  fullHeight: false
};

export default Image;
