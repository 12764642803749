import React, { useMemo } from "react";
import { withTheme } from "styled-components";

import { Image, Row, Col, Popover, Div } from "atoms";
import { socialLinks } from "micro-data";
import ListenConnects from "../components/ListenConnects";

import {
  ImageContainer,
  PageTitle,
  HeaderBanner,
  ListenButton,
  ListenPopoverInner,
  MobilePageTitle,
} from "../components/FanPage";
import { FaHeadphones, FaLink } from "icons";

const FanPageHeader = ({ show, theme: { isDesktop, isMobile } }) => {

  const showLinks = useMemo(() => {
    return show
      ? [
        ...(show.email
          ? [
            {
              ...socialLinks.email,
              name: "email",
              link: `mailto:${show.email}`,
            },
          ]
          : []),
        ...(show.website
          ? [
            {
              ...socialLinks.website,
              name: "website",
              link: `https://${show.website}`,
            },
          ]
          : []),
        ...(show.instagram
          ? [
            {
              ...socialLinks.instagram,
              name: "instagram",
              link: `https://${show.instagram}`,
            },
          ]
          : []),
        ...(show.twitter
          ? [
            {
              ...socialLinks.twitter,
              name: "twitter",
              link: `https://${show.twitter}`,
            },
          ]
          : []),
        ...(show.facebook
          ? [
            {
              ...socialLinks.facebook,
              name: "facebook",
              link: `https://${show.facebook}`,
            },
          ]
          : []),
        ...(show.linkedin
          ? [
            {
              ...socialLinks.linkedin,
              name: "linkedin",
              link: `https://${show.linkedin}`,
            },
          ]
          : []),
        ...(show.tiktok
          ? [
            {
              ...socialLinks.tiktok,
              name: "tiktok",
              link: `https://${show.tiktok}`,
            },
          ]
          : []),

        ...(show.applePodcast
          ? [
            {
              ...socialLinks.applePodcast,
              name: "applePodcast",
              link: `https://${show.applePodcast}`,
            },
          ]
          : []),
        ...(show.spotify
          ? [
            {
              ...socialLinks.spotify,
              name: "spotify",
              link: `https://${show.spotify}`,
            },
          ]
          : []),
        ...(show.rssFeed
          ? [
            {
              ...socialLinks.rssFeed,
              name: "rssFeed",
              link: `https://${show.rssFeed}`,
            },
          ]
          : []),
        ...(show.youtube
          ? [
            {
              ...socialLinks.youtube,
              name: "youtube",
              link: `https://${show.youtube}`,
            },
          ]
          : []),
      ]
      : [];
  }, [show]);
  const listenLinks = useMemo(
    () => showLinks.filter(
      // @ts-ignore
      (l) => !!l.listenOn
    ),
    [showLinks]
  );
  const normalLinks = useMemo(
    () => showLinks.filter(
      // @ts-ignore
      (l) => !l.listenOn
    ),
    [showLinks]
  );

  const bannerImage = useMemo(() => show?.bannerImageUrl || null, [show]);

  return (
    <div className="FanPageHeader">
      <HeaderBanner bannerImage={bannerImage}>
        {!isMobile && (
          <div>
            <Row align="bottom" gutter={20}>
              {show.coverImageUrl && (
                <Col>
                  <ImageContainer pushTop={0}>
                    <Image src={show.coverImageUrl} radius={"5px"} />
                  </ImageContainer>
                </Col>
              )}
              <Col>
                <PageTitle>{show.title}</PageTitle>
                {showLinks.length > 0 && (
                  <Div>
                    <Row singleRow gutter={10}>
                      {listenLinks.length > 0 && (
                        <Col>
                          <Popover
                            trigger={["click"]}
                            placement="bottomLeft"
                            simple
                            content={
                              <ListenPopoverInner>
                                <ListenConnects
                                  title="Listen On"
                                  items={listenLinks}
                                />
                              </ListenPopoverInner>
                            }
                          >
                            <ListenButton>
                              <Row gutter={5} align="middle">
                                <Col>
                                  <FaHeadphones />
                                </Col>
                                <Col>listen</Col>
                              </Row>
                            </ListenButton>
                          </Popover>
                        </Col>
                      )}
                      {normalLinks.length > 0 && (
                        <Col>
                          <Popover
                            trigger={["click"]}
                            placement="bottomLeft"
                            simple
                            content={
                              <ListenPopoverInner>
                                <ListenConnects
                                  title="Connect"
                                  items={normalLinks}
                                />
                              </ListenPopoverInner>
                            }
                          >
                            <ListenButton>
                              <Row gutter={5} align="middle">
                                <Col>
                                  <FaLink />
                                </Col>
                                <Col>connect</Col>
                              </Row>
                            </ListenButton>
                          </Popover>
                        </Col>
                      )}
                    </Row>
                  </Div>
                )}
              </Col>
            </Row>
          </div>
        )}
      </HeaderBanner>
      {
        isMobile && (
          <div style={{ marginTop: '20px' }}>
            <div className="mb-4">
              <Row align="bottom" gutter={[20, 20]} justify="space-between">
                {show.coverImageUrl && (
                  <Col>
                    <ImageContainer pushTop={100}>
                      <Image src={show.coverImageUrl} radius={"5px"} />
                    </ImageContainer>
                  </Col>
                )}
                {showLinks.length > 0 && (
                  <Col>
                    <Div>
                      <Row singleRow gutter={10}>
                        {listenLinks.length > 0 && (
                          <Col>
                            <Popover
                              trigger={["click"]}
                              placement="bottomLeft"
                              simple
                              content={
                                <ListenPopoverInner>
                                  <ListenConnects
                                    title="Listen On"
                                    items={listenLinks}
                                  />
                                </ListenPopoverInner>
                              }
                            >
                              <ListenButton>
                                <Row gutter={5} align="middle">
                                  <Col>
                                    <FaHeadphones />
                                  </Col>
                                  <Col>listen</Col>
                                </Row>
                              </ListenButton>
                            </Popover>
                          </Col>
                        )}
                        {normalLinks.length > 0 && (
                          <Col>
                            <Popover
                              trigger={["click"]}
                              placement="bottomLeft"
                              simple
                              content={
                                <ListenPopoverInner>
                                  <ListenConnects
                                    title="Connect"
                                    items={normalLinks}
                                  />
                                </ListenPopoverInner>
                              }
                            >
                              <ListenButton>
                                <Row gutter={5} align="middle">
                                  <Col>
                                    <FaLink />
                                  </Col>
                                  <Col>connect</Col>
                                </Row>
                              </ListenButton>
                            </Popover>
                          </Col>
                        )}
                      </Row>
                    </Div>
                  </Col>
                )}
              </Row>
            </div>
            {show.title && (
              <MobilePageTitle>{show.title}</MobilePageTitle>
            )}
          </div>
        )
      }
    </div>
  );
};

export default withTheme(FanPageHeader);
