import React, { useMemo, useState } from "react";
import { ThemeProvider } from "styled-components";
import { useMediaQuery } from "react-responsive";
import { colors } from "styles";
import { ConfigProvider } from "antd";

const PodThemeProvider = (props) => {
  const isLarge = useMediaQuery({ minWidth: 1320 });
  const isDesktop = useMediaQuery({ minWidth: 769 });
  const isTablet = useMediaQuery({ maxWidth: 768, minWidth: 569 });
  const isMobile = useMediaQuery({ maxWidth: 568 });
  const isXMobile = useMediaQuery({ maxWidth: 424 });

  const [primaryColor, setPrimaryColor] = useState(colors.primary);
  const primaryHoverColor = useMemo(() => `${primaryColor}D8`, [primaryColor]);

  const theme = {
    isLarge,
    isDesktop,
    isTablet,
    isMobile,
    isXMobile,
    colors: {
      ...colors,
      primary: primaryColor,
      primaryHover: primaryHoverColor,
    },
    setPrimaryColor,
  };

  return (
    <ThemeProvider theme={theme}>
      <ConfigProvider theme={{
        token: {
          borderRadius: 4,
          colorPrimary: primaryColor,
          colorPrimaryActive: primaryColor,
          colorPrimaryHover: primaryHoverColor,
          colorPrimaryText: primaryColor,
          colorLink: primaryColor,
          colorLinkHover: primaryColor,
          colorLinkActive: primaryColor,
          colorHighlight: primaryColor,
          colorIcon: primaryColor,
          colorIconHover: primaryColor,
          fontFamily: "'Lexend Deca', sans-serif",
          fontSize: 14,
          lineHeight: 1.5
        }
      }}>
        {props.children}
      </ConfigProvider>
    </ThemeProvider>
  );
};

export default PodThemeProvider;
