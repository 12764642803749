import React from 'react';
import styled from 'styled-components';

import { A, Row, Col } from 'atoms';
import { FaDesktop, FaInstagram, FaTwitter, FaEnvelope } from 'icons';

const SSocialList = styled.div``;

const SocialItemContainer = styled.div`
  display: flex;
`;

const SocialItem = styled(A)`
  display: block;
  margin-bottom: 3px;
  font-size: 16px;
`;

const IconContainer = styled.div`
  width: 20px;
  text-align: center;
  line-height: 0;
`;

const SocialText = styled.div`
  font-size: 14px;
`;

const SocialList = (
  {
    twitter,
    instagram,
    website,
    email
  }) => {
  return (
    <SSocialList>
      {
        !!email && (
          <SocialItemContainer>
            <SocialItem href={`mailto:${email}`} target={'_blank'}>
              <Row align={'middle'} gutter={12} singleRow>
                <Col>
                  <IconContainer>
                    <FaEnvelope verticalMiddle/>
                  </IconContainer>
                </Col>
                <Col>
                  <SocialText>
                    email
                  </SocialText>
                </Col>
              </Row>
            </SocialItem>
          </SocialItemContainer>
        )
      }
      {
        website && (
          <SocialItemContainer>
            <SocialItem href={`https://${website}`} target={'_blank'}>
              <Row align={'middle'} gutter={12} singleRow>
                <Col>
                  <IconContainer>
                    <FaDesktop color={'#4285F4'} verticalMiddle/>
                  </IconContainer>
                </Col>
                <Col>
                  <SocialText>
                    website
                  </SocialText>
                </Col>
              </Row>
            </SocialItem>
          </SocialItemContainer>
        )
      }
      {
        instagram && (
          <SocialItemContainer>
            <SocialItem href={`https://instagram.com/${instagram}`} target={'_blank'}>
              <Row align={'middle'} gutter={12} singleRow>
                <Col>
                  <IconContainer>
                    <FaInstagram color={'#C13584'} verticalMiddle/>
                  </IconContainer>
                </Col>
                <Col>
                  <SocialText>
                    @{instagram}
                  </SocialText>
                </Col>
              </Row>
            </SocialItem>
          </SocialItemContainer>
        )
      }
      {
        twitter && (
          <SocialItemContainer>
            <SocialItem href={`https://twitter.com/${twitter}`} target={'_blank'}>
              <Row align={'middle'} gutter={12} singleRow>
                <Col>
                  <IconContainer>
                    <FaTwitter color={'#197efe'} verticalMiddle/>
                  </IconContainer>
                </Col>
                <Col>
                  <SocialText>
                    @{twitter}
                  </SocialText>
                </Col>
              </Row>
            </SocialItem>
          </SocialItemContainer>
        )
      }
    </SSocialList>
  )
}

export default SocialList;
