import { gql } from '@apollo/client';

export const UPDATE_PUBLIC_PAGE_COVER_IMAGE_MUTATION = gql`
    mutation updateShowCoverImage($image: Upload!) {
        updateShowCoverImage(image: $image) {
            success,
            error,
            publicPage {
                coverImageUrl
            }
        }
    }
`;

export const UPDATE_PUBLIC_PAGE_MUTATION = gql`
    mutation updateShow($slug: String, $title: String, $website: String) {
        updateShow(slug: $slug, title: $title, website: $website) {
            success,
            error,
            publicPage {
                slug,
                website,
                title
            }
        }
    }
`;

export const DELETE_PUBLIC_PAGE_COVER_IMAGE_MUTATION = gql`
    mutation deleteShowCoverImage {
        deleteShowCoverImage {
            success,
            error
        }
    }
`;
