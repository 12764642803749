import { gql } from '@apollo/client';

export const CREATE_SHOW_TAG_MUTATION = gql`
    mutation createShowTag($name: String!) {
        createShowTag(name: $name) {
            success,
            error
        }
    }
`;

export const DELETE_SHOW_TAG_MUTATION = gql`
    mutation deleteShowTag($id: String!) {
        deleteShowTag(id: $id) {
            success,
            error
        }
    }
`;

export const UPDATE_SHOW_TAG_MUTATION = gql`
    mutation updateShowTag($id: String!, $name: String!) {
        updateShowTag(id: $id, name: $name) {
            success,
            error
        }
    }
`;

export const TOGGLE_MESSAGE_TAG_ACTIVE_MUTATION = gql`
    mutation toggleMessageTagActive($messageId: ID, $tagId: ID) {
        toggleMessageTagActive(messageId: $messageId, tagId: $tagId) {
            success,
            error,
            message {
                id,
                tags {
                    id,
                    name
                }
            }
        }
    }
`;