import { gql } from '@apollo/client';

export const CREATE_ACCOUNT_MUTATION = gql`
   mutation CreateAccount($email: String!, $firstName: String!, $lastName: String!, $password: String!, $referral: String) {
      userSignUp(email: $email, firstName: $firstName, lastName: $lastName, password: $password, referral: $referral) {
      success
      error
    }
  }
`;


export const MEMBER_SIGNUP_MUTATION = gql`
   mutation memberSignUp($email: String!, $firstName: String!, $lastName: String!, $password: String!, $invitedUserKey: String!) {
    memberSignUp(email: $email, firstName: $firstName, lastName: $lastName, password: $password, invitedUserKey: $invitedUserKey) {
      success
      error
    }
  }
`;

export const SUBSCRIPTION_TOTAL = gql`
  query subscriptionTotal($annually: Boolean!, $promotionCode: String, $product: String!) {
    subscriptionTotal(annually: $annually, promotionCode: $promotionCode, product: $product)
  }
`;
export const CHECK_URL_QUERY = gql`
  query CheckUrl($url: String!) {
    checkUrl(url: $url) {
      success
    }
  }
`;

export const LOGOUT_MUTATION = gql`
  mutation userLogout {
    userLogout {
      success
    }
  }
`;

export const CREATE_PUBLIC_PAGE_MUTATION = gql`
  mutation createPublicPage($slug: String!) {
    createPublicPage(slug: $slug) {
      success
      error
    }
  }
`;

export const PUBLIC_PAGE_SLUG_VALIDATOR_QUERY = gql`
  query publicPageSlugValidator($slug: String!) {
    publicPageSlugValidator(slug: $slug) {
      success,
      error
    }
  }
`;

export const LOGIN_MUTATION = gql`
  mutation userLogin($email: String!, $password: String!) {
    userLogin(email: $email, password: $password) {
      success
      error
    }
  }
`;

export const WIDGET_LOGIN_MUTATION = gql`
  mutation widgetLogin($email: String!, $password: String!) {
    widgetLogin(email: $email, password: $password) {
      authenticationToken
      success
      error
    }
  }
`;

export const REQUEST_RESET_PASSWORD_MUTATION = gql`
  mutation requestResetPassword($email: String!) {
    requestResetPassword(email: $email) {
      success,
      error
    }
  }
`;

export const RESET_PASSWORD_WITH_TOKEN_MUTATION = gql`
  mutation resetPasswordWithToken($password: String!, $token: String!) {
    resetPasswordWithToken(password: $password, token: $token) {
      success,
      error
    }
  }
`;

export const CREATE_SHOW_MUTATION = gql`
  mutation createShow($slug: String!, $title: String, $image: Upload) {
    createShow(slug: $slug, title: $title, image: $image) {
      success,
      error
    }
  }
`;

export const FAN_SIGNUP_MUTATION = gql`
  mutation fanSignUp($email: String!, $firstName: String!, $lastName: String!, $password: String!, $referral: String, $showId: ID) {
    fanSignUp(email: $email, firstName: $firstName, lastName: $lastName, password: $password, referral: $referral, showId: $showId) {
      success,
      error
    }
  }
`;

export const WIDGET_SIGNUP_MUTATION = gql`
   mutation WidgetSignUpMutation($email: String!, $firstName: String!, $lastName: String!, $password: String!) {
    widgetSignUp(email: $email, firstName: $firstName, lastName: $lastName, password: $password) {
      authenticationToken
      success
      error
    }
  }
`;


export const VALIDATE_PROMOTION_CODE_QUERY = gql`
  query validatePromotionCode($promotionCode: String!) {
    validatePromotionCode(promotionCode: $promotionCode) {
      text
    }
  }
`;

export const SET_INITIAL_SLUG_MUTATION = gql`
  mutation setInitialSlug($slug: String) {
    setInitialSlug(slug: $slug) {
      success
      error
    }
  }
`;
