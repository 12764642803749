import React, { useState, useMemo } from "react";
import styled from "styled-components";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import {
  GET_SUPPORTING_PAYMENT_METHOD,
  PURCHASE_PERK_MUTATION,
} from "apps/PublicPage/graphql";

import { ElementsProvider } from "providers/StripeProvider";
import {
  Col,
  Form,
  FormItemLabel,
  FormItemUIOnly,
  Row,
  Button,
  A,
  Skeleton,
  FormItemError,
  Spacer,
  TextArea,
  Span,
} from "atoms";
import CardDetails from "components/payment/CardDetails";
import { TRUE } from "consts";
import { default as VisaIcon } from "icons/Visa";
import { PublicPerkType, Show } from "types";
import { ME_QUERY } from "graphql-api";
const SavedCardRow = styled.div`
  padding: 10px 0 13px;
`;
const CardNumberInfo = styled.div``;
const VisaIconContainer = styled.div`
  width: 22px;
  padding-right: 5px;
  padding-bottom: 2px;
  box-sizing: content-box;
  color: var(--color-grey-lighter);
  line-height: 0;
`;
const Terms = styled.div`
  margin-top: 20px;
  font-size: 12px;
  color: var(--color-content-grey);
  ${A}:not(:hover) {
    color: var(--color-content-grey);
  }
`;

type Props = {
  perk: PublicPerkType;
  onSuccess?: ({
    perk,
    intendMessage,
  }: {
    perk: PublicPerkType;
    intendMessage: false | string;
  }) => void;
  onError?: () => void;
};

const PurchaseForm = ({ perk, onSuccess, onError }: Props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [customFieldValue, setCustomFieldValue] = useState("");
  const [cardDetailsComplete, setCardDetailsComplete] = useState(false);
  const [cardDetailsError, setCardDetailsError] = useState(null);
  const [stripeCardIncompleteError, setStripeCardIncompleteError] =
    useState(false);
  const [apiIntendMessage, setApiIntendMessage] = useState<false | string>(
    false
  );
  const [isInChangeCard, setIsInChangeCard] = useState(false);

  const { data: supportingPaymentMethodData, loading } = useQuery(
    GET_SUPPORTING_PAYMENT_METHOD,
    {
      fetchPolicy: "network-only",
    }
  );
  const supportingPaymentMethod = useMemo(
    () => supportingPaymentMethodData?.getSupportingPaymentMethod,
    [supportingPaymentMethodData]
  );
  const { brand, last4 } = useMemo(
    () => supportingPaymentMethod || {},
    [supportingPaymentMethod]
  );
  const hasSavedCard = useMemo(() => !!last4, [last4]);
  const isInSavedCard = useMemo(
    () => hasSavedCard && !isInChangeCard,
    [hasSavedCard, isInChangeCard]
  );

  const [stripeApiError, setStripeApiError] = useState(null);
  const [form] = Form.useForm();
  const [isTokenBusy, setIsTokenBusy] = useState(false);
  const [triggerPurchase, { loading: isAccessPaymentBusy }] = useMutation(
    PURCHASE_PERK_MUTATION,
    {
      onCompleted({ purchasePerk: { error, success, perk } }) {
        if (success === TRUE) {
          handleAfterSuccess({ perk });
        } else {
          if (error === "Perk previously purchased, so this was not charged.") {
            setApiIntendMessage(error);
            onSuccess && onSuccess({ perk, intendMessage: error });
          } else {
            setStripeApiError({ message: error });
          }
        }
      },
      onError(errorPayload) {},
    }
  );

  const handleAfterSuccess = ({ perk }) => {
    onSuccess && onSuccess({ perk, intendMessage: apiIntendMessage });
  };
  const handleFinish = async () => {
    try {
      if (isInSavedCard || perk.amountCents === 0) {
        triggerPurchase({
          variables: {
            perkId: perk.id,
            customFieldValue,
          },
        });
        return;
      }
      if (!cardDetailsComplete) {
        !cardDetailsError && setStripeCardIncompleteError(true);
        return;
      }
      const cardElement = elements.getElement(CardElement);
      setIsTokenBusy(true);
      const {
        token: { id: tokenId },
      } = await stripe.createToken(cardElement);
      triggerPurchase({
        variables: {
          perkId: perk.id,
          token: tokenId,
        },
      });
      setIsTokenBusy(false);
    } catch (e) {
      onError && onError();
    }
  };

  const handleCardDetailsChange = (event) => {
    setStripeApiError(null);
    setStripeCardIncompleteError(false);
    setCardDetailsComplete(event.complete);
    setCardDetailsError(event.error);
  };

  if (loading) {
    return <Skeleton />;
  }

  return (
    <Form onFinish={handleFinish} initialValues={{}} form={form}>
      {perk.customFieldEnabled && (
        <FormItemUIOnly>
          <FormItemLabel className="pb-1">{perk.customFieldLabel}</FormItemLabel>
          {perk.customFieldDescription && (
            <Span color="grey">
              {perk.customFieldDescription}
            </Span>
          )}
          <Form.Item
            className="mt-2"
            name="customField"
            rules={[
              {
                required: perk.customFieldRequired,
                message: `${perk.customFieldLabel} is required`,
              },
            ]}
          >
            <TextArea rows={3} style={{ resize: 'none' }} onChange={(e) => setCustomFieldValue(e.target.value)}/>
          </Form.Item>
        </FormItemUIOnly>
      )}
      {perk.amountCents === 0 ? (
        <Button
          htmlType="submit"
          block
          className="mt-3"
        >
          YES, I WANT THIS
        </Button>
      ) : (
        <>
          {isInSavedCard && (
            <FormItemUIOnly>
              <FormItemLabel>Payment Info</FormItemLabel>
              <SavedCardRow>
                <Row align="middle">
                  <Col>
                    <VisaIconContainer>
                      <VisaIcon />
                    </VisaIconContainer>
                  </Col>
                  <Col>
                    <CardNumberInfo>
                      {brand} xxxx {last4}
                    </CardNumberInfo>
                  </Col>
                  <Spacer />
                  <Col>
                    <A onClick={() => setIsInChangeCard(true)}>change card</A>
                  </Col>
                </Row>
              </SavedCardRow>
              {stripeApiError && (
                <FormItemError>{stripeApiError.message}</FormItemError>
              )}
            </FormItemUIOnly>
          )}
          {!isInSavedCard && (
            <FormItemUIOnly>
              <FormItemLabel>Payment Info</FormItemLabel>
              <CardDetails onChange={handleCardDetailsChange} />
              {stripeApiError && (
                <FormItemError>{stripeApiError.message}</FormItemError>
              )}
              {stripeCardIncompleteError && (
                <FormItemError>All fields are required</FormItemError>
              )}
            </FormItemUIOnly>
          )}
          <Button
            htmlType="submit"
            block
            className="mt-3"
            loading={isTokenBusy || isAccessPaymentBusy}
          >
            purchase this perk
          </Button>
        </>
      )}
    </Form>
  );
};

const PurchasePerk = (props: Props) => {
  return (
    <ElementsProvider>
      <PurchaseForm {...props} />
    </ElementsProvider>
  );
};

export default PurchasePerk;
