import React from 'react';
import { Skeleton, Card, CardInner, CardTitle, Div } from "atoms";

type Props = {
  title?: string
  rows?: number
  css?: string
  shadow?: boolean
  customRow?: boolean
}

const SkeletonCard = (
  {
    title,
    rows = 2,
    css,
    shadow,
    customRow,
    ...props
  }: Props) => (
  <Card shadow={shadow} css={css}>
    {
      title && (
        <CardInner>
          <CardTitle>{title}</CardTitle>
        </CardInner>
      )
    }
    <CardInner css={`padding: 20px 20px 0 20px;`}>
      {
        customRow && (
          <div>
            <Skeleton.Input active/>
            <Div css={`margin-top: 20px;`}>
              <Skeleton.Avatar active size={80} shape={'square'}/>
            </Div>
          </div>
        )
      }
      <Skeleton active paragraph={{rows}} {...props} />
    </CardInner>
  </Card>
);

export default SkeletonCard;
