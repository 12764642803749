import SuperfanItem from 'components/SuperfanItem';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Superfan } from 'types';

const SSuperfanList = styled.div``;

type Props = {
  superfans: Superfan[],
  sortCompare?: false | ((a: Superfan, b: Superfan) => number)
}

const SuperfanList = (props: Props) => {

  const { superfans, sortCompare } = props;
  const sortedSuperfans: Superfan[] = useMemo(() => sortCompare ? [...superfans].sort(sortCompare) : superfans, [superfans, sortCompare]);

  return (
    <SSuperfanList>
      {
        sortedSuperfans.map(superfan => (
          <SuperfanItem key={superfan.user.id} superfan={superfan} />
        ))
      }
    </SSuperfanList>
  )
}

SuperfanList.defaultProps = {
  sortCompare: (a, b) => b.totalCents - a.totalCents
}

export default SuperfanList;