import React, { useContext, useMemo } from "react";
import styled, { withTheme } from "styled-components";
import { FanPageContext, withPublicPageState } from 'apps/PublicPage/context';
import {
  PageContentColumnLeft,
  PageContentColumnRight,
  PageContentRow,
  SectionHeader,
  SectionTitle,
} from "apps/PublicPage/components/FanPage";

import TierSelection from "apps/PublicPage/components/TierSelection";
import MembersContainer from "apps/PublicPage/containers/MembersContainer";
import { Flex } from "antd";
import RssFeedCard from "apps/PublicPage/components/RssFeedCard";

const ActiveMembers = (
  {
    refetchShowQuery,
    publicPageState: { me },
    theme: { isDesktop }
  }) => {
  const { show, membership, isMember } = useContext(FanPageContext);

  return (
    <PageContentRow>
      <PageContentColumnLeft>
        <MembersContainer />
      </PageContentColumnLeft>
      <PageContentColumnRight>
        <div>
          <SectionHeader>
            {
              isMember ? (
                <SectionTitle>Your Membership</SectionTitle>
              ) : (
                <SectionTitle>Become a Member</SectionTitle>
              )
            }
          </SectionHeader>
          <div>
            <TierSelection
              show={show}
              membership={membership}
              me={me}
              refetchShowQuery={refetchShowQuery}
            />
          </div>
          {
            show.podcast && isMember && (
              <div>
                <RssFeedCard />
              </div>
            )
          }
        </div>
      </PageContentColumnRight>
    </PageContentRow>
  )
}

export default withPublicPageState(withTheme(ActiveMembers));