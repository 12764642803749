import React, { useRef, useState } from 'react';
import styled from 'styled-components';

import { Row, Col } from 'atoms';

import { PlayButton, PauseButton, LoadingButton } from 'libraries/Media/atoms';
import AudioLazyPlayer from 'libraries/Audio/components/AudioLazyPlayer';
import { INITIAL, PLAYING } from 'libraries/Audio/const';

const S = {};

S.ReplyAudioPlayer = styled.div`
  width: 100%;
`;

const ReplyAudioPlayer = ({src, duration}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [playerStatus, setPlayerStatus] = useState(INITIAL);

  const playerRef = useRef();

  const handlePlay = () => {
    playerRef.current.play();
  }

  const handlePause = () => {
    playerRef.current.pause();
  }

  return (
    <S.ReplyAudioPlayer>
      <Row gutter={10} align={'middle'}>
        <Col>
          {
            isLoading && (
              <LoadingButton/>
            )
          }
          {
            !isLoading && playerStatus !== PLAYING && (
              <PlayButton onClick={handlePlay}/>
            )
          }
          {
            !isLoading && playerStatus === PLAYING && (
              <PauseButton onClick={handlePause}/>
            )
          }
        </Col>
        <Col flex={`1 0 0`}>
          <AudioLazyPlayer
            src={src}
            duration={duration}
            ref={playerRef}
            onLoadingChange={_isLoading => setIsLoading(_isLoading)}
            onStatusChange={_status => setPlayerStatus(_status)}
            singleRow
          />
        </Col>
      </Row>
    </S.ReplyAudioPlayer>
  )
}

export default ReplyAudioPlayer;
