import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Button, Alert } from 'atoms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone, faPause } from '@fortawesome/free-solid-svg-icons';
import AudioPlayer from 'libraries/Audio/components/AudioPlayer';

import {
    RecordRow,
    VisualizationContainer,
    RecordVisualization,
    RecordCTAContainer,
    RecordFooter,
    FooterRow,
    FooterSecondaryCol,
    FooterPrimaryCol,
    PlayButton,
    RecordCTAButton,
    ButtonIcon
} from './common';
import { IDLE, INITIAL, PLAYING } from 'libraries/Video/const';
import VideoPlayer from '../../VideoPlayer';

export const PauseButton = (props) => (
    <RecordCTAButton type="primary" icon={<FontAwesomeIcon icon={faPause} />} {...props} />
);

const StyledRecordPlayer = styled.div``;

const PlayerContainer = styled.div``;

const AlertContainer = styled.div`
    margin-top: 20px;
`;

const RecordPlayer = ({ onSave, src, duration, onCancel, isUploadBusy }) => {

    const handleClickCancel = () => {
        onCancel();
    }

    return (
        <StyledRecordPlayer>
            <PlayerContainer>
                <VideoPlayer src={src} />
            </PlayerContainer>
            <RecordFooter>
                <FooterRow>
                    <FooterSecondaryCol>
                        <Button type={'default'} block onClick={() => handleClickCancel()}>CANCEL</Button>
                    </FooterSecondaryCol>
                    <FooterPrimaryCol>
                        {
                            !isUploadBusy && (
                                <Button onClick={() => onSave()} block>SAVE RECORDING</Button>
                            )
                        }
                        {
                            isUploadBusy && (
                                <Button block loading={true} type={'green'}>UPLOADING</Button>
                            )
                        }
                    </FooterPrimaryCol>
                </FooterRow>
            </RecordFooter>
            {
                isUploadBusy && (
                    <AlertContainer>
                        <Alert
                            type={'info'}
                            description={'Please stay on this page while your video is uploading. This can take several minutes.'}
                        />
                    </AlertContainer>
                )
            }
        </StyledRecordPlayer>
    );
};

export default RecordPlayer;
