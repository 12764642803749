import React, { useState, useMemo, useEffect, useContext } from "react";
import styled, { withTheme } from "styled-components";
import {
  PublicPageContext,
} from "apps/PublicPage/context";
import { useLazyQuery } from "@apollo/client";
import { ME_QUERY } from "graphql-api";

import { afterModalClose, Col, message, Modal, Row, confettie } from "atoms";

import GiveSupport, { SUPPORT, CUSTOM_AMOUNT } from "components/GiveSupport";
import ThanksSuperfan from "components/ThanksSuperfan";
import Confetti from "components/Confetti";
import { supportAmounts } from "micro-data";
import { ID, Message, Show, Theme } from "types";
import { LOGIN, SIGNUP } from "consts";

const SPayoutAccount = styled.div``;
const AmountList = styled.div<{ simple?: boolean }>`
  ${(props) => !props.simple && "margin-bottom: 10px;"}
`;
const AmountItem = styled.div<{ small: boolean, selected?: boolean, custom?: boolean }>`
  ${(props) =>
    props.theme.isMobile &&
    `
        min-width: ${props.small ? 60 : 70}px;
        padding: ${props.small ? "10px 2px" : "10px 2px"};
        font-size: ${props.small ? 12 : 14}px;
    `}
  ${(props) =>
    !props.theme.isMobile &&
    `
        min-width: ${props.small ? 70 : 82}px;
        padding: ${props.small ? "10px 10px" : "10px 15px"};
        font-size: ${props.small ? 13 : 15}px;
    `}
  letter-spacing: 0.05em;
  text-align: center;
  cursor: pointer;
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
  border-radius: 30px;

  :hover {
    background-color: var(--color-primary);
    color: white;
  }

  ${(props) =>
    props.selected &&
    `
        background-color: var(--color-primary);
        color: white;
        :hover {
            background-color: var(--color-primary-hover);
        }
    `}
  ${(props) =>
    props.custom &&
    `
        letter-spacing: 1px;
    `}
`;
const ModalInner = styled.div`
  padding: 0 0 20px;
`;
const ShowYourSupport = styled.div`
  text-align: center;
  font-size: 18px;
  color: var(--color-content-grey);
`;

type Props = {
  isLoggedIn?: boolean;
  show: Show;
  messageId?: ID;
  showYourSupport?: boolean;
  theme: Theme;
  center?: boolean;
  small?: boolean;
  onSelect?: () => void;
  onSupport?: ({message}: { message: Message }) => void;
  simple?: boolean;
};
const PaymentSelection = (
  {
    isLoggedIn: $isLoggedIn,
    show,
    messageId,
    showYourSupport,
    theme: {isMobile},
    center,
    small,
    onSelect,
    onSupport,
    simple,
  }: Props) => {
  // TODO: this is quick solution, should apply stable solution soon.
  const publicPage = useContext(PublicPageContext);
  const {isLoggedIn, openAuthModal, authModalVisible} = publicPage || {isLoggedIn: $isLoggedIn};
  const [paymentIntended, setPaymentIntended] = useState(false);
  const [supportModalVisible, setSupportModalVisible] = useState(false);
  const [selectedAmount, setSelectedAmount] = useState(null);
  const availableAmounts = useMemo(
    () =>
      (messageId ? show?.messageSupportAmounts : show?.supportAmounts) ||
      supportAmounts,
    [show, messageId]
  );
  const [thanksModalVisible, setThanksModalVisible] = useState(false);
  const [triggerMeQuery] = useLazyQuery(ME_QUERY);

  const handleSelectAmount = (amount) => {
    onSelect && onSelect();
    setSelectedAmount(amount);
    if (isLoggedIn) {
      setSupportModalVisible(true);
    } else {
      openAuthModal(SIGNUP);
      setPaymentIntended(true);
    }
  };
  const handleSelectCustom = () => {
    onSelect && onSelect();
    setSelectedAmount(null);
    if (isLoggedIn) {
      setSupportModalVisible(true);
    } else {
      openAuthModal(SIGNUP);
      setPaymentIntended(true);
    }
  };
  const handleSuccess = ({message}) => {
    onSupport && onSupport({message});

    setSupportModalVisible(false);
    afterModalClose(() => {
      setThanksModalVisible(true);
      if (!isLoggedIn) {
        console.log("trigger Me Query");
        triggerMeQuery();
      }
    });
  };
  const handleThanksModalClose = () => {
    setThanksModalVisible(false);
  };
  const handleAfterClose = () => {
  };
  const handleError = () => {
    // @ts-ignore
    message.error({content: "Something went wrong, please try again later."});
  };

  useEffect(() => {
    if (paymentIntended && isLoggedIn) {
      afterModalClose(() => {
        setSupportModalVisible(true);
      });
    }
  }, [isLoggedIn, paymentIntended]);

  useEffect(() => {
    if (!authModalVisible) {
      setPaymentIntended(false);
    }
  }, [authModalVisible]);

  return (
    <SPayoutAccount>
      <AmountList simple={simple}>
        <Row
          gutter={isMobile ? [4, 4] : small ? [7, 7] : [10, 10]}
          justify={center ? "center" : "initial"}
        >
          {availableAmounts.map((amount) => (
            <Col span={6} key={amount}>
              <AmountItem
                onClick={() => handleSelectAmount(amount)}
                small={small}
              >
                ${amount}
              </AmountItem>
            </Col>
          ))}
          <Col span={6} key="custom">
            <AmountItem
              onClick={() => handleSelectCustom()}
              custom
              small={small}
            >
              ...
            </AmountItem>
          </Col>
        </Row>
      </AmountList>
      {showYourSupport && <ShowYourSupport>show your support</ShowYourSupport>}
      <Modal
        open={supportModalVisible}
        width={380}
        onCancel={() => setSupportModalVisible(false)}
        simple
        destroyOnClose
      >
        <ModalInner>
          <GiveSupport
            isLoggedIn={isLoggedIn}
            initialStep={selectedAmount ? SUPPORT : CUSTOM_AMOUNT}
            amount={selectedAmount}
            showId={show.id}
            messageId={messageId}
            onSuccess={handleSuccess}
            onError={handleError}
          />
        </ModalInner>
      </Modal>
      <Modal
        open={thanksModalVisible}
        onCancel={handleThanksModalClose}
        mask={false}
        zIndex={1100}
        afterClose={handleAfterClose}
      >
        <ThanksSuperfan show={show} forMessage={!!messageId}/>
      </Modal>
      {thanksModalVisible && <Confetti zIndex={1090} mask/>}
    </SPayoutAccount>
  );
};

PaymentSelection.defaultProps = {
  showYourSupport: false,
  center: false,
  messageId: null,
  small: false,
};

export default withTheme(PaymentSelection);
