import React, { useEffect, useRef, useState } from 'react';

import { TimeDomain } from './AnalyzerTimeDomain';
import LevelVolumeVisualization from './LevelVolumeVisualization';

const StreamLevelVisualization = ({
    height,
    stream,
    rate
}) => {
    const [analyser, setAnalyser] = useState(null);

    useEffect(() => {

        if (!stream) {
            return () => { };
        }
        const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
        const _analyser = audioCtx.createAnalyser();

        const source = audioCtx.createMediaStreamSource(stream);
        source.connect(_analyser);
        setAnalyser(_analyser);
        return () => {
            source.disconnect();
            audioCtx.close();
            setAnalyser(null);
        }
    }, [stream]);

    if (!analyser) {
        return null;
    }

    return (
        <TimeDomain analyser={analyser} render={({ dataArray }) => {
            if (!dataArray) {
                return null;
            }
            const graphArray = dataArray.map(v => {
                const cv = Math.max(v / 128, 1);
                return Math.round(rate * 40 * (cv - 1));
            });
            return (
                <LevelVolumeVisualization dataArray={graphArray} height={height} />
            )
        }} />
    );
};

StreamLevelVisualization.defaultProps = {
    height: 40,
    stream: null,
    rate: 8
}

export default StreamLevelVisualization;
