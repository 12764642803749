import { gql } from "@apollo/client";

import { inboxFields } from "graphql-api";

export const INBOX_QUERY = gql`
  query inbox {
    inbox {
      ...inboxFields
    }
  }
  ${inboxFields}
`;

export const UPDATE_INBOX_MUTATION = gql`
  mutation updateInbox(
    $inboxId: ID!
    $file: Upload
    $name: String
    $description: String
    $prompt: String
    $private: Boolean
  ) {
    updateInbox(
      inboxId: $inboxId
      file: $file
      name: $name
      description: $description
      prompt: $prompt
      private: $private
    ) {
      success
      error
      inbox {
        ...inboxFields
      }
    }
  }
  ${inboxFields}
`;

export const DELETE_INBOX_WELCOME_VIDEO_MUTATION = gql`
  mutation deleteInboxWelcomeVideo($inboxId: ID!) {
    deleteInboxWelcomeVideo(inboxId: $inboxId) {
      success
      error
      inbox {
        ...inboxFields
      }
    }
  }
  ${inboxFields}
`;

export const CREATE_INBOX_MUTATION = gql`
  mutation createInbox(
    $file: Upload
    $name: String
    $description: String
    $prompt: String
    $private: Boolean
    $showTranscription: Boolean
    $showTextContent: Boolean
    $welcomeVideoActive: Boolean
  ) {
    createInbox(
      file: $file
      name: $name
      description: $description
      prompt: $prompt
      private: $private
      showTranscription: $showTranscription
      showTextContent: $showTextContent
      welcomeVideoActive: $welcomeVideoActive
    ) {
      success
      error
      inbox {
        ...inboxFields
      }
    }
  }
  ${inboxFields}
`;

export const DELETE_INBOX_MUTATION = gql`
  mutation deleteInbox($inboxId: String!) {
    deleteInbox(inboxId: $inboxId) {
      success
      error
    }
  }
`;

export const TOGGLE_WELCOME_VIDEO_MUTATION = gql`
  mutation toggleWelcomeVideo($inboxId: ID) {
    toggleWelcomeVideo(inboxId: $inboxId) {
      success
      error
      inbox {
        id
        welcomeVideoActive
      }
    }
  }
`;

export const ORDER_INBOXES_MUTATION = gql`
  mutation orderInboxes($inboxOrder: [String!]!) {
    orderInboxes(inboxOrder: $inboxOrder) {
      success
      error
    }
  }
`;
export const TOGGLE_INBOX_ACTIVE_MUTATION = gql`
  mutation toggleInboxActive($inboxId: ID) {
    toggleInboxActive(inboxId: $inboxId) {
      success
      error
      inbox {
        id
        active
      }
    }
  }
`;

export const TOGGLE_INBOX_PRIVATE = gql`
  mutation updateInbox($inboxId: ID!, $private: Boolean) {
    updateInbox(inboxId: $inboxId, private: $private) {
      success
      error
      inbox {
        id
        private
      }
    }
  }
`;

export const TOGGLE_INBOX_SHOW_TRANSCRIPTION = gql`
  mutation updateInbox($inboxId: ID!, $showTranscription: Boolean) {
    updateInbox(inboxId: $inboxId, showTranscription: $showTranscription) {
      success
      error
      inbox {
        id
        showTranscription
      }
    }
  }
`;

export const TOGGLE_INBOX_ALLOW_TEXT_CONTENT = gql`
  mutation updateInbox($inboxId: ID!, $showTextContent: Boolean) {
    updateInbox(inboxId: $inboxId, showTextContent: $showTextContent) {
      success
      error
      inbox {
        id
        showTextContent
      }
    }
  }
`;

export const INBOXES_BY_SHOW = gql`
  query inboxesByShow {
    inboxesByShow {
      ...inboxFields
    }
  }
  ${inboxFields}
`;
