import React, { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import styled, { withTheme } from "styled-components";
import {
  Row,
  Col,
  Card,
  CardInner,
  SkeletonCard,
  Avatar,
} from "atoms";
import {
  SectionHeader,
  SectionTitle,
  SuperfansContainer,
  PageContentRow,
  PageContentColumnLeft,
  PageContentColumnRight,
} from "apps/PublicPage/components/FanPage";

import TipsContainer from "apps/PublicPage/containers/TipsContainer";
import Superfans from "apps/PublicPage/components/Superfans";
import { useQuery } from "@apollo/client";
import {
  PUBLIC_LEADERBOARDS_QUERY,
} from "apps/PublicPage/graphql";
import GeneralTipsPayment from "apps/PublicPage/components/GeneralTipsPayment";

const SupportersContainer = styled.div<{ css?: string }>`
  ${(props) => props.css}
`;

const ActiveTips = (
  {
    show,
    refetchShowQuery,
    paymentSelectionVisible,
    theme: { isDesktop }
  }) => {
  // @ts-ignore
  const { slug } = useParams<{ slug: string }>();

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 12;
  const { data, loading, refetch } = useQuery(PUBLIC_LEADERBOARDS_QUERY, {
    variables: {
      showSlug: slug,
      limit: pageSize,
      page: currentPage
    },
  });

  const superFansData = useMemo(
    () => data?.publicLeaderboards || [],
    [data]
  );

  const hasLeaderboard = useMemo(() => superFansData.length > 0, [superFansData]);

  const refetchLeaderboards = useCallback(() => {
    return refetch();
  }, [refetch]);

  const onSupport = async ({ message }) => {
    await refetchShowQuery();
    await refetchLeaderboards();
  }
  if (paymentSelectionVisible) {
    return (
      <PageContentRow>
        <PageContentColumnLeft>
          <TipsContainer
            show={show}
            generalTipsCount={show.generalTipsCount}
          />
        </PageContentColumnLeft>
        <PageContentColumnRight>
          <SupportersContainer css={`margin-bottom: 20px;`}>
            <SectionHeader css="">
              <SectionTitle>{show.donationLabel}</SectionTitle>
            </SectionHeader>
            <GeneralTipsPayment
              show={show}
              center
              simple
              onSupport={onSupport}
            />
          </SupportersContainer>

          {show.showLeaderboard && (
            <SupportersContainer>
              <SectionHeader css="">
                <SectionTitle>Top Supporters</SectionTitle>
              </SectionHeader>
              <Card simple shadow>
                <CardInner css="padding-left: 0; padding-right: 0;">
                  {
                    loading ?
                      (
                        <div className="mb-4">
                          {/*@ts-ignore*/}
                          <SkeletonCard avatar={{ size: 60, shape: 'square' }} rows={1} />
                        </div>
                      ) :
                      (
                        hasLeaderboard ? (
                          <SuperfansContainer>
                            <Superfans
                              superfans={superFansData}
                              showSuperfanDollarAmounts={show.showSuperfanDollarAmounts}
                            />
                          </SuperfansContainer>
                        ) : (
                          <Row align='middle' gutter={20} css={`padding: 0 10px;`}>
                            <Col>
                              <Avatar width="46px" src="" placeholder="" />
                            </Col>
                            <Col>Be one of my top supporters!</Col>
                          </Row>
                        )
                      )
                  }
                </CardInner>
              </Card>
            </SupportersContainer>
          )}
        </PageContentColumnRight>
      </PageContentRow>
    )
  } else {
    return (
      <TipsContainer
        show={show}
        generalTipsCount={show.generalTipsCount}
      />
    )
  }
}

export default withTheme(ActiveTips);