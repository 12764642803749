import React, { memo } from 'react';
import styled from 'styled-components';
import { A, Avatar, Col, Div, message, Popover, PopoverInner, Row, Spacer } from 'atoms';

import { ChatMessage, User } from 'types';
import { useMemo } from 'react';
import { parseDate } from 'utils/Datetime';
import { FaEllipsisV, FaHeart, FaSHeart, FaTrashAlt } from 'icons';
import { useMutation } from '@apollo/client';
import { DELETE_CHAT_MESSAGE_MUTATION, TOGGLE_CHAT_MESSAGE_LIKE_MUTATION } from 'graphql-api';
import { TRUE } from 'consts';
import { DeleteCta } from 'molecules';
import UserProfile from 'components/UserProfile';

const SChatMessageItem = styled.div``;
const MessageBody = styled.div``;
const MessageHeader = styled.div`
  margin-bottom: 4px;
`;
const UserName = styled.div`
  font-size: 13px;
  color: var(--color-content-grey);
`;
const CreatedAt = styled.div`
  font-size: 13px;
  color: var(--color-grey-lighter);
`;
export const MessageText = styled.div`
  background-color: #f9f9f9;
  padding: 10px 12px;
  border-radius: 4px;
  font-size: 14px;
  border: 1px solid #f5f5f5;
  white-space: pre-line;
  .mention_user {
    color: var(--color-primary);
  }
  .hicon {
    font-size: 1.2em;
    vertical-align: middle;
  }
`;
const ControlText = styled.div`
  color: var(--color-content);
  font-size: 15px;
  ${A}:hover & {
    color: inherit;
  }
`;
const AvatarContainer = styled.div`
  padding-top: 6px;
`;
const ControlItemInner = styled(PopoverInner)`
  border-bottom: none;
  padding-top: 5px;
  padding-bottom: 5px;
`;
const Likes = styled.div`
  padding: 6px 9px;
  border-radius: 15px;
  border: 1px solid #e5d6d7;
  cursor: pointer;
`;
const LikesCount = styled.div`
  font-weight: 500;
  line-height: 1;
  user-select: none;
`;
const LikeUserItem = styled.div`
  margin-bottom: 3px;
  &:last-child {
    margin-bottom: 0;
  }
`;

type Props = {
  message: ChatMessage,
  me: User,
  viewerIsAdmin: boolean
}

const ChatMessageItem = (props: Props) => {
  // @ts-ignore
  const { message: { id, content, user, createdAt, likeUsers, __typename }, me, viewerIsAdmin } = props;
  const liked = useMemo(() => likeUsers.map(u => u.id).includes(me?.id), [likeUsers, me]);
  const theCreatedAt = useMemo(() => {
    const date = new Date(createdAt);
    const parsed = parseDate(date);;
    return `${parsed.hh}:${parsed.mm} ${parsed.apm}`;
  }, [createdAt]);
  const canDeleteMessage = useMemo(() => viewerIsAdmin || me?.id === user.id, [me, user, viewerIsAdmin]);
  const theContent = useMemo(() => {
    // @ts-ignore
    return content.replaceAll(/@\w[^\s]+/g, (match, p1) => `<span class="mention_user">${match}</span>`);
  }, [content]);

  const [triggerDelete, { loading }] = useMutation(DELETE_CHAT_MESSAGE_MUTATION, {
    variables: { chatMessageId: id },
    onCompleted({ deleteChatMessage: { success } }) {
      if (success === TRUE) {
        message.success({ content: "Message Deleted" });
      }
    }
  });
  const [triggerToggleLike] = useMutation(TOGGLE_CHAT_MESSAGE_LIKE_MUTATION, {
    variables: { chatMessageId: id },
    onCompleted({ toggleChatMessageLike: { success } }) {
    }
  });

  const handleDelete = async () => {
    await triggerDelete();
  }
  const handleToggleLike = () => {

    let expectLikeUsers: User[] = [];

    if (liked) {
      expectLikeUsers = likeUsers.filter(u => u.id !== me.id);
    } else {
      expectLikeUsers = [...likeUsers, { id: me.id, displayName: me.displayName } as User];
    }

    triggerToggleLike({
      optimisticResponse: {
        toggleChatMessageLike: {
          success: true,
          chatMessage: {
            __typename,
            id,
            likeUsers: expectLikeUsers
          }
        }
      }
    });
  }
  const handleMessageTextClick = (e) => {
    if (e.detail === 2) {
      handleToggleLike();
    }
  }

  return (
    <SChatMessageItem>
      <Row gutter={12}>
        <Col>
          <Popover content={<UserProfile user={user} />} simple trigger={['click']} placement='topLeft'>
            <AvatarContainer>
              <Avatar src={user.avatarUrl} width="35px" />
            </AvatarContainer>
          </Popover>
        </Col>
        <Col flex="1 0 0" minZero>
          <MessageBody>
            <MessageHeader>
              <Row align='middle' gutter={12}>
                <Col>
                  <Popover content={<UserProfile user={user} />} simple trigger={['click']} placement='topLeft'>
                    <A color='contentGrey'>
                      {user.displayName}
                    </A>
                  </Popover>
                </Col>
                <Spacer />
                <Col>
                  <CreatedAt>{theCreatedAt}</CreatedAt>
                </Col>
                {
                  !!me && (
                    <Col>
                      <Popover
                        trigger={['click']}
                        placement='bottomRight'
                        simple
                        content={(
                          <Div className="pt-1 pb-1">
                            <A color='contentGrey' block onClick={handleToggleLike}>
                              <ControlItemInner>
                                <Row algin='middle' gutter={8}>
                                  <Col><FaSHeart verticalMiddle color={liked ? 'primary' : 'inherit'} /></Col>
                                  <Col><ControlText>like</ControlText></Col>
                                </Row>
                              </ControlItemInner>
                            </A>
                            {
                              canDeleteMessage && (
                                <DeleteCta
                                  loading={loading}
                                  onDelete={handleDelete}
                                >
                                  <A color='contentGrey' block>
                                    <ControlItemInner>
                                      <Row algin='middle' gutter={8}>
                                        <Col><FaTrashAlt verticalMiddle /></Col>
                                        <Col><ControlText>delete</ControlText></Col>
                                      </Row>
                                    </ControlItemInner>
                                  </A>
                                </DeleteCta>
                              )
                            }
                          </Div>
                        )}>
                        <A color='#b3adad'>
                          <FaEllipsisV />
                        </A>
                      </Popover>
                    </Col>
                  )
                }
              </Row>
            </MessageHeader>
            <MessageText onClick={handleMessageTextClick} dangerouslySetInnerHTML={{ __html: theContent }}></MessageText>
            {
              likeUsers.length > 0 && (
                <Div className='mt-1'>
                  <Row>
                    <Col>
                      <Popover
                        placement='rightTop'
                        content={(
                          <Div>
                            {
                              likeUsers.map(likeUser => (
                                <LikeUserItem key={likeUser.id}>{likeUser.displayName.replace(' ', '')}</LikeUserItem>
                              ))
                            }
                          </Div>
                        )}
                      >
                        <Likes>
                          <Row align="middle" gutter={6}>
                            <Col css="line-height: 0;">
                              <FaSHeart color="primary" verticalMiddle css="font-size: 15px;" />
                            </Col>
                            <Col>
                              <LikesCount>{likeUsers.length}</LikesCount>
                            </Col>
                          </Row>
                        </Likes>
                      </Popover>
                    </Col>
                  </Row>
                </Div>
              )
            }
          </MessageBody>
        </Col>
      </Row>
    </SChatMessageItem>
  )
}

export default memo(ChatMessageItem);
