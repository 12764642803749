import { useQuery } from "@apollo/client";
import { Flex } from "antd";
import { FanPageContext } from "apps/PublicPage/context";
import { PUBLIC_EPISODE_MEMBER_QUERY, PUBLIC_EPISODE_NON_MEMBER_QUERY } from "apps/PublicPage/graphql";
import { A, Card, CardInner, CardTitle, Div, ErrorCard, Link, SkeletonCard, Span, HtmlEllipsis, Row, Col, Image } from "atoms";
import { CardDescription, CardGreyInner } from "atoms/Card";

import { FaChevronLeft } from "icons";
// import PreviewPlayerDark from "libraries/Audio/components/AudioRecord/components/PreviewPlayerDark";
import AudioPlayerDark from 'apps/PublicPage/components/AudioPlayerDark';
import PreviewPlayerInactive from "libraries/Audio/components/AudioRecord/components/PreviewPlayerInactive";
import React, { useContext, useMemo } from "react";
import { useParams } from "react-router-dom";
import styled, { ThemeContext } from "styled-components";
import { EpisodeType } from "types";
import { formatFromIos, formatSameAsISOTime } from "utils/Datetime";

const EpisodeDescripton = styled.div`
  p {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const EpisodeDetails = (props: { episode: EpisodeType }) => {

  const { isMember, show } = useContext(FanPageContext);
  const { isMobile } = useContext(ThemeContext);
  const { episode } = props;
  return (
    <div className="EpisodeDetails">
      <div className="mb-3">
        <Link to={`/${show.slug}/members`}>
          <Flex align="center" gap={10}>
            <FaChevronLeft />
            <Span>Back to List</Span>
          </Flex>
        </Link>
      </div>
      <div className="mb-3">
        <CardTitle css='font-size: 22px;'>{episode.title}</CardTitle>
        <CardDescription grey>{formatSameAsISOTime(episode.publishAt)}</CardDescription>
      </div>
      <div className="mb-3">
        <Card simple shadow css="background-color: #444444;">
          <CardInner>
            <div className="mb-3">
              <Flex gap={10}>
                <div style={{ flex: '1 0 0', minWidth: 0, paddingTop: isMobile ? '10px' : 0, overflow: 'hidden' }}>
                  <CardTitle css="color: white;">{episode.title}</CardTitle>
                </div>
                {
                  isMobile && (
                    <div style={{flex: '0 0 60px'}}>
                      <Image width={'60px'} src={episode.customArtworkUrl || show.podcast.coverArtUrl} radius="5px" />
                    </div>
                  )
                }
              </Flex>
            </div>
            <Row gutter={20}>
              <Col flex="1 0 0" minZero>
                {
                  isMember ? (
                    <AudioPlayerDark src={episode.audioUrl} duration={episode.audioDuration} simple />
                  ) : (
                    <PreviewPlayerInactive simple duration={episode.audioDuration} />
                  )
                }
              </Col>
              {
                !isMobile && (
                  <Col>
                    <Image width={'70px'} src={episode.customArtworkUrl || show.podcast.coverArtUrl} radius="5px" />
                  </Col>
                )
              }
            </Row>
          </CardInner>
        </Card>
      </div>
      {
        isMember && (
          <Card simple>
            <CardInner>
              <HtmlEllipsis
                unsafeHTML={episode.description}
                maxLine={5} />
            </CardInner>
          </Card>
        )
      }
    </div>
  )
}

const WithData = () => {
  const { isMember } = useContext(FanPageContext);
  const { uuid } = useParams<{ uuid: string }>();
  const { data: episodeMemberData, loading: eMemberLoading } = useQuery(PUBLIC_EPISODE_MEMBER_QUERY, {
    variables: { uuid },
    skip: !isMember
  });
  const { data: episodeNonMemberData, loading: eNonMemberLoading } = useQuery(PUBLIC_EPISODE_NON_MEMBER_QUERY, {
    variables: { uuid },
    skip: isMember
  });
  const episode = useMemo(() => isMember ? episodeMemberData?.publicEpisodeMember : episodeNonMemberData?.publicEpisodeNonMember, [isMember, episodeMemberData, episodeNonMemberData]);
  if (eMemberLoading || eNonMemberLoading) {
    return (
      <SkeletonCard />
    )
  }
  if (!episode) {
    return (
      <ErrorCard title="Something went wrong!"></ErrorCard>
    )
  }
  return (
    <EpisodeDetails episode={episode} />
  )
}

export default WithData;
