import { message as antMessage } from 'antd';

export const messageConfig = {
};

export const success = (props) => {
   antMessage.success({
      ...messageConfig,
      content: "Changes saved",
      duration: 2,
      ...(typeof props === 'string' ? { content: props } : props)
   });
};
export const error = (props) => {
   antMessage.error({
      ...messageConfig,
      content: "Something went wrong, please try later",
      duration: 2,
      ...(typeof props === 'string' ? { content: props } : props)
   })
};
export const loading = ({ ...props }) => antMessage.loading({ ...messageConfig, duration: 4, ...props });

const message = {
   ...antMessage,
   success,
   error,
   loading
};

export default message;
