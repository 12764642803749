import React, { useState } from "react";
import styled from "styled-components";

import { useMutation } from "@apollo/client";
import { CREATE_REPLY_MUTATION } from "graphql-api";
import { MESSAGE_QUERY } from "apps/Dashboard/graphql";

import { Row, Col, Button, message } from "atoms";
import ReplyTextRecorder from "./ReplyTextRecorder";
import ReplyAudioRecorder from "./ReplyAudioRecorder";

import { FaFont, FaMicrophone } from "icons";
import { TRUE } from "consts";

const S = {};

S.ReplyRecorder = styled.div``;
S.ChooseTypeContainer = styled.div`
  margin-bottom: 20px;
`;
S.TypeButton = styled(({ active, ...props }) => <Button { ...props } />)`
  width: 40px;
  height: 40px;
  --color: ${ ({
                 active,
                 theme: {
                   colors: { primary, inputBorder },
                 },
               }) => (active ? primary : inputBorder) };
  background-color: var(--color);
  border-color: var(--color);

  :hover,
  :focus {
    --color: var(--color-primary-hover);
  }
`;

S.RecorderContainer = styled.div``;

const [AUDIO, TEXT] = ["AUDIO", "TEXT"];

const ReplyRecorder = ({ messageId, onCancel, onCreate }) => {
  const [type, setType] = useState(TEXT);

  const [triggerCreateReply, { loading: isCreateBusy }] = useMutation(
    CREATE_REPLY_MUTATION,
    {
      awaitRefetchQueries: true,
      refetchQueries: [{ query: MESSAGE_QUERY, variables: { id: messageId } }],
      onCompleted({ createReply: { success } }) {
        if (success === TRUE) {
          onCreate && onCreate();
          message.success({ content: "Message replied" });
        } else {
          message.error({
            content: "Something went wrong, please try again later.",
          });
        }
      },
    }
  );

  const handleCreate = async (payload) => {
    await triggerCreateReply({ variables: { messageId, ...payload } });
  };

  return (
    <S.ReplyRecorder>
      <S.ChooseTypeContainer>
        <Row gutter={ 10 }>
          <Col>
            <S.TypeButton
              onClick={ () => setType(TEXT) }
              active={ type === TEXT }
              icon={ <FaFont/> }
            />
          </Col>
          <Col>
            <S.TypeButton
              onClick={ () => setType(AUDIO) }
              active={ type === AUDIO }
              icon={ <FaMicrophone/> }
            />
          </Col>
        </Row>
      </S.ChooseTypeContainer>
      <S.RecorderContainer>
        { type === TEXT && (
          <ReplyTextRecorder
            onCancel={ onCancel }
            onCreate={ handleCreate }
            loading={ isCreateBusy }
          />
        ) }
        { type === AUDIO && (
          <ReplyAudioRecorder
            messageId={ messageId }
            onCancel={ onCancel }
            onCreate={ handleCreate }
            loading={ isCreateBusy }
          />
        ) }
      </S.RecorderContainer>
    </S.ReplyRecorder>
  );
};

export default ReplyRecorder;
