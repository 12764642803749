import React, { useState, useEffect } from 'react';

export const useFrequency = ({
    analyser,
    fftSize = 32
}) => {

    analyser.fftSize = fftSize;
    const bufferLength = analyser.frequencyBinCount;
    const _dataArray = new Uint8Array(5);
    const [dataArray, setDataArray] = useState(null);
    let animationTimeout = null;
    const getByteFrequencyData = () => {

        animationTimeout = setTimeout(() => {
            requestAnimationFrame(getByteFrequencyData);
        }, 30);
        analyser.getByteFrequencyData(_dataArray);
        setDataArray([..._dataArray]);
    }
    useEffect(() => {
        getByteFrequencyData();
        return () => {
            cancelAnimationFrame(getByteFrequencyData);
            clearTimeout(animationTimeout);
        };
    }, [analyser]);

    return {
        getByteFrequencyData,
        dataArray
    }
};

export const Frequency = ({ analyser, render: Render }) => {
    const {
        dataArray
    } = useFrequency({ analyser });

    return (
        <Render dataArray={dataArray} />
    );
};
