import React from 'react';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';

const AvatarIcon = styled(FontAwesomeIcon)`
   display: block;
   font-size: ${props => props.width};
   line-height: ${props => props.height || props.width};
   color: #cecece;
   cursor: pointer;
   border: 0;
   ${props => props.css}
`;

const AvatarPlaceholder = (props) => (
   <AvatarIcon icon={faUserCircle} {...props} />
);

AvatarPlaceholder.defaultProps = {
   width: '50px'
};

export default AvatarPlaceholder;
