import React from "react";
import styled from "styled-components";

import { Route, Switch } from "react-router-dom";

import { Layout } from "antd";
import Header from "./components/Header";
import Footer from "./components/Footer";

import { FanPageContainer } from "./containers/FanPageContainer";
import ErrorBoundary from "components/ErrorBoundary";
import { FanPageProvider } from "./context";

const [CONTENT_WIDTH] = [1150];

const SPublicPage = styled.div`
  background-color: var(--color-body-background);
`;

const PageInner = styled.div`
  padding: 0 15px;
`;

const AppLayout = styled(Layout)`
  background-color: transparent;
  min-height: 100vh;
  display: block;
`;

const LayoutHeader = styled(Layout.Header)`
  height: auto;
  padding: 0 15px;
  background-color: white;
  box-shadow: 0 10px 10px 2px rgba(0, 0, 0, 0.03);
  margin: 0 -15px;
  ${(props) =>
    props.theme.isLarge
      ? `
    `
      : `
    `}
`;
const HeaderContentContainer = styled.div`
  margin: 0 auto;
  ${(props) =>
    props.theme.isLarge
      ? `
        max-width: ${CONTENT_WIDTH + 150}px;
    `
      : `
        max-width: ${CONTENT_WIDTH}px;
    `}
`;

const LayoutContent = styled(Layout.Content)`
  max-width: ${CONTENT_WIDTH}px;
  margin: 0 auto;
  padding: 0 0 20px;
`;

const LayoutFooter = styled(Layout.Footer)`
  max-width: ${CONTENT_WIDTH}px;
  margin: 0 auto;
  background-color: transparent;
  padding: 30px 0 40px;
  border-top: 1px solid var(--light-grey);
`;

const PublicPage = () => {
  return (
    <SPublicPage>
      <ErrorBoundary>
        <PageInner>
          <AppLayout>
            <LayoutHeader>
              <HeaderContentContainer>
                <Header />
              </HeaderContentContainer>
            </LayoutHeader>
            <LayoutContent>
              <Switch>
                <Route path={`/:slug`}>
                  <FanPageProvider>
                    <FanPageContainer />
                  </FanPageProvider>
                </Route>
              </Switch>
            </LayoutContent>
            <LayoutFooter>
              <Footer />
            </LayoutFooter>
          </AppLayout>
        </PageInner>
      </ErrorBoundary>
    </SPublicPage>
  );
};

export default PublicPage;
