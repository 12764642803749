import React from 'react';
import styled from 'styled-components';

import { Form, FormItem, TextArea, Row, Col, Spacer, Button } from 'atoms';

const S = {};

S.TextRecorder = styled.div`
  padding: 0;
`;

const ReplyTextRecorder = ({
  onCancel,
  onCreate,
  loading
}) =>
{
  const handleFinish = ({content}) =>
  {
    onCreate && onCreate({content});
  }

  const handleCancel = () =>
  {
    onCancel();
  }

  const [form] = Form.useForm();

  return (
    <S.TextRecorder>
      <Form
        initialValues={{
          content: ''
        }}
        onFinish={handleFinish}
        form={form}
      >
        <FormItem
          name="content"
          rules={[
            {type: 'string', max: 2000, message: "You've exceeded the 2,000 character limit"},
          ]}
        >
          <TextArea
            placeholder={`type your reply...`}
            rows={4}
          />
        </FormItem>
        <Row gutter={14}>
          <Spacer/>
          <Col>
            <Button
              type={'default'}
              onClick={handleCancel}
            >
              CANCEL
            </Button>
          </Col>
          <Col>
            <Button
              htmlType={'submit'}
              loading={loading}
            >
              SUBMIT REPLY
            </Button>
          </Col>
        </Row>
      </Form>
    </S.TextRecorder>
  )
};

export default ReplyTextRecorder;
