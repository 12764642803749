import React, { useEffect } from 'react';
import { useMemo } from 'react';
import ReactDOM from 'react-dom';

type Props = {
  children: React.ReactNode
}

const Portal = (props: Props) => {
  const el = useMemo(() => document.createElement('div'), []);
  

  useEffect(() => {
    document.querySelector('body').appendChild(el);
    return () => {
      document.querySelector('body').removeChild(el);
    }
  }, [el]);

  return ReactDOM.createPortal(
    props.children,
    el
  );
}

export default Portal;
