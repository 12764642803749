import React from 'react';
import styled from 'styled-components';
import { useMutation, useQuery } from '@apollo/client';
import { CREATE_CHAT_MESSAGE_MUTATION } from 'graphql-api';
import { Show, ChatContact, Contact } from 'types';
import { Avatar, Button, Col, Div, Form, FormItem, Row, Span } from 'atoms';
import { FaPaperPlane } from 'icons';
import { Mentions } from 'antd';
import { useState } from 'react';
import { useMemo } from 'react';

const {Option, getMentions} = Mentions;

const SChatForm = styled.div``;

const SMentions = styled(Mentions)`
  padding: 6px 0 6px 2px;
  background-color: #f9f9f9;
  border-radius: 4px;
  border-color: var(--color-border);

  textarea {
    background-color: #f9f9f9;
    font-size: 14px;
  }

  ${props => props.disabled && `
    textarea::placeholder {
      color: #e6aab7;
    }
  `}
`;

type ChatContactItemProps = {
  chatContact: ChatContact
}
const ChatContactItem = (props: ChatContactItemProps) => {

  const {chatContact: {avatarUrl, displayName}} = props;

  return (
    <Div>
      <Row align="middle" gutter={10}>
        <Col>
          <Avatar asBackground src={avatarUrl} width='35px'/>
        </Col>
        <Col>
          <Span>{displayName}</Span>
        </Col>
      </Row>
    </Div>
  );
}
const SendButton = styled(({active, ...props}) => <Button {...props} />) <{
  active: boolean
}>`
  width: 45px;
  height: 45px;
  min-width: 45px;

  &.ant-btn-icon-only {
    min-width: 45px;
  }

  ${props => props.active ? `
  ` : `
      --color: #f8f8f8;
      --color-hover: #f8f8f8;
      color: #a4a4a4;
      :hover, :focus, :active {
        color: #a4a4a4;
      }
      &:not(:hover) {
        border-color: #eeeeee;
      }
  `}
`;

type Props = {
  show: Show,
  chatContacts: Contact
}
const ChatForm = (props: Props) => {
  const {show, chatContacts} = props;
  const {viewerSuspendedPosting} = show;
  const [form] = Form.useForm();
  const [inputText, setInputText] = useState('');
  const canSendText = useMemo(() => !!inputText, [inputText]);
  const autoSize = useMemo(() => ({maxRows: 5}), []);
  const [triggerSendMessage, {loading: isSendBusy}] = useMutation(CREATE_CHAT_MESSAGE_MUTATION, {
    onCompleted() {
    }
  })

  const handleFinish = async ({messageText}) => {
    if (!messageText) return false;
    form.resetFields(['messageText']);
    setInputText('');
    const input = form.getFieldInstance('messageText');
    input.textarea.focus();
    await triggerSendMessage({
      variables: {
        content: messageText,
        showId: show.id
      }
    })
  }
  const handleEnter = (e) => {
    if (!e.shiftKey) {
      e.preventDefault();
      e.stopPropagation();
      form.submit();
      return false;
    }
  }
  const handleChange = (text) => {
    setInputText(text);
  }
  return (
    <SChatForm>
      <Form
        form={form}
        onFinish={handleFinish}
        initialValues={{
          messageText: ''
        }}
      >
        <Row gutter={6}>
          <Col flex='1 0 0'>
            <FormItem name="messageText">
              <SMentions
                rows={1}
                autoSize={autoSize}
                placement="top"
                disabled={viewerSuspendedPosting}
                placeholder={viewerSuspendedPosting ? 'your chat privileges are currently on hold' : 'send a message...'}
                onPressEnter={handleEnter}
                onChange={handleChange}
              >
                {
                  chatContacts && chatContacts.map(chatContact => chatContact && (
                    <Option
                      key={chatContact.id.toString()}
                      value={chatContact.displayName ? chatContact.displayName.replace(' ', '') : ''}
                    >
                      <ChatContactItem chatContact={chatContact}/>
                    </Option>
                  ))
                }
              </SMentions>
            </FormItem>
          </Col>
          <Col>
            <SendButton
              block
              htmlType='submit'
              shape="circle"
              active={canSendText}
              disabled={viewerSuspendedPosting}
              icon={<FaPaperPlane/>}
            />
          </Col>
        </Row>
      </Form>
    </SChatForm>
  )
};

export default ChatForm;