import React from 'react';
import styled from 'styled-components';
import { Typography } from "antd";

const {Paragraph} = Typography;

const SParagraph = styled(Paragraph)`
  flex: 1 0 calc(100% - 33px);
`;

const ReplyTextContent = ({text}) => {
  return (
    <SParagraph ellipsis={{rows: 3, expandable: true, symbol: "more"}}>
      {text}
    </SParagraph>
  )
};

export default ReplyTextContent;
