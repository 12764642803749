import { gql } from '@apollo/client';

export const UPDATE_CREDIT_CARD_MUTATION = gql`
  mutation updateCreditCard($token: String!) {
    updateCreditCard(token: $token) {
      success,
      error
    }
  }
`;

export const PAYMENT_HISTORY_QUERY = gql`
  query paymentHistory {
    paymentHistory {
      invoices {
        date,
        invoiceUrl,
        plan,
        planAmount
      }
    }
  }
`;

export const CREDIT_CARD_QUERY = gql`
  query creditCard {
    creditCard {
      brand,
      last4
    }
  }
`;

export const DELETE_SHOW_STRIPE_ACCOUNT_MUTATION = gql`
  mutation deleteShowStripeAccount {
    deleteShowStripeAccount {
      success
      error
      show {
        id
        monetization
      }
    }
  }
`;

export const SETUP_SUPPORTING_CREDIT_CARD_MUTATION = gql`
  mutation setupSupportingCreditCard($token: String!) {
    setupSupportingCreditCard(token: $token) {
      success
      error
    }
  }
`;

export const UPDATE_SUPPORTING_CREDIT_CARD_MUTATION = gql`
  mutation updateSupportingCreditCard($token: String!) {
    updateSupportingCreditCard(token: $token) {
      success
      error
    }
  }
`;

export const DELETE_SUPPORTING_CREDIT_CARD = gql`
  mutation deleteSupportingCreditCard {
    deleteSupportingCreditCard {
      success
      error
    }
  }
`;

export const SUPPORTING_CREDIT_CARD_QUERY = gql`
  query supportingCreditCard {
    supportingCreditCard {
      brand
      last4
    }
  }
`;

