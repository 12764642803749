import React, { useMemo, useState } from "react";

import InboxContainer from "apps/PublicPage/containers/InboxContainer";
import { Card, CardInner, CardDescription, Paragraph } from "atoms";
import styled, { withTheme } from "styled-components";

import {
  SectionHeader,
  SectionTitle,
  PageContentRow,
  PageContentColumnLeft,
  PageContentColumnRight,
} from "apps/PublicPage/components/FanPage";
import { CircleContainer } from "components/VideoContainer";
import { useParams } from "react-router-dom";

const SWelcomeContainer = styled.div``;
const SWelcomeVideo = styled.div<{ css?: string }>`
  float: right;
  margin: 0 0 5px 5px;
  shape-outside: content-box;
  shape-margin: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;

  ${(props) => props.css}
`;

const SCardDescription = styled(CardDescription)`
  word-wrap: break-word;
`;
const SParagraph = styled(Paragraph)`
  font-size: 15px;
  color: #777;
`;
const SCardInner = styled(CardInner)`
  display: inline-block;
`;

const ActiveInbox = (
  {
    show,
    theme: { isDesktop }
  }) => {
  const welcomeVideoVisible = useMemo(
    () => !!show?.welcomeVideoUrl && show?.welcomeVideoActive,
    [show]
  );
  const welcomeMessage = useMemo(
    () => show.welcomeMessage,
    [show]
  );

  const [descriptionExpanded, setDescriptionExpanded] = useState<boolean>(false);

  return (
    <PageContentRow>
      <PageContentColumnLeft>
        <div style={{ margin: '-15px', padding: '15px', overflow: 'hidden' }}>
          <InboxContainer show={show} />
        </div>
      </PageContentColumnLeft>
      <PageContentColumnRight>
        <SWelcomeContainer>
          <SectionHeader css="">
            <SectionTitle>Welcome!</SectionTitle>
          </SectionHeader>
          <Card simple shadow>
            <SCardInner>
              {welcomeVideoVisible && (
                <SWelcomeVideo
                  css={
                    isDesktop ?
                      `
                          width: 110px;
                          height: 110px;
                          margin-top: -80px;
                        ` : `
                          width: 85px;
                          height: 85px;
                          margin-top: -65px;
                        `
                  }
                >
                  <CircleContainer
                    size={isDesktop ? 100 : 75}
                    videoRatio={show.welcomeVideoRatio}
                    videoUrl={show.welcomeVideoUrl}
                  />
                </SWelcomeVideo>
              )}
              <SCardDescription
                ignoreLineBreak={!descriptionExpanded}
              >
                <SParagraph
                  ellipsis={{
                    rows: 4,
                    expandable: true,
                    symbol: "more",
                    onExpand: () => setDescriptionExpanded(true),
                  }}
                  className="mb-0"
                >
                  {welcomeMessage}
                </SParagraph>
              </SCardDescription>
            </SCardInner>
          </Card>
        </SWelcomeContainer>
      </PageContentColumnRight>
    </PageContentRow>
  )
}

export default withTheme(ActiveInbox);