import React, { useMemo, useState, useEffect } from "react";
import styled, { withTheme } from "styled-components";

import { Pagination, SkeletonCard } from "atoms";
import Message from "./Message";
import { useQuery } from "@apollo/client";
import { PUBLIC_INBOX_MESSAGES_QUERY } from "../graphql";

const ListFooter = styled.div`
  margin-top: 30px;
`;

const MessageContainer = styled.div`
  margin-bottom: 20px;
`;

const InboxMessages = (
  {
    inbox,
    slug,
    afterMessageUpdate,
    showTranscription,
    theme: {isMobile}
  }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const total = useMemo(() => inbox.messageCount, [inbox]);
  const {data, loading} = useQuery(PUBLIC_INBOX_MESSAGES_QUERY, {
    variables: {
      inboxId: inbox.id,
      page: currentPage,
      limit: pageSize,
      showSlug: slug,
      publicShow: true,
    },
  });
  const messages = useMemo(
    () => data?.publicInboxMessages.collection || [],
    [data]
  );
  useEffect(() => {
    setCurrentPage(1);
  }, [total]);

  return (
    <div className="InboxMessages">
      <div>
        {loading ? (
          <div>
            {[1, 2, 3, 4, 5, 6].map((i) => (
              <div key={i} className="mb-4">
                <SkeletonCard avatar={{size: (isMobile ? 40 : 50), shape: 'square'}} rows={2}/>
              </div>
            ))}
          </div>
        ) : (
          messages.map((message) => (
            <MessageContainer key={message.id}>
              <Message
                message={message}
                slug={slug}
                afterMessageUpdate={afterMessageUpdate}
                showTranscription={showTranscription}
              />
            </MessageContainer>
          ))
        )}
      </div>
      {total > pageSize && (
        <ListFooter>
          <Pagination
            current={currentPage}
            onChange={(pageNumber) => setCurrentPage(pageNumber)}
            pageSize={pageSize}
            total={total}
          />
        </ListFooter>
      )}
    </div>
  );
};

export default withTheme(InboxMessages);
