import React from "react";
import styled from "styled-components";

import TipsList from "apps/PublicPage/components/TipsList";

const S = {
  Tips: null
};

S.Tips = styled.div``;

const TipsListContainer = styled.div``;

const Tips = ({show, generalTipsCount}) => {
  return (
    <S.Tips>
      <TipsListContainer>
        <TipsList
          show={show}
          generalTipsCount={generalTipsCount}
        />
      </TipsListContainer>
    </S.Tips>
  );
};

export default Tips;
