import React, { useState, useEffect } from 'react';
import styled, { withTheme } from 'styled-components';
import { Row, Col, Avatar, A, Span, Amount, Popover, OneCollapse, Dropdown, message as messageAlert } from 'atoms';
import UserProfile from 'components/UserProfile';
import ReplyTextRecorder from 'components/ReplyTextRecorder'
import MessageEditor from 'components/MessageEditor'
import { withPublicPageState } from "apps/PublicPage/context";
import { FaReply, FaEllipsisV, CircleCheck } from 'icons'
import { Divider, Typography } from "antd";
import PaymentMessageControls from "./PaymentMessageControls";
import { useMutation } from "@apollo/client";
import { TRUE } from 'consts';
import { UPDATE_PAYMENT_MESSAGE_MUTATION, UPDATE_PAYMENT_REPLY_MUTATION } from 'graphql-api';

const {Paragraph} = Typography;

const STipsItem = styled.div``;
const SPaymentMessage = styled.div<{ css?: string }>`
  padding: 12px 15px 0;
  border-radius: 4px;
  border: 1px solid var(--color-border);
  background-color: var(--color-body-background);
  display: flex;
  align-items: flex-start;
  gap: 5px;
  ${(props) => props.css}
`;

const SMessage = styled(Paragraph)`
  flex: 1;
`

const Inner = styled.div<{ css?: string }>`
  padding: 10px 15px;

  ${(props) => props.css}
  .ant-typography-expand {
    color: var(--color-primary);

    &:hover {
      color: var(--color-primary-hover);
    }
  }
`;

const Control = styled.a<{ active?: boolean, disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 16px;
  cursor: pointer;
  color: var(--color-content-grey);
  margin-top: 4px;
  min-width: 18px;

  ${(props) =>
    !props.disabled && `:hover {
    color: var(--color-primary);
  }`
  }

  ${(props) =>
    !props.disabled && props.active && `color: var(--color-primary);`
  }
`;

const ControlInner = styled(Inner)<{ last?: boolean }>`
  height: 100%;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 14px;
  ${(props) =>
    props.last &&
    `
          border-right: none;
    `}
`;

const SDivider = styled(Divider)`
  margin: 14px 0;
`;

const SDisplayName = styled(A)<{ css?: string }>`
  font-size: 15px;
  ${(props) => props.css}
`;

const SRow = styled(Row)`
  padding: 0 15px;
`;

const SReplyEditor = styled.div`
  padding: 0 15px;
`

const TipsMessageContainer = styled.div`
  padding: 0 15px;
`;

const SReplyButton = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`;

const TipsItem = (props) => {
  const {
    isShowOwner,
    isAdmin,
    generalTip: {id, user, amountCents, paymentMessage, paymentReply},
    reFetchTips,
    theme: {isDesktop, isMobile},
    publicPageState: {me, isLoggedIn}
  } = props;
  const [visibleReply, setVisibleReply] = useState(false);
  const [visibleMessageEditor, setVisibleMessageEditor] = useState(false);
  const [visibleReplyEditor, setVisibleReplyEditor] = useState(false);
  const [messageControlsVisible, setMessageControlsVisible] = useState(false);
  const [replyControlsVisible, setReplyControlsVisible] = useState(false);
  const [message, setMessage] = useState(paymentMessage);
  const [reply, setReply] = useState(paymentReply);
  const [isCreatedReply, setIsCreatedReply] = useState(false);

  const handleMessageControlsOpenChange = (e) => {
    setMessageControlsVisible(e);
  };

  const handleReplyControlsOpenChange = (e) => {
    setReplyControlsVisible(e);
  };

  const onHideReply = () => {
    setVisibleReply(false);
  };

  const onHideReplyUpdate = () => {
    setReplyControlsVisible(false);
    setVisibleReplyEditor(false);
  }

  const [triggerUpdateReply, {loading: isReplyBusy}] = useMutation(UPDATE_PAYMENT_REPLY_MUTATION, {
    onCompleted({updatePaymentReply: {paymentReply, success, error}}) {
      if (success === TRUE) {
        setReply(paymentReply);
        onHideReply();
        messageAlert.success({content: !isCreatedReply ? `Reply Sent` : `Reply Saved`});
      } else {
        onHideReply();
        // @ts-ignore
        messageAlert.error({content: 'Something went wrong, please try later'});
      }
    }
  });

  const handleCreateReply = async ({content}) => {
    setIsCreatedReply(!!reply);
    onHideReplyUpdate();

    await triggerUpdateReply({
      variables: {
        replyId: reply ? reply.id : null,
        paymentId: id,
        content: content
      }
    })
  }

  const onHideUpdate = () => {
    setMessageControlsVisible(false);
    setVisibleMessageEditor(false);
  }

  const [triggerUpdateMessage, {loading: isUpdateBusy}] = useMutation(UPDATE_PAYMENT_MESSAGE_MUTATION, {
    onCompleted({updatePaymentMessage: {paymentMessage, success, error}}) {
      if (success === TRUE) {
        setMessage(paymentMessage);
        messageAlert.success({content: 'Message Saved'});
      } else {
        // @ts-ignore
        messageAlert.error({content: 'Something went wrong, please try later'});
      }
    }
  });

  const handleUpdateMessage = async ({content}) => {
    onHideUpdate();

    await triggerUpdateMessage({
      variables: {
        messageId: message.id,
        content: content
      }
    })
  }

  const onReplyToggle = () => {
    if (!!visibleReply) {
      return;
    }

    setVisibleReply(true);
  }

  const onToggleDeletePaymentMessage = () => {
    onHideUpdate();
    reFetchTips && reFetchTips();
  }

  const onToggleDeletePaymentReply = () => {
    onHideReplyUpdate();
    reFetchTips && reFetchTips();
  }

  const onToggleUpdate = () => {
    setMessageControlsVisible(false);
    setVisibleMessageEditor(true);
  }

  const onToggleReplyUpdate = () => {
    setReplyControlsVisible(false);
    setVisibleReplyEditor(true);
  }

  useEffect(() => {
    setMessage(paymentMessage)
  }, [paymentMessage]);

  useEffect(() => {
    setReply(paymentReply)
  }, [paymentReply]);

  return (
    <STipsItem>
      <SRow gutter={12}>
        <Col>
          <Avatar
            width={isDesktop ? "50px" : '40px'}
            src={user.avatarUrl}
            placeholder={user.displayName?.slice(0, 1).toUpperCase()}
            css={`border-radius: 4px;`}
          />
        </Col>
        <Col flex='1 0 0'>
          <Row align='middle'>
            <Col flex='1 0 0'>
              <Popover
                simple
                placement="bottom"
                trigger={["click"]}
                content={<UserProfile user={user}/>}
              >
                <SDisplayName css={isDesktop ? `` : `font-size: 14px;`}>
                  {user.displayName}{" "}
                  {user.isMembership && <CircleCheck color="#0092ff" size={`14px`}/>}
                </SDisplayName>
              </Popover>
            </Col>
            {
              !!amountCents && (
                <Col>
                  <Amount
                    color='green'
                    css={'font-size: 16px; font-weight: normal;'}
                  >
                    ${Math.round(amountCents / 100)}
                  </Amount>
                </Col>
              )
            }
          </Row>
        </Col>
      </SRow>
      <TipsMessageContainer>
        {
          !!message && !(message.status === 'deleted') && (
            <SPaymentMessage
              css={`margin-top: 10px;`}
            >
              {!visibleMessageEditor ? (
                <>
                  <SMessage ellipsis={{rows: 3, expandable: true, symbol: "more"}}>
                    {message.content}
                  </SMessage>
                  {
                    isLoggedIn && (me.id === user.id || !!isShowOwner || !!isAdmin) &&
                    (
                      <Dropdown
                        trigger={["click"]}
                        open={messageControlsVisible}
                        arrow
                        onOpenChange={handleMessageControlsOpenChange}
                        key={'MESSAGE'}
                        overlay={
                          <PaymentMessageControls
                            message={message}
                            reply={reply}
                            controls={me.id === user.id ? ['EDIT', 'DELETE'] : ((!!isShowOwner || !!isAdmin) ? ['DELETE'] : [])}
                            onDelete={onToggleDeletePaymentMessage}
                            onUpdate={me.id === user.id ? onToggleUpdate : () => {
                            }}
                          />
                        }
                      >
                        <Control>
                          <ControlInner>
                            <FaEllipsisV/>
                          </ControlInner>
                        </Control>
                      </Dropdown>
                    )
                  }
                </>
              ) : (
                <MessageEditor
                  onCancel={onHideUpdate}
                  onUpdate={handleUpdateMessage}
                  message={message.content}
                  loading={isUpdateBusy}
                  text={'message'}
                  css={`padding-bottom: 12px;`}
                />
              )}
            </SPaymentMessage>
          )
        }
        {
          !!isLoggedIn && !reply && !!isShowOwner && (
            <SReplyButton>
              <Control
                disabled={!!visibleReply}
                onClick={() => onReplyToggle()}
              >
                <ControlInner css={`margin-top: 9px;`}>
                  <>
                    <FaReply/>
                  </>
                </ControlInner>
              </Control>
            </SReplyButton>
          )
        }
      </TipsMessageContainer>
      {
        (
          (!!reply && !(reply.status === 'deleted')) || (!reply && !!visibleReply)
        ) && (
          <SDivider/>
        )
      }
      {/*@ts-ignore*/}
      <OneCollapse open={!reply && !!visibleReply}>
        <SReplyEditor>
          <ReplyTextRecorder
            onCancel={onHideReply}
            onCreate={handleCreateReply}
            loading={isReplyBusy}
          />
        </SReplyEditor>
      </OneCollapse>
      {
        !!reply && !(reply.status === 'deleted') && (
          <SRow
            gutter={isMobile ? 10 : 12}
          >
            <Col>
              <Avatar
                width={isDesktop ? "50px" : '40px'}
                src={reply.user.avatarUrl}
                placeholder={reply.user.displayName?.slice(0, 1).toUpperCase()}
                css={`border-radius: 4px;`}
              />
            </Col>

            <Col flex='1 0 0'>
              <Row>
                <Col>
                  <Popover
                    simple
                    placement="bottom"
                    trigger={["click"]}
                    content={<UserProfile user={reply.user}/>}
                  >
                    <SDisplayName css={isDesktop ? `` : `font-size: 14px;`}>
                      {reply.user.displayName}{" "}
                      {reply.user.isMembership && <CircleCheck color="#0092ff" size={`14px`}/>}
                    </SDisplayName>
                  </Popover>
                </Col>
              </Row>
              <Row css={`margin-top: 5px;`}>
                <Col flex='1 0 0'>
                  <SPaymentMessage>
                    {!visibleReplyEditor ? (
                      <>
                        <SMessage ellipsis={{rows: 3, expandable: true, symbol: "more"}}>
                          {reply.content}
                        </SMessage>
                        {
                          isLoggedIn && !!isShowOwner && me.id === reply.user.id &&
                          (
                            <Dropdown
                              trigger={["click"]}
                              open={replyControlsVisible}
                              arrow
                              onOpenChange={handleReplyControlsOpenChange}
                              key={'REPLY'}
                              overlay={
                                <PaymentMessageControls
                                  message={message}
                                  reply={reply}
                                  controls={['REPLY_EDIT', 'REPLY_DELETE']}
                                  onDelete={onToggleDeletePaymentReply}
                                  onUpdate={onToggleReplyUpdate}
                                />
                              }
                            >
                              <Control>
                                <ControlInner>
                                  <FaEllipsisV/>
                                </ControlInner>
                              </Control>
                            </Dropdown>
                          )
                        }
                      </>
                    ) : (
                      <MessageEditor
                        onCancel={onHideReplyUpdate}
                        onUpdate={handleCreateReply}
                        message={reply.content}
                        loading={isReplyBusy}
                        text={'reply'}
                        css={`padding-bottom: 12px;`}
                      />
                    )}
                  </SPaymentMessage>
                </Col>
              </Row>
            </Col>
          </SRow>
        )
      }
    </STipsItem>
  )
}

export default withPublicPageState(withTheme(TipsItem));
