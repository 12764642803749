import React, { useState, useMemo, useEffect } from "react";
import styled, { withTheme } from "styled-components";
import { withPublicPageState } from "apps/PublicPage/context";
import { useLazyQuery } from "@apollo/client";
import { ME_QUERY } from "graphql-api";

import { Col, message, Modal, Row, Card, CardInner } from "atoms";

import ThanksSuperfan from "components/ThanksSuperfan";
import Confetti from "components/Confetti";
import { supportAmounts } from "micro-data";
import { SIGNUP } from "consts";
import GiveGeneralTips from "./GiveGeneralTips";
import { OneCollapse } from "atoms";
import { Divider } from "antd";

const SPayoutAccount = styled(Card)``;
const AmountList = styled.div<{ simple?: boolean }>`
  padding: 0 15px;
  ${(props) => !props.simple && "margin-bottom: 10px;"}
`;

const AmountInner = styled(CardInner)`
  padding: 20px 0;
`

// @ts-ignore
const AmountItem = styled.div<{ small: boolean, selected?: boolean, custom?: boolean }>`
  ${(props) =>
    props.theme.isMobile &&
    `
        min-width: ${props.small ? 60 : 70}px;
        padding: ${props.small ? "10px 2px" : "10px 2px"};
        font-size: ${props.small ? 12 : 14}px;
    `}
  ${(props) =>
    !props.theme.isMobile &&
    `
        min-width: ${props.small ? 70 : 82}px;
        padding: ${props.small ? "10px 10px" : "10px 15px"};
        font-size: ${props.small ? 13 : 15}px;
    `}
  letter-spacing: 0.05em;
  text-align: center;
  cursor: pointer;
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
  border-radius: 30px;

  :hover {
    background-color: var(--color-primary);
    color: white;
  }

  ${(props) =>
    props.selected &&
    `
        background-color: var(--color-primary);
        color: white;
        :hover {
            background-color: var(--color-primary-hover);
        }
    `}
  ${(props) =>
    props.custom &&
    `
        letter-spacing: 1px;
    `}
`;

const GeneralTipsPayment = (
  {
    show,
    publicPageState: {isLoggedIn, openAuthModal, authModalVisible},
    theme: {isMobile},
    center,
    onSupport,
    simple,
  }) => {
  const [paymentIntended, setPaymentIntended] = useState(false);
  const [supportPaymentVisible, setSupportPaymentVisible] = useState(false);
  const [selectedAmount, setSelectedAmount] = useState(null);
  const availableAmounts = useMemo(
    () => show?.supportAmounts || supportAmounts,
    [show]
  );
  const [thanksModalVisible, setThanksModalVisible] = useState(false);
  const [triggerMeQuery] = useLazyQuery(ME_QUERY);

  const openSupportPayment = () => {
    if (isLoggedIn) {
      setSupportPaymentVisible(true);
    } else {
      openAuthModal(SIGNUP);
      setPaymentIntended(true);
    }
  }

  const handleSelectAmount = (amount) => {
    setSelectedAmount(amount);

    openSupportPayment();
  };
  const handleSelectCustom = () => {
    setSelectedAmount(null);

    openSupportPayment();
  };
  const handleSuccess = async ({message}) => {
    onSupport && onSupport({message});

    setSupportPaymentVisible(false);
    setThanksModalVisible(true);
    if (!isLoggedIn) {
      console.log("trigger Me Query");
      await triggerMeQuery();
    }
  };
  const handleThanksModalClose = () => {
    setThanksModalVisible(false);
  };
  const handleCloseSupport = () => {
    setSelectedAmount(null);

    setSupportPaymentVisible(false);
  };
  const handleError = () => {
    // @ts-ignore
    message.error({content: "Something went wrong, please try again later."});
  };

  useEffect(() => {
    if (paymentIntended && isLoggedIn) {
      setSupportPaymentVisible(true);
    }
  }, [isLoggedIn, paymentIntended]);

  useEffect(() => {
    if (!authModalVisible) {
      setPaymentIntended(false);
    }
  }, [authModalVisible]);

  return (
    <>
      <SPayoutAccount simple shadow>
        <AmountInner>
          <AmountList simple={simple}>
            <Row
              gutter={isMobile ? [4, 4] : [10, 10]}
              justify={center ? "center" : "initial"}
            >
              {availableAmounts.map((amount) => (
                <Col span={6} key={amount}>
                  <AmountItem
                    onClick={() => handleSelectAmount(amount)}
                    small={false}
                  >
                    ${amount}
                  </AmountItem>
                </Col>
              ))}
              <Col span={6} key="custom">
                <AmountItem
                  onClick={() => handleSelectCustom()}
                  custom
                  small={false}
                >
                  ...
                </AmountItem>
              </Col>
            </Row>
          </AmountList>
          {!!supportPaymentVisible && (<Divider/>)}
          {/*@ts-ignore*/}
          <OneCollapse open={!!supportPaymentVisible}>
            <GiveGeneralTips
              amount={selectedAmount}
              showId={show.id}
              onSuccess={handleSuccess}
              onCancel={handleCloseSupport}
              onError={handleError}
            />
          </OneCollapse>
        </AmountInner>
      </SPayoutAccount>
      <Modal
        open={thanksModalVisible}
        onCancel={handleThanksModalClose}
        mask={false}
        zIndex={1100}
        afterClose={handleCloseSupport}
      >
        <ThanksSuperfan show={show} forMessage={false}/>
      </Modal>
      {thanksModalVisible && <Confetti zIndex={1090} mask/>}
    </>
  );
};

export default withPublicPageState(withTheme(GeneralTipsPayment));
