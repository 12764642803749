import React, { useContext, useState } from "react";

import EpisodeCard from "../components/EpisodeCard";
import { useQuery } from "@apollo/client";
import { PUBLIC_EPISODES_MEMBER_QUERY, PUBLIC_EPISODES_NON_MEMBER_QUERY } from "../graphql";
import { FanPageContext } from "../context";
import { Card, CardInner, NoContentCard, Pagination, Skeleton } from "atoms";

const PremiumContent = () => {

  const { show } = useContext(FanPageContext);
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const { data, loading } = useQuery(PUBLIC_EPISODES_NON_MEMBER_QUERY, { variables: { podcastId: show?.podcast?.id, page: currentPage, limit: pageSize } });
  const episodes = data?.publicEpisodesNonMember.collection || [];
  const { totalCount = 0 } = {
    ...(data?.publicEpisodesNonMember.metadata)
  };

  if (loading) {
    return (
      <div>
        {
          [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => (
            <div key={i} className="mb-3">
              <Card simple>
                <CardInner thin>
                  <Skeleton active paragraph={{ rows: 2 }} />
                </CardInner>
              </Card>
            </div>
          ))
        }
      </div>
    )
  }
  if (episodes.length === 0) {
    return (
      <NoContentCard shadow>Content coming soon. We're working on it!</NoContentCard>
    )
  }
  return (
    <div className="PrimiumContent">
      {
        episodes.map((episode) => (
          <div key={episode.id} className="mb-3">
            <EpisodeCard episode={episode} inactive />
          </div>
        ))
      }
      {totalCount > pageSize && (
        <div className="mt-2">
          <Pagination
            current={currentPage}
            onChange={(pageNumber) => setCurrentPage(pageNumber)}
            pageSize={pageSize}
            total={totalCount}
          />
        </div>
      )}
    </div>
  )
}

export default PremiumContent;
