import React, { useState, useMemo } from 'react';
import styled from 'styled-components';

import { A, Checkbox, Col, Row, PopoverInner, Modal, message as messageAlert, NoContent } from 'atoms';
import { FaCog } from 'icons';
import ManageShowTags from './ManageShowTags';

import { TOGGLE_MESSAGE_TAG_ACTIVE_MUTATION } from 'apps/Dashboard/graphql';
import { useMutation } from '@apollo/client';
import { TRUE } from 'consts';

const S = {};

S.MessageTagControl = styled.div`
`;

S.TagList = styled(PopoverInner)`
  border-bottom: 1px solid var(--color-border);
`;
S.TagContainer = styled.div`
  margin-bottom: 10px;

  :last-child {
    margin-bottom: 0;
  }
`;
S.Footer = styled(PopoverInner)`
`;

const MessageTagControl = ({message, onTag, show, showTags}) => {
  const tags = useMemo(() => show?.tags || [], [show]);
  const slug = useMemo(() => show?.slug || null, [show]);
  const messageTags = useMemo(() => message.tags, [message]);
  const messageTagIDs = useMemo(() => messageTags.map(t => t.id), [messageTags]);
  const fTags = useMemo(() => tags.map(tag => ({
    ...tag,
    checked: messageTagIDs.includes(tag.id)
  })), [tags, messageTagIDs])
  const [manageVisible, setManageVisible] = useState(false);

  const [triggerToggleTagActive, {loading: isToggleTagBusy}] = useMutation(TOGGLE_MESSAGE_TAG_ACTIVE_MUTATION, {
    onCompleted({toggleMessageTagActive: {success, error}}) {
      if (success === TRUE) {
        onTag && onTag()
      } else {
        messageAlert({content: 'Something went wrong, please try later'});
      }
    }
  });

  const handleChange = async (tagID, checked) => {
    await triggerToggleTagActive({
      variables: {
        messageId: message.id,
        tagId: tagID
      },
      optimisticResponse: {
        toggleMessageTagActive: {
          success: TRUE,
          error: null,
          message: {
            __typename: message.__typename,
            id: message.id,
            tags: checked ? [...messageTags, tags.find(t => t.id === tagID)] : messageTags.filter(t => t.id !== tagID)
          }
        }
      },
    });
  }

  return (
    <S.MessageTagControl>
      <S.TagList>
        {
          fTags.map(tag => (
            <S.TagContainer key={tag.id}>
              <Checkbox
                checked={tag.checked}
                onChange={(e) => handleChange(tag.id, e.target.checked)}>
                {tag.name}
              </Checkbox>
            </S.TagContainer>
          ))
        }
        {
          fTags.length === 0 && (
            <NoContent>
              Please create a tag
            </NoContent>
          )
        }
      </S.TagList>
      <S.Footer>
        <A block color={`content`} onClick={() => setManageVisible(true)}>
          <Row singleRow gutter={10}>
            <Col>
              <FaCog/>
            </Col>
            <Col>
              Manage Tags
            </Col>
          </Row>
        </A>
      </S.Footer>
      {
        manageVisible && (
          <Modal
            title={'Manage Tags'}
            open={manageVisible}
            onCancel={() => setManageVisible(false)}
            simple
            zIndex={1062}
          >
            <ManageShowTags slug={slug} showTags={showTags}/>
          </Modal>
        )
      }
    </S.MessageTagControl>
  )
};

export default MessageTagControl;
