import styled from "styled-components";
import { Link, A } from "atoms";

const TabLink = styled(Link)`
   display: block;
   padding: 0 0 5px;
   font-size: 15px;
   letter-spacing: 1px;
   color: black;
   border-bottom: 2px solid transparent;
   :hover {
      color: var(--color-primary);
   }
   &.active {
      color: var(--color-primary);
      border-bottom-color: var(--color-primary);
   }
`;

export default TabLink;

export const TabA = styled(A)`
   display: block;
   padding: 0 0 5px;
   font-size: 15px;
   letter-spacing: 1px;
   color: black;
   border-bottom: 2px solid transparent;
   :hover {
      color: var(--color-primary);
   }
   ${props => props.selected && `
      color: var(--color-primary);
      border-bottom-color: var(--color-primary);
   `}
`;
