import React, { useMemo, useState } from "react";
import styled, { withTheme } from "styled-components";

import {
  Avatar,
  Popover,
} from "atoms";
import UserProfile from "components/UserProfile";
import { Superfan, Theme } from "types";
import floatingShow from "../../WidgetPage/pages/FloatingShow";

const SSuperfanItem = styled.div`
  cursor: pointer;
`;

const SAvatar = styled.div<any>`
  position: relative;
`

const SAmount = styled.div<any>`
  position: absolute;
  bottom: 7px;
  left: -18px;
  background: var(--color-green);
  border-radius: 10px;
  color: white;
  padding: 0 5px;
`

type SuperfanItemProps = {
  user: any;
  size: string;
  totalCents: any;
  showSuperfanDollarAmounts: boolean;
};

const SuperfanItem = ({ user, size, totalCents, showSuperfanDollarAmounts }: SuperfanItemProps) => {
  const amountCents = useMemo(() => totalCents ? 
    Math.round(totalCents / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0, 
    [totalCents]
  );
  const [showAmount, setShowAmount] = useState(false);

  const toggleShowAmount = (flag) => {
    setShowAmount(flag)
  }

  return (
    <SSuperfanItem>
      <Popover
        simple
        placement="top"
        trigger={["click"]}
        content={<UserProfile user={user}/>}
      >
        <SAvatar
          onMouseOver={() => {
            toggleShowAmount(true)
          }}
          onMouseOut={() => {
            toggleShowAmount(false)
          }}
        >
          <Avatar
            src={user.avatarUrl}
            width={size}
            placeholder={user.displayName ? user.displayName?.slice(0, 1).toUpperCase() : user.displayName}
          />
          {
            showSuperfanDollarAmounts && showAmount && (
              <SAmount
                onMouseOver={() => {
                  toggleShowAmount(true)
                }}
              >${amountCents}</SAmount>
            )
          }
        </SAvatar>
      </Popover>
    </SSuperfanItem>
  );
};

const SSuperfans = styled.div``;
const SSuperfanList = styled.div<{ css?: string }>`
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  ${(props) => props.css}
`;
const SuperfanItemContainer = styled.div<{ css?: string }>`
  border-radius: 50%;
  ${(props) => props.css}
`;

type Props = {
  superfans: Superfan[];
  showSuperfanDollarAmounts: boolean;
  theme: Theme;
};
const SuperFans = (props: Props) => {
  const {
    superfans,
    showSuperfanDollarAmounts,
    theme: { isMobile, isXMobile },
  } = props;

  console.log('showSuperfanDollarAmounts', showSuperfanDollarAmounts)

  return (
    <SSuperfans>
      <SSuperfanList>
        {superfans.map((s) => (
          <SuperfanItemContainer key={s.user.id}>
            <SuperfanItem
              user={s.user}
              size={isMobile ? `48px` : `60px`}
              totalCents={s.totalCents}
              showSuperfanDollarAmounts={showSuperfanDollarAmounts}
            />
          </SuperfanItemContainer>
        ))}
      </SSuperfanList>
    </SSuperfans>
  );
};

export default withTheme(SuperFans);
