import React, { useMemo } from 'react';
import styled from 'styled-components';

import { Modal, Logo, A, ModalInner } from 'atoms';
import { formatFromIos } from 'utils/Datetime';

const S = {};
S.AccountLocked = styled.div``;
S.LogoContainer = styled.div`
    margin-bottom: 20px;
`;
S.Content = styled.div`
    margin-bottom: 20px;
`;
S.Footer = styled.footer``;

const AccountLocked = ({ lockedUntil }) => {
    const lockedUntilFormatted = useMemo(() => formatFromIos(lockedUntil), [lockedUntil]);
    return (
        <S.AccountLocked>
            <S.LogoContainer>
                <Logo />
            </S.LogoContainer>
            <S.Content>
                Your account is locked until {lockedUntilFormatted}, due to suspicious activity.<br />
                This prevents you from posting messages. Please <A href={`mailto:support@fanlist.com;`}>contact us</A> to request account reactivation. Thanks!
            </S.Content>
            <S.Footer>
                Podinbox Staff
            </S.Footer>
        </S.AccountLocked>
    );
}

export default AccountLocked;

S.ModalInner = styled.div`
    padding: 20px 10px;
`;

export const AccountLockedModal = ({ lockedUntil, ...props }) => {
    return (
        <Modal width={330} {...props}>
            <S.ModalInner>
                <AccountLocked lockedUntil={lockedUntil} />
            </S.ModalInner>
        </Modal>
    )
};
