import React from 'react';
import styled from 'styled-components';
import { Button } from 'atoms';

import { FaPlay, FaPause } from 'icons';

export const ControlButton = styled(Button)`
  width: 40px !important;
  height: 40px;
  box-sizing: border-box;
  --color: #f0f1ef;
  --color-hover: var(--color-primary);
  color: var(--color-primary);
  display: flex;
  align-items: center;
  justify-center: center;
  &:hover {
    color: white;
  }
`;

export const PlayButton = ({ playing = false, ...props }) => {
  return (
    <ControlButton icon={playing ? <FaPause/> : <FaPlay/>} {...props} />
  )
}

export const PauseButton = ({ ...props }) => {
  return (
    <ControlButton icon={<FaPause/>} {...props} />
  )
}


export const LoadingButton = ({ ...props }) => {
  return (
    <ControlButton loading {...props} />
  )
}
