import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import LinesEllipsis from 'react-lines-ellipsis';

import { CardTitle, CardDescription, Row, Col, Alert } from 'atoms';
import AudioRecord from 'libraries/Audio/components/AudioRecord';
import {
  RecordRow,
  MessageCTAContainer,
  TextContentButton,
} from "libraries/Audio/components/AudioRecord/components/common";
import { CircleContainer } from 'components/VideoContainer';
import TextContentEditor from 'components/TextContentEditor';
import { DAILY_MESSAGE_LIMIT_TEXT, INACTIVE_MESSAGE_LIMIT_TEXT } from "consts";
import { useQuery } from "@apollo/client";
import { ME_QUERY } from 'graphql-api';

const SInboxCardContent = styled.div``;

const CardContent = styled.div``;

const STextContent = styled.div`
  flex: 1 0 calc(50% - 5px);
`;

const MessageRecorderContainer = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  flex-wrap: wrap;
`;

const InboxCardContent = (
  {
    inbox,
    show,
    slug,
    onCreatedMessage,
    onCreatedTextMessage,
    showVideo,
    active,
    isMobile,
  }) => {
  const [showRecordContainer, setShowRecordContainer] = useState(false);
  const [showTextContentContainer, setShowTextContentContainer] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [warningMessage, setWarningMessage] = useState(null);
  const {data: meData} = useQuery(ME_QUERY);
  const me = useMemo(() => meData?.me, [meData]);
  const isConfirmed = useMemo(() => me?.isConfirmed, [me]);
  const reachedMaxMessagesLimit = useMemo(() => show ? show.reachedMaxDailyMessagesCount : false, [show]);
  const viewerSuspendedPosting = useMemo(() => show ? show.viewerSuspendedPosting : false, [show]);

  const handleError = (error) => {
    setErrorMessage(error);
  }

  const toggleShowRecordContainer = (payload) => {
    setShowRecordContainer(payload);
  }

  const toggleShowTextContentContainer = () => {
    handleError(null);

    setShowTextContentContainer(!showTextContentContainer);
  }

  const handleLimit = () => {
    if (isConfirmed)
      setWarningMessage(DAILY_MESSAGE_LIMIT_TEXT);
    else
      setWarningMessage(INACTIVE_MESSAGE_LIMIT_TEXT);
  }

  useEffect(() => {
    if (!!reachedMaxMessagesLimit || !!viewerSuspendedPosting) {
      setShowRecordContainer(false);
      setShowTextContentContainer(false);
      handleLimit();
    }
  }, [reachedMaxMessagesLimit, viewerSuspendedPosting]);

  return (
    <SInboxCardContent>
      <Row gutter={ [20, 16] }>
        {
          active && showVideo && (
            <Col>
              <CircleContainer
                size={ (isMobile ? 100 : 115) }
                videoRatio={ inbox?.welcomeVideoRatio }
                videoUrl={ inbox?.welcomeVideoUrl }
              />
            </Col>
          )
        }
        <Col flex={ `1 0 300px` }>
          <CardContent>
            <CardTitle css={ `margin-bottom: 15px;
              letter-spacing: 0.5px;` }>
              {
                !active && (
                  <LinesEllipsis
                    text={ inbox.name }
                    maxLine='2'
                    ellipsis='...'
                    trimRight
                    basedOn='letters'
                  />
                )
              }
              {
                active && inbox.name
              }
            </CardTitle>
            <CardDescription css={ `font-size: 15px;
              color: #777;` }>
              {
                !active && (
                  <LinesEllipsis
                    text={ inbox.description }
                    maxLine='3'
                    ellipsis='...'
                    trimRight
                    basedOn='letters'
                  />
                )
              }
              {
                active && inbox.description
              }
            </CardDescription>
            {
              active && (
                <MessageRecorderContainer>
                  { showTextContentContainer && inbox.id ? (
                    <TextContentEditor
                      inbox={ inbox }
                      slug={ slug }
                      text={ 'message' }
                      me={ me }
                      onCancel={ toggleShowTextContentContainer }
                      onSuccess={ onCreatedTextMessage }
                      onToggle={ toggleShowTextContentContainer }
                      onLimit={ handleLimit }
                    />
                  ) : (
                    <>
                      { !showRecordContainer && inbox.showTextContent && (
                        <STextContent>
                          <RecordRow>
                            <MessageCTAContainer>
                              <TextContentButton
                                onClick={ toggleShowTextContentContainer }
                                disabled={ !!reachedMaxMessagesLimit || !!viewerSuspendedPosting }
                              />
                            </MessageCTAContainer>
                          </RecordRow>
                        </STextContent>
                      ) }
                      <AudioRecord
                        inboxId={ inbox.id }
                        slug={ slug }
                        me={ me }
                        disableMessagesPosting={ !!reachedMaxMessagesLimit || !!viewerSuspendedPosting }
                        onSuccess={ onCreatedMessage }
                        onToggle={ toggleShowRecordContainer }
                        onError={ handleError }
                        onLimit={ handleLimit }
                      />
                    </>
                  ) }
                </MessageRecorderContainer>
              )
            }
          </CardContent>
        </Col>
      </Row>
      { errorMessage && !viewerSuspendedPosting && (
        <div className="mt-2">
          <Alert
            type={ "error" }
            description={ errorMessage }
          />
        </div>
      ) }
      { warningMessage && !viewerSuspendedPosting && (
        <div className="mt-2">
          <Alert
            type={ "warning" }
            description={ warningMessage }
          />
        </div>
      ) }
      { viewerSuspendedPosting && (
        <div className="mt-2">
          <Alert
            type={ "warning" }
            description={ 'Your posting privileges are currently on hold.' }
          />
        </div>
      ) }
    </SInboxCardContent>
  )
}

export default InboxCardContent;
