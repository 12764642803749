import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPause } from '@fortawesome/free-solid-svg-icons';
import AudioPlayer from 'libraries/Audio/components/AudioPlayer';
import { Howl, Howler } from 'howler';

import {
  RecordRow,
  VisualizationContainer,
  RecordVisualization,
  RecordCTAContainer,
  PlayButton,
  RecordCTAButton,
} from './common';
import { INITIAL, PLAYING } from 'libraries/Audio/const';
import AudioPlayerInactive from '../../AudioPlayerInactive';

export const PauseButton = (props) => (
  <RecordCTAButton type="primary" icon={<FontAwesomeIcon icon={faPause} />} {...props} />
);

const StyledPreviewPlayer = styled.div``;

const AudioPlayContainer = styled.div`
  width: 100%;
  padding: 15px 0 15px 15px;
`;

const PreviewPlayer = ({ src, duration, simple = false }) => {
  const audio = useRef(null);
  const [playerStatus, setPlayerStatus] = useState(INITIAL);

  const handleReady = ({ audio: _audio }) => {
    audio.current = _audio;
  };
  const handlePlayClick = () => {
    if (playerStatus !== INITIAL) {
      audio.current && audio.current.play();
    }
  };
  const handlePauseClick = () => {
    audio.current && audio.current.pause();
  };

  return (
    <StyledPreviewPlayer>
      <RecordRow css={!simple ? `padding: 15px 15px 15px 15px; border: 1px solid var(--color-border); border-radius: 5px;` : ''}>
        <RecordCTAContainer css={`width: 40px; align-items: center;`}>
          {
            playerStatus !== PLAYING && (
              <PlayButton
                css={`width: 40px; height: 40px; border-radius: 5px;`}
                onClick={() => handlePlayClick()}
              />
            )
          }
          {
            playerStatus === PLAYING && (
              <PauseButton
                css={`width: 40px; height: 40px; border-radius: 5px;`}
                onClick={() => handlePauseClick()}
              />
            )
          }
        </RecordCTAContainer>
        <VisualizationContainer css={`margin-right: 0;`}>
          <RecordVisualization css={`border: 0;`}>
            <AudioPlayContainer>
              <AudioPlayer
                src={src}
                duration={Math.floor(duration)}
                onReady={handleReady}
                onChangeStatus={status => setPlayerStatus(status)}
              />
            </AudioPlayContainer>
          </RecordVisualization>
        </VisualizationContainer>
      </RecordRow>
    </StyledPreviewPlayer>
  );
};

export default PreviewPlayer;
