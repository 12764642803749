import React, { useMemo } from 'react';
import styled from 'styled-components';

import { Superfan } from 'types';
import SuperfanItem from './SuperfanItem';

export const TippersContainer = styled.div`
    margin-bottom: 25px;
`;

export const Tippers = styled.div`
    font-size: 20px;
    color: black;
    margin-bottom: 20px;
    ${props => {
      // @ts-ignore
      return props.center && `text-align: center;`;
    }}
`;

const SMessageTips = styled.div``;
const SuperfanList = styled.div``;

type Props = {
  superfans: Superfan[]
}
const MessageTips = (props: Props) => {
  const { superfans } = props;
  const sortedSuperfans = useMemo<Superfan[]>(() => [...superfans].sort((a, b) => b.totalCents - a.totalCents), [superfans]);

  return (
    <SMessageTips>
      <SuperfanList>
        {
          sortedSuperfans.map(superfan => (
            <SuperfanItem key={superfan.user.id} superfan={superfan} />
          ))
        }
      </SuperfanList>
    </SMessageTips>
  )
}
MessageTips.defaultProps = {
  superfans: []
}
export default MessageTips;
