import { gql } from "@apollo/client";
import { superfanFields } from "graphql-api";

export const perkFields = gql`
  fragment perkFields on Perk {
    id
    title
    summary
    description
    amountCents
    unlockedDescription
    status
    showClaimButton
    claimButtonLabel
    claimButtonLink
    customFieldLabel
    customFieldDescription
    customFieldRequired
    customFieldEnabled
    priority
    uuid
    announcementSentAt
  }
`;
export const PERKS_QUERY = gql`
  query perks {
    perks {
      ...perkFields
      superfans {
        ...superfanFields
      }
      perkImages {
        id
        position
        imageUrl
      }
    }
  }
  ${perkFields}
  ${superfanFields}
`;

export const CREATE_PERK_MUTATION = gql`
  mutation createPerk(
      $title: String!
      $summary: String!
      $description: String!
      $unlockedDescription: String!
      $amountCents: Int!
      $perkImages: [Upload!]
      $coverImageRemoteUrl: String
      $showClaimButton: Boolean
      $claimButtonLabel: String
      $claimButtonLink: String
      $customFieldLabel: String
      $customFieldDescription: String
      $customFieldRequired: Boolean
      $customFieldEnabled: Boolean
    ) {
      createPerk(
        title: $title
        summary: $summary
        description: $description
        unlockedDescription: $unlockedDescription
        amountCents: $amountCents
        perkImages: $perkImages
        coverImageRemoteUrl: $coverImageRemoteUrl
        showClaimButton: $showClaimButton
        claimButtonLabel: $claimButtonLabel
        claimButtonLink: $claimButtonLink
        customFieldLabel: $customFieldLabel
        customFieldDescription: $customFieldDescription
        customFieldRequired: $customFieldRequired
        customFieldEnabled: $customFieldEnabled
      ) {
        success
        error
        perk {
          ...perkFields
        }
      }
  }
  ${perkFields}
`;

export const UPDATE_PERK_MUTATION = gql`
  mutation updatePerk(
    $id: ID!
    $title: String!
    $summary: String!
    $description: String!
    $unlockedDescription: String!
    $amountCents: Int!
    $perkImages: [Upload!]
    $showClaimButton: Boolean
    $claimButtonLabel: String
    $claimButtonLink: String
    $customFieldLabel: String
    $customFieldDescription: String
    $customFieldRequired: Boolean
    $customFieldEnabled: Boolean
  ) {
    updatePerk(
      id: $id
      title: $title
      summary: $summary
      description: $description
      unlockedDescription: $unlockedDescription
      amountCents: $amountCents
      perkImages: $perkImages
      showClaimButton: $showClaimButton
      claimButtonLabel: $claimButtonLabel
      claimButtonLink: $claimButtonLink
      customFieldLabel: $customFieldLabel
      customFieldDescription: $customFieldDescription
      customFieldRequired: $customFieldRequired
      customFieldEnabled: $customFieldEnabled
    ) {
      success
      error
      perk {
        ...perkFields
      }
    }
  }
  ${perkFields}
`;
export const TOGGLE_ARCHIVE_PERK_MUTATION = gql`
  mutation toggleArchivePerk($id: ID!) {
    toggleArchivePerk(id: $id) {
      success
      error
      perk {
        id
        status
      }
    }
  }
`;

export const TOGGLE_PUBLISH_PERK_MUTATION = gql`
  mutation togglePublishPerk($id: ID!) {
    togglePublishPerk(id: $id) {
      success
      error
      perk {
        id
        status
      }
    }
  }
`;

export const SHOW_USER_PERKS_BY_FILTERS_QUERY = gql`
  query showUserPerksByFilters(
    $page: Int
    $limit: Int
    $search: String
    $perkFilters: [ID!]
  ) {
    showUserPerksByFilters(
      page: $page
      limit: $limit
      search: $search
      perkFilters: $perkFilters
    ) {
      collection {
        id
        forAmountCents
        perk {
          ...perkFields
        }
        user {
          id
          displayName
        }
        unlockedAt
        customFieldValue
      }
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
    }
  }
  ${perkFields}
`;

export const ORDER_PERKS_MUTATION = gql`
  mutation orderPerks($perkOrder: [String!]!) {
    orderPerks(perkOrder: $perkOrder) {
      success
      error
    }
  }
`;

export const SEND_PERK_ANNOUNCEMENT = gql`
  mutation sendPerkAnnouncement($id: ID!) {
    sendPerkAnnouncement(id: $id) {
      success
      error
      perk {
        ...perkFields
      }
    }
  }
  ${perkFields}
`;
