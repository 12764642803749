import {
  A,
  afterModalClose,
  Amount,
  Card,
  CardDescription,
  CardImage,
  CardInner,
  CardTitle,
  Col,
  Div,
  message,
  Paragraph,
  Row,
  Span,
} from "atoms";
import React, { useContext, useState } from "react";
import { PublicPerkType } from "types";
import { useMemo } from "react";
import { useEffect } from "react";
import { PublicPageContext } from "../context";
import { SIGNUP } from "consts";
import styled from 'styled-components';
import { StyledSwiper, SwiperSlide } from 'utils/swiperConfig';

const NoImageCard = styled(CardImage)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

type PropsType = {
  perk: PublicPerkType;
  superfansPluralName: string;
  superfansSingularName: string;
  imageOptional?: boolean;
  onPerkCreate: () => void;
};

const PerkCard = (props: PropsType): JSX.Element => {
  const {
    perk,
    imageOptional,
  } = props;

  const [summaryExpanded, setSummaryExpanded] = useState(false);

  const renderImages = (perk) => {
    if (perk.perkImages.length > 1) {
      return (
        <StyledSwiper
          slidesPerView={1}
          spaceBetween={30}
          loop={true}
          pagination={{ clickable: true }}
          navigation={true}
        >
          {perk.perkImages.map((image, index) => (
            <SwiperSlide key={image.id}>
              <CardImage image={image.imageUrl} aspectRatio={2} />
            </SwiperSlide>
          ))}
        </StyledSwiper>
      );
    } else if (perk.perkImages.length === 1) {
      return <CardImage image={perk.perkImages[0].imageUrl} aspectRatio={2} />;
    } else {
      return <NoImageCard image="" aspectRatio={2}>
      <Amount color="green" medium css={'position: absolute;  bottom: 50%; top: 50%;'}>
        {perk.amountCents === 0 ? 'FREE' : `$${perk.amountCents / 100}`}
      </Amount>
    </NoImageCard>
    }
  }

  return (
    <Card simple shadow>
      {renderImages(perk)}
      <CardInner thin>
        <div className="mb-1">
          <Row align="middle" gutter={10}>
            <Col flex="1 0 0">
              <CardTitle css="text-transform: none;">{perk.title}</CardTitle>
            </Col>
            <Col>
              <Amount color="green" medium>
                {perk.amountCents === 0 ? 'FREE' : `$${perk.amountCents / 100}`}
              </Amount>
            </Col>
          </Row>
        </div>
        <CardDescription
          css={`
            min-height: 6.29em;
          `}
          ignoreLineBreak={!summaryExpanded}
        >
          <Paragraph
            ellipsis={{
              rows: 4,
              expandable: true,
              symbol: "read more",
              onExpand: () => setSummaryExpanded(true),
            }}
            className="mb-0"
          >
            {perk.summary}
          </Paragraph>
        </CardDescription>
      </CardInner>
    </Card>
  );
};

export default PerkCard;
