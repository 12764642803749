import React from 'react';
import styled from 'styled-components';
import { Row, Col, Button } from 'atoms';

const PopContent = styled.div`
`;
const ContentText = styled.div`
  max-width: 184px;
  margin-bottom: 10px;
  color: var(--color-content-grey);
`;
const SCol = styled(Col)`
  width: 100%;
`;
const SButton = styled(Button)`
  width: 100%;
  display: block;
`;

const DeleteConfirmPopContent = ({
  text = 'Do you want to remove this content?',
  onYes,
  onNo,
  loading,
  showNo = true
}) => (
  <PopContent>
    <ContentText>
      {text}
    </ContentText>
    {showNo ? (
      <Row gutter={10}>
        <Col flex={`1 1 30%`}>
          <Button type={'default'} block size={'small'} onClick={onNo}>NO</Button>
        </Col>
        <Col flex={`1 1 60%`}>
          <Button size={'small'} block onClick={onYes} loading={loading}>YES</Button>
        </Col>
      </Row>
    ) : (
      <Row>
        <SCol flex={`1 1 100%`}>
          <SButton size={'small'} onClick={onYes} loading={loading}>DELETE</SButton>
        </SCol>
      </Row>
    )}
  </PopContent>
);

export default DeleteConfirmPopContent;
