import React from 'react';
import styled from 'styled-components'

import { Card, CardInner, CardDescription } from 'atoms';

type PropsType = {
    noMessage?: string | JSX.Element
    children?: string | JSX.Element
    css?: string
    shadow?: boolean
};

const NoContentCard = ({ noMessage, children, css, shadow }: PropsType) => {
    return (
        <Card simple css={css} shadow={shadow}>
            <CardInner>
                <CardDescription>
                    {children || noMessage}
                </CardDescription>
            </CardInner>
        </Card>
    )
};

NoContentCard.defaultProps = {
}

export default NoContentCard;
