import React from "react";
import styled from "styled-components";
import { Superfan, User } from "types";
import { Row, Col, Avatar, A, Amount, Popover } from "atoms";
import UserProfile from "components/UserProfile";
import { formatFromIos } from "utils/Datetime";

const SSuperfanItem = styled.div`
  margin-bottom: 6px;
`;

const UnlockedAt = styled.div`
  font-size: 12px;
  color: var(--color-content-grey);
`;

type ItemProps = {
  superfan: Superfan;
  showUnlockedAt?: boolean;
  mutePopup?: boolean;
  onSelect?: (props: { user: User }) => void;
};
const SuperfanItem = (props: ItemProps) => {
  const {
    superfan: { totalCents, user, unlockedAt },
    showUnlockedAt,
    mutePopup = false,
    onSelect
  } = props;
  return (
    <SSuperfanItem>
      <Row align="middle" gutter={20}>
        <Col>
          <Avatar
            width="46px"
            src={user.avatarUrl}
            placeholder={user.displayName?.slice(0, 1).toUpperCase()}
          />
        </Col>
        <Col flex="1 0 0">
          {mutePopup ? (
            <A onClick={() => onSelect({ user })}>{user.displayName}</A>
          ) : (
            <Popover
              simple
              placement="bottom"
              trigger={["click"]}
              content={<UserProfile user={user} />}
            >
              <A>{user.displayName}</A>
            </Popover>
          )}
          {showUnlockedAt && (
            <UnlockedAt>unlocked {formatFromIos(unlockedAt)}</UnlockedAt>
          )}
        </Col>
        {!!totalCents && (
          <Col>
            <Amount color="green" css={"font-size: 15px;"}>
              ${Math.round(totalCents / 100)}
            </Amount>
          </Col>
        )}
      </Row>
    </SSuperfanItem>
  );
};

SuperfanItem.defaultProps = {
  showUnlockedAt: false,
  showSuperfanDollarAmounts: true,
};

export default SuperfanItem;
