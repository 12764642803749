import { Alert as AntAlert } from 'antd';
import styled from 'styled-components';

const Alert = styled(AntAlert)`
    ${props => props.card && `
        padding: 20px;
        margin-bottom: 20px;
        border-radius: 8px;
        box-shadow: 0px 0px 15px rgb(0 0 0 / 10%);
    `}
    ${props => props.type === 'info' && `
        && {
            padding: 15px;
        }
    `}
    ${props => props.type === 'success' && `
        && {
            padding: 15px;
            background-color: #f6fffa;
            border-color: var(--color-green);
        }
    `}
    ${props => props.css}
`;

export default Alert;
