import { Slider } from "antd";
import styled from "styled-components";

const PodSlider = styled(Slider)`
  .ant-slider-track {
    background-color: ${(props) => `${props.theme.colors.primary}4C`};
  }
  .ant-slider-handle {
    border-color: ${(props) => props.theme.colors.primary};
  }
  &:hover {
    .ant-slider-track {
      background-color: ${(props) => `${props.theme.colors.primary}99`};
    }
    .ant-slider-handle:not(.ant-tooltip-open) {
      border-color: ${(props) => props.theme.colors.primary};
    }
  }
`;

export default PodSlider;
