import { gql } from "@apollo/client";
import { collectionMetadataFields } from "graphql-api";

export const podcastFields = gql`
  fragment podcastFields on Podcast {
    id
    name
    description
    authorDisplayName
    explicitContent
    coverArtUrl
  }
`;

export const episodeFields = gql`
  fragment episodeFields on Episode {
    id
    title
    description
    audioUrl
    audioDuration
    explicitContent
    useCustomArtwork
    customArtworkUrl
    publish
    publishAt
    announcementSentAt
    status
  }
`;

export const CREATE_PODCAST_MUTATION = gql`
  mutation createPodcast(
    $name: String!
    $description: String!
    $authorDisplayName: String!
    $explicitContent: Boolean!
    $coverArtFile: Upload!
  ) {
    createPodcast(
      name: $name
      description: $description
      authorDisplayName: $authorDisplayName
      explicitContent: $explicitContent
      coverArtFile: $coverArtFile
    ) {
      success
      error
      show {
        id
        podcast {
          ...podcastFields
        }
      }
    }
  }
  ${podcastFields}
`;

export const UPDATE_PODCAST_MUTATION = gql`
  mutation updatePodcast(
    $name: String!
    $description: String!
    $authorDisplayName: String!
    $explicitContent: Boolean!
    $coverArtFile: Upload
  ) {
    updatePodcast(
      name: $name
      description: $description
      authorDisplayName: $authorDisplayName
      explicitContent: $explicitContent
      coverArtFile: $coverArtFile
    ) {
      success
      error
      show {
        id
        podcast {
          ...podcastFields
        }
      }
    }
  }
  ${podcastFields}
`;

export const PODCAST_QUERY = gql`
  query podcast {
    podcast {
      ...podcastFields
    }
  }
  ${podcastFields}
`;

export const CREATE_EPISODE_MUTATION = gql`
  mutation createEpisode(
    $title: String!
    $description: String!
    $explicitContent: Boolean!
    $useCustomArtwork: Boolean!
    $audio: Upload
    $customArtwork: Upload
    $publish: Boolean!
    $publishAt: ISO8601DateTime
  ) {
    createEpisode(
      title: $title
      description: $description
      explicitContent: $explicitContent
      useCustomArtwork: $useCustomArtwork
      audio: $audio
      customArtwork: $customArtwork
      publish: $publish
      publishAt: $publishAt
    ) {
      success
      error
    }
  }
`;
export const UPDATE_EPISODE_MUTATION = gql`
  mutation updateEpisode(
    $id: ID!
    $title: String!
    $description: String!
    $explicitContent: Boolean!
    $useCustomArtwork: Boolean!
    $audio: Upload
    $customArtwork: Upload
    $publish: Boolean!
    $publishAt: ISO8601DateTime
  ) {
    updateEpisode(
      id: $id
      title: $title
      description: $description
      explicitContent: $explicitContent
      useCustomArtwork: $useCustomArtwork
      audio: $audio
      customArtwork: $customArtwork
      publish: $publish
      publishAt: $publishAt
    ) {
      success
      error
      episode {
        ...episodeFields
      }
    }
  }
  ${episodeFields}
`;

export const DELETE_EPISODE_MUTATION = gql`
  mutation deleteEpisode($id: ID!) {
    deleteEpisode(id: $id) {
      success
      error
    }
  }
`;

export const EPISODES_QUERY = gql`
  query episodes($podcastId: ID!, $page: Int, $limit: Int) {
    episodes(podcastId: $podcastId, page: $page, limit: $limit) {
      collection {
        ...episodeFields
      }
      metadata {
        ...collectionMetadataFields
      }
    }
  }
  ${episodeFields}
  ${collectionMetadataFields}
`;

export const SEND_EPISODE_ANNOUNCEMENT = gql`
  mutation sendEpisodeAnnouncement($id: ID!) {
    sendEpisodeAnnouncement(id: $id) {
      success
      error
      episode {
        ...episodeFields
      }
    }
  }
  ${episodeFields}
`;
