import { gql } from "@apollo/client";
import { membershipFields } from "graphql-api";

const chatContactFields = gql`
  fragment chatContactFields on ChatContact {
    displayName
    email
    avatarUrl
    bio
    id
    contactId
    addedAt
    joinedAt
    suspended
    accessType
    amountCents
    totalPaid
    instagram
    twitter
    website
  }
`;

export const TOGGLE_SHOW_CHAT_CONTACT_SUSPENDED = gql`
  mutation toggleShowChatContactSuspended($id: ID!) {
    toggleShowChatContactSuspended(id: $id) {
      success
      error
      chatContact {
        id
        suspended
      }
    }
  }
`;

export const REMOVE_CHAT_PARTICIPANT_MUTATION = gql`
  mutation removeChatParticipant($id: ID!) {
    removeChatParticipant(id: $id) {
      success
      error
    }
  }
`;

export const TOGGLE_CHATROOM_WELCOME_ENABLED_MUTATION = gql`
  mutation toggleChatroomWelcomeEnabled {
    toggleChatroomWelcomeEnabled {
      success
      error
      show {
        id
        chatroomWelcomeEnabled
      }
    }
  }
`;
