import { colors } from 'styles';

export {
   isID,
   getUsernameFromProfile,
   isHttp,
   removeHttp,
   isWebsite,
   isInstagram,
   isInstagramProfile,
   isTwitter,
   isTwitterProfile,
   isFacebook,
   isFacebookPage,
   isFacebookProfile,
   isLinkedin,
   isLinkedinProfile,
   isTiktok,
   isTiktokProfile,
   isYoutube,
   isYoutubeChannel,
   generateInstagramProfileByID,
   generateTwitterProfileByID,
   generateFacebookProfileByID,
   generateFacebookPageByID,
   generateLinkedinProfileByID,
   generateTiktokProfileByID,
   generateYoutubeChannelByID
} from './url';

export const getColorValue = (color) => {
   if (color in colors) {
      return colors[color];
   }
   return color;
}

export const hexToRgb = (hex) => {
   const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
   return {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
   };
}

export const passwordChecker = (v) => {
   let azCount = 0;
   let AZCount = 0;
   let numberCount = 0;
   let specialCount = 0;
   for (let i = 0; i < v.length; i++) {
      const char = v[i];
      if (/^[a-z]$/.test(char)) {
         azCount++;
      } else if (/^[A-Z]$/.test(char)) {
         AZCount++;
      } else if (/^\d$/.test(char)) {
         numberCount++;
      } else {
         specialCount++;
      }
   }
   if (
      // azCount >= 1 &&
      // AZCount >= 1 &&
      // numberCount >= 1 &&
      // specialCount >= 1 &&
      v.length >= 8
   ) {
      return true;
   }
   return "8+ characters is needed for security";
}

export function intToRGB(i) {
   let c = (i & 0x00FFFFFF)
      .toString(16)
      .toUpperCase();

   return "00000".substring(0, 6 - c.length) + c;
}

export const getColorFromString = (str) => {
   let hash = 0;
   for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
   }
   return `#${intToRGB(hash)}`;
}