import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import {
  QUERIES_AFTER_LAZY_LOGIN,
} from "../graphql";
import FanPage from "../pages/FanPage";
import { FanPageContext, withPublicPageState } from "../context";
import { useLazyQuery, useSubscription } from "@apollo/client";
import styled, { ThemeContext } from "styled-components";
import { ErrorCard, Skeleton } from "atoms";
import { PageInner } from "apps/PublicPage/atoms";
import {
  MESSAGE_TRANSCRIBING_SUBSCRIPTION,
  MESSAGE_TRANSCRIBED_SUBSCRIPTION,
  REACHED_MAX_MESSAGES_COUNT_QUERY,
} from "graphql-api";

const FanPageWithTranscribing = (props) => {
  const { show } = props;
  useSubscription(MESSAGE_TRANSCRIBING_SUBSCRIPTION, {
    variables: { showId: show.id },
    onSubscriptionData(payload) {
      console.log("on data", payload.subscriptionData.data);
    },
  });
  useSubscription(MESSAGE_TRANSCRIBED_SUBSCRIPTION, {
    variables: { showId: show.id },
  });
  return <FanPage {...props} />;
};

export const SShow = styled.div`
  padding-bottom: 40px;
`;

const FanPageContainer = ({
  publicPageState: { setShowId, wasLoggedIn, isLoggedIn },
}) => {
  // @ts-ignore
  const { slug } = useParams<{ slug: string }>();
  const { setPrimaryColor } = useContext(ThemeContext);

  const { show, loading, client, onCompleted, refetch, showQueryVariables } = useContext(FanPageContext);
  const [triggerQueriesAfterLazyLogin] = useLazyQuery(
    QUERIES_AFTER_LAZY_LOGIN,
    {
      fetchPolicy: "network-only",
      onCompleted(payload) {
        console.log("updates after lazy login", payload);
      },
    }
  );

  const [triggerMessagesCountQueryAfterLazyLogin] = useLazyQuery(REACHED_MAX_MESSAGES_COUNT_QUERY, {
    fetchPolicy: "network-only"
  });

  const refetchShowQuery = useCallback(() => {
    return refetch();
  }, [refetch]);

  const handleAfterLazyLogin = async () => {
    await triggerMessagesCountQueryAfterLazyLogin({
      variables: {
        slug,
        uuid: "",
      },
    });

    await triggerQueriesAfterLazyLogin({
      variables: showQueryVariables,
    });

    client.cache.evict({
      id: "ROOT_QUERY",
      fieldName: "publicInboxMessages",
    });
  };
  useEffect(() => {
    const clear = onCompleted(({ publicShow }) => {
      setShowId(publicShow.id);
      setPrimaryColor(publicShow.themeColor);
    })
    return () => clear()
  }, [])
  useEffect(() => {
    if (isLoggedIn && wasLoggedIn === false) {
      handleAfterLazyLogin();
    }
  }, [wasLoggedIn, isLoggedIn]);

  if ((loading && !show)) {
    return (
      <SShow>
        <PageInner>
          <Skeleton />
        </PageInner>
      </SShow>
    );
  }

  if (!show) {
    return (
      <SShow>
        <PageInner>
          <ErrorCard
            title={"Show Summary"}
            description={"Something went wrong!"}
          />
        </PageInner>
      </SShow>
    );
  }

  return (
    <FanPageWithTranscribing
      show={show}
      refetchShowQuery={refetchShowQuery}
    />
  );
};

const FanPageWithPageState = withPublicPageState(FanPageContainer);

export { FanPageWithPageState as FanPageContainer };
