import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useLazyQuery } from '@apollo/client';

import { Logo, AsideHelper, CardTitle, A, Alert } from 'atoms';
import { SIGNUP, LOGIN, FORGOT_PASSWORD } from 'consts';
import Signup from 'apps/Auth/components/CreateAccount';
import LoginForm from 'apps/Auth/components/LoginForm';
import ForgotPasswordForm from 'apps/Auth/components/ForgotPasswordForm';

import { ME_QUERY } from 'graphql-api';

const SAuth = styled.div``;

const LogoContainer = styled.div`
  margin-bottom: 20px;
`;

const Header = styled.header`
  margin-bottom: 20px;
`;

const Body = styled.div`
  margin-bottom: 20px;
`;

const Footer = styled.footer``;

const FooterLink = styled(A)`
  text-align: center;
  margin-top: 10px;
  font-size: 15px;
`;

const AuthAlert = styled(Alert)`
  margin-bottom: 15px;
`;

const nameOfType = {
  [LOGIN]: 'Login',
  [SIGNUP]: 'Sign Up',
  [FORGOT_PASSWORD]: 'Forgot Password'
};

const Auth = ({ type, setType, onSuccess, successQuery, widget, showId, ...props }) => {

  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [triggerMeQuery] = useLazyQuery(successQuery, {
    onCompleted() {
      onSuccess();
    }
  });

  useEffect(() => {
    setSuccess(null);
    setError(null);
    return () => {
    }
  }, [type]);

  const onAuthSuccess = async () => {
    if (!widget) {
      await triggerMeQuery();
    } else {
      onSuccess();
    }
  }

  const onSentEmail = () => {
    setError(false);
    setSuccess('Please check your email to reset your password');
  }

  const onEmailError = ({ message }) => {
    setSuccess(null);
    setError(message);
  }

  return (
    <SAuth>
      <Header>
        <LogoContainer>
          <Logo alwaysWide />
        </LogoContainer>
        <CardTitle css={ `margin-bottom: 10px;
          font-size: 20px;` }>{ nameOfType[type] }</CardTitle>
        {
          type === SIGNUP && (
            <AsideHelper css={ `font-size: 16px;` }>Please <A onClick={ () => setType(LOGIN) }>login</A> if you have an
              account</AsideHelper>
          )
        }
        {
          type === LOGIN && (
            <AsideHelper css={ `font-size: 16px;` }>or <A onClick={ () => setType(SIGNUP) }>sign up</A> if you need an
              account.</AsideHelper>
          )
        }
      </Header>
      <Body>
        {
          success && (
            <AuthAlert type={ 'success' } message={ success }/>
          )
        }
        {
          error && (
            <AuthAlert type={ 'error' } message={ error }/>
          )
        }
        { type === SIGNUP && (
          <Signup onCreateAccount={ onAuthSuccess } showId={ showId } simple widget={ widget }/>
        ) }
        { type === LOGIN && (
          <LoginForm onLogin={ onAuthSuccess } showId={ showId } widget={ widget }/>
        ) }
        { type === FORGOT_PASSWORD && (
          <ForgotPasswordForm onSend={ onSentEmail } onError={ onEmailError }/>
        ) }
      </Body>
      <Footer>
        {
          type === LOGIN && (
            <FooterLink onClick={ () => setType(FORGOT_PASSWORD) } block>Forgot Password</FooterLink>
          )
        }
        {
          type === FORGOT_PASSWORD && (
            <FooterLink onClick={ () => setType(LOGIN) } block css={ `font-size: 15px;` }>Login</FooterLink>
          )
        }
      </Footer>
    </SAuth>
  )
};

Auth.defaultProps = {
  successQuery: ME_QUERY,
  widget: false,
};

export default Auth;
