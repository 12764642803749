import { createGlobalStyle } from 'styled-components';

export const cssConfig = {
    space1: '5px',
    space2: '10px',
    space3: '15px',
    space4: '25px',
    space5: '40px',
};

export const CssClasses = createGlobalStyle`
    .mb-0.mb-0.mb-0 {
        margin-bottom: 0;
    }
    .mb-1.mb-1.mb-1 {
        margin-bottom: ${cssConfig.space1};
    }
    .mb-2.mb-2.mb-2 {
        margin-bottom: ${cssConfig.space2};
    }
    .mb-3.mb-3.mb-3 {
        margin-bottom: ${cssConfig.space3};
    }
    .mb-4.mb-4.mb-4 {
        margin-bottom: ${cssConfig.space4};
    }
    .mb-5.mb-5.mb-5 {
        margin-bottom: ${cssConfig.space5};
    }

    .mt-1.mt-1.mt-1 {
        margin-top: ${cssConfig.space1};
    }
    .mt-2.mt-2.mt-2 {
        margin-top: ${cssConfig.space2};
    }
    .mt-3.mt-3.mt-3 {
        margin-top: ${cssConfig.space3};
    }
    .mt-4.mt-4.mt-4 {
        margin-top: ${cssConfig.space4};
    }
    .mt-5.mt-5.mt-5 {
        margin-top: ${cssConfig.space5};
    }
    
    .pb-1.pb-1.pb-1 {
        padding-bottom: ${cssConfig.space1};
    }
    .pb-2.pb-2.pb-2 {
        padding-bottom: ${cssConfig.space2};
    }
    .pb-3.pb-3.pb-3 {
        padding-bottom: ${cssConfig.space3};
    }
    .pb-4.pb-4.pb-4 {
        padding-bottom: ${cssConfig.space4};
    }
    .pb-5.pb-5.pb-5 {
        padding-bottom: ${cssConfig.space5};
    }

    .pt-1.pt-1.pt-1 {
        padding-top: ${cssConfig.space1};
    }
    .pt-2.pt-2.pt-2 {
        padding-top: ${cssConfig.space2};
    }
    .pt-3.pt-3.pt-3 {
        padding-top: ${cssConfig.space3};
    }
    .pt-4.pt-4.pt-4 {
        padding-top: ${cssConfig.space4};
    }
    .pt-5.pt-5.pt-5 {
        padding-top: ${cssConfig.space5};
    }
    .text-center {
        text-align: center;
    }
`;
