import React, { useContext } from "react";
import {
  SectionTitle,
  SectionHeader,
} from "../components/FanPage";
import PremiumContentMember from "./PremiumContentMember";
import PremiumContentNonMember from "./PremiumContentNonMember";
import { FanPageContext } from "../context";
import { NoContentCard } from "atoms";

const MembersContainer = () => {
  const { isMember, show } = useContext(FanPageContext);
  return (
    <div className="MembersContainer">
      <SectionHeader style={{ marginBottom: '20px' }}>
        <SectionTitle>Premium Content</SectionTitle>
      </SectionHeader>
      {
        show.finishedMembershipSetup ? isMember ? (
          <PremiumContentMember />
        ) : (
          <PremiumContentNonMember />
        ) : (
          <NoContentCard shadow>Content coming soon. We're working on it!</NoContentCard>
        )
      }
    </div>
  );
};

// @ts-ignore
export default MembersContainer;
