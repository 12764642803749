import React from 'react';
import {useMemo} from 'react';
import styled from 'styled-components';
import {PublicSponsorType} from 'types';
import SponsorCard from './SponsorCard';

const StylesSponsorList = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 15px 30px;
  flex-wrap: wrap;
`;

const StyledSponsorCard = styled.div`
  flex: 0 1 100%;
  
  @media screen and (min-width: 768px) {
    flex: 0 1 48%;
  }
  
  @media screen and (min-width: 1150px) {
    flex: 0 1 31%;
  }
`;

type PropsType = {
  sponsors: PublicSponsorType[];
  isLoggedIn: boolean;
  onSponsorCreate: () => void;
}

const SponsorList = (props: PropsType): JSX.Element => {
  const {sponsors, ...forwardProps} = props;
  const sortedSponsors = useMemo<PublicSponsorType[]>(() => [...sponsors].sort((a, b) => a.priority - b.priority), [sponsors]);

  return (
    <StylesSponsorList>
      {
        sortedSponsors.map((sponsor) => (
          <StyledSponsorCard key={sponsor.id} className='mb-3'>
            <SponsorCard
              sponsor={sponsor}
              {...forwardProps}
            />
          </StyledSponsorCard>
        ))
      }
    </StylesSponsorList>
  )
}

export default SponsorList;
