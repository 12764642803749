import { gql } from "@apollo/client";

import {
  RewardFields,
  TierFields,
  superfanFields,
} from "graphql-api";
import { episodeFields, podcastFields } from "./Podcast";

const teamMemberFields = gql`
  fragment teamMemberFields on TeamMember {
    avatarUrl
    displayName
    email
    id
  }
`;

export const showFields = gql`
  fragment showFields on Show {
    id
    active
    slug
    uuid
    title
    monetization
    themeColor
    coverImageUrl
    currentStep
    bannerImageUrl
    welcomeVideoUrl
    welcomeVideoRatio
    welcomeVideoActive
    showPurchasesNumberOnPerk
    showPurchasedFansOnPerk
    chatroomEnabled
    chatroomWelcomeEnabled
    chatRoomForMembers
    donationLabel
    finishedMembershipSetup
    locked
    membersCount
    membershipDescription
    membershipWelcomeEmail
    messageSupportAmounts
    messageTipsLabel
    messageTipsThankYou
    onboardingCompletedAt
    contactsCount
    timezone
    tags {
      id
      name
    }
    viewerIsOwner
    owner {
      id
      avatarUrl
      displayName
      email
    }
    superfans {
      ...superfanFields
    }
    teamMembers {
      ...teamMemberFields
    }
    invitedTeamMembers {
      email
      id
    }
    applePodcast
    email
    facebook
    instagram
    linkedin
    rssFeed
    spotify
    tiktok
    twitter
    website
    youtube
    disabled
    superfansSingularName
    superfansPluralName
    superfansThankYou
    supportAmounts
    floatingWidgetColor
    floatingWidgetTextColor
    floatingWidgetDisplayOption
    floatingWidgetShowMultiInbox
    floatingWidgetShowButtonText
    floatingWidgetIconType
    widgetVideoUrl
    widgetVideoRatio
    widgetVideoThumbnailUrl
    welcomeMessage
  }
  ${teamMemberFields}
  ${superfanFields}
`;

export const ALLOWED_SHOWS_QUERY = gql`
  query allowedShows {
    allowedShows {
      ...showFields
    }
  }
  ${showFields}
`;

export const SHOW_QUERY = gql`
  query show {
    show {
      ...showFields
      podcast {
        ...podcastFields
      }
      activeTiers {
        ...tierFields
      }
      rewards {
        ...rewardFields
      }
    }
  }
  ${showFields}
  ${RewardFields}
  ${TierFields}
  ${podcastFields}
`;

export const SHOW_TEAM_QUERY = gql`
  query shows {
    shows {
      id
      owner {
        id
        displayName
        avatarUrl
      }
      teamMembers {
        id
        displayName
        email
        avatarUrl
      }
      invitedTeamMembers {
        id
        email
      }
    }
  }
`;

export const CREATE_SHOW_MUTATION = gql`
  mutation createShow($slug: String, $title: String, $image: Upload) {
    createShow(slug: $slug, title: $title, image: $image) {
      success
      error
    }
  }
`;

export const VIEW_SHOW_MUTATION = gql`
  mutation viewShow($showId: String!) {
    viewShow(showId: $showId) {
      success
      error
    }
  }
`;

export const SHOWS_QUERY = gql`
  query shows {
    shows {
      ...showFields
    }
  }
  ${showFields}
`;

export const TOGGLE_SHOW_ACTIVE_MUTATION = gql`
  mutation toggleShowActive {
    toggleShowActive {
      success
      error
      show {
        id
        active
      }
    }
  }
`;
export const TOGGLE_CHATROOM_EANBLED_MUTATION = gql`
  mutation toggleChatroomEnabled {
    toggleChatroomEnabled {
      success
      error
      show {
        id
        chatroomEnabled
      }
    }
  }
`;

export const UPDATE_SHOW_MUTATION = gql`
  mutation updateShow(
    $slug: String
    $title: String
    $themeColor: String
    $website: String
    $applePodcast: String
    $email: String
    $facebook: String
    $instagram: String
    $linkedin: String
    $rssFeed: String
    $spotify: String
    $tiktok: String
    $twitter: String
    $youtube: String
    $superfansSingularName: String
    $superfansPluralName: String
    $superfansThankYou: String
    $supportAmounts: [Int!]
    $welcomeVideoActive: Boolean
    $floatingWidgetColor: String
    $floatingWidgetTextColor: String
    $floatingWidgetDisplayOption: String
    $floatingWidgetShowMultiInbox: Boolean
    $floatingWidgetShowButtonText: Boolean
    $floatingWidgetIconType: String
    $messageSupportAmounts: [Int!]
    $messageTipsLabel: String
    $messageTipsThankYou: String
    $welcomeMessage: String
    $currentStep: Int
  ) {
    updateShow(
      slug: $slug
      title: $title
      themeColor: $themeColor
      website: $website
      applePodcast: $applePodcast
      email: $email
      facebook: $facebook
      instagram: $instagram
      linkedin: $linkedin
      rssFeed: $rssFeed
      spotify: $spotify
      tiktok: $tiktok
      twitter: $twitter
      youtube: $youtube
      superfansSingularName: $superfansSingularName
      superfansPluralName: $superfansPluralName
      superfansThankYou: $superfansThankYou
      supportAmounts: $supportAmounts
      welcomeVideoActive: $welcomeVideoActive
      floatingWidgetColor: $floatingWidgetColor
      floatingWidgetTextColor: $floatingWidgetTextColor
      floatingWidgetDisplayOption: $floatingWidgetDisplayOption
      floatingWidgetShowMultiInbox: $floatingWidgetShowMultiInbox
      floatingWidgetShowButtonText: $floatingWidgetShowButtonText
      floatingWidgetIconType: $floatingWidgetIconType
      messageSupportAmounts: $messageSupportAmounts
      messageTipsLabel: $messageTipsLabel
      messageTipsThankYou: $messageTipsThankYou
      welcomeMessage: $welcomeMessage
      currentStep: $currentStep
    ) {
      success
      error
      show {
        ...showFields
      }
    }
  }
  ${showFields}
`;

export const UPDATE_SHOW_WELCOME_VIDEO_ACTIVE_MUTATION = gql`
  mutation updateShow($welcomeVideoActive: Boolean) {
    updateShow(welcomeVideoActive: $welcomeVideoActive) {
      success
      error
      show {
        id
        welcomeVideoActive
      }
    }
  }
`;

export const UPDATE_SHOW_DONATION_LABEL_MUTATION = gql`
  mutation updateShow($donationLabel: String) {
    updateShow(donationLabel: $donationLabel) {
      success
      error
      show {
        id
        donationLabel
      }
    }
  }
`;

export const UPDATE_MEMBERSHIP_DESCRIPTION_MUTATION = gql`
  mutation updateShow($membershipDescription: String) {
    updateShow(membershipDescription: $membershipDescription) {
      success
      error
      show {
        id
        membershipDescription
      }
    }
  }
`;

export const UPDATE_MEMBERSHIP_EMAIL_MUTATION = gql`
  mutation updateShow($membershipWelcomeEmail: String) {
    updateShow(membershipWelcomeEmail: $membershipWelcomeEmail) {
      success
      error
      show {
        id
        membershipWelcomeEmail
      }
    }
  }
`;

const showFeaturesFields = gql`
  fragment showFeaturesFields on ShowFeatures {
    id
    displayInbox
    displayGeneralTips
    displayMessageTips
    displayPerks
    showSuperfanDollarAmounts
    showLeaderboard
    showSponsorsAsList
    displayMembers
    displaySponsors
    tabsOnFanpage
  }
`;

export const SHOW_FEATURES_QUERY = gql`
  query showFeatures {
    showFeatures {
      ...showFeaturesFields
    }
  }
  ${showFeaturesFields}
`;

export const UPDATE_SHOW_FEATURES_MUTATION = gql`
  mutation updateShowFeatures(
    $displayInbox: Boolean
    $displayGeneralTips: Boolean
    $displayMessageTips: Boolean
    $displayPerks: Boolean
    $displayMembers: Boolean
    $displaySponsors: Boolean
    $showSuperfanDollarAmounts: Boolean
    $showLeaderboard: Boolean
    $showSponsorsAsList: Boolean
    $tabsOnFanpage: [String!]
  ) {
    updateShowFeatures(
      displayInbox: $displayInbox
      displayGeneralTips: $displayGeneralTips
      displayMessageTips: $displayMessageTips
      displayPerks: $displayPerks
      displayMembers: $displayMembers
      displaySponsors: $displaySponsors
      showSuperfanDollarAmounts: $showSuperfanDollarAmounts
      showLeaderboard: $showLeaderboard
      showSponsorsAsList: $showSponsorsAsList
      tabsOnFanpage: $tabsOnFanpage
    ) {
      success
      error
      showFeatures {
        ...showFeaturesFields
      }
    }
  }
  ${showFeaturesFields}
`;

export const UPDATE_SHOW_COVER_IMAGE_MUTATION = gql`
  mutation updateShowCoverImage($image: Upload!) {
    updateShowCoverImage(image: $image) {
      success
      error
      show {
        ...showFields
      }
    }
  }
  ${showFields}
`;

export const UPDATE_SHOW_BANNER_IMAGE_MUTATION = gql`
  mutation updateShowBannerImage($image: Upload!) {
    updateShowBannerImage(image: $image) {
      success
      error
      show {
        id
        bannerImageUrl
      }
    }
  }
`;

export const DELETE_SHOW_COVER_IMAGE_MUTATION = gql`
  mutation deleteShowCoverImage {
    deleteShowCoverImage {
      success
      error
      show {
        ...showFields
      }
    }
  }
  ${showFields}
`;

export const DELETE_SHOW_BANNER_IMAGE_MUTATION = gql`
  mutation deleteShowBannerImage {
    deleteShowBannerImage {
      success
      error
      show {
        id
        bannerImageUrl
      }
    }
  }
`;

export const SHOW_TAGS_QUERY = gql`
  query messageTags($id: String!) {
    messageTags(id: $id) {
      active
      id
      name
    }
  }
`;

export const DELETE_SHOW_MUTATION = gql`
  mutation deleteShow($showId: String!) {
    deleteShow(showId: $showId) {
      success
      error
    }
  }
`;
export const TOGGLE_MONETIZATION_MUTATION = gql`
  mutation toggleMonetization {
    toggleMonetization {
      success
      error
      show {
        id
        monetization
      }
    }
  }
`;

export const SHOW_PAYMENTS_TOTAL_QUERY = gql`
  query showPaymentsTotal(
    $pastDays: Int!
  ) {
    showPaymentsTotal(
      pastDays: $pastDays
    ) {
      totalEarnings
      show {
        id
        slug
      }
    }
  }
`;

export const SHOW_PAYMENTS_QUERY = gql`
  query showPayments(
    $page: Int
    $limit: Int
    $filters: [String!]
  ) {
    showPayments(
      page: $page
      limit: $limit
      filters: $filters
    ) {
      collection {
        amountCents
        refund
        date
        id
        source
        show {
          id
          slug
          title
          coverImageUrl
          owner {
            id
            displayName
          }
        }
        user {
          id
          email
          avatarUrl
          displayName
          bio
          website
          instagram
          twitter
          isMembership
        }
      }
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
    }
  }
`;

export const SHOW_FANS_PAYMENTS_QUERY = gql`
  query showFansPayments(
    $userId: ID!
  ) {
    showFansPayments(
      userId: $userId
    ) {
      amountCents
      refund
      date
      id
      source
      show {
        id
      }
      user {
        id
      }
    }
  }
`;

export const UPDATE_SHOW_WELCOME_VIDEO_MUTATION = gql`
  mutation updateShowWelcomeVideo($file: Upload!) {
    updateShowWelcomeVideo(file: $file) {
      success
      error
      show {
        id
        welcomeVideoUrl
        welcomeVideoRatio
      }
    }
  }
`;

export const DELETE_SHOW_WELCOME_VIDEO_MUTATION = gql`
  mutation deleteShowWelcomeVideo {
    deleteShowWelcomeVideo {
      success
      error
      show {
        id
        welcomeVideoUrl
        welcomeVideoRatio
      }
    }
  }
`;

export const UPDATE_SHOW_WIDGET_VIDEO_MUTATION = gql`
  mutation updateShowWidgetVideo($file: Upload!) {
    updateShowWidgetVideo(file: $file) {
      success
      error
      show {
        id
        widgetVideoUrl
        widgetVideoRatio
      }
    }
  }
`;

export const DELETE_SHOW_WIDGET_VIDEO_MUTATION = gql`
  mutation deleteShowWidgetVideo {
    deleteShowWidgetVideo {
      success
      error
      show {
        id
        widgetVideoUrl
        widgetVideoRatio
      }
    }
  }
`;

export const TOGGLE_SHOW_ON_LEADERBOARD_MUTATION = gql`
  mutation toggleShowOnLeaderboard($userId: String!) {
    toggleShowOnLeaderboard(userId: $userId) {
      success
      error
      show {
        id
        superfans {
          ...superfanFields
        }
      }
    }
  }
  ${superfanFields}
`;

export const CREATE_SHOW_TIER_MUTATION = gql`
  mutation createActiveTier(
    $monthly: Boolean!,
    $monthlyPrice: Int,
    $yearly: Boolean!,
    $yearlyPrice: Int,
    $paid: Boolean!,
    $paidPrice: Int
  ) {
    createActiveTier(
      monthly: $monthly,
      monthlyPrice: $monthlyPrice,
      yearly: $yearly,
      yearlyPrice: $yearlyPrice,
      paid: $paid,
      paidPrice: $paidPrice
    ) {
      success
      error
      show {
        id
        activeTiers {
          ...tierFields
        }
      }
    }
  }
  ${TierFields}
`;

export const UPDATE_SHOW_SUPPORTER_NAME_MUTATION = gql`
  mutation updateShow(
    $superfansSingularName: String!
    $superfansPluralName: String!
  ) {
    updateShow(
      superfansSingularName: $superfansSingularName
      superfansPluralName: $superfansPluralName
    ) {
      success
      error
      show {
        id
        superfansSingularName
        superfansPluralName
      }
    }
  }
`;

export const UPDATE_SHOW_PURCHASES_NUMBER_ON_PERK_MUTATION = gql`
  mutation updateShow($showPurchasesNumberOnPerk: Boolean!) {
    updateShow(showPurchasesNumberOnPerk: $showPurchasesNumberOnPerk) {
      success
      error
      show {
        id
        showPurchasesNumberOnPerk
      }
    }
  }
`;

export const UPDATE_SHOW_PURCHASED_FANS_ON_PERK_MUTATION = gql`
  mutation updateShow($showPurchasedFansOnPerk: Boolean!) {
    updateShow(showPurchasedFansOnPerk: $showPurchasedFansOnPerk) {
      success
      error
      show {
        id
        showPurchasedFansOnPerk
      }
    }
  }
`;

const showUserNotificationsFields = gql`
  fragment showUserNotificationsFields on ShowUserNotifications {
    id
    showNewAudio
    showNewLike
    showGeneralTip
    showMessageTip
    showNewChatUser
    showPerkBought
    showMembershipPurchased
    showMembershipCanceled
  }
`;

export const SHOW_USER_NOTIFICATIONS_QUERY = gql`
  query showUserNotifications {
    showUserNotifications {
      ...showUserNotificationsFields
    }
  }
  ${showUserNotificationsFields}
`;

export const UPDATE_SHOW_USER_NOTIFICATIONS_MUTATION = gql`
  mutation updateShowUserNotifications(
    $showNewAudio: Boolean
    $showNewLike: Boolean
    $showGeneralTip: Boolean
    $showMessageTip: Boolean
    $showNewChatUser: Boolean
    $showPerkBought: Boolean
    $showMembershipPurchased: Boolean
    $showMembershipCanceled: Boolean
  ) {
    updateShowUserNotifications(
      showNewAudio: $showNewAudio
      showNewLike: $showNewLike
      showGeneralTip: $showGeneralTip
      showMessageTip: $showMessageTip
      showNewChatUser: $showNewChatUser
      showPerkBought: $showPerkBought
      showMembershipPurchased: $showMembershipPurchased
      showMembershipCanceled: $showMembershipCanceled
    ) {
      success
      error
      notifications {
        ...showUserNotificationsFields
      }
    }
  }
  ${showUserNotificationsFields}
`;

export const COMPLETE_ONBOARDING_MUTATION = gql`
  mutation completeOnboarding {
    completeOnboarding {
      success
      error
      show {
        id
        onboardingCompletedAt
      }
    }
  }
`;

export const UPDATE_SHOW_TIMEZONE_MUTATION = gql`
  mutation updateShowTimezone($timezone: String!) {
    updateShowTimezone(timezone: $timezone) {
      show {
        id
        timezone
        finishedMembershipSetup
      }
      success
      error
    }
  }
`;
