import styled from 'styled-components';
import { Select } from 'antd';

const PodSelect = styled(Select)`
    display: block;
    .ant-select-selector {
        padding: 3px 5px;
    }
    &.ant-select-lg, &.ant-select-single {
        font-size: 14px;
        
        .ant-select-selector {
            font-size: 14px;
        }
    }
    &.ant-select-multiple {
        .ant-select-selector .ant-select-selection-overflow {
            .ant-select-selection-overflow-item {
                .ant-select-selection-item {
                    background-color: var(--color-primary);
                    border-color: var(--color-primary);
                    color: white;
                    border-radius: 3px;
                    padding-bottom: 2px;
                    font-size: 14px;
                    .ant-select-selection-item-content {
                        margin-right: 6px;
                    }
                    .anticon {
                        color: white;
                    }
                }
            }
        }
    }
`;

PodSelect.defaultProps = {
    size: 'large'
}

export default PodSelect;

export const Option = styled(PodSelect.Option)``;
