export const superfansWelcomeMessage = "Thanks for your interest in being a Superfan! By supporting this podcast with a monetary tip, you'll join my tribe of Superfans and greatly help keep this podcast up and running!";
export const superfansThankYouMessage = "Thanks so much for your support!\n\nIt's great Superfans like you that help keep our show running!";
export const supportAmounts = [5, 15, 25];
export const supportUnits = {
  monthly: 'month',
  yearly: 'year',
  one_time: 'once',
};
export const NoShowTitle = "No Show Title";
export const sourceMap = {
  refund: 'refund',
  message: 'message tip',
  membership: 'membership',
  perk: 'perk',
  general: 'general tip'
};
