import {
  ApolloClient,
  ApolloLink,
  Observable,
  InMemoryCache,
  from
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { createUploadLink } from 'apollo-upload-client'
import { AUTH_TOKEN_KEY } from 'consts';
import { createConsumer } from '@rails/actioncable';
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink';
import { relayStylePagination } from '@apollo/client/utilities';

export const createCache = () => {
  const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          chatMessages: relayStylePagination(),
        },
      },
    },
  })
  if (process.env.NODE_ENV === 'development') {
    window.secretVariableToStoreCache = cache
  }
  return cache
}

const cable = createConsumer();

// getToken from meta tags
const getTokens = () => {
  const tokens = {
    'X-CSRF-Token': document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute('content'),
  }
  const authToken = localStorage.getItem(AUTH_TOKEN_KEY)
  return authToken ? { ...tokens, Authorization: authToken } : tokens
}

const setTokenForOperation = async (operation) =>
  operation.setContext({
    headers: {
      ...getTokens(),
    },
  })

// link with token
const createLinkWithToken = () =>
  new ApolloLink(
    (operation, forward) =>
      new Observable((observer) => {
        let handle
        Promise.resolve(operation)
          .then(setTokenForOperation)
          .then(() => {
            handle = forward(operation).subscribe({
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            })
          })
          .catch(observer.error.bind(observer))
        return () => {
          if (handle) handle.unsubscribe()
        }
      }),
  )

// log erors
// eslint-disable-next-line no-console
const logError = (error) => console.error(error)
// create error link
const createErrorLink = () =>
  onError(({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors) {
      logError('GraphQL - Error', {
        errors: graphQLErrors,
        operationName: operation.operationName,
        variables: operation.variables,
      })
    }
    if (networkError) {
      logError('GraphQL - NetworkError', networkError)
    }
  })

// const host = window.location.origin;

const options = {
  uri: `/graphql`,
  credentials: 'include',
}

const hasSubscriptionOperation = ({ query: { definitions } }) => {
  return definitions.some(
    ({ kind, operation }) => kind === 'OperationDefinition' && operation === 'subscription'
  )
}

const httpLink = from([
  createErrorLink(),
  createLinkWithToken(),
  createUploadLink(options)
])


const link = ApolloLink.split(
  hasSubscriptionOperation,
  new ActionCableLink({ cable }),
  httpLink
);

export const createClient = (cache) =>
  new ApolloClient({
    link,
    cache,
  })
