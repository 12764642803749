import styled from 'styled-components';
import { getColorValue } from 'ai';

const Div = styled.div<{color?: string, css?: string, originPrimaryColor?: boolean}>`
   ${({ color }) => color && `
      color: ${getColorValue(color)};
   `}
   ${({ originPrimaryColor }) => originPrimaryColor && `
      --color-primary: ${getColorValue('primary')};
      --color-primary-hover: ${getColorValue('primaryHover')};
   `}
   ${props => props.css}
`;

export default Div;
