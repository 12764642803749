import styled from 'styled-components';
import { Form } from 'antd';

const FormItem = styled(Form.Item)`
   && {
      margin-bottom: 20px;
      .ant-form-item-label {
         label {
            text-transform: capitalize;
         }
      }
      .ant-form-item-explain-success {
         color: var(--color-green);
      }
      :last-child {
         margin-bottom: 0;
      }
   }
   &.ant-form-item .ant-form-item-label {
      padding-bottom: 6px;
      > label {
         font-size: 15px;
      }
   }
`;

export default FormItem;

export const FormItemUIOnly = styled.div`
   margin-bottom: 20px;
   &:last-child {
      margin-bottom: 0;
   }
   
   .StripeElement {
      box-sizing: border-box;
   }
`;

export const FormItemLabel = styled.label`
   display: block;
   padding-bottom: ${props => props.simple ? 0 : 6}px;
   font-size: 15x;
   line-height: 1.5715;
   white-space: initial;
   text-align: left;
   text-transform: capitalize;
   color: rgba(0, 0, 0, 0.85);
   .ant-form ${FormItemUIOnly} label& {
      font-size: 15px;
   }
   ${props => props.css}
`;

export const FormItemError = styled.div`
   min-height: 24px;
   color: #ff4d4f;
   ${props => props.css}
`;

export const FormItemContent = styled.div`
   ${props => props.css}
`;
