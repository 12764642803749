import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';

export const useTimeDomain = ({
    analyser,
    fftSize = 2048
}) => {

    analyser.fftSize = fftSize;
    const bufferLength = useMemo(() => analyser.frequencyBinCount, [analyser]);
    const _dataArray = useMemo(() => new Uint8Array(bufferLength / 32), [bufferLength]);
    const [dataArray, setDataArray] = useState(null);
    const timeoutRef = useRef(null);
    const getByteTimeDomainData = useCallback(() => {

        const _animationTimeout = setTimeout(() => {
            requestAnimationFrame(getByteTimeDomainData);
        }, 30);
        analyser.getByteTimeDomainData(_dataArray);
        setDataArray([..._dataArray]);
        timeoutRef.current = _animationTimeout;
    }, [_dataArray]);
    useEffect(() => {
        requestAnimationFrame(getByteTimeDomainData);
        return () => {
            cancelAnimationFrame(getByteTimeDomainData);
            clearTimeout(timeoutRef.current);
        };
    }, [analyser]);

    return {
        getByteTimeDomainData,
        dataArray
    }
};

export const TimeDomain = ({ analyser, render: Render }) => {
    const {
        dataArray
    } = useTimeDomain({ analyser });

    return (
        <Render dataArray={dataArray} />
    );
};
