import React, { useState, useMemo } from "react";
import styled, { withTheme } from "styled-components";
import { Typography } from "antd";
import {
  Card,
  Row,
  Col,
  Image,
  A,
  Popover,
  Modal,
  Avatar,
  Dropdown,
  OneCollapse,
  PopoverInner,
  Amount,
  Div,
  Spin,
  RangeSlider,
  message as messageAlert,
} from "atoms";
import { Button } from "antd/lib";
import { formatFromIos } from "utils/Datetime";
import AudioPlayer from "libraries/Audio/components/AudioPlayer";
import { PlayButton, PauseButton, LoadingButton } from "libraries/Media/atoms";
import MessageLikes, { MessageLikesHeader } from "./MessageLikes";
import EllipsisControls, { ShareControl } from "./MessageEllipsisControls";
import AddTip from "./AddTip";
import MessageTips from "./MessageTips";
import { hasTwoDigit } from "utils/Datetime";
import { useMutation } from "@apollo/client";
import { UPDATE_MESSAGE_MUTATION } from "graphql-api";
import { MESSAGE_QUERY } from "apps/Dashboard/graphql";
import { INITIAL, PLAYING } from "libraries/Audio/const";
import { ARCHIVED, ARCHIEVE, PINNED, TRUE } from "consts";
import UserProfile from "./UserProfile";
import { getColorValue } from "ai";
import PaymentMessageControls from "../apps/PublicPage/components/PaymentMessageControls";
import MessageEditor from "./MessageEditor";
import {
  CircleCheck,
  FaFolder,
  FaHeart,
  FaEllipsisV,
  FaShareAlt,
  FaThumbtack,
  FaDollarSign,
  FaReply,
  FaSHeart,
  FaEyeSlash,
  FaTag,
} from "icons";

const { Paragraph } = Typography;

export const [REPLY, SHARE, TAG, PIN, PRIVATE, TIPS] = [
  `REPLY`,
  `SHARE`,
  `TAG`,
  `PIN`,
  `PRIVATE`,
  `TIPS`,
];

const MessageCard = styled(Card)`
  padding-bottom: 0;
  ${(props) => props.simple && `background-color: transparent; border: 0;`}
`;

const MessageContent = styled.div`
  border: 0;
`;

const MessageFooter = styled.footer`
  div {
    box-sizing: border-box;
  }
`;

const Inner = styled.div`
  padding: 10px 15px;

  ${(props) => props.css}
  .ant-typography-expand {
    color: var(--color-primary);

    &:hover {
      color: var(--color-primary-hover);
    }
  }
`;

const UserContainer = styled.div`
  margin-bottom: 15px;
  position: relative;
`;

const AudioContainer = styled.div`
  position: relative;
  width: 100%;
`;

const RangeContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: space-between;
`;

const Title = styled.div`
  font-size: 16px;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  ${(props) => props.css}
`;

const CreatedAt = styled.div`
  display: flex;
  align-items: center;
  color: var(--color-content-grey);
  font-size: 13px;
`;

const ControlInner = styled(Inner)`
  width: 100%;
  min-height: 50px;
  height: 100%;
  padding: 8px 12px;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ControlInnerLeft = styled(ControlInner)`
  width: auto;
  padding-right: 4px;
  border: 0;
  justify-content: right;
`;

const ControlInnerRight = styled(ControlInner)`
  width: auto;
  padding-left: 4px;
  border: 0;
  justify-content: left;
`;

const Control = styled.a`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  cursor: pointer;
  color: var(--color-content-grey);

  :hover {
    color: var(--color-primary);
  }

  ${(props) => props.active && `color: var(--color-primary);`}
  ${(props) =>
    props.hoverGreen &&
    `&:hover, &:active, &:focus {
    color: var(--color-green);
  }`}
`;
const SFaDollarSign = styled(FaDollarSign)`
  :hover,
  :active,
  :focus {
    color: var(--color-green);
  }
`;
const SFaSHeart = styled(FaSHeart)``;
const SFaHeart = styled(FaHeart)``;
const Like = styled(Control)`
  --color-primary: ${getColorValue("primary")};
  --color-primary-hover: ${getColorValue("primaryHover")};
  ${(props) =>
    props.active
      ? `
        ${SFaHeart} {
            display: none;
        }
        ${SFaSHeart} {
            display: block;
        }
        ${ControlInnerLeft} {
            &:hover {
                ${SFaHeart} {
                    display: block;
                }
                ${SFaSHeart} {
                    display: none;
                }
            }
        }
    `
      : `
        ${SFaHeart} {
            display: block;
        }
        ${SFaSHeart} {
            display: none;
        }
        ${ControlInnerLeft}, ${ControlInner} {
            &:hover {
                ${SFaHeart} {
                    display: none;
                }
                ${SFaSHeart} {
                    display: block;
                }
            }
        }
    `}
`;

const SocialLink = styled(A)`
  font-size: 20px;
`;
const LikeCount = styled.span`
  padding-left: 0;
  font-weight: normal;
`;
SocialLink.defaultProps = {
  target: "_blank",
};

const ReplyContainer = styled.div`
  padding: 15px 15px;
  border-top: 1px solid var(--color-border);
  ${(props) => props.css}
`;

const LikesContainer = styled.div``;

const TagList = styled.div`
  padding: 10px 15px;
  max-width: 200px;
`;

const TagItem = styled.div`
  padding: 4px 10px;
  border-radius: 2px;
  background-color: ${(props) => props.color};
  color: white;
  font-size: 12px;
`;

const TotalTipsCents = styled(Amount)`
  font-size: inherit;
`;

const TotalTipsCentsNumber = styled.span`
  font-weight: normal;
`;

const Transcribing = styled.span`
  color: grey;
  margin-left: 15px;
`;
const SContentContainer = styled.div`
  padding: 1em 0 0 15px;
  border: 1px solid var(--color-border);
  border-radius: 4px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  background-color: var(--color-body-background);
  ${(props) => props.css}
`;

const TextControl = styled.a`
  font-size: 16px;
  cursor: pointer;
  color: var(--color-content-grey);
  margin-top: 4px;
  width: 29px;
  flex: 1 0 29px;
`;

const TextControlInner = styled(Inner)`
  width: 100%;
  height: 100%;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 14px;
  border: 0;
`;

const EditorContainer = styled.div`
  padding: 0 0 1em;
  width: 100%;
  flex: 1 0 100%;
  ${(props) => props.css}
`;

const SParagraph = styled(Paragraph)`
  flex: 1 0 calc(100% - 33px);
`;

const Message = ({
  slug,
  message,
  image,
  title,
  titleLink,
  onToggleLike,
  onToggleArchive,
  onToggleDelete,
  onPinToggle,
  onPrivateToggle,
  onReplyToggle,
  onTag,
  controls,
  ellipsisControls,
  replyRecorder,
  repliedMessage,
  simple,
  showPin,
  showTags,
  show,
  isLoggedIn,
  tipsPayment,
  tipsList,
  controlled,
  onSupport,
  shadow,
  createdAtVisible,
  onTranscribe,
  manualTranscribe,
  showTranscription,
  hasOwnDelete,
  theme: { isMobile },
  renderTitle,
  renderLikesModal,
}) => {
  const {
    transcodedAudioUrl,
    createdAt,
    duration,
    id,
    liked,
    likes,
    user,
    status,
    transcodedDownloadAudioUrl,
    pinned,
    tags,
    private: privateMessage,
    reply,
    superfans,
    totalTipsCents,
    transcribedText,
    messageType,
    textContent,
  } = message;
  const [ellipsisControlsVisible, setEllipsisControlsVisible] = useState(false);
  const [isPlayed, setIsPlayed] = useState(false);
  const [audio, setAudio] = useState(null);
  const [playerStatus, setPlayerStatus] = useState(INITIAL);
  const isLoading = useMemo(() => isPlayed && !audio, [isPlayed, audio]);
  const isAdmin = useMemo(() => isLoggedIn && show.viewerIsAdmin, [show]);
  const isShowOwner = useMemo(() => isLoggedIn && show.viewerIsOwner, [show]);
  const [messageControlsVisible, setMessageControlsVisible] = useState(false);
  const [transcriptsControlsVisible, setTranscriptsControlsVisible] =
    useState(false);
  const [messageEditorVisible, setMessageEditorVisible] = useState(false);
  const [transcriptsEditorVisible, setTranscriptsEditorVisible] =
    useState(false);

  const computedTags = useMemo(() => {
    return [...tags.map((t) => ({ ...t, color: `rgb(0, 118, 219)` }))];
  }, [tags]);
  const mm = useMemo(() => {
    const currentSeconds = Math.round(duration);
    return hasTwoDigit(Math.floor(currentSeconds / 60));
  }, [duration]);
  const ss = useMemo(() => {
    const currentSeconds = Math.round(duration);
    return hasTwoDigit(currentSeconds % 60);
  }, [duration]);
  const [tipsPopoverVisible, setTipsPopoverVisible] = useState(false);
  const [likesModalVisible, setLikesModalVisible] = useState(false);

  const shareText = `${window.location.hostname}/${slug}?mid=${message.id}&open=true`;
  const shareCopy = `${window.location.origin}/${slug}?mid=${message.id}&open=true`;
  const imageSrc = useMemo(
    () => (controlled ? image : image || user.avatarUrl),
    [controlled, image, user]
  );

  const [triggerUpdateMessage, { loading: isUpdateBusy }] = useMutation(
    UPDATE_MESSAGE_MUTATION,
    {
      awaitRefetchQueries: true,
      refetchQueries: [{ query: MESSAGE_QUERY, variables: { id: message.id } }],
      onCompleted({ updateMessage: { success } }) {
        if (success === TRUE) {
          setMessageEditorVisible(false);
          setTranscriptsEditorVisible(false);

          messageAlert.success({ content: "Changes Saved" });
        } else {
          messageAlert.error({
            content: "Something went wrong, please try again later.",
          });
        }
      },
      onError() {
        messageAlert.error({
          content: "Something went wrong, please try again later.",
        });
      },
    }
  );

  const onReady = ({ audio: _audio }) => {
    setAudio(_audio);
    if (playerStatus === INITIAL) {
      _audio.play();
    }
  };

  const handlePlayClick = () => {
    if (audio) {
      audio.play();
    } else {
      setIsPlayed(true);
    }
  };

  const handlePauseClick = () => {
    audio.pause();
  };

  const handleToggleLike = () => {
    onToggleLike && onToggleLike();
  };

  const handleToggleArchive = () => {
    onToggleArchive && onToggleArchive();
  };

  const handleEllipsisControlsOpenChange = (e) => {
    setEllipsisControlsVisible(e);
  };
  const handleReplyToggle = () => {
    setEllipsisControlsVisible(false);
    onReplyToggle();
  };

  const handleMessageControlsOpenChange = (e) => {
    setMessageControlsVisible(e);
  };

  const handleUpdateMessageContent = async (payload) => {
    await triggerUpdateMessage({
      variables: { messageId: message.id, ...payload },
    });
  };

  const handleEditorToggle = () => {
    setMessageEditorVisible(!messageEditorVisible);

    setMessageControlsVisible(false);
  };

  const handleTranscriptsControlsOpenChange = (e) => {
    setTranscriptsControlsVisible(e);
  };

  const handleTranscriptsToggle = () => {
    setTranscriptsEditorVisible(!transcriptsEditorVisible);

    setTranscriptsControlsVisible(false);
  };

  const handleTranscribe = () => {
    onTranscribe && onTranscribe();
  };

  return (
    <MessageCard simple={simple} shadow={shadow}>
      <MessageContent>
        <Inner
          css={`
            padding: 15px 15px 10px;
          `}
        >
          <UserContainer>
            <Row singleRow gutter={isMobile ? 10 : 12}>
              <Col>
                {imageSrc ? (
                  <Avatar
                    src={imageSrc}
                    asBackground
                    placeholder={
                      controlled
                        ? null
                        : user.displayName?.slice(0, 1).toUpperCase()
                    }
                    radius={"5px"}
                    width={isMobile ? "40px" : "50px"}
                  />
                ) : (
                  <Image
                    src={imageSrc}
                    asBackground
                    placeholder={
                      controlled
                        ? null
                        : user.displayName?.slice(0, 1).toUpperCase()
                    }
                    radius={"5px"}
                    width={isMobile ? "40px" : "50px"}
                  />
                )}
              </Col>
              <Col flex={"1 0 0"}>
                <Row
                  singleRow
                  align={"middle"}
                  gutter={isMobile ? 10 : 12}
                  justify={"space-between"}
                >
                  <Col minZero>
                    {renderTitle ? (
                      renderTitle({ user, message })
                    ) : (
                      <div>
                        {titleLink && (
                          <A
                            href={titleLink}
                            target={`_blank`}
                            color={"content"}
                          >
                            <Title css={isMobile ? `font-size: 14px;` : ``}>
                              {title || `${user.displayName}`}{" "}
                              {user.isMembership && (
                                <CircleCheck color="#0092ff" />
                              )}
                            </Title>
                          </A>
                        )}
                        {!titleLink && (
                          <Popover
                            placement={"top"}
                            trigger={["click"]}
                            content={<UserProfile user={user} />}
                            simple
                          >
                            <A>
                              <Title css={isMobile ? `font-size: 14px;` : ``}>
                                {title || `${user.displayName}`}{" "}
                                {user.isMembership && (
                                  <CircleCheck color="#0092ff" />
                                )}
                              </Title>
                            </A>
                          </Popover>
                        )}
                      </div>
                    )}
                  </Col>
                  {createdAtVisible && (
                    <Col>
                      <CreatedAt>{formatFromIos(createdAt)}</CreatedAt>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </UserContainer>
        </Inner>
        {messageType === "audio" && (
          <Inner
            css={`
              padding: 0 15px 10px;
              margin-top: -15px;
            `}
          >
            <AudioContainer>
              <Row singleRow gutter={10}>
                <Col>
                  <PlayButton
                    loading={isLoading}
                    playing={playerStatus === PLAYING}
                    onClick={() => playerStatus === PLAYING ? handlePauseClick() : handlePlayClick()}
                  />
                </Col>
                <Col flex={"1 0 0"}>
                  {isPlayed && (
                    <AudioPlayer
                      src={transcodedAudioUrl}
                      duration={Math.round(duration)}
                      onReady={onReady}
                      onChangeStatus={(status) => setPlayerStatus(status)}
                      singleRow
                    />
                  )}
                  {!isPlayed && (
                    <RangeContainer>
                      <Div
                        css={`
                          flex: 1 0 calc(100% - 45px);
                        `}
                      >
                        <RangeSlider
                          defaultValue={0}
                          value={0}
                          tooltip={{ open: false }}
                        />
                      </Div>
                      <Div
                        css={`
                          flex: 1 0 40px;
                        `}
                      >
                        {mm}:{ss}
                      </Div>
                    </RangeContainer>
                  )}
                </Col>
              </Row>
            </AudioContainer>
          </Inner>
        )}
        {messageType === "audio" &&
          showTranscription &&
          !message.transcribedText &&
          manualTranscribe && (
            <Inner
              css={`
                padding: 0 15px;
                margin-top: -8px;
              `}
            >
              <SContentContainer
                css={`
                  padding-bottom: 1em;
                  justify-content: start;
                `}
              >
                {message.transcribing || message.transcribingIntended ? (
                  <>
                    <Spin /> <Transcribing>transcribing</Transcribing>
                  </>
                ) : (
                  <A onClick={handleTranscribe}>transcribe</A>
                )}
              </SContentContainer>
            </Inner>
          )}
        {messageType === "audio" && showTranscription && transcribedText && (
          <Inner
            css={`
              padding: 0 15px;
              margin-top: -8px;
            `}
          >
            <SContentContainer
              css={
                transcriptsEditorVisible ||
                  !isLoggedIn ||
                  !(message.isSender || !!isShowOwner || !!isAdmin)
                  ? `padding-right: 15px;`
                  : ``
              }
            >
              {transcriptsEditorVisible ? (
                <EditorContainer>
                  <OneCollapse open={transcriptsEditorVisible}>
                    <MessageEditor
                      onCancel={handleTranscriptsToggle}
                      onUpdate={handleUpdateMessageContent}
                      message={transcribedText}
                      loading={isUpdateBusy}
                      text={"edit"}
                      required
                    />
                  </OneCollapse>
                </EditorContainer>
              ) : (
                <>
                  <SParagraph
                    ellipsis={{ rows: 3, expandable: true, symbol: "more" }}
                  >
                    {transcribedText}
                  </SParagraph>
                  {isLoggedIn &&
                    (message.isSender || !!isShowOwner || !!isAdmin) && (
                      <Dropdown
                        trigger={["click"]}
                        open={transcriptsControlsVisible}
                        arrow
                        onOpenChange={handleTranscriptsControlsOpenChange}
                        key={"TRANSCRIPTS"}
                        overlay={
                          <PaymentMessageControls
                            controls={["EDIT"]}
                            onUpdate={handleTranscriptsToggle}
                            text={"transcripts"}
                          />
                        }
                      >
                        <TextControl>
                          <TextControlInner>
                            <FaEllipsisV />
                          </TextControlInner>
                        </TextControl>
                      </Dropdown>
                    )}
                </>
              )}
            </SContentContainer>
          </Inner>
        )}
        {messageType === "text" && textContent && (
          <Inner
            css={`
              padding: 0 15px;
              margin-top: -5px;
            `}
          >
            <SContentContainer
              css={
                messageEditorVisible ||
                  !isLoggedIn ||
                  !(message.isSender || !!isShowOwner || !!isAdmin)
                  ? `padding-right: 15px;`
                  : ``
              }
            >
              {messageEditorVisible ? (
                <EditorContainer>
                  <OneCollapse open={messageEditorVisible}>
                    <MessageEditor
                      onCancel={handleEditorToggle}
                      onUpdate={handleUpdateMessageContent}
                      message={textContent}
                      loading={isUpdateBusy}
                      text={"edit"}
                      required
                    />
                  </OneCollapse>
                </EditorContainer>
              ) : (
                <>
                  <SParagraph
                    ellipsis={{ rows: 3, expandable: true, symbol: "more" }}
                  >
                    {textContent}
                  </SParagraph>
                  {isLoggedIn &&
                    (message.isSender || !!isShowOwner || !!isAdmin) && (
                      <Dropdown
                        trigger={["click"]}
                        open={messageControlsVisible}
                        arrow
                        onOpenChange={handleMessageControlsOpenChange}
                        key={"MESSAGE"}
                        overlay={
                          <PaymentMessageControls
                            controls={["EDIT"]}
                            onUpdate={handleEditorToggle}
                          />
                        }
                      >
                        <TextControl>
                          <TextControlInner>
                            <FaEllipsisV />
                          </TextControlInner>
                        </TextControl>
                      </Dropdown>
                    )}
                </>
              )}
            </SContentContainer>
          </Inner>
        )}
      </MessageContent>
      <MessageFooter>
        <Row
          singleRow
          css={`
            padding: 0 15px 0 5px;
          `}
          align="middle"
        >
          {showPin && pinned && (
            <Col key={PINNED}>
              <Control>
                <ControlInner>
                  <FaThumbtack />
                </ControlInner>
              </Control>
            </Col>
          )}
          {privateMessage && (
            <Col key={PRIVATE}>
              <Control>
                <ControlInner>
                  <FaEyeSlash />
                </ControlInner>
              </Control>
            </Col>
          )}
          {showTags && computedTags.length > 0 && (
            <Col key={"TAG"}>
              <Control>
                <ControlInner>
                  <Popover
                    placement={"top"}
                    trigger={["click"]}
                    content={
                      <TagList>
                        <Row gutter={[10, 10]}>
                          {computedTags.map((tag) => (
                            <Col key={tag.id}>
                              <TagItem color={tag.color}>{tag.name}</TagItem>
                            </Col>
                          ))}
                        </Row>
                      </TagList>
                    }
                    simple
                  >
                    <FaTag />
                  </Popover>
                </ControlInner>
              </Control>
            </Col>
          )}
          {controls.map((control) => {
            switch (control) {
              case ARCHIEVE:
                return (
                  <Col key={ARCHIEVE}>
                    <Control
                      active={status === ARCHIVED}
                      onClick={handleToggleArchive}
                    >
                      <ControlInner>
                        <FaFolder />
                      </ControlInner>
                    </Control>
                  </Col>
                );
              case SHARE:
                return (
                  <Col key={SHARE}>
                    <ShareControl text={shareText} copy={shareCopy}>
                      <Control>
                        <ControlInner>
                          <FaShareAlt />
                        </ControlInner>
                      </Control>
                    </ShareControl>
                  </Col>
                );
            }
          })}
          <Col flex={"auto"}>
            <ControlInner />
          </Col>
          {controls.includes(REPLY) && (
            <Col>
              <Control onClick={() => onReplyToggle()}>
                <ControlInner>
                  {reply ? (
                    <></>
                  ) : (
                    <>
                      <FaReply />
                    </>
                  )}
                </ControlInner>
              </Control>
            </Col>
          )}
          {controls.includes(TIPS) && tipsPayment ? (
            <Col>
              <Popover
                trigger={["click"]}
                open={tipsPopoverVisible}
                arrow
                onOpenChange={(v) => setTipsPopoverVisible(v)}
                placement="bottom"
                simple
                content={
                  <>
                    <PopoverInner>
                      <AddTip
                        show={show}
                        messageId={id}
                        isLoggedIn={isLoggedIn}
                        onSelect={() => setTipsPopoverVisible(false)}
                        onSupport={onSupport}
                      />
                    </PopoverInner>

                    {tipsList && superfans.length > 0 && (
                      <PopoverInner>
                        <MessageTips superfans={superfans} />
                      </PopoverInner>
                    )}
                  </>
                }
              >
                <Control hoverGreen>
                  <ControlInner>
                    {totalTipsCents > 0 ? (
                      <TotalTipsCents color="green">
                        <TotalTipsCentsNumber>
                          ${Math.round(totalTipsCents / 100)}
                        </TotalTipsCentsNumber>
                      </TotalTipsCents>
                    ) : (
                      <SFaDollarSign />
                    )}
                  </ControlInner>
                </Control>
              </Popover>
            </Col>
          ) : totalTipsCents > 0 ? (
            <Col>
              <Control>
                <ControlInner>
                  <TotalTipsCents color="green">
                    <TotalTipsCentsNumber>
                      ${Math.round(totalTipsCents / 100)}
                    </TotalTipsCentsNumber>
                  </TotalTipsCents>
                </ControlInner>
              </Control>
            </Col>
          ) : (
            ""
          )}
          <Col>
            <Like active={liked} target="_self">
              {likes > 0 ? (
                <>
                  <ControlInnerLeft onClick={handleToggleLike}>
                    <SFaSHeart />
                    <SFaHeart />
                  </ControlInnerLeft>
                  <ControlInnerRight onClick={() => setLikesModalVisible(true)}>
                    <LikeCount>{likes}</LikeCount>
                  </ControlInnerRight>
                </>
              ) : (
                <ControlInner onClick={handleToggleLike}>
                  <SFaSHeart />
                  <SFaHeart />
                </ControlInner>
              )}
            </Like>
          </Col>
          {ellipsisControls.length > 0 && (
            <>
              <Col>
                <Dropdown
                  trigger={["click"]}
                  open={ellipsisControlsVisible}
                  arrow
                  onOpenChange={handleEllipsisControlsOpenChange}
                  dropdownRender={(menus) => (
                    <EllipsisControls
                        message={message}
                        slug={slug}
                        controls={ellipsisControls}
                        onPinToggle={onPinToggle}
                        onPrivateToggle={onPrivateToggle}
                        onReply={handleReplyToggle}
                        onDelete={onToggleDelete}
                        onTag={onTag}
                        transcodedDownloadAudioUrl={transcodedDownloadAudioUrl}
                        messageType={messageType}
                        hasOwnDelete={hasOwnDelete}
                        show={show}
                        showTags={showTags}
                      />
                  )}
                >
                  <Button icon={<FaEllipsisV />} type="text" />
                </Dropdown>
              </Col>
            </>
          )}
        </Row>
      </MessageFooter>
      <OneCollapse open={!!replyRecorder}>
        <ReplyContainer>{replyRecorder}</ReplyContainer>
      </OneCollapse>
      <OneCollapse open={!!repliedMessage}>
        <ReplyContainer>{repliedMessage}</ReplyContainer>
      </OneCollapse>
      {likesModalVisible &&
        (renderLikesModal ? (
          renderLikesModal({
            open: likesModalVisible,
            setOpen: setLikesModalVisible,
            liked,
            likes,
            message,
          })
        ) : (
          <Modal
            open={likesModalVisible}
            onCancel={() => setLikesModalVisible(false)}
            width={400}
            title={
              <MessageLikesHeader
                liked={liked}
                likes={likes}
                onToggle={handleToggleLike}
              />
            }
          >
            <LikesContainer>
              <MessageLikes message={message} center />
            </LikesContainer>
          </Modal>
        ))}
    </MessageCard>
  );
};

Message.defaultProps = {
  repliedMessage: null,
  hideSocial: false,
  reply: null,
  controls: [],
  ellipsisControls: [],
  simple: false,
  showPin: true,
  showTags: true,
  show: null,
  isLoggedIn: false,
  tipsPayment: true,
  tipsList: true,
  controlled: false,
  shadow: false,
  createdAtVisible: true,
  manualTranscribe: true,
  showTranscription: true,
  hasOwnDelete: false,
};

export default withTheme(Message);
