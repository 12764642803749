import React from 'react';
import styled from 'styled-components';
import { NavLink as PureLink } from 'react-router-dom';

const Link = styled(({ primary, block, ...props }) => <PureLink {...props} />)`
   ${props => props.block && `
      display: block;
   `}
   color: ${props => props.primary ? 'var(--color-primary)' : '#686868'};
   :hover {
      color: ${props => props.primary ? 'var(--color-primary-hover)' : 'var(--color-primary)'};
   }
   ${props => props.css}
`;

export default Link;
