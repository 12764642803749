import React, { useEffect, useState } from "react";

export const Delayed = (props: { children: React.ReactNode, timeout?: number }) => {
  const { timeout = 50, children } = props;
  const [ready, setReady] = useState(false);

  useEffect(() => {
    const t = setTimeout(() => {
      setReady(true)
    }, timeout);
    return () => {
      clearTimeout(t);
    }
  })

  if(!ready) {
    return null;
  }
  return children;
}
