import React from 'react';
import { Button } from 'atoms';
import { FaComments, CloseIcon } from 'icons';
import styled, { keyframes, withTheme } from 'styled-components';
import { Theme } from 'types';

const SChatButton = styled(Button)`
  min-height: ${ props => props.theme.isMobile ? 70 : 54 }px;
  min-width: ${ props => props.theme.isMobile ? 70 : 54 }px;
  border-radius: ${ props => props.theme.isMobile ? 50 : 30 }px;
  font-size: ${ props => props.theme.isMobile ? 20 : 14 }px;
  letter-spacing: 0;
  padding-left: 15px;
  padding-right: 15px;

  &.ant-btn-icon-only {
    display: flex;
    padding: 15px;
    min-width: 54px;
    min-height: 54px;

    span {
      margin-left: 0;
    }
  }

  svg {
    font-size: 1.2em;
  }
`;
const enterRotate = keyframes`
  from {
    transform: rotate(20deg);
  }

  to {
    transform: rotate(0deg);
  }
`;

const CloseIconContainer = styled.div`
  line-height: 0;
`;
const SCloseIcon = styled(CloseIcon)`
  animation: ${ enterRotate } .3s ease-out;
`;

type Props = {
  closed: boolean,
  theme: Theme
}

const ChatButton = (props: Props) => {

  const {closed, theme: {isMobile}, ...baseProps} = props;

  return (
    <SChatButton icon={ closed ? <CloseIconContainer><SCloseIcon/></CloseIconContainer> :
      <FaComments/> } { ...baseProps }>{ (closed || isMobile) ? '' : 'CHAT ROOM' }</SChatButton>
  )
}

ChatButton.defaultProps = {
  closed: false
}

export default withTheme(ChatButton);
