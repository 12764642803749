import { message } from "atoms";
import React from "react";
import styled from "styled-components";

const SCopyClipboard = styled.div`
  background: #f9f9f9;
  border: 1px solid #f4f4f4;
  border-radius: 3px;
  padding: 10px 15px;
  cursor: pointer;
  &:hover {
    background: #f4f4f4;
  }
`;

type Props = {
  children: React.ReactNode,
  copyContent: string
}
const CopyClipboard = (props: Props) => {
  const { copyContent } = props;
  const handleCopy = async () => {
    try {
        await navigator.clipboard.writeText(copyContent);
        message.success({ content: `Copied` });
    } catch (e) {
        message.error("Something went wrong, please try later");
    }
  }
  return (
    <SCopyClipboard onClick={handleCopy}>{props.children}</SCopyClipboard>
  )
}

export default CopyClipboard;
