import React, {
  useMemo,
  useState,
  useCallback,
} from "react";
import { withPublicPageState } from "apps/PublicPage/context";

import { useParams, useHistory } from "react-router-dom";
import { useURLQuery } from "hooks";

import {
  Modal,
  Row,
  Col,
  Portal,
} from "atoms";
import { AccountLockedModal } from "components/AccountLocked";
import Message from "../components/Message";
import MessageLikes, { MessageLikesContainer } from "components/MessageLikes";
import { CANCELED, LOGIN, SIGNUP } from "consts";
import MessageTips, { TippersContainer, Tippers } from "components/MessageTips";
import { CSSTransitionGroup } from "react-transition-group";
import ShowChat, { ShowChatContainer } from "libraries/Chat/ShowChat";
import ChatButton from "libraries/Chat/ChatButton";
import {
  SShow,
  MessageContainer,
  ChatButtonContainer,
} from "../components/FanPage";
import FanPageHeader from "../containers/FanPageHeader";
import FanPageMain from "../containers/FanPageMain";

const FanPage = ({
  show,
  refetchShowQuery,
  publicPageState: { openAuthModal, me, isLoggedIn },
}) => {
  const { slug } = useParams();

  const [accountLockedVisible, setAccountLockedVisible] = useState(false);

  const { mid = null, open = false } = useURLQuery();
  const modalVisible = useMemo(() => false && open === "true", [open]);
  const modalMessage = useMemo(
    () => null, // needs to corrected
    [mid]
  );
  const bannerImage = useMemo(() => show?.bannerImageUrl || null, [show]);

  const isCanceled = useMemo(() => show?.status === CANCELED, [show]);
  const history = useHistory();
  const [showChatVisibile, setShowChatVisibile] = useState(false);

  const popupLogin = useCallback(() => {
    openAuthModal(LOGIN);
  }, [openAuthModal]);

  const popupSignUp = useCallback(() => {
    openAuthModal(SIGNUP);
  }, [openAuthModal]);

  const handleCloseModal = () => {
    history.push(history.location.pathname);
  };

  return (
    <SShow hasBanner={!!bannerImage}>
      <div style={{ marginBottom: '30px' }}>
        <FanPageHeader show={show} />
      </div>
      {!isCanceled && show.active && (
        <FanPageMain />
      )}
      <AccountLockedModal
        open={accountLockedVisible}
        onCancel={() => setAccountLockedVisible(false)}
      />
      {modalMessage && modalVisible && (
        <Modal
          open={modalVisible}
          noBodyPadding
          width={700}
          overflowHidden
          onCancel={handleCloseModal}
        >
          <Row>
            <Col flex={`1 0 300px`}>
              <MessageContainer>
                <Message
                  message={modalMessage}
                  slug={slug}
                  initialRepliedMessageVisible
                />
              </MessageContainer>
            </Col>
            <Col flex={`1 0 300px`}>
              <MessageLikesContainer>
                {modalMessage.superfans.length > 0 && (
                  <TippersContainer>
                    <Tippers>Tippers</Tippers>
                    <MessageTips superfans={modalMessage.superfans} />
                  </TippersContainer>
                )}
                <MessageLikes message={modalMessage} />
              </MessageLikesContainer>
            </Col>
          </Row>
        </Modal>
      )}
      {show.chatroomEnabled && show.active && (
        <Portal>
          <ChatButtonContainer>
            <ChatButton
              onClick={() => setShowChatVisibile(!showChatVisibile)}
              closed={showChatVisibile}
            />
          </ChatButtonContainer>
          <CSSTransitionGroup
            transitionName="box"
            transitionLeave={true}
            transitionEnter={false}
            transitionLeaveTimeout={300}
          >
            {showChatVisibile && (
              <ShowChatContainer inPaywall={!show?.viewerHasChatAccess && show?.chatRoomForMembers}>
                <ShowChat
                  show={show}
                  refetchShowQuery={refetchShowQuery}
                  me={me}
                  onLogin={popupLogin}
                  onSignUp={popupSignUp}
                />
              </ShowChatContainer>
            )}
          </CSSTransitionGroup>
        </Portal>
      )}
    </SShow>
  );
};

export default withPublicPageState(FanPage);
