import { ReactElement, useCallback, useMemo, useEffect, useRef, useState } from "react";
import { message } from "atoms";

export type ReactMediaRecorderRenderProps = {
    error: string;
    muteAudio: () => void;
    unMuteAudio: () => void;
    startRecording: () => void;
    pauseRecording: () => void;
    resumeRecording: () => void;
    stopRecording: () => void;
    mediaBlobUrl: null | string;
    status: StatusMessages;
    isAudioMuted: boolean;
    previewStream: MediaStream | null;
    clearBlobUrl: () => void;
    getMediaStream: () => Promise;
    stopStream: () => void;
    stream: MediaStream | null;
};

export type ReactMediaRecorderHookProps = {
    audio?: boolean | MediaTrackConstraints;
    video?: boolean | MediaTrackConstraints;
    screen?: boolean;
    onStop?: (blobUrl: string, blob: Blob) => void;
    blobPropertyBag?: BlobPropertyBag;
    mediaRecorderOptions?: MediaRecorderOptions | null;
    active?: boolean
};
export type ReactMediaRecorderProps = ReactMediaRecorderHookProps & {
    render: (props: ReactMediaRecorderRenderProps) => ReactElement;
};

export type StatusMessages =
    | "media_aborted"
    | "permission_denied"
    | "no_specified_media_found"
    | "media_in_use"
    | "invalid_media_constraints"
    | "no_constraints"
    | "recorder_error"
    | "idle"
    | "acquiring_media"
    | "delayed_start"
    | "recording"
    | "stopping"
    | "stopped";

export enum RecorderErrors {
    AbortError = "media_aborted",
    NotAllowedError = "permission_denied",
    NotFoundError = "no_specified_media_found",
    NotReadableError = "media_in_use",
    OverconstrainedError = "invalid_media_constraints",
    TypeError = "no_constraints",
    NONE = "",
    NO_RECORDER = "recorder_error",
}

export function useReactMediaRecorder({
    audio = true,
    video = false,
    onStop = () => null,
    blobPropertyBag,
    screen = false,
    mediaRecorderOptions = null,
    active = false
}: ReactMediaRecorderHookProps): ReactMediaRecorderRenderProps {
    const mediaRecorder = useRef<MediaRecorder | null>(null);
    const mediaChunks = useRef<Blob[]>([]);
    const mediaStream = useRef<MediaStream | null>(null);
    const [status, setStatus] = useState<StatusMessages>("idle");
    const [isAudioMuted, setIsAudioMuted] = useState<boolean>(false);
    const [mediaBlobUrl, setMediaBlobUrl] = useState<string | null>(null);
    const [error, setError] = useState<keyof typeof RecorderErrors>("NONE");

    const getMediaStream = useCallback(async () => {
        setStatus("acquiring_media");
        const requiredMedia: MediaStreamConstraints = {
            audio: typeof audio === "boolean" ? !!audio : audio,
            video: typeof video === "boolean" ? !!video : video,
        };
        try {
            if (screen) {
                //@ts-ignore
                const stream = (await window.navigator.mediaDevices.getDisplayMedia({
                    video: video || true,
                })) as MediaStream;
                if (audio) {
                    const audioStream = await window.navigator.mediaDevices.getUserMedia({
                        audio,
                    });

                    audioStream
                        .getAudioTracks()
                        .forEach((audioTrack) => stream.addTrack(audioTrack));
                }
                mediaStream.current = stream;
                setError("NONE");
            } else {
                console.log('current stream', mediaStream.current?.id);
                const stream = await window.navigator.mediaDevices.getUserMedia(
                    requiredMedia
                );
                mediaStream.current = stream;
                console.log('created stream', stream.id);
                setError("NONE");
                // console.log('create stream with', requiredMedia);
            }
            setStatus("idle");
        } catch (error) {
            setError(error.name);
            setStatus("idle");
        }
    }, [audio, video, screen]);

    useEffect(() => {

        if (!window.MediaRecorder) {
            setError('NotAllowedError');
            return () => { };
        }

        if (screen) {
            //@ts-ignore
            if (!window.navigator.mediaDevices.getDisplayMedia) {
                throw new Error("This browser doesn't support screen capturing");
            }
        }

        const checkConstraints = (mediaType: MediaTrackConstraints) => {
            const supportedMediaConstraints = navigator.mediaDevices.getSupportedConstraints();
            const unSupportedConstraints = Object.keys(mediaType).filter(
                (constraint) =>
                    !(supportedMediaConstraints as { [key: string]: any })[constraint]
            );

            if (unSupportedConstraints.length > 0) {
                console.error(
                    `The constraints ${unSupportedConstraints.join(
                        ","
                    )} doesn't support on this browser. Please check your ReactMediaRecorder component.`
                );
            }
        };

        if (typeof audio === "object") {
            checkConstraints(audio);
        }
        if (typeof video === "object") {
            checkConstraints(video);
        }

        if (mediaRecorderOptions && mediaRecorderOptions.mimeType) {
            if (!MediaRecorder.isTypeSupported(mediaRecorderOptions.mimeType)) {
                console.error(
                    `The specified MIME type you supplied for MediaRecorder doesn't support this browser`
                );
            }
        }

        if (!mediaStream.current && active) {
            getMediaStream();
        }
        return () => {
            stopStream();
        }
    }, [audio, screen, video, getMediaStream, mediaRecorderOptions, active]);

    // stop tracks on ummount

    useEffect(() => {
        return () => {
            stopStream();
        }
    }, []);

    // Media Recorder Handlers

    const startRecording = async () => {
        setError("NONE");
        if (!mediaStream.current) {
            await getMediaStream();
        }
        if (mediaStream.current) {
            const isStreamEnded = mediaStream.current
                .getTracks()
                .some((track) => track.readyState === "ended");
            if (isStreamEnded) {
                await getMediaStream();
            }
            mediaRecorder.current = new MediaRecorder(mediaStream.current);
            mediaRecorder.current.ondataavailable = onRecordingActive;
            mediaRecorder.current.onstop = onRecordingStop;
            mediaRecorder.current.onerror = () => {
                setError("NO_RECORDER");
                setStatus("idle");
            };
            mediaRecorder.current.start();
            setStatus("recording");
        }
    };

    const onRecordingActive = ({ data }: BlobEvent) => {
        mediaChunks.current.push(data);
    };

    const onRecordingStop = () => {
        const [chunk] = mediaChunks.current;
        const blobProperty: BlobPropertyBag = Object.assign(
            { type: chunk.type },
            blobPropertyBag || (video ? { type: "video/mp4" } : { type: "audio/wav" })
        );
        const blob = new Blob(mediaChunks.current, blobProperty);
        const url = URL.createObjectURL(blob);
        setStatus("stopped");
        setMediaBlobUrl(url);
        onStop(url, blob);
    };

    const muteAudio = (mute: boolean) => {
        setIsAudioMuted(mute);
        if (mediaStream.current) {
            mediaStream.current
                .getAudioTracks()
                .forEach((audioTrack) => (audioTrack.enabled = !mute));
        }
    };

    const pauseRecording = () => {
        if (mediaRecorder.current && mediaRecorder.current.state === "recording") {
            mediaRecorder.current.pause();
        }
    };
    const resumeRecording = () => {
        if (mediaRecorder.current && mediaRecorder.current.state === "paused") {
            mediaRecorder.current.resume();
        }
    };

    const stopRecording = () => {
        if (mediaRecorder.current) {
            if (mediaRecorder.current.state !== "inactive") {
                setStatus("stopping");
                mediaRecorder.current.stop();
                stopStream();
                mediaChunks.current = [];
            }
        }
    };

    const stopStream = () => {
        console.log('stopped stream', mediaStream.current?.id);
        mediaStream.current &&
            mediaStream.current.getTracks().forEach((track) => track.stop());
        mediaStream.current = null;
    }

    const previewStream = useMemo(() => {
        return mediaStream.current
            ? new MediaStream(mediaStream.current.getVideoTracks())
            : null
    }, [mediaStream.current]);
    useEffect(() => {
        try {
            let permissionStatus: PermissionStatus = null;
            const handleChangePermission = (event) => {
                console.log("Permission changed to ", event.target.state);
                if (event.target.state === 'granted') {
                    setError("NONE");
                } else if (event.target.state === 'denied') {
                    setError("NotAllowedError");
                }
            }
            navigator.permissions.query(
                { name: 'microphone' as PermissionName }
            ).then(function (ps) {
                permissionStatus = ps;
                console.log('Current Permission', permissionStatus.state); // granted, denied, prompt
                permissionStatus.addEventListener('change', handleChangePermission);

            })
            return () => {
                permissionStatus && permissionStatus.removeEventListener('change', handleChangePermission);
            }
        } catch (e) {
            console.log('permission watch does not support');
        }
    }, [])
    return {
        error: RecorderErrors[error],
        muteAudio: () => muteAudio(true),
        unMuteAudio: () => muteAudio(false),
        startRecording,
        pauseRecording,
        resumeRecording,
        stopRecording,
        mediaBlobUrl,
        status,
        isAudioMuted,
        previewStream,
        clearBlobUrl: () => setMediaBlobUrl(null),
        getMediaStream,
        stopStream,
        stream: mediaStream.current
    };
}

export const ReactMediaRecorder = (props: ReactMediaRecorderProps) =>
    props.render(useReactMediaRecorder(props));
