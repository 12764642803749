import React from 'react';
import styled from "styled-components";
import { Steps } from "antd";

const PodSteps = styled(({simple, ...props}) => <Steps {...props} />)`
  ${props => props.simple && `
    &.ant-steps-horizontal:not(.ant-steps-label-vertical) {
      .ant-steps-item {
        padding-left: 0;
        .ant-steps-item-container {
          .ant-steps-item-icon {
            margin-right: 0;
            font-size: 16px;
            background-color: #fff;
            border-color: rgba(0, 0, 0, 0.09);

            .ant-steps-icon {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 30px;
              height: 30px;

              svg {
                height: 16px;
              }
            }
          }

          .ant-steps-item-content {
            .ant-steps-item-title {
              padding-right: 0;
              font-size: 16px;
            }
          }
        }

        &.ant-steps-item-process {
          > .ant-steps-item-container {
            > .ant-steps-item-icon {
              background-color: var(--color-primary);
            }
          }
        }

        &.ant-steps-item-finish {
          > .ant-steps-item-container {
            > .ant-steps-item-icon {
              border: 1px solid var(--color-primary);
            }

            > .ant-steps-item-tail {
              background-color: var(--color-primary);
            }
          }
        }
      }
    }
    
    &.ant-steps-vertical {
      flex-direction: row;

      .ant-steps-item {
        > .ant-steps-item-container {
          > .ant-steps-item-tail {
            top: 15px;
            height: 1px;
            padding: 0px 28px 0px 6px;
            inset-inline-start: 31px;
            background-color: rgba(0, 0, 0, 0.06);
          }
        }

        &.ant-steps-item-finish {
          > .ant-steps-item-container {
            > .ant-steps-item-icon {
              border: 1px solid var(--color-primary);
              height: 32px;

              .ant-steps-icon {
                font-size: 14px;
                line-height: 14px;
              }
            }

            > .ant-steps-item-tail {
              background-color: var(--color-primary);
            }
          }
        }
      }

      .ant-steps-item-icon {
        span.ant-steps-icon {
          top: -1px;
        }
      }
    }
  `}
`;

export const Step = styled(Steps.Step)``;

export default PodSteps;