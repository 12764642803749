import {
  Card,
  CardDescription,
  CardImage,
  CardInner,
  CardTitle,
  Paragraph
} from "atoms";
import React, { useState, useMemo } from "react";
import { SponsorType } from "types";
import { FaExtraLinkAlt } from 'icons';
import { CardPlayer } from "libraries/Audio/components/AudioRecord/components";
import styled, { withTheme } from 'styled-components';

const SA = styled.a`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SCardTitle = styled(CardTitle)`
  color: #23282f;
  flex: 1 0 auto;
  text-transform: none;

  :hover {
    color: var(--color-primary-hover);
  }
`;

const SCardPlayer = styled.div`
  margin-top: -40px;
  margin-bottom: -15px;
`;

type PropsType = {
  sponsor: SponsorType;
  theme;
};

const SponsorCard = (props: PropsType): JSX.Element => {
  const {
    sponsor,
    theme: {isMobile}
  } = props;

  const [descriptionExpanded, setDescriptionExpanded] = useState(false);
  const fullUrlLink = useMemo(() =>
      sponsor?.fullUrlLink ? (
          (sponsor?.fullUrlLink?.startsWith('http') && sponsor?.fullUrlLink?.includes('://'))
            ? sponsor?.fullUrlLink
            : sponsor?.fullUrlLink?.startsWith('://') ?
              `https${sponsor?.fullUrlLink}`
              : `https://${sponsor?.fullUrlLink}`
        )
        : null,
    [sponsor]
  );

  return (
    <Card simple shadow css="height: 100%">
      {
        fullUrlLink ? (
          <a href={fullUrlLink} target="_blank">
            <CardImage image={sponsor?.sponsorImageUrl} aspectRatio={2.5}/>
          </a>
        ) : (
          <CardImage image={sponsor?.sponsorImageUrl} aspectRatio={2.5}/>
        )
      }
      {
        sponsor?.sponsorAudioUrl && (
          <SCardPlayer>
            <CardPlayer
              src={sponsor?.sponsorAudioUrl}
              duration={sponsor?.sponsorAudioDuration}
            />
          </SCardPlayer>
        )
      }
      <CardInner thin>
        {
          fullUrlLink ? (
            <SA href={fullUrlLink} target="_blank">
              <SCardTitle>{sponsor?.name}</SCardTitle>
              <FaExtraLinkAlt css={`vertical-align: middle;`}/>
            </SA>
          ) : (
            <CardTitle css="text-transform: none;">{sponsor?.name}</CardTitle>
          )
        }
        <CardDescription
          css="margin-bottom: 10px"
          ignoreLineBreak={!descriptionExpanded}
        >
          <Paragraph
            ellipsis={{
              rows: 3,
              expandable: true,
              symbol: "read more",
              onExpand: () => setDescriptionExpanded(true),
            }}
            className="mb-0"
            css={`color: #777;`}
          >
            {sponsor?.description}
          </Paragraph>
        </CardDescription>
      </CardInner>
    </Card>
  );
};

export default withTheme(SponsorCard);
