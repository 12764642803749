import { gql } from "@apollo/client";

export const sponsorFields = gql`
  fragment sponsorFields on Sponsor {
    id
    name
    description
    fullUrlLink
    priority
    active
    sponsorImageUrl
    sponsorAudioUrl
    sponsorAudioDuration
  }
`;

export const SPONSORS_QUERY = gql`
  query sponsors {
    sponsors {
      ...sponsorFields
    }
  }
  ${sponsorFields}
`;

export const CREATE_SPONSOR_MUTATION = gql`
  mutation createSponsor(
    $name: String!
    $description: String
    $fullUrlLink: String
    $sponsorImage: Upload
    $sponsorImageRemoteUrl: String
    $sponsorAudio: Upload
    $sponsorAudioRemoteUrl: String
  ) {
    createSponsor(
      name: $name
      description: $description
      fullUrlLink: $fullUrlLink
      sponsorImage: $sponsorImage
      sponsorImageRemoteUrl: $sponsorImageRemoteUrl
      sponsorAudio: $sponsorAudio
      sponsorAudioRemoteUrl: $sponsorAudioRemoteUrl
    ) {
      success
      error
      sponsor {
        ...sponsorFields
      }
    }
  }
  ${sponsorFields}
`;

export const UPDATE_SPONSOR_MUTATION = gql`
  mutation updateSponsor(
    $sponsorId: ID!
    $name: String!
    $description: String
    $fullUrlLink: String
    $sponsorImage: Upload
    $sponsorAudio: Upload
  ) {
    updateSponsor(
      sponsorId: $sponsorId
      name: $name
      description: $description
      fullUrlLink: $fullUrlLink
      sponsorImage: $sponsorImage
      sponsorAudio: $sponsorAudio
    ) {
      success
      error
      sponsor {
        ...sponsorFields
      }
    }
  }
  ${sponsorFields}
`;

export const DELETE_SPONSOR_AUDIO_MUTATION = gql`
  mutation deleteSponsorAudio($sponsorId: ID!) {
    deleteSponsorAudio(sponsorId: $sponsorId) {
      success
      error
      sponsor {
        ...sponsorFields
      }
    }
  }
  ${sponsorFields}
`;

export const DELETE_SPONSOR_MUTATION = gql`
  mutation deleteSponsor($sponsorId: ID!) {
    deleteSponsor(sponsorId: $sponsorId) {
      success
      error
    }
  }
`;

export const TOGGLE_SPONSOR_ACTIVE_MUTATION = gql`
  mutation toggleSponsorActive($sponsorId: ID!) {
    toggleSponsorActive(sponsorId: $sponsorId) {
      success
      error
      sponsor {
        id
        active
      }
    }
  }
`;

export const ORDER_SPONSORS_MUTATION = gql`
  mutation orderSponsors($sponsorOrder: [String!]!) {
    orderSponsors(sponsorOrder: $sponsorOrder) {
      success
      error
    }
  }
`;