import { gql } from "@apollo/client";
import { superfanFields } from "./fragments";

export const RewardFields = gql`
  fragment rewardFields on Reward {
    deletable
    description
    id
    minCents
    name
    status
    superfans {
      ...superfanFields
    }
    unlocked
    unlockedDescription
  }
  ${superfanFields}
`;

export const REWARDS_QUERY = gql`
  query rewards {
    rewards {
      ...rewardFields
    }
  }
  ${RewardFields}
`;
