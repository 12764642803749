import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { createCache, createClient } from 'utils/apollo';
import ThemeProvider from 'providers/ThemeProvider';
import { PublicPageStateProvider } from './context';

import PublicPage from './PublicPage';

import AppStyle from 'styles';
import { CssClasses } from 'styles/CssClasses';

const PublicPageApp = (props) => (
  <ApolloProvider client={createClient(createCache())}>
    <Router history={history}>
      <ThemeProvider>
        <AppStyle />
        <CssClasses />
        <PublicPageStateProvider>
          <PublicPage />
        </PublicPageStateProvider>
      </ThemeProvider>
    </Router>
  </ApolloProvider >
);

export default PublicPageApp;
