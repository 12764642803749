import React, { createContext, useContext, useState, useMemo, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';

import { Modal } from 'atoms';
import Auth from 'components/Auth';

import { ME_QUERY } from 'graphql-api';
import { SIGNUP } from 'consts';
export * from './FanPageContext';

export const PublicPageContext = createContext(undefined);

const AuthModalInner = styled.div`
  padding: 25px 0 20px;
`;

export const PublicPageStateProvider = (props) => {
  // wasLoggedIn means if user was already logged in before me query, so we can be aware show query was invoked at logged in state.
  const [wasLoggedIn, setWasLoggedIn] = useState(null);
  const { data: meData, refetch } = useQuery(ME_QUERY, {
    onCompleted({ me }) {
      if (me) {
        setWasLoggedIn(true);
      }
    },
    onError() {
      setWasLoggedIn(false);
    }
  });
  const me = useMemo(() => meData?.me, [meData]);
  const isLoggedIn = useMemo(() => !!me, [me]);
  const isConfirmed = useMemo(() => {
    return me?.isConfirmed === true
  }, [me]);

  const [authModalType, setAuthModalType] = useState(null);
  const authModalVisible = useMemo(() => !!authModalType, [authModalType]);
  const [showId, setShowId] = useState(null);

  const reFetchMeQuery = useCallback(() => {
    return refetch();
  }, [refetch]);

  const openAuthModal = useCallback(
    (type = SIGNUP, showId = null) => {
      showId !== null && setShowId(showId);
      setAuthModalType(type);
    },
    [setAuthModalType]
  );

  useEffect(() => {
    if (isLoggedIn) {
      setAuthModalType(null);
    }
    return () => {
    }
  }, [isLoggedIn]);

  const onAuthSuccess = useCallback(
    () => {
    },
    [],
  );  

  return (
    <PublicPageContext.Provider
      value={ {
        me,
        isLoggedIn,
        wasLoggedIn,
        isConfirmed,
        openAuthModal,
        authModalVisible,
        setShowId,
        showId,
        reFetchMeQuery
      } }
    >
      { props.children }
      {
        (
          <Modal open={ authModalVisible } onCancel={ () => setAuthModalType(null) }>
            <AuthModalInner>
              <Auth onSuccess={ onAuthSuccess } type={ authModalType } setType={ setAuthModalType } showId={ showId }/>
            </AuthModalInner>
          </Modal>
        )
      }
    </PublicPageContext.Provider>
  )
};

export const withPublicPageState = (Component) => {
  return props => {
    const publicPageState = useContext(PublicPageContext);
    return <Component { ...props } publicPageState={ publicPageState }/>
  }
};
