import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from 'atoms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPause } from '@fortawesome/free-solid-svg-icons';
import AudioPlayer from 'libraries/Audio/components/AudioPlayer';

import {
  RecordRow,
  VisualizationContainer,
  RecordVisualization,
  RecordCTAContainer,
  RecordFooter,
  FooterRow,
  FooterSecondaryCol,
  FooterPrimaryCol,
  PlayButton,
  RecordCTAButton,
} from './common';
import { IDLE, INITIAL, PLAYING } from 'libraries/Audio/const';

export const PauseButton = (props) => (
  <RecordCTAButton type="primary" icon={ <FontAwesomeIcon icon={ faPause }/> } { ...props } />
);

const StyledRecordPlayer = styled.div``;

const AudioPlayContainer = styled.div`
  width: 100%;
  padding: 15px;
`;

const RecordPlayer = ({ onSave, src, duration, onCancel, isUploadBusy, isSmallScreen, showFooter = true }) => {

  const [audio, setAudio] = useState(null);
  const [playerStatus, setPlayerStatus] = useState(INITIAL);

  const handleReady = ({ audio: _audio }) => {
    setAudio(_audio);
  };
  const handlePlayClick = () => {
    if (playerStatus !== INITIAL) {
      audio && audio.play();
    }
  };
  const handlePauseClick = () => {
    audio && audio.pause();
  };
  const handleClickCancel = () => {
    audio && audio.pause();
    onCancel();
  }
  return (
    <StyledRecordPlayer>
      <RecordRow>
        <VisualizationContainer>
          <RecordVisualization>
            <AudioPlayContainer>
              <AudioPlayer
                src={ src }
                duration={ duration }
                onReady={ handleReady }
                onChangeStatus={ status => setPlayerStatus(status) }
              />
            </AudioPlayContainer>
          </RecordVisualization>
        </VisualizationContainer>
        <RecordCTAContainer>
          {
            playerStatus !== PLAYING && (
              <PlayButton onClick={ () => handlePlayClick() }/>
            )
          }
          {
            playerStatus === PLAYING && (
              <PauseButton onClick={ () => handlePauseClick() }/>
            )
          }
        </RecordCTAContainer>
      </RecordRow>
      { showFooter && (
        <RecordFooter>
          <FooterRow>
            <FooterSecondaryCol>
              <Button type={ 'default' } block onClick={ () => handleClickCancel() }>CANCEL</Button>
            </FooterSecondaryCol>
            <FooterPrimaryCol>
              {
                !isUploadBusy && (
                  <Button onClick={ () => onSave() } block>{ isSmallScreen ? `SAVE` : `SAVE RECORDING` }</Button>
                )
              }
              {
                isUploadBusy && (
                  <Button block loading={ true } type={ 'green' }>UPLOADING</Button>
                )
              }
            </FooterPrimaryCol>
          </FooterRow>
        </RecordFooter>
      ) }
    </StyledRecordPlayer>
  );
};

export default RecordPlayer;
