import React from 'react';
import styled from "styled-components";
import { Drawer } from "antd";

export const afterDrawerClose = (callback) => {
  setTimeout(() => {
    callback();
  }, 400);
}

export const DrawerInner = styled.div`
  padding: 20px 24px;
  border-bottom: 1px solid var(--light-grey);

  :last-child {
    border-bottom: none;
  }

  ${props => props.css}
`;

export const DrawerOuter = styled.div`
  margin: -24px;
`;

const PodDrawer = styled(({ simple, overflowHidden, ...props }) => <Drawer {...props} />)`
  padding: 0;
  
  .ant-drawer-content-wrapper {
    max-width: 100%;

    .ant-drawer-content {
      .ant-drawer-wrapper-body {
        .ant-drawer-header {

        }

        .ant-drawer-body {
          ${props => props.simple && `
            padding: 0;
          `}
          ${props => props.overflowHidden && `
            overflow: hidden;
          `}
        }
      }
    }
  }
`;

export default PodDrawer;
